import React from 'react';

const Diamond1 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M20.7196 74.28L25.8396 100.6L15.5996 47.96L20.7196 74.28L15.5996 47.96L22.1196 0L67.7996 17L124.32 84.36L117.92 112.76L97.4396 139.92L48.0396 131.68L25.8396 100.64L20.7196 74.28Z" fill="#17F1D8" />
			<path d="M55.9199 116.278L97.5999 90.0781L97.4399 139.838L55.9199 116.278Z" fill="#15C8D1" />
			<path d="M22.1602 0L24.4002 34.4L57.8402 19.44L22.1602 0Z" fill="#3CF7DC" />
			<path d="M97.6004 90.0814L57.8804 19.4414L24.4004 34.3614L41.2804 60.5614L97.6004 90.0814Z" fill="#18E9DA" />
			<path d="M97.6009 90.08L124.361 84.32L67.8409 17L57.8809 19.44L97.6009 90.08Z" fill="#3CF7DC" />
			<path d="M25.8804 100.639L48.0804 131.679L55.9604 116.279L41.2804 60.5594L24.4004 34.3594L25.8804 100.639Z" fill="#3CF7DC" />
			<path d="M97.4801 139.881L55.9601 116.281L48.0801 131.681L97.4801 139.881Z" fill="#18E9DA" />
			<path d="M57.8801 19.44L22.1602 0L67.8401 17L57.8801 19.44Z" fill="#4DFFE1" />
			<path d="M117.96 112.721L97.4805 139.881L97.6005 90.1211L117.96 112.721Z" fill="#17D7D8" />
			<path d="M22.1196 0L24.3996 34.4L25.8396 100.64L15.5996 47.96L22.1196 0Z" fill="#A4FFEE" />
			<path d="M41.2793 60.5586L64.8793 89.0386L55.8793 116.279L41.2793 60.5586Z" fill="#18E9DA" />
			<path d="M97.5996 90.0803L117.96 112.72L124.36 84.3203L97.5996 90.0803Z" fill="#18E9DA" />
			<path d="M97.6003 90.0786L64.9203 89.0386L41.3203 60.5586L97.6003 90.0786Z" fill="#4DFFE1" />
			<path d="M97.5999 90.0791L64.9199 89.0391L55.9199 116.279L97.5999 90.0791Z" fill="#17F1D8" />
		</svg>
	);
};

export default Diamond1;
