import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { fromEvent, tap } from 'rxjs';

import CheckIcon from 'shared/designTokens/icons/ui/tiny/CheckIcon';
import CloseIcon from 'shared/designTokens/icons/ui/tiny/CloseIcon';

import Card from 'app/src/components/ui/Card';
import { motionSpeed02, motionOut } from 'shared/vars';
import _ from 'shared/copy';

const ListItemCard = styled(Card)`
	min-width: 100%;
	padding: 4px;
	position: absolute;
	top: 88px;
	z-index: 1;
	max-height: 252px;
	border: 4px solid ${props => props.theme.pureWhite}; ${''/* Border-based padding for nicer overflow effect */}
	overflow: auto;
`;

const StyledCheckIcon = styled(CheckIcon)`
	display: none;

	[fill] {
		fill: ${props => props.theme.pureWhite};
	}
`;

const CheckBox = styled.div`
	position: relative;
	width: 16px;
	height: 16px;
	background: ${props => props.theme.pureWhite};
	border: 3px solid ${props => props.theme.grey5};
	box-sizing: border-box;
	border-radius: 4px;
	margin-right: 8px;

	${props => props.checked && `
	background: ${props.theme.pokiBlue};
	border: transparent;

	${StyledCheckIcon} {
		display: block;
	}
	`}
`;

const ListItem = styled.div`
	display: flex;
	font-size: 16px;
	line-height: 16px;
	height: 32px;
	border-radius: 4px;
	padding: 8px 12px;
	cursor: pointer;

	&:hover {
		background: ${props => props.theme.grey7};
	}
`;

const SelectedItems = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-height: 170px;
	overflow: auto;
`;

const SelectedItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 12px;
	background: ${props => props.theme.grey7};
	border-radius: 50px;
	color: ${props => props.theme.grey1};
	font-size: 14px;
	line-height: 24px;
	margin-right: 4px;
	whitespace: nowrap;
	margin-bottom: 4px;
`;

const StyledCloseIcon = styled(CloseIcon)`
	cursor: pointer;
	margin-left: 8px;

	path {
		fill: ${props => props.theme.grey5};
	}

	&:hover {
		path {
			fill: ${props => props.theme.rose1};
		}
	}
`;

const StyledInput = styled.input`
	display: block;
	padding: 0 16px;
	line-height: 48px;
	height: 48px;
	width: 100%;
	background: ${props => props.theme.grey7};
	color: ${props => props.theme.denimBlue};
	font-size: 16px;
	border: 1px solid ${props => props.theme.grey5};
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	border-radius: 8px;

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	${props => props.focus && `
	outline: none;
	border-color: ${props.theme.pokiBlue};
	box-shadow: inset 0 0 0 2px ${props.theme.pokiBlue};
	background: ${props.theme.grey9};
	`}

	${props => (props.disabled ? `
	color: ${props.theme.grey3};
	cursor: not-allowed;
	border: none;
	padding: 0 17px;
	` : `
	&:hover {
		background: ${props.theme.grey9};
	}
	`)}

	${props => props.error && `
	border-color: ${props.theme.rose1};
	background: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}

	${props => props.valid && `
	border-color: ${props.theme.green1};
	background: ${props.theme.green9};
	`}
`;

const InputContainer = styled.div`
	position: relative;
	margin-bottom: 8px;
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${StyledInput} {
		margin-top: 4px;
	}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;

	${props => props.shortDesc && `
	min-height: 18px;
	text-align: right;
	margin-top: 4px;
	`}
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const AutoCompleteInput = props => {
	const { autoFocus, className, name, errors = [], description, descriptionHTML, disabled, label, shortDesc, onBlur, valid, values, value, valueSetter, required } = props;

	const [query, setQuery] = useState('');
	const inputRef = useRef();
	const containerRef = useRef();
	const [active, setActive] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		if (value) {
			setSelectedItems(values.filter(v => value.indexOf(v.value) > -1));
		}
	}, [values.length]); // Values.length is the cheapest check whether we go from no values to having values

	const handleChange = evt => {
		setQuery(evt.target.value);
	};

	const handleFocus = () => {
		setActive(true);
	};

	// Exit on clicking outside
	useEffect(() => {
		if (!active || !containerRef.current) return;

		const click$ = fromEvent(document.body, 'click').pipe(
			tap(e => {
				if (!containerRef.current.contains(e.target)) {
					setActive(false);
				}
			}),
		);

		const subscription = click$.subscribe();

		return () => {
			if (subscription) subscription.unsubscribe();
		};
	}, [active, containerRef]);

	const createHandleListItemClick = item => () => {
		const newSelected = [...selectedItems];

		const current = newSelected.findIndex(check => item.value === check.value);
		if (current > -1) {
			newSelected.splice(current, 1);
		} else {
			newSelected.unshift(item);
		}

		setSelectedItems(newSelected);
		valueSetter(newSelected.map(i => i.value));

		inputRef.current.focus();
	};

	const createHandleSelectedItemClick = item => () => {
		const newSelected = [...selectedItems];

		const current = newSelected.findIndex(check => item.value === check.value);
		if (current > -1) {
			newSelected.splice(current, 1);
			setSelectedItems(newSelected);
			valueSetter(newSelected.map(i => i.value));
		}
	};

	const input = (
		<InputContainer>
			<StyledInput
				ref={inputRef}
				id={name}
				type="text"
				autoFocus={!!autoFocus}
				name={name}
				disabled={disabled}
				placeholder={_`xItemsSelected${{ amount: selectedItems.length }}`}
				value={active ? query : ''}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={onBlur}
				error={errors.length > 0}
				valid={valid}
				focus={active}
			/>
		</InputContainer>
	);

	const filteredValues = values.filter(val => !query || val.desc.toLowerCase().includes(query.toLowerCase()));

	return (
		<StyledFieldset className={className} ref={containerRef}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0} valid={valid}>{label}{input}</StyledLabel>
			) : input}
			{selectedItems.length > 0 && (
				<SelectedItems>
					{selectedItems.map(item => (
						<SelectedItem
							key={`${name}-selected-item-${item.value}`}
						>
							{item.desc}
							<StyledCloseIcon onClick={createHandleSelectedItemClick(item)} />
						</SelectedItem>
					))}
				</SelectedItems>
			)}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					descriptionHTML ? (
						<Description shortDesc={shortDesc} dangerouslySetInnerHTML={{ __html: description }} />
					) : (
						<Description shortDesc={shortDesc}>{description}</Description>
					)
				)
			)}
			{active && (
				<ListItemCard noPadding elevated>
					{filteredValues.length > 0 ? (
						filteredValues.map(val => (
							<ListItem
								key={`${name}-list-item-${val.value}`}
								onClick={createHandleListItemClick(val)}
							>
								<CheckBox checked={selectedItems.find(check => check.value === val.value)}>
									<StyledCheckIcon />
								</CheckBox>
								{val.desc}
							</ListItem>
						))
					) : _`noMatches`}
				</ListItemCard>
			)}
		</StyledFieldset>
	);
};

export default AutoCompleteInput;
