import React from 'react';

const Star2 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path fillRule="evenodd" clipRule="evenodd" d="M85.3596 14.8008L52.7596 49.6808L23.1196 60.5608L33.7196 72.0808L17.5996 88.7208L38.2796 93.1208L41.0796 124.761L79.8796 93.3208L93.5596 100.921L89.1196 85.9208L122.56 60.0008L98.3596 57.0808L99.7996 38.9608L84.3996 42.3208L85.3596 14.8008Z" fill="#FFD100" />
			<path d="M98.3199 57.0809L99.7599 38.9609L84.0799 50.3209L83.9199 55.3609L98.3199 57.0809Z" fill="#FF9E00" />
			<path d="M84.4001 42.3209L99.7601 38.9609L84.0801 50.3209L84.4001 42.3209Z" fill="#FFAF00" />
			<path d="M38.2796 93.1601L17.5996 88.7201L37.1996 81.1602L38.2796 93.1601Z" fill="#FFAF00" />
			<path d="M33.7196 72.0781L17.5996 88.7181L37.1996 81.1581L36.6796 75.2781L33.7196 72.0781Z" fill="#FFC500" />
			<path d="M79.8809 93.3609L93.5609 100.961L89.1209 85.9609L86.7209 87.9209L79.8809 93.3609Z" fill="#FFAF00" />
			<path d="M83.5605 66.1594L122.521 60.0394L83.9206 55.3594L83.5605 66.1594Z" fill="#FFED85" />
			<path d="M41.0801 124.8L79.8801 93.3602L86.7201 87.8802L47.6801 87.1602L41.0801 124.8Z" fill="#FFC500" />
			<path d="M83.1597 78L47.6797 87.16L86.7197 87.88L83.1597 78Z" fill="#FFDC00" />
			<path d="M64.7997 69.0391L83.1597 77.9991L47.6797 87.1591L64.7997 69.0391Z" fill="#FFC500" />
			<path d="M47.6401 87.1586L23.0801 60.5586L64.8001 69.0386L47.6401 87.1586Z" fill="#FFAF00" />
			<path d="M41.0787 124.801L36.7188 75.2812L47.6787 87.1613L41.0787 124.801Z" fill="#FFE200" />
			<path d="M64.8008 69.0408L83.1608 78.0008L85.4008 14.8008L64.8008 69.0408Z" fill="#FFE200" />
		</svg>
	);
};

export default Star2;
