import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import Star1 from 'shared/components/svg/symbols/Star1';
import Star2 from 'shared/components/svg/symbols/Star2';
import Star3 from 'shared/components/svg/symbols/Star3';
import Heart1 from 'shared/components/svg/symbols/Heart1';
import Heart2 from 'shared/components/svg/symbols/Heart2';
import Heart3 from 'shared/components/svg/symbols/Heart3';
import Block3 from 'shared/components/svg/symbols/Block3';
import Block1 from 'shared/components/svg/symbols/Block1';
import Block2 from 'shared/components/svg/symbols/Block2';
import Diamond1 from 'shared/components/svg/symbols/Diamond1';
import Diamond2 from 'shared/components/svg/symbols/Diamond2';
import Diamond3 from 'shared/components/svg/symbols/Diamond3';
import Coin3 from 'shared/components/svg/symbols/Coin3';
import Coin1 from 'shared/components/svg/symbols/Coin1';
import Coin2 from 'shared/components/svg/symbols/Coin2';

const glitchGapHorizontal = 200;
const glitchGapVertical = 80;
const glitchDuration = 6000;
const glitchDelay = 4000;

const imgPurple = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2N4deDPfwAI5AOmTrpLmwAAAABJRU5ErkJggg==';
const imgYellow = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2P49TryPwAIXwM+qATseAAAAABJRU5ErkJggg==';
const imgGreen = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2No/v/8PwAH2wNpwtOvHwAAAABJRU5ErkJggg==';
const imgPink = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2P4f+jafwAI8gOXkue/IAAAAABJRU5ErkJggg==';

const bgs = [
	{
		key: 'diamonds',
		color: '#83ffe7',
		glitchColors: [imgGreen, imgPurple],
		layers: [
			// Front layer
			[
				{
					component: Diamond1,
					blur: 20,
					transform: 'translateX(-50%) scaleX(-1)',
					top: '12.5%',
					left: '0',
					width: '32.5%',
				},
				{
					component: Diamond1,
					blur: 20,
					top: '27.5%',
					bottom: 'auto',
					right: '0',
					width: '35%',
					transform: 'translateX(50%)',
				},
			],
			// Middle layer
			[
				{
					component: Diamond2,
					bottom: '8%',
					right: '25%',
					width: '14%',
				},
				{
					component: Diamond1,
					top: '17.5%',
					left: '15%',
					width: '14.5%',
				},
				{
					component: Diamond3,
					top: '17.5%',
					right: '15%',
					width: '16%',
				},
			],
			// Background layer
			[
				{
					component: Diamond2,
					blur: 15,
					bottom: '16%',
					left: '48%',
					width: '12.5%',
				},
				{
					component: Diamond2,
					blur: 10,
					top: '15%',
					right: '33%',
					width: '8.125%',
				},
				{
					component: Diamond1,
					blur: 10,
					top: '0',
					left: '23%',
					width: '15%',
				},
				{
					component: Diamond2,
					blur: 5,
					top: 'auto',
					bottom: '2%',
					right: 'auto',
					left: '15%',
					width: '19.25%',
				},
				{
					component: Diamond2,
					blur: 5,
					top: '21%',
					right: '40%',
					width: '12.5%',
				},
			],
		],
	},
	{
		key: 'blocks',
		color: '#eac0fc',
		glitchColors: [imgPurple, imgGreen],
		layers: [
			// Front layer
			[
				{
					component: Block1,
					blur: 20,
					transform: 'translateX(-50%) scaleX(-1)',
					top: '12.5%',
					left: '0',
					width: '32.5%',
				},
				{
					component: Block1,
					blur: 20,
					top: '27.5%',
					bottom: 'auto',
					right: '0',
					width: '35%',
					transform: 'translateX(50%)',
				},
			],
			// Middle layer
			[
				{
					component: Block2,
					bottom: '8%',
					right: '25%',
					width: '14%',
				},
				{
					component: Block1,
					top: '17.5%',
					left: '15%',
					width: '14.5%',
				},
				{
					component: Block3,
					top: '17.5%',
					right: '15%',
					width: '16%',
				},
			],
			// Background layer
			[
				{
					component: Block3,
					blur: 15,
					bottom: '16%',
					left: '48%',
					width: '12.5%',
				},
				{
					component: Block1,
					blur: 10,
					top: '15%',
					right: '33%',
					width: '8.125%',
				},
				{
					component: Block1,
					blur: 10,
					top: '0',
					left: '23%',
					width: '15%',
				},
				{
					component: Block2,
					blur: 5,
					top: 'auto',
					bottom: '2%',
					right: 'auto',
					left: '15%',
					width: '19.25%',
				},
				{
					component: Block2,
					blur: 5,
					top: '21%',
					right: '40%',
					width: '12.5%',
				},
			],
		],
	},
	{
		key: 'stars',
		color: '#faeb59',
		glitchColors: [imgYellow, imgPink],
		layers: [
			// Front layer
			[
				{
					component: Star1,
					blur: 20,
					transform: 'translateX(-50%) scaleX(-1)',
					top: '12.5%',
					left: '0',
					width: '32.5%',
				},
				{
					component: Star1,
					blur: 20,
					top: '27.5%',
					bottom: 'auto',
					right: '0',
					width: '35%',
					transform: 'translateX(50%)',
				},
			],
			// Middle layer
			[
				{
					component: Star2,
					bottom: '8%',
					right: '25%',
					width: '14%',
				},
				{
					component: Star1,
					top: '17.5%',
					left: '15%',
					width: '14.5%',
				},
				{
					component: Star3,
					top: '17.5%',
					right: '15%',
					width: '16%',
				},
			],
			// Background layer
			[
				{
					component: Star3,
					blur: 15,
					bottom: '16%',
					left: '48%',
					width: '12.5%',
				},
				{
					component: Star1,
					blur: 10,
					top: '15%',
					right: '33%',
					width: '8.125%',
				},
				{
					component: Star1,
					blur: 10,
					top: '0',
					left: '23%',
					width: '15%',
				},
				{
					component: Star2,
					blur: 5,
					top: 'auto',
					bottom: '2%',
					right: 'auto',
					left: '15%',
					width: '19.25%',
				},
				{
					component: Star2,
					blur: 5,
					top: '21%',
					right: '40%',
					width: '12.5%',
				},
			],
		],
	},
	{
		key: 'hearts',
		color: '#ffc2d6',
		glitchColors: [imgPink, imgGreen],
		layers: [
			// Front layer
			[
				{
					component: Heart1,
					blur: 20,
					transform: 'translateX(-50%) scaleX(-1)',
					top: '12.5%',
					left: '0',
					width: '32.5%',
				},
				{
					component: Heart1,
					blur: 20,
					top: '27.5%',
					bottom: 'auto',
					right: '0',
					width: '35%',
					transform: 'translateX(50%)',
				},
			],
			// Middle layer
			[
				{
					component: Heart2,
					bottom: '8%',
					right: '25%',
					width: '14%',
				},
				{
					component: Heart1,
					top: '17.5%',
					left: '15%',
					width: '14.5%',
				},
				{
					component: Heart3,
					top: '17.5%',
					right: '15%',
					width: '16%',
				},
			],
			// Background layer
			[
				{
					component: Heart3,
					blur: 15,
					bottom: '16%',
					left: '48%',
					width: '12.5%',
				},
				{
					component: Heart1,
					blur: 10,
					top: '15%',
					right: '33%',
					width: '8.125%',
				},
				{
					component: Heart1,
					blur: 10,
					top: '0',
					left: '23%',
					width: '15%',
				},
				{
					component: Heart2,
					blur: 5,
					top: 'auto',
					bottom: '2%',
					right: 'auto',
					left: '15%',
					width: '19.25%',
				},
				{
					component: Heart2,
					blur: 5,
					top: '21%',
					right: '40%',
					width: '12.5%',
				},
			],
		],
	},
	{
		key: 'coins',
		color: '#faeb59',
		glitchColors: [imgYellow, imgPink],
		layers: [
			// Front layer
			[
				{
					component: Coin1,
					blur: 20,
					transform: 'translateX(-50%) scaleX(-1)',
					top: '12.5%',
					left: '0',
					width: '32.5%',
				},
				{
					component: Coin1,
					blur: 20,
					top: '27.5%',
					bottom: 'auto',
					right: '0',
					width: '35%',
					transform: 'translateX(50%)',
				},
			],
			// Middle layer
			[
				{
					component: Coin2,
					bottom: '8%',
					right: '25%',
					width: '14%',
				},
				{
					component: Coin1,
					top: '17.5%',
					left: '15%',
					width: '14.5%',
				},
				{
					component: Coin3,
					top: '17.5%',
					right: '15%',
					width: '16%',
				},
			],
			// Background layer
			[
				{
					component: Coin3,
					blur: 15,
					bottom: '16%',
					left: '48%',
					width: '12.5%',
				},
				{
					component: Coin1,
					blur: 10,
					top: '15%',
					right: '33%',
					width: '8.125%',
				},
				{
					component: Coin1,
					blur: 10,
					top: '0',
					left: '23%',
					width: '15%',
				},
				{
					component: Coin2,
					blur: 5,
					top: 'auto',
					bottom: '2%',
					right: 'auto',
					left: '15%',
					width: '19.25%',
				},
				{
					component: Coin2,
					blur: 5,
					top: '21%',
					right: '40%',
					width: '12.5%',
				},
			],
		],
	},
];

const Container = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
`;

const Layer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
`;

const backgroundAnimation1 = keyframes`
	0% {
		transform: translate3d(0, 0, 0) scale(1) rotate(0);
	}

	100% {
		transform: translate3d(10px, 25px, 0) scale(1.25) rotate(15deg);
	}
`;

const backgroundAnimation2 = keyframes`
	0% {
		transform: translate3d(0, 0, 0) scale(1) rotate(0);
	}

	100% {
		transform: translate3d(-8px, 45px, 0) scale(.9) rotate(-10deg);
	}
`;

const backgroundAnimation3 = keyframes`
	0% {
		transform: translate3d(0, 0, 0) scale(1) rotate(0);
	}

	100% {
		transform: translate3d(2px, 18px, 0) scale(1.2) rotate(13deg);
	}
`;

const backgroundAnimation4 = keyframes`
	0% {
		transform: translate3d(0, 0, 0) scale(1) rotate(0);
	}

	100% {
		transform: translate3d(17px, 5px, 0) scale(1.3) rotate(-10deg);
	}
`;

const backgroundAnimation5 = keyframes`
	0% {
		transform: translate3d(0, 0, 0) scale(1) rotate(0);
	}

	100% {
		transform: translate3d(4px, -8px, 0) scale(.65) rotate(23deg);
	}
`;

const animateIn = keyframes`
	0% {
		opacity: 0;
		transform: scale3d(0.8, 0.8, 1);
	}

	70% {
		transform: scale3d(1.03, 1.03, 1);
	}

	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
`;

const ElementContainer = styled.div`
	position: absolute;
	animation: ${backgroundAnimation1} 10s infinite linear alternate-reverse;

	img {
		width: 100%;
		color: rgba(0, 0, 0, 0); // hide alt text
	}

	&:nth-child(2n) {
		animation-name: ${backgroundAnimation2};
	}

	&:nth-child(3n) {
		animation-name: ${backgroundAnimation3};
	}

	&:nth-child(4n) {
		animation-name: ${backgroundAnimation4};
	}

	&:nth-child(5n) {
		animation-name: ${backgroundAnimation5};
	}
`;

const ElementAnimator = styled.div`
	position: relative;
	animation: ${animateIn} 0.5s both ease-out;
`;

const Element = ({ top, left, right, bottom, width, transform, component: Component, blur }) => (
	<ElementContainer style={{ top, left, right, bottom, width }}>
		<ElementAnimator>
			<Component
				style={{
					transform,
					filter: blur ? `blur(${blur}px)` : '',
				}}
			/>
		</ElementAnimator>
	</ElementContainer>
);

const glitchAnim1 = keyframes`
	0% {
		opacity: 1;
		transform: translate3d(${glitchGapHorizontal}px,0,0);
		clip-path: polygon(0 2%,100% 2%,100% 5%,0 5%)
	}

	2% {
		clip-path: polygon(0 15%,100% 15%,100% 15%,0 15%)
	}

	4% {
		clip-path: polygon(0 10%,100% 10%,100% 20%,0 20%)
	}

	6% {
		clip-path: polygon(0 1%,100% 1%,100% 2%,0 2%)
	}

	8% {
		clip-path: polygon(0 33%,100% 33%,100% 33%,0 33%)
	}

	10% {
		clip-path: polygon(0 44%,100% 44%,100% 44%,0 44%)
	}

	12% {
		clip-path: polygon(0 50%,100% 50%,100% 20%,0 20%)
	}

	14% {
		clip-path: polygon(0 70%,100% 70%,100% 70%,0 70%)
	}

	16% {
		clip-path: polygon(0 80%,100% 80%,100% 80%,0 80%)
	}

	18% {
		clip-path: polygon(0 50%,100% 50%,100% 55%,0 55%)
	}

	20% {
		clip-path: polygon(0 10%,100% 10%,100% 80%,0 80%)
	}

	21.9% {
		opacity: 1;
		transform: translate3d(${glitchGapHorizontal}px,0,0);
		clip-path: polygon(0 60%,100% 60%,100% 50%,0 50%)
	}

	100%,22% {
		opacity: 0;
		transform: translate3d(0,0,0)
	}
`;

const glitchAnim2 = keyframes`
	0% {
		opacity: 1;
		transform: translate3d(${-glitchGapHorizontal}px,0,0);
		clip-path: polygon(0 25%,100% 25%,100% 30%,0 30%)
	}

	3% {
		clip-path: polygon(0 3%,100% 3%,100% 3%,0 3%)
	}

	5% {
		clip-path: polygon(0 5%,100% 5%,100% 20%,0 20%)
	}

	7% {
		clip-path: polygon(0 20%,100% 20%,100% 20%,0 20%)
	}

	9% {
		clip-path: polygon(0 40%,100% 40%,100% 40%,0 40%)
	}

	11% {
		clip-path: polygon(0 52%,100% 52%,100% 59%,0 59%)
	}

	13% {
		clip-path: polygon(0 60%,100% 60%,100% 60%,0 60%)
	}

	15% {
		clip-path: polygon(0 75%,100% 75%,100% 75%,0 75%)
	}

	17% {
		clip-path: polygon(0 65%,100% 65%,100% 40%,0 40%)
	}

	19% {
		clip-path: polygon(0 45%,100% 45%,100% 50%,0 50%)
	}

	20% {
		clip-path: polygon(0 14%,100% 14%,100% 33%,0 33%)
	}

	21.9% {
		opacity: 1;
		transform: translate3d(${-glitchGapHorizontal}px,0,0)
	}

	100%,22% {
		opacity: 0;
		transform: translate3d(0,0,0)
	}
`;

const glitchAnim3 = keyframes`
	0% {
		opacity: 1;
		transform: translate3d(0, ${-glitchGapVertical}px, 0) scale3d(-1,-1,1);
		clip-path: polygon(0 1%,100% 1%,100% 53%,0 53%)
	}

	1.5% {
		clip-path: polygon(0 10%,100% 10%,100% 9%,0 9%)
	}

	2% {
		clip-path: polygon(0 5%,100% 5%,100% 26%,0 26%)
	}

	2.5% {
		clip-path: polygon(0 20%,100% 20%,100% 20%,0 20%)
	}

	3% {
		clip-path: polygon(0 10%,100% 10%,100% 10%,0 10%)
	}

	5% {
		clip-path: polygon(0 30%,100% 30%,100% 25%,0 25%)
	}

	5.5% {
		clip-path: polygon(0 20%,100% 20%,100% 50%,0 50%)
	}

	7.9% {
		clip-path: polygon(0 50%,100% 50%,100% 80%,0 80%)
	}

	8% {
		clip-path: polygon(0 20%,100% 20%,100% 21%,0 21%)
	}

	9% {
		clip-path: polygon(0 60%,100% 60%,100% 55%,0 55%)
	}

	10.5% {
		clip-path: polygon(0 30%,100% 30%,100% 31%,0 31%)
	}

	11% {
		clip-path: polygon(0 70%,100% 70%,100% 69%,0 69%)
	}

	13% {
		clip-path: polygon(0 40%,100% 40%,100% 41%,0 41%)
	}

	14% {
		clip-path: polygon(0 80%,100% 80%,100% 75%,0 75%)
	}

	14.5% {
		clip-path: polygon(0 50%,100% 50%,100% 51%,0 51%)
	}

	15% {
		clip-path: polygon(0 90%,100% 90%,100% 90%,0 90%)
	}

	16% {
		clip-path: polygon(0 60%,100% 60%,100% 60%,0 60%)
	}

	18% {
		clip-path: polygon(0 100%,100% 100%,100% 99%,0 99%)
	}

	20% {
		clip-path: polygon(0 70%,100% 70%,100% 71%,0 71%)
	}

	21.9% {
		opacity: 1;
		transform: translate3d(0, ${-glitchGapVertical}px, 0) scale3d(-1,-1,1)
	}

	22%,62% {
		opacity: 0;
		transform: translate3d(0,0,0)
	}

	62.5% {
		opacity: 1;
		clip-path: polygon(0 80%,100% 80%,100% 31%,0 31%)
	}

	64% {
		clip-path: polygon(0 50%,100% 50%,100% 40%,0 40%)
	}

	65% {
		opacity: 1;
		clip-path: polygon(0 70%,100% 70%,100% 71%,0 71%)
	}

	100%, 66% {
		opacity: 0
	}
`;

const glitchAnimFlash = keyframes`
	0%, 5% {
		opacity: .2;
		transform: translate3d(${glitchGapHorizontal}px, ${glitchGapVertical}px, 0);
	}

	100%,5.5% {
		opacity: 0;
		transform: translate3d(0,0,0);
	}
`;

const float = keyframes`
	0% {
		transform: translateY(0)
	}

	50% {
		transform: translateY(-25px)
	}

	100% {
		transform: translateY(0)
	}
`;

const GlitchImages = styled.div`
	position: absolute;
	width: calc(100% + 40px);
	height: calc(100% + 40px);
	top: 0;
	left: 0;
	will-change: transform;
	animation: ${float} 8s linear infinite;
`;

const GlitchImage = styled.div`
	position: absolute;
	top: ${-glitchGapVertical}px;
	left: ${-glitchGapHorizontal}px;
	width: calc(100% + ${2 * glitchGapHorizontal}px);
	height: calc(100% + ${2 * glitchGapVertical}px);
	animation-duration: ${glitchDuration}ms;
	animation-delay: ${glitchDelay}ms;
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	&:nth-child(2) {
		animation-name: ${glitchAnim1};
	}

	&:nth-child(3) {
		animation-name: ${glitchAnim2};
	}

	&:nth-child(4) {
		animation-name: ${glitchAnim3};
	}

	&:nth-child(5) {
		animation-name: ${glitchAnim3};
	}

	&:nth-child(6) {
		animation-name: ${glitchAnimFlash};
	}
`;

const GlitchWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity .5s linear .3s;
	transition-delay: ${glitchDelay}ms;

	${props => props.visible && `
	opacity: 1;
	`}

	${GlitchImage} {
		background-image: url(${props => props.bgColor});

		&:nth-child(4), &:nth-child(5) {
			background-image: url(${props => props.glitchColor});
		}
	}
`;

const BGGlitch = ({ colors }) => {
	const [dimensions, setDimensions] = useState();
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setDimensions([window.innerWidth, window.innerHeight]);
		};
		window.addEventListener('resize', handleResize);

		handleResize();

		window.setTimeout(() => {
			setVisible(true);
		}, 0);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	if (!dimensions) return null;

	return (
		<GlitchWrapper
			visible={visible}
			style={{
				width: dimensions[0],
				height: dimensions[1],
			}}
			bgColors={colors[0]}
			glitchColor={colors[1]}
		>
			<GlitchImages>
				<GlitchImage />
				<GlitchImage />
				<GlitchImage />
				<GlitchImage />
				<GlitchImage />
				<GlitchImage />
			</GlitchImages>
		</GlitchWrapper>
	);
};

const FullPageSymbolBackground = ({ symbol, glitch = false }) => {
	let [bg] = useState(bgs[Math.floor(Math.random() * bgs.length)]);

	if (symbol) {
		const forcedBg = bgs.find(b => b.key === symbol);
		if (forcedBg) {
			bg = forcedBg;
		}
	}

	return (
		<Container
			style={{
				backgroundColor: bg.color,
			}}
		>
			{glitch && <BGGlitch colors={bg.glitchColors} />}
			{bg.layers.map((layer, idx) => (
				<Layer key={`bg-layer-${idx}`}> {/* eslint-disable-line react/no-array-index-key */}
					{layer.map((item, itemIdx) => (
						<Element
							key={`bg-item-${itemIdx}`} /* eslint-disable-line react/no-array-index-key */
							{...item}
						/>
					))}
				</Layer>
			))}
		</Container>
	);
};

export default FullPageSymbolBackground;
