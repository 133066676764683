const prefixes = ['angry', 'amazing', 'ultimate', 'mysterious', 'obscure', 'fancy', 'funky', 'crossy', 'tap', 'clicker', 'skiing', 'falling', 'super', 'boost', 'shell', 'sling', 'rodeo', 'crazy', 'rooftop', 'bomber', 'pointy', 'rolly', 'rolling', 'double', 'virtual', 'original', 'total', 'triple', 'happy', 'steppy', 'jelly', 'magic', 'idle', 'kick the', 'break the', 'merge', 'interactive', 'my', 'talking', 'audrey\'s', 'baby\'s first', 'extreme', 'grippy', 'loopy', 'hidden', 'smart', 'pixel', 'real', 'random', 'massive', 'charming', 'epic', 'fruity', 'sweet', 'mini', 'giant', 'tiny', 'bubbly', 'save the', 'little'];
const subjects = ['android', 'boom', 'beach', 'beef', 'station', 'wolf', 'glass', 'banana', 'train', 'road', 'snout', 'ham', 'bob', 'kong', 'town', 'subway', 'iron', 'slime', 'dating', 'king', 'knight', 'survival', 'princess', 'doctor', 'hair', 'zoi', 'booze', 'piano', 'library', 'party', 'rogue', 'chicken', 'horse', 'penguin', 'sloth', 'geometry', 'burger', 'cooking', 'factory', 'town', 'cave', 'pants', 'judge', 'shoe', 'salon', 'money', 'candy', 'paint', 'stickman', 'master', 'cookie', 'story', 'helix', 'piñata', 'dragon', 'ball', 'brawl', 'picnic', 'bottle', 'tower', 'soda', 'bullet', 'hero', 'sky', 'yoga', 'guru', 'buddha', 'hacker', 'news', 'space', 'fruit', 'ninja', 'samurai', 'shock', 'lightning', 'prototype', 'cowboy', 'water', 'bubble', 'palace', 'skate', 'coffee', 'freeze', 'ice', 'vanilla', 'summer', 'business', 'warfare', 'elevator', 'guitar', 'trash', 'boiler', 'room', 'plant', 'tree', 'zombie', 'pizza', 'fire', 'marble', 'tunnel', 'god', 'fish', 'snake', 'desk', 'phone'];
const suffixes = ['brothers', 'sisters', 'masters', 'boys', 'girls', 'people', 'heroes', 'odyssey', 'lords', 'tribe', 'combat', '.io', 'stampede', 'royale', 'trouble', 'surfers', '3D', 'clash', 'maker', 'buddies', 'shockers', 'friends', 'snipers', 'simulator', 'kings', 'knights', 'league', 'defense', 'assault', 'attack', 'express', 'tiles', 'roads', 'editor', 'creator', 'kart', 'racing', 'labs', 'hunters', 'party', 'studio', 'dash', 'flipper', 'architect', 'Z', 'X', 'arena', 'blast', 'wheels', 'shoes', 'crush', 'pop', 'craft', 'birds', 'gears', 'creed', 'stars', 'rush', 'flip', 'trivia', 'jump', 'invaders', 'pro', 'adventure', 'punks', 'dress-up', 'mania', 'saga', 'madness', 'cleanup', 'legacy', 'of war', 'wars'];
const versions = ['the finale', '2', 'the prequel', 'the lost chapters', 'conquest', 'the escape', 'definitive edition', 'survival', 'multiplayer', `${new Date().getFullYear()}`, 'heroes', 'judgement', 'origins', 'the game', 'the card game', 'redemption', 'legacies', 'millenium edition'];

const illegalNames = ['shell shockers', 'crossy road', 'subway surfers', 'sling kong', 'iron snout', 'angry birds', 'tap hero', 'helix jump', 'boost buddies', 'skiing fred', 'falling fred', 'piano tiles', 'rolling sky', 'candy crush', 'boom beach', 'rooftop snipers', 'steppy pants', 'double dragon', 'happy wheels', 'tunnel rush', 'god of war'];

const getRandom = array => array[Math.floor(Math.random() * array.length)];

const capitalize = string => string.replace(/(?:^|\s)\S/g, a => a.toUpperCase());

const getRandomTitle = () => {
	let chance = 0.5;
	let usedSuffix = false;

	let subject = '';
	if (Math.random() < 0.8) {
		subject = getRandom(subjects);
	} else {
		usedSuffix = true;
		subject = getRandom(suffixes);
	}

	let prefix = '';
	if (Math.random() < chance || usedSuffix) {
		if (Math.random() < 0.2) {
			prefix = getRandom(subjects);
		} else {
			prefix = getRandom(prefixes);
		}
	} else {
		chance = 1;
	}

	let result = `${prefix} ${subject}`;

	if (Math.random() < chance && !usedSuffix) {
		result = `${result} ${getRandom(suffixes)}`;
		usedSuffix = true;
	}

	if (Math.random() < 1 / 7) {
		result = `${result}: ${getRandom(versions)}`;
	}

	result = result.replace(/ +/, ' ');

	if (result.split(' ').length === 1) { // We never want to end up with a one word name
		if (!usedSuffix) {
			result = `${result} ${getRandom(suffixes)}`;
			usedSuffix = true;
		} else {
			result = `${getRandom(prefixes)} ${result}`;
		}
	}

	return capitalize(result.trim());
};

const getRandomGameTitle = () => {
	let result = '';
	do {
		result = getRandomTitle();
	} while (illegalNames.includes(result.toLowerCase()));

	return result;
};

export default getRandomGameTitle;
