import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
	position: relative;
	width: 36px;
	height: 36px;
	overflow: hidden;
	border-radius: 8px;
	flex-shrink: 0;
	background: ${props => props.theme.grey7};

	::after {
		position: absolute;
		content: '?';
		font-size: 32px;
		font-weight: bold;
		color: ${props => props.theme.pureWhite};
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
`;

const StyledImage = styled.img`
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
`;

const UserImage = props => {
	const { src, className } = props;

	return (
		<ImageContainer className={className}>
			{ src && <StyledImage src={src} /> }
		</ImageContainer>
	);
};

export default UserImage;
