import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ignoreElements, map } from 'rxjs';
import styled, { css } from 'styled-components';

import { getRealTimePlayers } from 'app/src/epics/game';
import { isMobile } from 'shared/vars';
import _ from 'shared/copy';

const UserCounter = styled.div`
	width: fit-content;
	background-color: ${props => props.theme[`${props.colorIndex}7`]};
	position: relative;
	border-radius: 8px;
	margin-left: auto;
	margin-right: 20px;
	padding: 8px;
	z-index: 1;
	height: fit-content;
	animation: ease 0.2s normal forwards 1 fadein;

	@keyframes fadein{
		0% { opacity:0; }
		50% { opacity:0; }
		100% { opacity:1; }
	}

	${isMobile && css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background-color: ${props => props.theme.grey7};
		height: fit-content;
		padding: 16px;
		width: 100%;
		margin: 0;
	`}
`;

const CaseWrapper = styled.div`
	display: flex;
	gap: 4px;
`;

const CounterTitle = styled.h4`
	font-size: 12px;
	line-height: 16px;
	font-weight: 700;
	margin: 0 0 4px;
	color: ${props => props.theme[`${props.colorIndex}1`]};

	${isMobile && css`
		font-size: 14px;
		margin: 0 0 8px;
		color: ${props => props.theme.denimBlue};
	`}
`;

const CounterNumberWrapper = styled.div`
	transform: translate(0, var(--offset, 0px));
	transition: transform 1s ease 1s;
`;

const CounterNumber = styled.div`
	font-size: 36px;
	font-weight: bold;
	width: 40px;
	height: 48px;
	color: ${props => props.theme[`${props.colorIndex}1`]};
	margin: 15px 10px;

	${isMobile && css`
		color: ${props => props.theme.denimBlue};
	`}
`;

const UserCounterCase = styled.div`
	width: 40px;
	height: 48px;
	border-radius: 4px;
	overflow: hidden;
	background-color: #ffffff;

	${isMobile && css`
		background-color: ${props => props.theme.pureWhite};
	`}
`;

const positionOffset = 48;
const marginOffset = 15;
const minimalUpdateInterval = 5 * 1000;
const counterNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'K'];

const getColorCode = colorNumber => {
	switch (colorNumber) {
		case 0:
		case 2:
			return 'yellow';
		case 1:
			return 'green';
		case 3:
			return 'rose';
		case 4:
			return 'purple';
		default:
			return null;
	}
};

const getOffset = num => {
	switch (num) {
		case '0':
			return 0;
		case 'K':
			return (counterNumbers.length - 1) * 63;
		default:
			return (positionOffset * (num * 1)) + marginOffset * (num * 1);
	}
};

const getExtendedCount = (arr, firstRender) => {
	switch (arr?.length) {
		case 1:
			return {
				firstRender,
				count: ['0', '0', '0', arr[0]],
			};
		case 2:
			return {
				firstRender,
				count: ['0', '0', arr[0], arr[1]],
			};
		case 3:
			return {
				firstRender,
				count: ['0', arr[0], arr[1], arr[2]],
			};
		case 4:
			return {
				firstRender,
				count: [arr[0], arr[1], arr[2], arr[3]],
			};
		case 5:
			return {
				firstRender,
				count: [arr[0], arr[1], arr[2], arr[3], arr[4]],
			};
		case 6:
			return {
				firstRender,
				count: ['0', '0', arr[0], arr[1], arr[2], 'K'],
			};
		default:
			return {
				count: ['0'],
			};
	}
};

const RealTimeCounter = ({ game, colorNumber }) => {
	const dispatch = useDispatch();

	const [realTimePlayers, setRealTimePlayers] = useState({ count: ['0', '0', '0', '0'] });
	const timeoutRef = useRef();

	const dispatchGetRealTimePlayers = ({ firstRender }) => {
		if (game) {
			dispatch(getRealTimePlayers.fetch({ gameId: game.id }, ({ success$ }) => (
				success$.pipe(
					map(({ payload: { result: { response } } }) => {
						const numbersArray = Array.from(String(response.metrics[game.id]));

						if (firstRender) {
							const initialNumbersArray = getExtendedCount(numbersArray, firstRender).count.fill('0');

							setRealTimePlayers({ count: initialNumbersArray });
						}

						timeoutRef.current = setTimeout(() => {
							dispatchGetRealTimePlayers({ firstRender: false });

							setRealTimePlayers(getExtendedCount(numbersArray, firstRender));
						}, firstRender ? 500 : Math.max(minimalUpdateInterval, response.retry_in_seconds * 1000));
					}),
					ignoreElements(),
				)
			)));
		}
	};

	useEffect(() => {
		dispatchGetRealTimePlayers({ firstRender: true });

		return () => clearTimeout(timeoutRef.current);
	}, []);

	return (
		<UserCounter length={realTimePlayers?.count?.length} colorIndex={getColorCode(colorNumber)}>
			<CounterTitle colorIndex={getColorCode(colorNumber)}>{_`currentlyPlaying`}</CounterTitle>
			<CaseWrapper>
				{realTimePlayers?.count?.map((r, index) => {
					const offset = getOffset(r);

					return (
						// eslint-disable-next-line react/no-array-index-key
						<UserCounterCase key={index} number={r}>
							<CounterNumberWrapper style={{ '--offset': `-${offset}px` }}>
								{counterNumbers.map(n => <CounterNumber colorIndex={getColorCode(colorNumber)}>{n}</CounterNumber>)}
							</CounterNumberWrapper>
						</UserCounterCase>
					);
				})}
			</CaseWrapper>
		</UserCounter>
	);
};

export default RealTimeCounter;
