import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import Card from 'app/src/components/ui/Card';
import Container from 'app/src/components/ui/Container';
import GridContainer from 'app/src/components/ui/GridContainer';
import InvoicesTable from 'app/src/components/tables/InvoicesTable';
import { listInvoicesForTeam, patchInvoice } from 'app/src/epics/invoice';
import { useSelectInvoicesForTeam } from 'app/src/selectors/invoice';
import _ from 'shared/copy';
import MessageBox from 'app/src/components/ui/MessageBox';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectPermissions } from 'app/src/selectors/user';
import { listMinimumGuaranteesByTeamId, listUpfrontPaymentsByTeamId } from 'app/src/epics/additionalPayments';
import Table from 'app/src/components/ui/Table';
import { useSelectMinimumGuaranteesByTeamId, useSelectUpfrontPaymentsByTeamId } from 'app/src/selectors/additionalPayments';
import { dayMonthYearFormat } from 'shared/vars';
import GamesTooltip from 'app/src/components/GamesTooltip';
import ProgressBar from 'app/src/components/ui/ProgressBar';
import formatNumber from 'app/src/utils/formatNumber';
import PageDivider from 'app/src/components/ui/PageDivider';
import useURLState from 'app/src/hooks/useURLState';
import checkPermissions from 'app/src/utils/checkPermissions';
import useEpic from 'app/src/hooks/useEpic';

const Status = styled.div`
	font-weight: bold;
	color: ${props => props.theme.grey3};

	${props => props.active && `
	color: ${props.theme.green1};
	`}
`;

const upfrontPaymentsPerPage = 5;
const minimumGuaranteesPerPage = 5;
const invoicesPerPage = 10;

const FinancePage = () => {
	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();
	const dispatch = useDispatch();

	const { data: invoices, meta: { total: totalInvoices } = {} } = useSelectInvoicesForTeam(activeTeam.id);
	const { data: upfrontPayments, meta: { total: totalUpfrontPayments } = {} } = useSelectUpfrontPaymentsByTeamId(activeTeam.id);
	const { data: minimumGuarantees, meta: { total: totalMinimumGuarantees } = {} } = useSelectMinimumGuaranteesByTeamId(activeTeam.id);

	const listInvoicesForTeamStatus = useSelectApiStatus(listInvoicesForTeam.id);
	const listUpfrontPaymentsByTeamIdStatus = useSelectApiStatus(listUpfrontPaymentsByTeamId.id);
	const listMinimumGuaranteesByTeamIdStatus = useSelectApiStatus(listMinimumGuaranteesByTeamId.id);

	const [invoicesSort, setInvoicesSort] = useURLState('invoiceSort', { field: 'to', direction: -1 });
	const [invoicesPage, setInvoicesPage] = useURLState('invoicePage', 1);
	const [upfrontPaymentsSort, setUpfrontPaymentsSort] = useURLState('upSort', { field: 'start_date', direction: -1 });
	const [upfrontPaymentsPage, setUpfrontPaymentsPage] = useURLState('upPage', 1);
	const [minimumGuaranteesSort, setMinimumGuaranteesSort] = useURLState('mgSort', { field: 'start_date', direction: -1 });
	const [minimumGuaranteesPage, setMinimumGuaranteesPage] = useURLState('mgPage', 1);

	useEffect(() => {
		dispatch(listInvoicesForTeam.fetch({ teamId: activeTeam.id, sortField: invoicesSort.field, sortDirection: invoicesSort.direction, page: invoicesPage, perPage: invoicesPerPage }));
	}, [invoicesSort, invoicesPage]);

	useEffect(() => {
		dispatch(listUpfrontPaymentsByTeamId.fetch({ teamId: activeTeam.id, sortField: upfrontPaymentsSort.field, sortDirection: upfrontPaymentsSort.direction, page: upfrontPaymentsPage, perPage: upfrontPaymentsPerPage }));
	}, [upfrontPaymentsSort, upfrontPaymentsPage]);

	useEffect(() => {
		dispatch(listMinimumGuaranteesByTeamId.fetch({ teamId: activeTeam.id, sortField: minimumGuaranteesSort.field, sortDirection: minimumGuaranteesSort.direction, page: minimumGuaranteesPage, perPage: minimumGuaranteesPerPage }));
	}, [minimumGuaranteesSort, minimumGuaranteesPage]);

	const canSeeUpfrontPayments = checkPermissions(permissions, [['can_read_all_upfront_payments', 'can_read_owned_upfront_payments']]);
	const canSeeMinimumGuarantees = checkPermissions(permissions, [['can_read_all_minimum_guarantees', 'can_read_owned_minimum_guarantees']]);

	// If an invoice is patched, we gotta refetch everything
	useEpic(action$ => action$.pipe(
		ofType(patchInvoice.success.type),
		map(() => listInvoicesForTeam.fetch({ teamId: activeTeam.id, sortField: invoicesSort.field, sortDirection: invoicesSort.direction, page: invoicesPage, perPage: invoicesPerPage })),
	), [invoicesSort, invoicesPage]);

	return (
		<Container>
			<Helmet key={`FinancePage-${activeTeam.code}`}>
				<title>{`Finance - ${activeTeam.name} - Poki for Developers`}</title>
			</Helmet>
			<MessageBox
				id="invoices-new-jan-17"
				persistHide
				title={_`newInvoicesTabTitle`}
				description={(
					<span dangerouslySetInnerHTML={{ __html: _`newInvoicesTabDescription` }} />
				)}
			/>
			<GridContainer cols={1}>
				{checkPermissions(permissions, [['can_read_all_invoices', 'can_read_owned_invoices']]) && (
					<Card title={_`invoices`} noPadding>
						<InvoicesTable
							isLoading={!listInvoicesForTeamStatus.done}
							invoices={invoices}
							setSort={setInvoicesSort}
							sortField={invoicesSort.field}
							sortDirection={invoicesSort.direction}
							setPagination={setInvoicesPage}
							page={invoicesPage}
							perPage={invoicesPerPage}
							totalItems={totalInvoices}
						/>
					</Card>
				)}
				{((canSeeUpfrontPayments && upfrontPayments?.length > 0) || (canSeeMinimumGuarantees && minimumGuarantees?.length > 0)) && (
					<PageDivider />
				)}
				{canSeeUpfrontPayments && upfrontPayments?.length > 0 && (
					<Card
						title={_`upfrontPayments`}
						noPadding
					>
						<Table
							apiStatus={listUpfrontPaymentsByTeamIdStatus}
							items={upfrontPayments}
							setSort={setUpfrontPaymentsSort}
							sortField={upfrontPaymentsSort.field}
							sortDirection={upfrontPaymentsSort.direction}
							setPagination={setUpfrontPaymentsPage}
							page={upfrontPaymentsPage}
							perPage={upfrontPaymentsPerPage}
							totalItems={totalUpfrontPayments}
							columns={[
								{
									title: _`startDate`,
									content: ({ item }) => moment(item.start_date * 1000).format(dayMonthYearFormat),
									sortField: 'start_date',
								},
								{
									title: _`amount`,
									content: ({ item }) => `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.amount / 100), { allowDecimals: true })}`,
									sortField: 'amount',
								},
								{
									title: _`games`,
									content: ({ item }) => <GamesTooltip games={item.games} />,
								},
								{
									title: _`progress`,
									width: 'max-content',
									content: ({ item }) => (
										<ProgressBar
											desc={item.amount_met < item.amount
												? `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.amount_met / 100), { allowDecimals: true })}`
												: _`completedOn${{ date: moment(item.completed_at * 1000).format(dayMonthYearFormat) }}`}
											progress={item.amount_met / item.amount}
											isPaused={item._paused}
											hideProgressIfFull
										/>
									),
									sortField: 'progress',
								},
							]}
						/>
					</Card>
				)}
				{canSeeMinimumGuarantees && minimumGuarantees?.length > 0 && (
					<Card
						title={_`minimumGuarantees`}
						noPadding
					>
						<Table
							apiStatus={listMinimumGuaranteesByTeamIdStatus}
							items={minimumGuarantees}
							setSort={setMinimumGuaranteesSort}
							sortField={minimumGuaranteesSort.field}
							sortDirection={minimumGuaranteesSort.direction}
							setPagination={setMinimumGuaranteesPage}
							page={minimumGuaranteesPage}
							perPage={minimumGuaranteesPerPage}
							totalItems={totalMinimumGuarantees}
							columns={[
								{
									title: _`startMonth`,
									content: ({ item }) => moment.utc(item.start_date * 1000).format('MMM YYYY'),
									sortField: 'start_date',
								},
								{
									title: _`endMonth`,
									content: ({ item }) => moment.utc(item.end_date * 1000).subtract(1, 'day').format('MMM YYYY'),
									sortField: 'end_date',
								},
								{
									title: _`amount`,
									content: ({ item }) => `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.monthly_amount / 100), { allowDecimals: true })}`,
									sortField: 'monthly_amount',
								},
								{
									title: _`status`,
									content: ({ item }) => {
										const now = moment.utc();
										const startMoment = moment.utc(item.start_date * 1000);
										const endMoment = moment.utc(item.end_date * 1000);
										const active = startMoment.isBefore(now) && endMoment.isAfter(now);

										return (
											<Status active={active}>
												{active ? _`active` : startMoment.isAfter(now) ? _`notStarted` : _`expired`}
											</Status>
										);
									},
								},
							]}
						/>
					</Card>
				)}
			</GridContainer>
		</Container>
	);
};

export default FinancePage;
