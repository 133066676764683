import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ignoreElements, tap, map } from 'rxjs';
import { ofType } from 'redux-observable';
import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import moment from 'moment';
import lodash from 'lodash';
import { Helmet } from 'react-helmet';

import { listPendingInvoices, patchInvoice, sendApprovedInvoices, listInvoicesForYearMonth, listAllPendingInvoices, listAllInvoicesForYearMonth } from 'app/src/epics/invoice';
import { useSelectPendingInvoicesList, useSelectInvoicesForYearMonth, useSelectAllPendingInvoices, useSelectAllInvoicesForYearMonth } from 'app/src/selectors/invoice';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import countries from 'app/src/utils/countries';
import useEpic from 'app/src/hooks/useEpic';

import Button from 'app/src/components/ui/Button';
import Card from 'app/src/components/ui/Card';
import Container from 'app/src/components/ui/Container';
import GridContainer from 'app/src/components/ui/GridContainer';
import AdminInvoicesTable from 'app/src/components/tables/AdminInvoicesTable';

import _ from 'shared/copy';

const earliestInvoiceMoment = moment('2019-11-01', 'YYYY-MM-DD');

const SendInvoicesButton = styled(Button)`
	margin-left: auto;
`;

const pendingPerPage = 10000000;
const pastPerPage = 10;

const useInvoicesFilters = allInvoices => useMemo(() => (
	[
		{
			title: _`teams`,
			field: 'team_id',
			type: 'multiselect',
			values: lodash.uniqBy(allInvoices?.map(i => (
				{
					value: i.team.id,
					desc: i.team.name,
				}
			)), 'value'),
		},
		{
			title: _`primaryCountry`,
			field: 'primary_country',
			type: 'multiselect',
			values: countries.map(({ name, iso_2_code }) => ({
				value: iso_2_code,
				desc: name,
			})) || [],
		},
		{
			title: _`billingCountry`,
			field: 'billing_country',
			type: 'multiselect',
			values: countries.map(({ name, iso_2_code }) => ({
				value: iso_2_code,
				desc: name,
			})) || [],
		},
		{
			title: _`status`,
			field: 'status',
			type: 'multiselect',
			values: [
				{
					value: 'approved',
					desc: _`approved`,
				},
				{
					value: 'manual',
					desc: _`handledManually`,
				},
				{
					value: 'open',
					desc: _`open`,
				},
			],
		},
		{
			title: 'Due date',
			type: 'multiselect',
			field: '_due_in_days',
			values: [
				{
					value: 'between:0:5',
					desc: 'Due within 5 days',
				},
				{
					value: 'greater:5',
					desc: 'Due in more than 5 days',
				},
				{
					value: 'less:0',
					desc: 'Overdue',
				},
			],
		},
		{
			title: _`isPaid`,
			field: 'paid_at',
			type: 'multiselect',
			values: [
				{
					value: 'not:null',
					desc: _`paid`,
				},
				{
					value: 'null',
					desc: _`toBePaid`,
				},
			],
		},
		{
			title: _`paymentMethod`,
			field: 'payment_method',
			type: 'multiselect',
			values: [
				{
					value: 'wire',
					desc: _`wireTransfer`,
				},
				{
					value: 'paypal',
					desc: _`paypal`,
				},
				{
					value: 'wise',
					desc: _`wise`,
				},
			],
		},
	]
), [allInvoices, countries]);

const MonthCard = ({ monthMoment }) => {
	const dispatch = useDispatch();

	const year = monthMoment.format('YYYY');
	const month = monthMoment.format('MM');

	const { data: invoices, meta: { total: totalInvoices } = {} } = useSelectInvoicesForYearMonth({ year, month });
	const { data: allInvoices } = useSelectAllInvoicesForYearMonth({ year, month });
	const listInvoicesForYearMonthStatus = useSelectApiStatus(listInvoicesForYearMonth[`${year}-${month}`].id);
	const filters = useInvoicesFilters(allInvoices);

	const [sort, setSort] = useState({ field: 'created_at', direction: -1 });
	const [page, setPage] = useState(1);
	const [activeFilters, setActiveFilters] = useState({});
	const [expanded, setExpanded] = useState(false);

	const onToggleExpand = toggle => setExpanded(toggle);

	useEffect(() => {
		if (expanded) {
			dispatch(listInvoicesForYearMonth[`${year}-${month}`].fetch({ year, month, sortField: sort.field, sortDirection: sort.direction, page, perPage: pastPerPage, filters: activeFilters }));
			dispatch(listAllInvoicesForYearMonth[`${year}-${month}`].fetch({ year, month }));
		}
	}, [expanded, sort, page, activeFilters]);

	return (
		<Card
			title={monthMoment.format('MMMM YYYY')}
			noPadding
			expandable
			onToggleExpand={onToggleExpand}
		>
			<AdminInvoicesTable
				isLoading={!listInvoicesForYearMonthStatus.done}
				invoices={invoices}
				readOnly
				sortField={sort.field}
				sortDirection={sort.direction}
				setSort={setSort}
				page={page}
				perPage={pastPerPage}
				setPagination={setPage}
				totalItems={totalInvoices}
				filters={filters}
				setActiveFilters={setActiveFilters}
				activeFilters={activeFilters}
			/>
		</Card>
	);
};

const AdminPaymentsInvoicesSubPage = () => {
	const dispatch = useDispatch();

	const { data: pendingInvoices, meta: { total: totalPendingInvoices } = {} } = useSelectPendingInvoicesList();
	const { data: allPendingInvoices } = useSelectAllPendingInvoices();

	const permissions = useSelectPermissions();
	const filters = useInvoicesFilters(allPendingInvoices);

	const listPendingInvoicesStatus = useSelectApiStatus(listPendingInvoices.id);
	const combinedStatus = combinedApiStatus(listPendingInvoicesStatus, useSelectApiStatus(patchInvoice.id), useSelectApiStatus(sendApprovedInvoices.id));

	const [sort, setSort] = useState({ field: 'created_at', direction: -1 });
	const [page, setPage] = useState(1);
	const [activeFilters, setActiveFilters] = useState({});

	const handleSendInvoices = () => {
		if (window.confirm(_`confirmSendApprovedInvoices`)) { // eslint-disable-line no-alert
			dispatch(sendApprovedInvoices.fetch(null, ({ success$ }) => success$.pipe(
				tap(({ payload: { result: { response } } }) => {
					window.alert(_`sendingXInvoices${{ amount: response.meta.total }}`); // eslint-disable-line no-alert
				}),
				ignoreElements(),
			)));
		}
	};

	useEffect(() => {
		dispatch(listAllPendingInvoices.fetch());
	}, []);

	useEffect(() => {
		dispatch(listPendingInvoices.fetch({ sortField: sort.field, sortDirection: sort.direction, page, perPage: pendingPerPage, filters: activeFilters }));
	}, [sort, page, activeFilters]);

	// If an invoice is patched, we gotta refetch everything
	useEpic(action$ => action$.pipe(
		ofType(patchInvoice.success.type),
		map(() => listPendingInvoices.fetch({ sortField: sort.field, sortDirection: sort.direction, page, perPage: pendingPerPage, filters: activeFilters })),
	), [activeFilters]);

	const lastMonth = new Date();
	lastMonth.setMonth(lastMonth.getMonth() - 1);

	const monthsOfData = useMemo(() => {
		const months = [];

		const mom = earliestInvoiceMoment;
		for (let i = Math.ceil(mom.diff(moment(), 'months', true)); i < 1; i++) {
			months.push(mom.clone().subtract(i, 'month').startOf('month'));
		}

		return months;
	}, []);

	return (
		<Container>
			<Helmet key="AdminPaymentsInvoicesSubPage">
				<title>Invoices - Admin - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				{checkPermissions(permissions, [['can_edit_all_teams']]) && (
					<>
						<Card title="Pending Invoices" noPadding>
							<AdminInvoicesTable
								isLoading={!listPendingInvoicesStatus.done || combinedStatus.pending}
								invoices={pendingInvoices}
								sortField={sort.field}
								sortDirection={sort.direction}
								setSort={setSort}
								page={page}
								perPage={pendingPerPage}
								setPagination={setPage}
								totalItems={totalPendingInvoices}
								filters={filters.filter(({ field }) => field !== 'paid_at')}
								setActiveFilters={setActiveFilters}
								activeFilters={activeFilters}
							/>
						</Card>
						<SendInvoicesButton
							disabled={combinedStatus.pending}
							onClick={handleSendInvoices}
						>
							Send All Approved Invoices
						</SendInvoicesButton>
					</>
				)}
				{monthsOfData.map(month => (
					<MonthCard
						key={month.format('YYYY-MM')}
						monthMoment={month}
					/>
				))}
			</GridContainer>
		</Container>
	);
};

export default AdminPaymentsInvoicesSubPage;
