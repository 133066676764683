import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import { merge, map, ignoreElements, tap } from 'rxjs';
import { Navigate } from 'react-router-dom';

import PokiLogo from 'shared/components/svg/PokiLogo';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import FullPageSymbolBackground from 'shared/components/FullPageSymbolBackground';

import { startScreenShake } from 'app/src/actions/effects';
import { useSelectUser } from 'app/src/selectors/user';
import { validate } from 'app/src/utils/validate';
import ThemeProvider from 'app/src/utils/ThemeProvider';
import CheckBoxInput from 'app/src/components/input/CheckBoxInput';
import { patchUser, retrieveUserDetails } from 'app/src/epics/user';
import { patchTeam } from 'app/src/epics/team';
import history from 'app/history';
import formatSlug from 'app/src/utils/formatSlug';

import { isMobile } from 'shared/vars';
import _ from 'shared/copy';

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${props => props.theme.static.pureWhite};
	padding: 50px;

	${!isMobile ? `
	position: absolute;
	left: 50%;
	top: 50%;
	width: 450px;
	transform: translate(-50%, -50%);
	border-radius: 24px;
	box-shadow: ${props => props.theme.static.boxShadowSmall};
	justify-content: space-evenly;
	` : `
	min-height: 100vh;
	`}
`;

const Form = styled.form`
	width: 100%;
	height: 50%;
`;

const StyledPokiLogo = styled(PokiLogo)`
	width: 300px;
	max-width: 100%;

	${!isMobile ? `
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin: 20px;
	transform: translateX(-50%);
	` :	`
	margin-bottom: 20px;
	`}
`;

const SubmitButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const StyledCheckBoxInput = styled(CheckBoxInput)`
	color: ${props => props.theme.static.grey3};
	font-weight: 400;
`;

const Error = styled.div`
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin-top: 24px;
	text-align: center;
	margin-bottom: -8px;
	color: ${props => props.theme.static.rose1};
`;

const StyledTitle = styled.h1`
	font: 700 36px/40px Torus;
	color: ${props => props.theme.denimBlue};
	margin: 0 0 8px;
	text-align: center;

	${isMobile && `
	font-size: 34px;
	`}
`;

const StyledSubTitle = styled.h2`
	font: 400 21px/32px Proxima Nova;
	color: ${props => props.theme.denimBlue};
`;

const CompleteSignupPage = () => {
	const dispatch = useDispatch();

	const user = useSelectUser();

	const [errors, setErrors] = useState({});
	const [userName, setUserName] = useState(user.name);
	const [teamName, setTeamName] = useState('');
	const [termsAccepted, setTermsAccepted] = useState(false);

	const patchUserStatus = useSelectApiStatus(patchUser.id);
	const patchTeamStatus = useSelectApiStatus(patchTeam.id);

	const fieldsDisabled = combinedApiStatus(patchUserStatus, patchTeamStatus).pending;

	const handleSubmit = evt => {
		evt.preventDefault();

		const err = { ...errors };

		delete err.api;

		if (!termsAccepted) {
			err.termsAccepted = ['You must accept the terms'];
		} else {
			delete err.termsAccepted;
		}

		const checks = [
			{
				validate: 'user_name',
				errorKey: 'userName',
				value: userName,
			},
			{
				validate: 'team_name',
				errorKey: 'teamName',
				value: teamName,
			},
		];

		checks.forEach(c => {
			const check = validate(c.validate, c.value);

			if (!check.valid) {
				err[c.errorKey] = check.messages;
			} else {
				delete err[c.errorKey];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		// Patch the user, then the team, then retrieve the user details
		// to redirect to the team page.
		const handleError = action$ => action$.pipe(
			tap(({ payload: { result: { response: { errors: [error] } } } }) => {
				setErrors({ ...errors, api: `${error.title}` });
			}),
			ignoreElements(),
		);
		const doRetrieveUserDetails = action$ => action$.pipe(
			map(() => retrieveUserDetails.fetch(
				{},
				({ success$, error$ }) => merge(
					success$.pipe(
						tap(() => history.push(`/${user.team.code}`)),
						ignoreElements(),
					),
					handleError(error$),
				),
			)),
		);
		const doPatchTeam = action$ => action$.pipe(
			map(() => patchTeam.fetch(
				{
					teamId: user.team.id,
					data: {
						read_privacy_addendum: 'accepted',
						name: teamName,
						code: formatSlug(teamName),
					},
				},
				({ success$, error$ }) => merge(
					doRetrieveUserDetails(success$),
					handleError(error$),
				),
			)),
		);
		dispatch(
			patchUser.fetch({
				userId: user.id,
				data: {
					name: userName,
				},
			}, ({ success$, error$ }) => merge(
				doPatchTeam(success$),
				handleError(error$),
			)),
		);
	};

	if (user?.team?.verified || user?.team?.read_privacy_addendum !== 'not-accepted') {
		return (
			<Navigate to={{ pathname: `/${user.team.code}` }} />
		);
	}

	return (
		<ThemeProvider forceLightMode>
			{!isMobile && <FullPageSymbolBackground symbol="coins" />}
			<Helmet key="completeSignupPage">
				<title>Complete Your Profile - Poki for Developers</title>
			</Helmet>
			<InnerContainer>
				<StyledPokiLogo dark />
				<StyledTitle>{_`completeProfile`}</StyledTitle>
				<StyledSubTitle>{_`addStudioDetails`}</StyledSubTitle>
				{errors.api && <Error>{errors.api}</Error>}

				<Form onSubmit={handleSubmit}>
					<TextInput
						label={_`yourName`}
						name="userName"
						placeholder="Your Name"
						value={userName}
						valueSetter={setUserName}
						errors={errors.userName}
						disabled={fieldsDisabled}
						autoFocus
					/>
					<TextInput
						label={_`teamName`}
						name="teamName"
						value={teamName}
						valueSetter={setTeamName}
						errors={errors.teamName}
						disabled={fieldsDisabled}
						description={_`nameVisibleNextToGames`}
					/>
					<StyledCheckBoxInput
						text={(<>{_`iAcceptThe`} <a href="/2024.03.13_Terms_and_Conditions_Poki_for_Developers.pdf" download="2024-03-13 Terms and Conditions Poki for Developers.pdf" target="_blank">{_`termsAndConditions`}</a></>)}
						name="termsAccepted"
						value={termsAccepted}
						errors={errors.termsAccepted}
						valueSetter={setTermsAccepted}
					/>

					<SubmitButton>
						<Button marketingButton submit disabled={fieldsDisabled}>{_`letsGo`}</Button>
					</SubmitButton>
				</Form>
			</InnerContainer>
		</ThemeProvider>
	);
};

export default CompleteSignupPage;
