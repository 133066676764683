import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
`;

const Label = styled.div`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};
	margin-right: 4px;
`;

const Value = styled.div`
	margin-top: 12px;
	font-size: 16px;
	line-height: 22px;
	color: ${props => props.theme.grey3};
	white-space: pre-wrap;
	max-height: 166px;
	overflow: auto;
`;

const LabeledValue = props => {
	const { label, children } = props;

	return (
		<Container>
			<Label>{label}</Label>
			<Value>{children}</Value>
		</Container>
	);
};

export default LabeledValue;
