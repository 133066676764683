const playtestFAQItems = [
	{
		isInitiallyOpen: true,
		question: 'How does Poki Playtesting work?',
		answer: 'After requesting your playtests instantly a mystery game thumbnail appears on the Poki playground to a selection of players. When a player finds it and starts the game, we ask them to join a playtest. And we ask consent to record the gameplay. Once the player is done playing, a recording will show up on this page. Players can decline to join a playtest. They will get to play your game, it simply won’t be recorded.',
	},
	{
		question: 'How many players will see my game?',
		answer: 'As many players as it takes to get 10 players to consent with doing a playtest. This usually varies from 10 to 100 players.',
	},
	{
		question: 'Why does it take so long to get the Playtest recordings?',
		answer: 'Depending on the moment of the day, it can take a while for recordings to appear. Your game is not shown to all players across the playground. And we try to select locations where higher spec devices and good internet connections are available. Because a playtest impacts the device’s performance.',
	},
	{
		question: 'Why are some playtest recordings blurry or laggy?',
		answer: 'The recordings are created on the device of the player. This is not free and will use some CPU and slow the game down. We try to find a fine balance between having a good recording and not slowing down the game too much.',
	},
	{
		question: 'Why are playtest recordings sometimes very short?',
		answer: 'Our players decide themselves what to do during a playtest. We don’t ask anything of them. If a player doesn’t like your game, doesn’t understand it or loading simply takes too long, they might already leave after a couple of seconds. It’s tough but you can learn from it!',
	},
	{
		question: 'Why do playtests expire?',
		answer: 'For privacy reasons we are not allowed to store playtest recordings for longer than 28 days.',
	},
];

export default playtestFAQItems;
