import { useEffect } from 'react';
import { Subject, takeUntil, tap } from 'rxjs';
import { addDynamicDataEpic, removeDynamicDataEpic } from 'app/src/reducers/data';

import { epicRoot$ } from 'app/src/store';

/**
 * useDataEpic
 *
 * Defines a data epic that is active for the lifetime of this component, also ensures the reducer is adjusted accordingly
 * Warning: Make sure that this is really what you want to do, usually an actual (global) data epic is what you need :)
 */

export default function useDataEpic(epic$, params) {
	useEffect(() => {
		addDynamicDataEpic(epic$);

		const unsubscriber$ = new Subject().pipe(
			tap(() => removeDynamicDataEpic(epic$)),
		);

		epicRoot$.next(
			(...args) => epic$(...args).pipe(
				takeUntil(unsubscriber$),
			),
		);

		return () => {
			unsubscriber$.next();
		};
	}, params);

	return epic$;
}
