import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import moment from 'moment';

import { SyncedKeyboard, VirtualKeyboardStyleKeyPressed } from 'app/src/components/playtest/SyncedKeyboard';
import { getTimeHashFromMilliseconds, getTimeHashFromSeconds } from 'app/src/components/playtest/util/getTimeHash';
import { selectVideoJS, selectVideoSeekId, useSelectPlaytestRecordingsByGameId } from 'app/src/selectors/playtests';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { getPlaytestRecordingsByGameId, markPlaytestWatched } from 'app/src/epics/playtests';
import { useSelectUser } from 'app/src/selectors/user';
import { openModal } from 'app/src/actions/client';
import getVersionLabel from 'app/src/utils/getVersionLabel';

import { ConsoleLogs } from 'app/src/components/playtest/ConsoleLogs';
import ToggleInput from 'app/src/components/input/ToggleInput';
import VideoJS from 'app/src/components/ui/VideoJS';
import Card from 'app/src/components/ui/Card';
import Tooltip from 'shared/components/Tooltip';
import Flag from 'app/src/components/ui/Flag';

import PencilIcon from 'shared/designTokens/icons/ui/tiny/PencilIcon';
import DesktopScreenIcon from 'shared/designTokens/icons/ui/small/DesktopScreenIcon';
import MobilePhoneIcon from 'shared/designTokens/icons/ui/small/MobilePhoneIcon';

import { dayMonthYearTimeFormat, _small } from 'shared/vars';
import _ from 'shared/copy';

const PlaytestContainer = styled.div`
	position: relative;
	display: block;

	.video-js {
		width: 100%;
	}
`;

const VideoContainer = styled.div`
	position: relative;
	box-sizing: initial;
	margin: auto;
	max-width: 100%;
	border: 1px solid ${props => props.theme.grey7};
	margin-bottom: 8px;
`;

const VideoContainerToggleInput = styled(ToggleInput)`
	float: right;
	margin: 8px 0;
	@media (max-width: 442px) {
		order: -1;
	}
`;

const StyledFPS = styled.div`
	color: #B4B9CE;
	font: bold 14px/18px 'Proxima Nova',Arial;
	height: 28px;
	margin: 0 0 22px;
	display: flex;
`;

const UnderVideo = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	${_small} {
		flex-direction: row;
	}
`;

const RecordedPlaytestDetailsItemTitle = styled.h4`
	margin: 0;

	&& {
		font-size: 14px;
	}
`;

const RecordedPlaytestDetailsItem = styled.p`
	&& {
		margin: 0;
		font-size: 12px;
	}
`;

const KeyboardContainer = styled.div`
	position: relative;
	width: 100%;
	height: 120px;
	margin: 0 0 24px;

	${_small} {
		height: 240px;
		margin: 0 0 48px;
	}

	svg {
		width: 100%;
		height: 100%;
	}

	#PVK_Keys {
		width: 100%;
		height: 100%;
	}

	#PVK_Keys path {
		fill: #c9ccdc;
	}

	#PVK_Keys path.active {
		animation: ${VirtualKeyboardStyleKeyPressed} 0.5s ease-out;
	}
`;

const StyledFlag = styled(Flag)`
	font-size: 17px;
	left: 3px;
	top: 2px;
`;

const StyledTooltip = styled(Tooltip)`
	display: flex;
	align-items: center;
	width: 32px;
`;

const DeviceInformationContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const StyledPencilIcon = styled(PencilIcon)`
	cursor: pointer;
`;

const StyledGridContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;

	${_small} {
		flex-direction: row;
	}
`;

function getDeviceIcon(device) {
	switch (device) {
		case 'desktop':
			return <DesktopScreenIcon />;
		case 'mobile':
		case 'tablet':
			return <MobilePhoneIcon />;
		default:
			return null;
	}
}

function renderFPS(stats) {
	const [avg = 0] = stats || [];

	return avg;
}

const RecordedPlaytest = props => {
	const dispatch = useDispatch();

	const { game } = props;

	const user = useSelectUser();
	const isAdmin = user.role === 'admin';

	const playtestContainerRef = useRef();
	const keyboardContainerRef = useRef();
	const processKeyTimeout = useRef(null);
	const keysDown = useRef({});
	const eventLookup = useRef({});
	const eventCurrentHash = useRef(-1);

	const [currentPlaytest, setCurrentPlaytest] = useState(null);
	const [videoOptions, setVideoOptions] = useState(null);
	const [keyboard] = useState(() => new SyncedKeyboard());
	const [showConsole, setShowConsole] = useState(false);
	const [keysData, setKeysData] = useState(null);
	const [fps, setFps] = useState(null);

	const { playtestId } = useParams();
	const videoJS = useSelector(selectVideoJS);
	const videoSeekId = useSelector(selectVideoSeekId);
	const activeTeam = useSelectActiveTeam();
	const { data: playtestRecordings } = useSelectPlaytestRecordingsByGameId(game.id);

	useEffect(() => {
		dispatch(getPlaytestRecordingsByGameId.fetch({ gameId: game.id }));
	}, []);

	useEffect(() => {
		if (playtestRecordings) {
			setCurrentPlaytest(playtestRecordings.find(playtest => playtest.id === playtestId));
		}
	}, [playtestRecordings]);

	// Show or hide the video player depending on if we have a player or recording.
	useEffect(() => {
		if (!playtestId) {
			return;
		}

		setVideoOptions({
			fluid: true,
			aspectRatio: '16:9',
			autoplay: true,
			loop: false,
			playbackRates: [0.5, 1, 1.25, 1.5, 2],
			controls: true,
			inactivityTimeout: 500,
			controlBar: {
				volumePanel: false,
				muteToggle: false,
			},
			sources: [
				{
					src: `https://storage.googleapis.com/poki-playtest-recordings/${playtestId}.webm`,
					type: 'video/webm',
				},
			],
		});

		fetch(`https://storage.googleapis.com/poki-playtest-recordings/${playtestId}.json`)
			.then(res => res.json())
			.then(data => {
				setKeysData(data);

				data.forEach(item => {
					// hash all the keys per 100ms
					const timeHash = getTimeHashFromMilliseconds(item.offset);
					if (!eventLookup.current[timeHash]) {
						eventLookup.current[timeHash] = [];
					}

					eventLookup.current[timeHash].push(item);
				});
			})
			.catch(err => {
				console.error(err);
			});
	}, [playtestContainerRef, playtestId]);

	const processKeys = () => {
		processKeyTimeout.current = setTimeout(() => {
			processKeys();
		}, 50);

		const timeHash = getTimeHashFromSeconds(videoJS.currentTime()); // 100ms

		if (eventCurrentHash.current === timeHash) {
			return;
		}

		eventCurrentHash.current = timeHash;

		const currentEvents = eventLookup.current[timeHash];

		if (currentEvents) {
			currentEvents.forEach(event => {
				if (event.type === 'keyboard') {
					Object.keys(event.keys).forEach(key => {
						if (event.keys[key]) {
							keysDown.current[key] = true;
						} else {
							delete keysDown.current[key];
						}
					});
				} else if (event.type === 'fps' && event.stats) {
					const stats = event.stats.split('|'); // min|max|avg|jitter
					setFps(stats);
				}
			});
		}

		keyboard.update(Object.keys(keysDown.current));
	};

	useEffect(() => {
		if (videoJS && keysData) {
			processKeys();
		}

		return () => {
			clearTimeout(processKeyTimeout.current);
		};
	}, [videoJS, keysData]);

	useEffect(() => {
		if (playtestId && keyboardContainerRef.current) {
			keyboardContainerRef.current.appendChild(keyboard.el);
		}
	}, [keyboardContainerRef.current, playtestId]);

	useEffect(() => {
		// on seek clear all keys
		keysDown.current = {};
	}, [videoSeekId]);

	const handleToggleConsole = () => {
		setShowConsole(t => !t);
	};

	useEffect(() => {
		dispatch(markPlaytestWatched.fetch({ gameId: game.id, playtestId }));
	}, []);

	return (
		<>
			<Helmet key={`GamePlaytestSubPage-${game.id}-${activeTeam.code}`}>
				<title>Playtest - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<Card
				title={_`recordedPlaytests`}
				description={_`recordedPlaytestDescription`}
			>
				<PlaytestContainer ref={playtestContainerRef}>
					<VideoContainer>
						{videoOptions && (
							<VideoJS options={videoOptions} />
						)}
						{showConsole && <ConsoleLogs data={keysData} />}
					</VideoContainer>
					<UnderVideo>
						<StyledFPS>FPS: {renderFPS(fps)}</StyledFPS>
						<VideoContainerToggleInput checked={showConsole} label="show console" onChange={handleToggleConsole} />
						<KeyboardContainer ref={keyboardContainerRef} />
						<StyledGridContainer cols={2}>
							<div>
								<div>
									<RecordedPlaytestDetailsItemTitle>When</RecordedPlaytestDetailsItemTitle>
									<RecordedPlaytestDetailsItem>{currentPlaytest ? moment(currentPlaytest.created_at * 1000).format(dayMonthYearTimeFormat) : '-'}</RecordedPlaytestDetailsItem>
								</div>
								<div>
									<RecordedPlaytestDetailsItemTitle>Notes</RecordedPlaytestDetailsItemTitle>
									<RecordedPlaytestDetailsItem>
										{currentPlaytest?.notes}
										<StyledPencilIcon
											onClick={() => dispatch(openModal({ key: 'edit-playtest-notes', data: { playtest: currentPlaytest } }))}
										/>
									</RecordedPlaytestDetailsItem>
								</div>
								<div>
									<RecordedPlaytestDetailsItemTitle>Version</RecordedPlaytestDetailsItemTitle>
									<RecordedPlaytestDetailsItem>{getVersionLabel(game.versions.find(v => v.id === currentPlaytest?.version_id))}</RecordedPlaytestDetailsItem>
								</div>
								<div>
									<RecordedPlaytestDetailsItemTitle>Country</RecordedPlaytestDetailsItemTitle>
									<RecordedPlaytestDetailsItem><StyledFlag countryCode={currentPlaytest?.country_id} /></RecordedPlaytestDetailsItem>
								</div>
								<div>
									<RecordedPlaytestDetailsItemTitle>Device</RecordedPlaytestDetailsItemTitle>
									<RecordedPlaytestDetailsItem>
										<StyledTooltip
											arrow
											placement="top"
											content={(
												<DeviceInformationContent>
													<strong>Device information</strong>
													<div>
														{_`device`}: {currentPlaytest?.device_category}<br />
														{_`browser`}: {currentPlaytest?.browser}<br />
														{_`operatingSystem`}: {currentPlaytest?.os}<br />
													</div>
												</DeviceInformationContent>
											)}
											text={`${currentPlaytest?.browser}`}
										>
											{getDeviceIcon(currentPlaytest?.device_category)}
										</StyledTooltip>
									</RecordedPlaytestDetailsItem>
								</div>
							</div>
							<div>
								<div>
									<RecordedPlaytestDetailsItemTitle>User Agent</RecordedPlaytestDetailsItemTitle>
									{currentPlaytest?.useragent ? (
										<RecordedPlaytestDetailsItem>{currentPlaytest.useragent}</RecordedPlaytestDetailsItem>
									) : (
										<RecordedPlaytestDetailsItem>Unknown</RecordedPlaytestDetailsItem>
									)}
								</div>
								<div>
									<RecordedPlaytestDetailsItemTitle>
										<Tooltip
											placement="top"
											arrow
											content={<><pre>gl.getParameter(gl.getExtension(&apos;WEBGL_debug_renderer_info&apos;).UNMASKED_RENDERER_WEBGL)</pre> or <pre>gl.getParameter(gl.RENDERER)</pre></>}
										>
											WebGL Renderer
										</Tooltip>
									</RecordedPlaytestDetailsItemTitle>
									{currentPlaytest?.webgl_renderer ? (
										<RecordedPlaytestDetailsItem>{currentPlaytest.webgl_renderer}</RecordedPlaytestDetailsItem>
									) : (
										<RecordedPlaytestDetailsItem>Unknown</RecordedPlaytestDetailsItem>
									)}
								</div>
								<div>
									<RecordedPlaytestDetailsItemTitle>navigator.hardwareConcurrency</RecordedPlaytestDetailsItemTitle>
									{currentPlaytest?.cpus ? (
										<RecordedPlaytestDetailsItem>{currentPlaytest.cpus}</RecordedPlaytestDetailsItem>
									) : (
										<RecordedPlaytestDetailsItem>Unknown</RecordedPlaytestDetailsItem>
									)}
								</div>
								<div>
									<RecordedPlaytestDetailsItemTitle>window.devicePixelRatio</RecordedPlaytestDetailsItemTitle>
									{currentPlaytest?.device_pixel_ratio ? (
										<RecordedPlaytestDetailsItem>{currentPlaytest.device_pixel_ratio}</RecordedPlaytestDetailsItem>
									) : (
										<RecordedPlaytestDetailsItem>Unknown</RecordedPlaytestDetailsItem>
									)}
								</div>
								{isAdmin && (
									<div>
										<RecordedPlaytestDetailsItemTitle>SDK Version</RecordedPlaytestDetailsItemTitle>
										{currentPlaytest?.sdk_version ? (
											<RecordedPlaytestDetailsItem>{currentPlaytest.sdk_version}</RecordedPlaytestDetailsItem>
										) : (
											<RecordedPlaytestDetailsItem>Unknown</RecordedPlaytestDetailsItem>
										)}
									</div>
								)}
							</div>
						</StyledGridContainer>
					</UnderVideo>
				</PlaytestContainer>
			</Card>
		</>
	);
};

export default RecordedPlaytest;
