import React from 'react';
import styled from 'styled-components';

const NotificationWrapper = styled.div`
	position: absolute;
	top: -12px;
	right: -10px;

	min-width: 16px;
	text-align: center;

	padding: 0 4px;
	border-radius: 8px;

	background-color: ${props => props.theme.rose1};
	color: ${props => props.theme.static.pureWhite};
`;

const Notification = props => {
	const { children } = props;

	return (
		<NotificationWrapper>
			{children}
		</NotificationWrapper>
	);
};

export default Notification;
