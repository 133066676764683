import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: ${props => props.theme.static.grey5};
	border-radius: 3px;
	box-shadow: 1px 1px ${props => props.theme.static.grey3};
	color: ${props => props.theme.static.grey3};
	height: 16px;
	margin: 0 4px;
	padding: 0 4px;
	font-size: 12px;
	font-weight: bold;
	font-family: Courier, courier new, mono;
`;

const Key = props => {
	const { children } = props;

	return (
		<Container>{children}</Container>
	);
};

export default Key;
