import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';

import { spawnEmoji } from 'shared/utils/emojiHover';
import FullPageSymbolBackground from 'shared/components/FullPageSymbolBackground';
import MarketingFooter from 'shared/components/MarketingFooter';
import loadStylesheet from 'shared/utils/loadStylesheet';
import { lightColors } from 'shared/vars';

const _small = '@media (min-width: 720px)';
const _inverseSmall = '@media (max-width: 720px)';

const InnerContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -60%);
	text-align: center;
	transition: opacity .3s linear .2s, transform .3s ease-out .2s;
	opacity: 0;

	${props => props.visible && `
	opacity: 1;
	transform: translate(-50%, -50%);
	`}
`;

const Description = styled.div`
	font-weight: bold;
	margin: 0 0 30px;
	font-size: 18px;
	line-height: 28px;
	color: ${lightColors.denimBlue};

	${_inverseSmall} {
		font-size: 14px;
	}
`;

const glitchTextAnim = keyframes`
	0% {
		transform: translate3d(-200px,0,0) scale3d(-1,-1,1);
		clip-path: polygon(0 20%,100% 20%,100% 21%,0 21%)
	}

	2% {
		clip-path: polygon(0 33%,100% 33%,100% 33%,0 33%)
	}

	4% {
		clip-path: polygon(0 44%,100% 44%,100% 44%,0 44%)
	}

	5% {
		clip-path: polygon(0 50%,100% 50%,100% 20%,0 20%)
	}

	6% {
		clip-path: polygon(0 70%,100% 70%,100% 70%,0 70%)
	}

	7% {
		clip-path: polygon(0 80%,100% 80%,100% 80%,0 80%)
	}

	8% {
		clip-path: polygon(0 50%,100% 50%,100% 55%,0 55%)
	}

	9% {
		clip-path: polygon(0 70%,100% 70%,100% 80%,0 80%)
	}

	9.9% {
		transform: translate3d(-200px,0,0) scale3d(-1,-1,1)
	}

	10%,29% {
		opacity: 1;
		transform: translate3d(0,0,0) scale3d(1,1,1);
		clip-path: polygon(0 0,100% 0,100% 100%,0 100%)
	}

	29.9% {
		opacity: 0
	}

	30%,78% {
		opacity: 1;
		transform: scaleX(1) scaleY(1) skewX(0)
	}

	79% {
		transform: scaleX(2.5) scaleY(.2) skewX(80deg)
	}

	80%,90% {
		transform: scaleX(1) scaleY(1) skewX(0)
	}

	91% {
		transform: scaleX(2.5) scaleY(.1) skewX(-80deg)
	}

	92% {
		transform: scaleX(1) scaleY(1) skewX(0)
	}
`;

const innerTextAnim1 = keyframes`
	0% {
		clip: rect(65px,1500px,94px,0)
	}

	6.66667% {
		clip: rect(152px,1500px,75px,0)
	}

	13.33333% {
		clip: rect(90px,1500px,47px,0)
	}

	20% {
		clip: rect(162px,1500px,40px,0)
	}

	26.66667% {
		clip: rect(17px,1500px,37px,0)
	}

	33.33333% {
		clip: rect(77px,1500px,27px,0)
	}

	40% {
		clip: rect(116px,1500px,157px,0)
	}

	46.66667% {
		clip: rect(108px,1500px,92px,0)
	}

	53.33333% {
		clip: rect(164px,1500px,149px,0)
	}

	60% {
		clip: rect(39px,1500px,115px,0)
	}

	66.66667% {
		clip: rect(121px,1500px,78px,0)
	}

	73.33333% {
		clip: rect(90px,1500px,9px,0)
	}

	80% {
		clip: rect(49px,1500px,62px,0)
	}

	86.66667% {
		clip: rect(182px,1500px,107px,0)
	}

	93.33333% {
		clip: rect(119px,1500px,12px,0)
	}

	100% {
		clip: rect(33px,1500px,57px,0)
	}
`;

const innerTextAnim2 = keyframes`
	0% {
		clip: rect(163px,1500px,70px,0)
	}

	6.66667% {
		clip: rect(92px,1500px,47px,0)
	}

	13.33333% {
		clip: rect(189px,1500px,108px,0)
	}

	20% {
		clip: rect(126px,1500px,60px,0)
	}

	26.66667% {
		clip: rect(82px,1500px,11px,0)
	}

	33.33333% {
		clip: rect(100px,1500px,19px,0)
	}

	40% {
		clip: rect(190px,1500px,91px,0)
	}

	46.66667% {
		clip: rect(33px,1500px,114px,0)
	}

	53.33333% {
		clip: rect(58px,1500px,53px,0)
	}

	60% {
		clip: rect(145px,1500px,24px,0)
	}

	66.66667% {
		clip: rect(162px,1500px,48px,0)
	}

	73.33333% {
		clip: rect(48px,1500px,123px,0)
	}

	80% {
		clip: rect(186px,1500px,117px,0)
	}

	86.66667% {
		clip: rect(29px,1500px,180px,0)
	}

	93.33333% {
		clip: rect(46px,1500px,173px,0)
	}

	100% {
		clip: rect(117px,1500px,169px,0)
	}
`;

const GlitchText = styled.h1`
	font-family: "Press Start 2P", sans-serif;
	font-size: 60px;
	line-height: 1;
	position: relative;
	margin-bottom: 10px;
	color: #fff;

	${_small} {
		margin-bottom: 20px;
		font-size: 100px;
	}

	${props => props.animateInner && css`
	&:before, &:after {
		content: "${props.children}";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: 0 0;
		clip: rect(0,0,0,0)
	}

	&:after {
		left: 2px;
		text-shadow: -1px 0 pink;
		animation: ${innerTextAnim1} 2s infinite linear alternate-reverse
	}

	&:before {
		left: -2px;
		text-shadow: 2px 0 #79fee7;
		animation: ${innerTextAnim2} 3s infinite linear alternate-reverse
	}
	`}

	${props => props.animate && css`
	animation-name: ${glitchTextAnim};
	animation-duration: 1200ms;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	`}
`;

const HelpMessage = styled.div`
	font-weight: normal;
	font-size: 16px;
`;

const GlitchTitle = ({ onMouseEnter, children }) => {
	const [animate, setAnimate] = useState(true);
	const [animateInner, setAnimateInner] = useState(false);

	useEffect(() => {
		const time = animate ? 2000 : 8000;

		const timeout = window.setTimeout(() => {
			setAnimate(!animate);
		}, time);

		return () => {
			window.clearTimeout(timeout);
		};
	}, [animate]);

	useEffect(() => {
		const time = animateInner ? 6000 : 3000;

		const timeout = window.setTimeout(() => {
			setAnimateInner(!animateInner);
		}, time);

		return () => {
			window.clearTimeout(timeout);
		};
	}, [animateInner]);

	return (
		<GlitchText onMouseEnter={onMouseEnter} animate={animate} animateInner={animateInner}>{children}</GlitchText>
	);
};

const GlitchPage = props => {
	const { title, message, subMessage } = props;

	const [visible, setVisible] = useState(false);

	useEffect(() => {
		loadStylesheet(`//fonts.googleapis.com/css?family=Press+Start+2P&text=${title}`).then(() => {
			setVisible(true);
		});
	}, []);

	return (
		<>
			<Helmet key="GlitchPage">
				<title>Try again! - Poki for Developers</title>
			</Helmet>
			<FullPageSymbolBackground glitch />
			<InnerContainer visible={visible}>
				<GlitchTitle
					onMouseEnter={evt => {
						const bounds = evt.target.getBoundingClientRect();

						spawnEmoji(evt.target, {
							position: {
								left: evt.clientX,
								top: bounds.top,
							},
							style: 'white',
							forceImage: 'https://a.poki-cdn.com/emoji/error_isolated_white.svg',
						});
					}}
				>
					{title}
				</GlitchTitle>
				<Description>
					{message}
					<HelpMessage dangerouslySetInnerHTML={{ __html: subMessage }} />
				</Description>
			</InnerContainer>
			<MarketingFooter tiny />
		</>
	);
};

export default GlitchPage;
