import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import lodash from 'lodash';

import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { ChartDetails, ChartDetailsInner, DataAvailableFrom, DetailNumber } from 'app/src/components/ChartSubComponents';
import { createGetTotalUsersForGameId, createGetUsersForGameId } from 'app/src/epicCreators/data';
import { getEarliestGameplaysForGameId, getTablesLastUpdatedAt } from 'app/src/epics/data';
import { useSelectTheme } from 'app/src/selectors/session';
import measureConversionToPlay from 'app/src/measures/measureConversionToPlay';
import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';
import getTimeSince from 'app/src/utils/getTimeSince';
import usePivot from 'app/src/hooks/usePivot';
import formatNumber from 'app/src/utils/formatNumber';
import useComponentId from 'app/src/hooks/useComponentId';
import useDataEpic from 'app/src/hooks/useDataEpic';

import ExclamationIcon from 'shared/designTokens/icons/ui/tiny/ExclamationIcon';

import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import Chart from 'app/src/components/ui/charts/Chart';
import InfoPoint from 'app/src/components/ui/InfoPoint';

import { dayMonthYearFormat } from 'shared/vars';
import _ from 'shared/copy';

const TooltipAdditionalInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const usersDataAvailableFromMoment = moment('2019-11-01');

const GameUsersChartModule = props => {
	const { game, startDate, endDate, deviceCategories, countries, contexts, versions } = props;

	const componentId = useComponentId();

	const getUsersForGameId = useDataEpic(createGetUsersForGameId(componentId), []);
	const getTotalUsersForGameId = useDataEpic(createGetTotalUsersForGameId(componentId), []);
	const getTotalUsersPrevPeriodForGameId = useDataEpic(createGetTotalUsersForGameId(`prev-${componentId}`), []);

	const dispatch = useDispatch();
	const theme = useSelectTheme();

	useEffect(() => {
		if (!game) return;

		const options = {
			gameId: game.id,
			teamId: game.team_id,
			from: startDate,
			to: endDate,
			deviceCategories,
			countries,
			contexts,
			versions,
		};

		dispatch(getTablesLastUpdatedAt.fetch());
		dispatch(getEarliestGameplaysForGameId.fetch(options));

		dispatch(getUsersForGameId.fetch(options));
		dispatch(getTotalUsersForGameId.fetch(options));

		// Previous period
		const fromMoment = moment.utc(startDate);
		const toMoment = moment.utc(endDate);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		dispatch(getTotalUsersPrevPeriodForGameId.fetch({
			...options,
			from: prevPeriodFrom,
			to: prevPeriodTo,
		}));
	}, [game?.id, startDate, endDate, deviceCategories, countries, contexts, versions]);

	const getUsersForGameIdStatus = useSelectApiStatus(getUsersForGameId.id);
	const getTotalDailyActiveUsersPerDayForGameIdStatus = useSelectApiStatus(getTotalUsersForGameId.id);
	const getTotalDailyActiveUsersPerDayPrevPeriodForGameIdStatus = useSelectApiStatus(getTotalUsersPrevPeriodForGameId.id);

	const apiStatus = combinedApiStatus(getUsersForGameIdStatus, getTotalDailyActiveUsersPerDayForGameIdStatus, getTotalDailyActiveUsersPerDayPrevPeriodForGameIdStatus);

	const usersForGame = usePivot(
		useSelectDataByEpic(getUsersForGameId.id).rows,
		row => {
			const { daily_playing_users, daily_not_playing_users, ...remaining } = row;

			return [
				{
					...remaining,
					users: daily_playing_users,
					is_playing: true,
				},
				{
					...remaining,
					users: daily_not_playing_users,
					is_playing: false,
				},
			];
		},
	);
	const [totalUsers] = measureConversionToPlay(useSelectDataByEpic(getTotalUsersForGameId.id).rows);
	const [totalUsersPrevPeriod] = useSelectDataByEpic(getTotalUsersPrevPeriodForGameId.id).rows;
	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_users');

	const usersDayRange = useMemo(() => (
		lodash.uniqBy(usersForGame, d => d.date).length
	), [usersForGame]);

	const earliestGameplayDataMoment = moment(useSelectDataByEpic(getEarliestGameplaysForGameId.id)?.rows[0] || Date.now());

	return (
		<Card
			title={_`users`}
			buttons={(
				<InfoPoint
					disabled={!lastUpdatedAt}
					warning={usersDataAvailableFromMoment.isAfter(earliestGameplayDataMoment)}
					icon={GraphIcon}
				>
					{_`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`}
					{usersDataAvailableFromMoment.isAfter(earliestGameplayDataMoment) && (
						<DataAvailableFrom>
							<ExclamationIcon /> {_`dataOnlyAvailableFromX${{ availableFrom: usersDataAvailableFromMoment.format(dayMonthYearFormat) }}`}
						</DataAvailableFrom>
					)}
				</InfoPoint>
			)}
		>
			{apiStatus.done && (
				<Stat
					value={totalUsers ? totalUsers.daily_active_users / usersDayRange : 0}
					valueDesc={_`avgDailyActiveUsers`}
					previous={totalUsersPrevPeriod ? totalUsersPrevPeriod.daily_active_users / usersDayRange : 0}
					previousDesc={_`fromPreviousPeriod`}
				/>
			)}
			<Chart
				type="bar"
				legend
				stacked
				apiStatus={apiStatus}
				data={usersForGame}
				getGroupedBy={d => d.is_playing}
				getGroupName={d => (d.is_playing ? _`playing` : _`notPlaying`)}
				keyOrder={[true, false]}
				getGroupColor={isPlaying => {
					if (isPlaying) {
						return theme.dataPurple5;
					}
					return theme.dataPurple3;
				}}
				xRange={[startDate, endDate]}
				xAxis={{
					type: 'date',
					displayName: _`date`,
					field: 'date',
				}}
				yAxis={{
					displayName: _`users`,
					field: 'users',
				}}
				getTooltipAdditionalInfo={d => {
					const users = d.reduce((acc, row) => acc + row.data.users, 0);
					const activeUsers = d.find(row => row.group.key)?.data.users || 0;

					return (
						<TooltipAdditionalInfoContainer>
							<div>
								C2P:
							</div>
							<div>
								{Math.round((activeUsers / users) * 100)}%
							</div>
						</TooltipAdditionalInfoContainer>
					);
				}}
			/>
			<ChartDetails>
				{apiStatus.done && totalUsers && (
					<ChartDetailsInner>
						{
							_`usersWhatDoesThisMean${{
								avgDau: <DetailNumber $color={theme.dataPurple3}>{formatNumber(totalUsers.daily_active_users / usersDayRange, { shorten: true })}</DetailNumber>,
								c2p: <DetailNumber>{Math.round((totalUsers.conversion_to_play || 0) * 100)}%</DetailNumber>,
								avgDpu: <DetailNumber $color={theme.dataPurple5}>{formatNumber(totalUsers.daily_playing_users / usersDayRange, { shorten: true })}</DetailNumber>,
							}}`
						}
					</ChartDetailsInner>
				)}
			</ChartDetails>
		</Card>
	);
};

export default GameUsersChartModule;
