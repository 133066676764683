import React from 'react';
import styled from 'styled-components';

const Tile = styled.div`
	position: relative;

	img {
		border-radius: 16px;
		overflow: hidden;
		margin-bottom: 8px;
	}
`;

const Label = styled.div`
	display: block;
	position: absolute;
	z-index: 3;
	left: -6px;
	right: 0;
	height: 28px;
	top: 8px;
	font: 700 11px/28px 'Proxima Nova', sans-serif;
	color: ${props => props.theme.static.pokiBlue};
	text-transform: uppercase;
	padding: 0 8px 0 6px;
	display: flex;
	justify-content: center;
	align-items: center;

	&::after {
		content:'';
		background: ${props => props.theme.static.grey5};
		width: 6px;
		height: 8px;
		border-radius: 4px 0 0 4px;
		position: absolute;
		bottom: -8px;
		left: 0;
		z-index: 1;
	}

	&::before {
		content: '';
		background: #fff;
		width: 4px;
		height: 4px;
		position: absolute;
		left: 0;
		bottom: -4px;
		z-index: 0;
	}
`;

const LabelHangingPiece = styled.div`
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 3px 0 0;
	width: 3px;
	height: 100%;
`;

const LabelInner = styled.div`
	background: #fff;
	position: absolute;
	top: 0;
	left: 3px;
	display: flex;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
	border-radius: 0 14px 14px 0;
	padding-right: 8px;

	> svg {
		fill: ${props => props.theme.static.pokiBlue};
		width: 18px;
		height: 28px;
		position: relative;
		padding: 2px 0 2px 2px;
		z-index: 2;
	}
`;

const GameTile = props => {
	const { url, width, height, hasLabel = false } = props;

	return (
		<Tile>
			<img src={url} style={{ width, height }} alt={`${width}x${height}`} />
			{hasLabel && (
				<Label>
					<LabelHangingPiece />
					<LabelInner>
						<svg id="fireIcon" viewBox="0 0 24 24">
							<path d="M10.9828 20.0414c-.7856.6508-1.2665 1.5768-1.3389 2.5696-.0774 1.0614-1.2047 1.7069-2.1592 1.2363-3.3837-1.6679-5.5127-5.0707-5.4843-8.759-.0344-3.5216 2.3036-6.8544 5.5934-9.672 1.2717-.9823 2.0509-2.4393 2.1479-4.007C9.8114.2816 11.0557-.367 12.02.2215c1.6728 1.0207 2.7725 2.5369 3.3761 4.3736.5424 1.6504.6382 3.352.4845 4.8893-.024.241-.0494.4224-.069.5348-.1422.8161-.919 1.3625-1.7352 1.2203-.8161-.1422-1.3625-.9191-1.2203-1.7352.0075-.0431.0229-.1528.0394-.3184.1156-1.1565.042-2.463-.3494-3.6541-.131-.3989-.2933-.768-.4892-1.106-.562 1.3001-1.4444 2.4485-2.57 3.3164-2.6095 2.237-4.5099 4.946-4.4867 7.343-.0148 1.9297.8207 3.7436 2.2458 5.017.4234-.912 1.0586-1.7369 1.8806-2.4166.607-.4545.9976-1.1222 1.0886-1.8526.129-1.0362 1.2507-1.6268 2.178-1.1468 2.1897 1.1334 3.8037 3.0503 4.5453 5.3023 1.2283-1.0917 1.9863-2.6305 2.0567-4.2888.0779-2.548-.8015-5.0377-2.4761-7.0039-.5372-.6306-.4614-1.5773.1693-2.1145.6307-.5372 1.5774-.4614 2.1145.1693 2.156 2.5313 3.2916 5.7465 3.1902 9.0585-.1474 3.4788-2.2822 6.5725-5.509 7.9895-.9614.4222-2.0479-.2486-2.1012-1.2972-.0804-1.5807-.7809-3.0497-1.9231-4.1271-.378.651-.8805 1.2214-1.4771 1.6671z" />
						</svg>
					</LabelInner>
				</Label>
			)}
		</Tile>
	);
};

export default GameTile;
