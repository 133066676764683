import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { listAllUpfrontPayments } from 'app/src/epics/additionalPayments';
import { useSelectAllUpfrontPayments } from 'app/src/selectors/additionalPayments';
import { openModal } from 'app/src/actions/client';
import formatNumber from 'app/src/utils/formatNumber';

import FloppyDiskIcon from 'shared/designTokens/icons/ui/small/FloppyDiskIcon';

import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import GridContainer from 'app/src/components/ui/GridContainer';
import ProgressBar from 'app/src/components/ui/ProgressBar';
import GamesTooltip from 'app/src/components/GamesTooltip';
import IconButton from 'app/src/components/ui/IconButton';
import Tooltip from 'app/src/components/ui/Tooltip';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';
import Flag from 'app/src/components/ui/Flag';

import { dayMonthYearFormat } from 'shared/vars';
import _ from 'shared/copy';

const perPage = 16;

const AdminPaymentsUpfrontPaymentsSubPage = () => {
	const dispatch = useDispatch();

	const { data: upfrontPayments, meta: { total: totalUpfrontPayments } = {} } = useSelectAllUpfrontPayments();
	const listAllUpfrontPaymentsStatus = useSelectApiStatus(listAllUpfrontPayments.id);

	const [sort, setSort] = useState({ field: 'start_date', direction: -1 });
	const [page, setPage] = useState(1);

	useEffect(() => {
		dispatch(listAllUpfrontPayments.fetch({ sortField: sort.field, sortDirection: sort.direction, page, perPage }));
	}, [sort, page]);

	const handleExportCSVModal = () => {
		dispatch(openModal({ key: 'export-csv' }));
	};

	return (
		<>
			<Helmet key="AdminPaymentsUpfrontPaymentsSubPage">
				<title>Upfront Payments - Admin - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title={_`upfrontPayments`}
					noPadding
					buttons={(
						<IconButton
							title={_`exportCsv`}
							icon={FloppyDiskIcon}
							onClick={handleExportCSVModal}
						/>
					)}
				>
					<Table
						isLoading={!listAllUpfrontPaymentsStatus.done}
						items={upfrontPayments}
						setSort={setSort}
						sortField={sort.field}
						sortDirection={sort.direction}
						setPagination={setPage}
						page={page}
						perPage={perPage}
						totalItems={totalUpfrontPayments}
						columns={[
							{
								title: _`startDate`,
								content: ({ item }) => moment.utc(item.start_date * 1000).format(dayMonthYearFormat),
								sortField: 'start_date',
							},
							{
								title: _`team`,
								content: ({ item }) => <TeamNameWithFlag team={item.team} overrideLink={`/${item.team.code}/settings/additional-payments`} />,
							},
							{
								title: _`amount`,
								content: ({ item }) => {
									const amountString = `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.amount / 100), { allowDecimals: true })}`;

									return (
										<>
											<Flag countryCode={item.team.billing?.country} />
											{item.internal_notes ? (
												<Tooltip
													text={amountString}
													title={_`internalNotes`}
													body={item.internal_notes}
												/>
											) : amountString}
										</>
									);
								},
								sortField: 'amount',
							},
							{
								title: _`games`,
								content: ({ item }) => <GamesTooltip games={item.games} />,
							},
							{
								title: _`progress`,
								width: 'max-content',
								content: ({ item }) => (
									<ProgressBar
										desc={item.amount_met < item.amount
											? `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.amount_met / 100), { allowDecimals: true })}`
											: _`completedOn${{ date: moment(item.completed_at * 1000).format(dayMonthYearFormat) }}`}
										progress={item.amount_met / item.amount}
										isPaused={item._paused}
										hideProgressIfFull
									/>
								),
								sortField: '_progress',
							},
						]}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default AdminPaymentsUpfrontPaymentsSubPage;
