// This logic is now in sync with the Inspector on how we generate zip files from folder uploads.
// Remember to always keep it up to date with the latest changes in the Inspector.

import JSZip from 'jszip';

const getFileFromEntry = async entry => (
	new Promise((resolve, reject) => {
		entry.file(file => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = () => reject(console.error('Error reading file'));
			reader.readAsArrayBuffer(file);
		});
	})
);

const readEntriesFromDirectory = async directory => (
	new Promise(resolve => {
		const reader = directory.createReader();
		const entries = [];

		// Keep calling readEntries() until no more results are returned.
		const readEntries = () => {
			reader.readEntries(result => {
				if (!result.length) {
					resolve(entries);
				} else {
					entries.push(...result);
					readEntries();
				}
			});
		};
		readEntries();
	})
);

// Recursively add each file and subfolder to the zip file
const addEntryToZip = async (zip, entry, path) => {
	try {
		if (entry.isFile) {
			const file = await getFileFromEntry(entry);
			zip.file(`${path}${entry.name}`, file);
		} else if (entry.isDirectory) {
			zip.folder(`${path}${entry.name}/`);
			const entries = await readEntriesFromDirectory(entry);

			await Promise.all(entries.map(childEntry => (
				addEntryToZip(zip, childEntry, `${path}${entry.name}/`)
			)));
		}
	} catch (error) {
		console.error(`Error adding entry to zip: ${error.message}`);
	}
};

export const generateZipFile = async ({ file, topLevelFolder }) => {
	let fileName = file.name;
	const zip = new JSZip();

	try {
		if (file instanceof FileList) {
			const promises = [];
			[fileName] = file[0].webkitRelativePath.split('/');

			for (let i = 0; i < file.length; i += 1) {
				promises.push(file[i].arrayBuffer().then(fileData => {
					zip.file(file[i].webkitRelativePath, fileData);
				}));
			}

			await Promise.all(promises);
		} else {
			// Recursively add each file and subfolder to the zip file
			await addEntryToZip(zip, topLevelFolder, '');
		}

		const blob = await zip.generateAsync({ type: 'blob' });

		return new File([blob], `${fileName}.zip`, { type: 'application/zip' });
	} catch (err) {
		console.error('Error while creating zip file', err);
		throw err;
	}
};
