import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import styled from 'styled-components';
import { ignoreElements, Subject, tap } from 'rxjs';
import moment from 'moment';
import * as d3 from 'd3';

import { useSelectGameErrorsPerMinute } from 'app/src/selectors/game';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import { getGameErrorsPerMinute } from 'app/src/epics/game';
import Card from 'app/src/components/ui/Card';
import Timer from 'app/src/components/ui/Timer';
import Chart from 'app/src/components/ui/charts/Chart';
import { apiDone, dayMonthYearTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const ChartAndTimerContainer = styled.div`
	position: relative;
`;

const ChartTimer = styled(Timer)`
	position: absolute;
	right: 0;
	top: -15px;
	z-index: 3;
`;

const updateIntervalTime = 5 * 1000;

const GameErrorsChartModule = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const [ignoreApiStatus, setIgnoreApiStatus] = useState(false);
	const [errorTime, setErrorTime] = useState(moment());
	const [startTimer$] = useState(new Subject());

	const errorsPerMinute = useSelectGameErrorsPerMinute(game.id);
	const getGameErrorPerMinuteStatus = useSelectApiStatus(getGameErrorsPerMinute.id);

	const versionIds = useMemo(() => {
		if (!game || !game.versions) return [];

		// Only get the first 10 versions and sort them by created_at
		const sortedVersions = game.versions.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
		const slicedVersions = sortedVersions.map(v => v.id).slice(0, 20);

		return slicedVersions;
	}, [game]);

	useEffect(() => {
		if (!game || !game.versions) return;

		startTimer$.next();

		const updateInterval = window.setInterval(() => {
			const gameId = game.id;

			dispatch(getGameErrorsPerMinute.fetch({ gameId, versionIds }, ({ success$ }) => success$.pipe(
				tap(() => startTimer$.next()),
				ignoreElements(),
			)));
		}, updateIntervalTime);

		return () => {
			window.clearInterval(updateInterval);
		};
	}, [game]);

	useEffect(() => {
		setErrorTime(moment());
	}, [errorsPerMinute]);

	useEffect(() => {
		if (getGameErrorPerMinuteStatus.done || !game.content_metadata) {
			setIgnoreApiStatus(true);
		}
	}, [getGameErrorPerMinuteStatus, game]);

	const { graphErrorsPerMinute, graphErrorsPer10Minutes, maxMinutes } = useMemo(() => {
		const _graphErrorsPerMinute = [];
		const _graphErrorsPer10Minutes = [];
		let _maxMinutes = 0;

		Object.keys(errorsPerMinute).forEach(versionId => {
			// If this version had no errors for the given period, skip it completely
			if (errorsPerMinute[versionId].reduce((total, curr) => total + curr, 0) === 0) return;

			const errorsPer10Minutes = [];

			errorsPerMinute[versionId].forEach((amount, minute) => {
				const version = game.versions.find(v => v.id === versionId);

				_maxMinutes = Math.max(minute, _maxMinutes);

				// Per minute (last 30 minutes)
				if (minute >= errorsPerMinute[versionId].length - 30) {
					_graphErrorsPerMinute.push({
						minute,
						versionId,
						versionName: version ? getVersionLabel(version) : versionId,
						amount,
					});
				}

				// Per 10 minutes
				const interval = Math.floor(minute / 10);

				if (errorsPer10Minutes[interval]) {
					errorsPer10Minutes[interval].amount += amount;
				} else {
					errorsPer10Minutes[interval] = {
						minute,
						versionId,
						versionName: version ? getVersionLabel(version) : versionId,
						amount,
					};
				}
			});

			_graphErrorsPer10Minutes.push(...errorsPer10Minutes);
		});

		return {
			graphErrorsPerMinute: _graphErrorsPerMinute,
			graphErrorsPer10Minutes: _graphErrorsPer10Minutes,
			maxMinutes: _maxMinutes,
		};
	}, [errorsPerMinute, game]);

	return (
		<>
			<Card
				title={_`errorRadar`}
				description={_`errorRadarDesc`}
			>
				<ChartAndTimerContainer>
					{game.content_metadata && (<ChartTimer time={updateIntervalTime} start$={startTimer$} />)}
					<Chart
						type="line"
						aspectRatio={0.2}
						apiStatus={!ignoreApiStatus ? getGameErrorPerMinuteStatus : apiDone}
						data={graphErrorsPerMinute}
						getGroupedBy={d => d.versionId}
						getGroupName={d => d.versionName}
						legend
						showXAxis={false}
						margins={{
							bottom: 10,
						}}
						xAxis={{
							type: 'number',
							displayName: _`minute`,
							field: 'minute',
							customFormat: value => _`xMinutes${{ amount: -((maxMinutes - value) + 1) }}`,
						}}
						yAxis={{
							displayName: _`amount`,
							field: 'amount',
						}}
						curve={d3.curveStep}
					/>
				</ChartAndTimerContainer>
			</Card>
			<Card
				title={_`errorScanner`}
				description={_`errorScannerDesc`}
			>
				<Chart
					type="line"
					aspectRatio={0.2}
					apiStatus={!ignoreApiStatus ? getGameErrorPerMinuteStatus : apiDone}
					data={graphErrorsPer10Minutes}
					getGroupedBy={d => d.versionId}
					getGroupName={d => d.versionName}
					legend
					showXAxis={false}
					margins={{
						bottom: 10,
					}}
					xAxis={{
						type: 'number',
						displayName: _`minute`,
						field: 'minute',
						customFormat: value => {
							let time = errorTime.clone().subtract(24, 'hours').add(value, 'minute');
							time = time.subtract(time.minute() % 10, 'minute');
							return moment(time).format(dayMonthYearTimeFormat);
						},
					}}
					yAxis={{
						displayName: _`amount`,
						field: 'amount',
					}}
					curve={d3.curveStep}
				/>
			</Card>
		</>
	);
};

export default GameErrorsChartModule;
