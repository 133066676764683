import React from 'react';

const Star3 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path fillRule="evenodd" clipRule="evenodd" d="M24.5592 80.7192L9.03922 108.519L48.0792 101.959L70.6392 129.079L82.2392 101.839L106.119 110.559L99.3592 85.3592L133.199 67.0392L91.8392 59.5192L93.3592 47.2792L86.9592 11.1992L66.5592 33.5192L55.3592 26.0392L54.6392 43.3992L10.8792 30.7592L26.5992 61.8392L7.19922 74.4392L24.5592 80.7192Z" fill="#FFD100" />
			<path d="M30.6792 69.8419L24.5592 80.7219L7.19922 74.4419L30.1992 68.9219L30.6792 69.8419Z" fill="#FF9E00" />
			<path d="M61.1596 39.3991L55.3196 26.0391L54.5996 43.3991L56.8796 44.1191L61.1596 39.3991Z" fill="#FFAF00" />
			<path d="M90.3188 71.9992L86.2787 92.4792L74.7188 50.4392L86.9587 11.1992L93.3988 47.2792L90.3188 71.9992Z" fill="#FFDC00" />
			<path d="M26.6392 61.8789L7.19922 74.4389L30.1992 68.9189L26.6392 61.8789Z" fill="#FFAF00" />
			<path d="M9.04102 108.52L30.681 69.8395L49.881 61.5195L44.441 86.8795L9.04102 108.52Z" fill="#FFDC00" />
			<path d="M62.561 99.5589L9.04102 108.519L44.441 86.8789L62.561 99.5589Z" fill="#FFAF00" />
			<path d="M10.8809 30.7617L30.6409 69.8417L49.8409 61.5217L10.8809 30.7617Z" fill="#FFD100" />
			<path d="M54.6009 43.4417L10.8809 30.7617L49.8809 61.5217L74.7209 50.4417L56.8409 44.1217L54.6009 43.4417Z" fill="#FFDC00" />
			<path d="M86.2809 92.4805L49.8809 61.5605L74.7209 50.4805L86.2809 92.4805Z" fill="#FFD100" />
			<path d="M44.4395 86.8786L62.5595 99.5586L86.2795 92.4786L49.8795 61.5586L44.4395 86.8786Z" fill="#FFC500" />
			<path d="M86.9609 11.1992L56.8809 44.1192L74.7609 50.4392L86.9609 11.1992Z" fill="#FFED85" />
			<path d="M91.8395 59.5586L133.2 67.0786L90.5195 70.1986L91.8395 59.5586Z" fill="#FFC500" />
			<path d="M86.2812 92.4784L106.121 110.598L99.3612 85.3984L86.2812 92.4784Z" fill="#FFAF00" />
			<path d="M86.2812 92.4781L90.3213 71.9981L90.5612 70.1981L133.241 67.0781L86.2812 92.4781Z" fill="#FFE200" />
			<path d="M86.2806 92.4805L70.6406 129.12L62.5605 99.5605L86.2806 92.4805Z" fill="#FFD100" />
			<path d="M48.0801 101.96L70.6401 129.08L62.5601 99.5195L48.0801 101.96Z" fill="#FFDC00" />
			<path d="M55.3203 26.0391L61.1603 39.3991L66.5203 33.5191L55.3203 26.0391Z" fill="#FFC500" />
			<path d="M106.12 110.6L82.2402 101.88L86.2402 92.4805L106.12 110.6Z" fill="#FF9E00" />
		</svg>
	);
};

export default Star3;
