export const isThisShare = (share, { gameId, teamId, source, playedOn, specialCondition }) => {
	if (gameId) {
		return share.game_id === gameId
			&& (!source || share.trigger_source === source)
			&& (!playedOn || share.played_on === playedOn)
			&& (!specialCondition || share.special_condition === specialCondition);
	}

	if (teamId) {
		return share.team_id === teamId
			&& (!source || share.trigger_source === source)
			&& (!playedOn || share.played_on === playedOn)
			&& (!specialCondition || share.special_condition === specialCondition);
	}

	return false;
};

export const getShare = (options, revShares) => {
	if (!revShares) return undefined;
	const share = revShares.find(s => isThisShare(s, options));
	return share ? share.developer_share : undefined;
};

export const sharesContainNonZeroShares = revShares => {
	if (!revShares) return false;
	const share = revShares.find(s => s.developer_share > 0);
	return !!share;
};

export const gameHasNonZeroShare = (game, revShares) => {
	if (!revShares) return false;
	const share = revShares.find(s => (isThisShare(s, { gameId: game.id }) || isThisShare(s, { teamId: game.team.id })) && s.developer_share > 0);
	return !!share;
};
