const prefixes = ['angry', 'amazing', 'ultimate', 'mysterious', 'obscure', 'fancy', 'funky', 'crossy', 'tap', 'clicker', 'skiing', 'falling', 'super', 'boost', 'shell', 'sling', 'rodeo', 'crazy', 'rooftop', 'bomber', 'pointy', 'rolly', 'rolling', 'double', 'virtual', 'original', 'total', 'triple', 'happy', 'steppy', 'jelly', 'magic', 'idle', 'kick the', 'break the', 'merge', 'interactive', 'my', 'talking', 'audrey\'s', 'baby\'s first', 'extreme', 'grippy', 'loopy', 'hidden', 'smart', 'pixel', 'real', 'random', 'massive', 'charming', 'epic', 'fruity', 'sweet', 'mini', 'giant', 'tiny', 'bubbly', 'save the', 'little'];
const subjects = ['android', 'boom', 'beach', 'beef', 'station', 'wolf', 'glass', 'banana', 'train', 'road', 'snout', 'ham', 'bob', 'kong', 'town', 'subway', 'iron', 'slime', 'dating', 'king', 'knight', 'survival', 'princess', 'doctor', 'hair', 'zoi', 'booze', 'piano', 'library', 'party', 'rogue', 'chicken', 'horse', 'penguin', 'sloth', 'geometry', 'burger', 'cooking', 'factory', 'town', 'cave', 'pants', 'judge', 'shoe', 'salon', 'money', 'candy', 'paint', 'stickman', 'master', 'cookie', 'story', 'helix', 'piñata', 'dragon', 'ball', 'brawl', 'picnic', 'bottle', 'tower', 'soda', 'bullet', 'hero', 'sky', 'yoga', 'guru', 'buddha', 'hacker', 'news', 'space', 'fruit', 'ninja', 'samurai', 'shock', 'lightning', 'prototype', 'cowboy', 'water', 'bubble', 'palace', 'skate', 'coffee', 'freeze', 'ice', 'vanilla', 'summer', 'business', 'warfare', 'elevator', 'guitar', 'trash', 'boiler', 'room', 'plant', 'tree', 'zombie', 'pizza', 'fire', 'marble', 'tunnel'];
const suffixes = [];

for (let i = 0; i < 5; i++) suffixes.push('games', 'game studio');
for (let i = 0; i < 3; i++) suffixes.push('studio', 'studios');
for (let i = 0; i < 2; i++) suffixes.push('independent');
for (let i = 0; i < 1; i++) suffixes.push('enterprises', 'united');

const illegalNames = [];

const getRandom = array => array[Math.floor(Math.random() * array.length)];

const capitalize = string => string.replace(/(?:^|\s)\S/g, a => a.toUpperCase());

const getRandomTitle = () => {
	let chance = 0.5;
	let usedSuffix = false;

	let subject = '';
	if (Math.random() < 0.8) {
		subject = getRandom(subjects);
	} else {
		usedSuffix = true;
		subject = getRandom(suffixes);
	}

	let prefix = '';
	if (Math.random() < chance || usedSuffix) {
		if (Math.random() < 0.2) {
			prefix = getRandom(subjects);
		} else {
			prefix = getRandom(prefixes);
		}
	} else {
		chance = 1;
	}

	let result = `${prefix} ${subject}`;

	if (Math.random() < chance && !usedSuffix) {
		result = `${result} ${getRandom(suffixes)}`;
		usedSuffix = true;
	}

	result = result.replace(/ +/, ' ');

	if (result.split(' ').length === 1) { // We never want to end up with a one word name
		if (!usedSuffix) {
			result = `${result} ${getRandom(suffixes)}`;
			usedSuffix = true;
		} else {
			result = `${getRandom(prefixes)} ${result}`;
		}
	}

	if (!usedSuffix) {
		// Always add the suffix
		result = `${result} ${getRandom(suffixes)}`;
	}

	return capitalize(result.trim());
};

const getRandomDeveloperDisplayName = () => {
	let result = '';
	do {
		result = getRandomTitle();
	} while (illegalNames.includes(result.toLowerCase()));

	return result;
};

export default getRandomDeveloperDisplayName;
