import { useSelector } from 'react-redux';

export const selectUpfrontPaymentsByTeamId = (state, teamId) => (state.additionalPayments.upfrontPaymentsByTeamId[teamId] ? state.additionalPayments.upfrontPaymentsByTeamId[teamId] : {});
export const selectAllUpfrontPayments = state => (state.additionalPayments.allUpfrontPayments ? state.additionalPayments.allUpfrontPayments : null);
export const selectMinimumGuaranteesByTeamId = (state, teamId) => (state.additionalPayments.minimumGuaranteesByTeamId[teamId] ? state.additionalPayments.minimumGuaranteesByTeamId[teamId] : {});
export const selectAllMinimumGuarantees = state => (state.additionalPayments.allMinimumGuarantees ? state.additionalPayments.allMinimumGuarantees : null);
export const selectRecurringMonthlyPaymentsByTeamId = (state, teamId) => (state.additionalPayments.recurringMonthlyPaymentsByTeamId[teamId] ? state.additionalPayments.recurringMonthlyPaymentsByTeamId[teamId] : {});
export const selectAllRecurringMonthlyPayments = state => (state.additionalPayments.allRecurringMonthlyPayments ? state.additionalPayments.allRecurringMonthlyPayments : null);
export const selectOneOffPaymentsByTeamId = (state, teamId) => (state.additionalPayments.oneOffPaymentsByTeamId[teamId] ? state.additionalPayments.oneOffPaymentsByTeamId[teamId] : {});
export const selectAllOneOffPayments = state => (state.additionalPayments.allOneOffPayments ? state.additionalPayments.allOneOffPayments : null);

export const useSelectUpfrontPaymentsByTeamId = teamId => useSelector(state => selectUpfrontPaymentsByTeamId(state, teamId));
export const useSelectAllUpfrontPayments = () => useSelector(state => selectAllUpfrontPayments(state));
export const useSelectMinimumGuaranteesByTeamId = teamId => useSelector(state => selectMinimumGuaranteesByTeamId(state, teamId));
export const useSelectAllMinimumGuarantees = () => useSelector(state => selectAllMinimumGuarantees(state));
export const useSelectRecurringMonthlyPaymentsByTeamId = teamId => useSelector(state => selectRecurringMonthlyPaymentsByTeamId(state, teamId));
export const useSelectAllRecurringMonthlyPayments = () => useSelector(state => selectAllRecurringMonthlyPayments(state));
export const useSelectOneOffPaymentsByTeamId = teamId => useSelector(state => selectOneOffPaymentsByTeamId(state, teamId));
export const useSelectAllOneOffPayments = () => useSelector(state => selectAllOneOffPayments(state));
