import React from 'react';
import styled, { css } from 'styled-components';

import AnnouncementIcon from 'shared/designTokens/icons/ui/medium/AnnouncementIcon';
import TrophyIcon from 'shared/designTokens/icons/ui/medium/TrophyIcon';
import HealthIcon from 'shared/designTokens/icons/ui/medium/HealthIcon';
import BestPracticesIcon from 'shared/designTokens/icons/ui/medium/BestPracticesIcon';
import PencilIcon from 'shared/designTokens/icons/ui/medium/PencilIcon';
import CoinIcon from 'shared/designTokens/icons/ui/medium/CoinIcon';

const DescriptionWrapper = styled.div`
	display: flex;
	max-width: 634px;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;

	color: ${props => props.theme.denimBlue};
	font: 400 16px/24px "Proxima Nova";
`;

const OfferContainerWrapper = styled.div``;

const OfferContainer = styled.div`
	display: flex;
	padding: 40px;
	flex-direction: column;
	align-items: flex-start;
	gap: 28px;
	align-self: stretch;

	border-radius: 12px;
	background: ${props => props.theme.grey7};
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	align-items: center;
`;

const OfferDescription = styled.div`
	font: 400 14px/20px "Proxima Nova";
`;

const OfferTitle = styled.h4`
	font-weight: 700;
	margin: 0 4px 0 0;
	display: inline;
`;

const OfferItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 8px 0 0;
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0 20px;
`;

const sharedIconStyles = css`
  min-width: 36px;
  min-height: 36px;
  margin-bottom: auto;
  & path {
    fill: ${props => props.theme.denimBlue};
  }
`;

const StyledTrophyIcon = styled(TrophyIcon)`${sharedIconStyles}`;
const StyledAnnouncementIcon = styled(AnnouncementIcon)`${sharedIconStyles}`;
const StyledHealthIcon = styled(HealthIcon)`${sharedIconStyles}`;
const StyledChecklistStarIcon = styled(BestPracticesIcon)`${sharedIconStyles}`;
const StyledPencilIcon = styled(PencilIcon)`${sharedIconStyles}`;
const StyledCoinIcon = styled(CoinIcon)`${sharedIconStyles}`;

const GetActiveDescription = () => (
	<>
		<div>
			Through the performance review we found out that:
			<StyledList>
				<li>We would be proud to have your game on Poki.</li>
				<li>The Poki audience loves your game too.</li>
				<li>Technically your game is sound.</li>
			</StyledList>
		</div>
		<div>
			We&apos;d like to move forward with your game. We want your game on Poki. We&apos;ll put time and effort in making sure your game has the highest chance of a successful release. We&apos;ll write you QA reports and take care of the monetisation. And you will also be putting work into getting your game ready for release. There we need an agreement, in short this is what we want to offer you:
		</div>
	</>
);

const GetInactiveDescription = () => (
	<div>
		If the Web Fit Test shows promising results, we&apos;ll continue to get your game ready for release on Poki. What that looks like for us both:
	</div>
);

const Level4Description = ({ active = false, isTeamVerified = false }) => (
	<DescriptionWrapper>
		{active ? <GetActiveDescription /> : <GetInactiveDescription />}
		<OfferContainerWrapper>
			<OfferContainer>
				<div>
					What Poki does
					<OfferItem>
						<IconWrapper>
							<StyledAnnouncementIcon />
							<OfferDescription>
								<OfferTitle>Marketing and promotion of your game</OfferTitle>to reach 60 million players and beyond.
							</OfferDescription>
						</IconWrapper>
						<IconWrapper>
							<StyledTrophyIcon />
							<OfferDescription>
								<OfferTitle>Monetization</OfferTitle>by securing top tier CPMs through the best brand deals.
							</OfferDescription>
						</IconWrapper>
					</OfferItem>
				</div>
				<div>
					What you do
					<OfferItem>
						<IconWrapper>
							<StyledHealthIcon />
							<OfferDescription>
								<OfferTitle>Work together with a dedicated Poki account manager</OfferTitle>to help you get the most out of our partnership.
							</OfferDescription>
						</IconWrapper>
						<IconWrapper>
							<StyledChecklistStarIcon />
							<OfferDescription>
								<OfferTitle>Improve your game with the help of our extensive QA reports</OfferTitle>with recommendations on how to get your game in the best shape for web and our audience.
							</OfferDescription>
						</IconWrapper>
					</OfferItem>
				</div>
				{!isTeamVerified && (
					<div>
						The deal
						<OfferItem>
							<IconWrapper>
								<StyledPencilIcon />
								<OfferDescription>
									<OfferTitle>A 3 year exclusive commitment -</OfferTitle>That&apos;s what it takes to grow your game and achieve our mutual goals. We&apos;re in it for the long run.
								</OfferDescription>
							</IconWrapper>
							<IconWrapper>
								<StyledCoinIcon />
								<div>
									<OfferDescription>
										<OfferTitle>100% of the game&apos;s earnings when you bring the player * -</OfferTitle>For example when a player searches for your game title in Google or a player saves your game link and lands directly on your game.<br /><i>* Starting Late Summer 2024.</i>
									</OfferDescription>
									<br />
									<OfferDescription>
										<OfferTitle>50% of the game&apos;s earnings when Poki brings the player -</OfferTitle>For example through Poki promotions or marketing campaigns.
									</OfferDescription>
								</div>
							</IconWrapper>
						</OfferItem>
					</div>
				)}
			</OfferContainer>
		</OfferContainerWrapper>
	</DescriptionWrapper>
);

export default Level4Description;
