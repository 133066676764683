import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
	flex-grow: 1;

	${props => props.noPadding && 'padding: 0;'}
`;

/** @component */
export default Container;
