import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { ignoreElements, tap } from 'rxjs';
import styled from 'styled-components';
import moment from 'moment';

import { openModal } from 'app/src/actions/client';
import { archiveVersion, listInactiveVersionsByGameId } from 'app/src/epics/game';
import { useSelectInactiveVersionListByGameId } from 'app/src/selectors/game';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import isVersionActive from 'app/src/utils/isVersionActive';

import ArchiveIcon from 'shared/designTokens/icons/ui/small/ArchiveIcon';

import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';
import Button from 'app/src/components/ui/Button';
import Tooltip from 'app/src/components/ui/Tooltip';
import VersionReviewStatus from 'app/src/components/ui/VersionReviewStatus';
import WarningMessage from 'app/src/components/ui/WarningMessage';
import VersionActions from 'app/src/components/ui/VersionActions';
import VersionNotes from 'app/src/components/ui/VersionNotes';
import VersionLabel from 'app/src/components/ui/VersionLabel';
import IconButton from 'app/src/components/ui/IconButton';

import { dayMonthYearTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const PER_PAGE = 10;

const ArchiveButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`;

const InactiveVersionsTable = props => {
	const { game, actionCounter, hasAnyLabelFocus, setHasAnyLabelFocus } = props;

	const dispatch = useDispatch();
	const permissions = useSelectPermissions();

	const [page, setPage] = useState(1);
	const [busy, setBusy] = useState(false);
	const [sort, setSort] = useState({ field: 'created_at', direction: -1 });
	const [pollIndicator, setPollIndicator] = useState(0); // Internal polling refresh mechanism

	const { data: inactiveVersions, meta: { total: totalInactiveVersions } = {} } = useSelectInactiveVersionListByGameId(game.id);
	const listInactiveVersionsByGameIdStatus = useSelectApiStatus(listInactiveVersionsByGameId.id);
	const canEditVersions = checkPermissions(permissions, [['can_edit_all_versions', 'can_edit_owned_versions']]);

	const isPolling = pollIndicator > 0;

	useEffect(() => {
		if (!hasAnyLabelFocus) {
			dispatch(listInactiveVersionsByGameId.fetch({ gameId: game.id, sortField: sort.field, sortDirection: sort.direction, page, perPage: PER_PAGE }));
		}
	}, [game?.id, sort, page, actionCounter, pollIndicator, hasAnyLabelFocus]);

	useEffect(() => {
		let timeout;

		// As long as there are versions in a processing state, we keep 'polling'
		if (inactiveVersions?.filter(v => ['uploading', 'processing', 'optimizing'].includes(v.state)).length > 0) {
			timeout = setTimeout(() => {
				setPollIndicator(pi => pi + 1);
			}, 1000 * (pollIndicator + 1));
		} else {
			setPollIndicator(0);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [inactiveVersions, pollIndicator]);

	const doArchive = item => {
		setBusy(true);

		dispatch(archiveVersion.fetch({ gameId: game.id, versionId: item.id }, ({ success$ }) => (
			success$.pipe(
				tap(() => setBusy(false)),
				ignoreElements(),
			)
		)));
	};

	return (
		<Card
			title={game.approved ? _`inactiveVersions` : _`versions`}
			noPadding
			buttons={(
				canEditVersions && (
					<Button onClick={() => dispatch(openModal({ key: 'create-game-version', data: { gameId: game.id } }))}>
						{_`uploadVersion`}
					</Button>
				)
			)}
		>
			<Table
				isLoading={(!listInactiveVersionsByGameIdStatus.done && !isPolling) || busy}
				items={inactiveVersions}
				itemAlign="top"
				setSort={setSort}
				sortField={sort.field}
				sortDirection={sort.direction}
				setPagination={setPage}
				page={page}
				perPage={PER_PAGE}
				totalItems={totalInactiveVersions}
				columns={[
					{
						title: _`uploadedAt`,
						width: 'max-content',
						content: ({ item }) => {
							const text = moment(item.created_at * 1000).format(dayMonthYearTimeFormat);
							const flags = item.flags ? item.flags.split(',') : [];

							if (flags.includes('google-analytics-replaced')) {
								return (
									<Tooltip
										text={text}
										body={<WarningMessage message={_`googleAnalyticsRemovedOnVersion`} />}
										direction="right"
									/>
								);
							}

							return text;
						},
						sortField: 'created_at',
					},
					{
						title: '',
						mobileTitle: _`versionNotes`,
						width: 'min-content',
						content: ({ item }) => <VersionNotes item={item} canEditVersions={canEditVersions} />,
						mobileContent: ({ item }) => (
							<Button
								secondary
								onClick={() => dispatch(openModal({ key: 'edit-version-notes', data: { version: item } }))}
							>
								{_`editVersionNotes`}
							</Button>
						),
					},
					{
						title: _`name`,
						content: ({ item }) => (
							item.state !== 'error' ? (
								<VersionLabel
									setHasAnyLabelFocus={setHasAnyLabelFocus}
									key={`label-${item.id}`}
									item={item}
								/>
							) : (
								<div>{_`invalidVersion`}</div>
							)
						),
					},
					inactiveVersions && {
						title: game.approved ? _`reviewStatus` : _`status`,
						content: ({ item }) => (
							<VersionReviewStatus
								game={game}
								item={item}
							/>
						),
					},
					{
						title: _`actions`,
						width: 'max-content',
						content: ({ item }) => (
							item.state !== 'error' ? (
								<VersionActions
									game={game}
									item={item}
									extendedActions={[
										canEditVersions && !isVersionActive(item, game) && {
											icon: ArchiveIcon,
											name: _`archive`,
											onClick: () => doArchive(item),
										},
									]}
								/>
							) : (
								<ArchiveButtonWrapper>
									<IconButton
										title={_`archive`}
										icon={ArchiveIcon}
										onClick={() => doArchive(item)}
									>
										{_`archive`}
									</IconButton>
								</ArchiveButtonWrapper>
							)
						),
					},
				]}
			/>
		</Card>
	);
};

export default InactiveVersionsTable;
