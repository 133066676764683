const parseAndDisplayOperatingSystem = (name, version) => {
	let versionName = version;

	if (name.toLowerCase() === 'windows') {
		if (version.startsWith('4.0')) {
			versionName = `95 (${version})`;
		} else if (version.startsWith('4.1')) {
			versionName = `98 (${version})`;
		} else if (version.startsWith('4.9')) {
			versionName = `ME (${version})`;
		} else if (version.startsWith('5.0')) {
			versionName = `2000 (${version})`;
		} else if (version.startsWith('5.1') || version.startsWith('5.2')) {
			versionName = `XP (${version})`;
		} else if (version.startsWith('6.0')) {
			versionName = `Vista (${version})`;
		} else if (version.startsWith('6.1')) {
			versionName = `7 (${version})`;
		} else if (version.startsWith('6.2')) {
			versionName = `8 (${version})`;
		} else if (version.startsWith('6.3')) {
			versionName = `8.1 (${version})`;
		}
	}

	return `${name} ${versionName !== '0.0.0' ? versionName : ''}`;
};

export default parseAndDisplayOperatingSystem;
