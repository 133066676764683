import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import deepEqual from 'fast-deep-equal';
import { fromEvent, timer, tap, debounce } from 'rxjs';

import { preventPageLeave } from 'app/src/actions/client';

/**
 * useTrackUnsavedChanges
 *
 * Keeps track of whether there are unsaved changes or not.
 * resetFormData should be called if the form is saved and therefore the source of truth is changed
 *
 * @param {Ref} formRef ref of the form
 * @param {Arrray<any>} fields values of any fields in the form
 */

const useTrackUnsavedChanges = formRef => {
	const dispatch = useDispatch();

	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [originalFormData, setOriginalFormData] = useState();

	const checkUnsavedChanges = useCallback(() => {
		if (!originalFormData || !formRef.current) return;

		const originalData = Object.fromEntries(originalFormData);
		const newData = Object.fromEntries(new FormData(formRef.current));
		const unsavedChangesCheck = !deepEqual(originalData, newData);

		setUnsavedChanges(unsavedChangesCheck);
	}, [originalFormData, formRef.current]);

	const resetFormData = useCallback(() => {
		if (!formRef.current) return;

		const formData = new FormData(formRef.current);
		setOriginalFormData(formData);
	}, [formRef.current]);

	useEffect(() => {
		window.setTimeout(() => {
			// Reset form data after 1 frame
			// We always give one frame leeway because some inputs need it to come to final values
			resetFormData();
		}, 0);
	}, [formRef.current]);

	useEffect(() => {
		if (!formRef.current) return;

		checkUnsavedChanges();

		const changeListener = fromEvent(formRef.current, 'input').pipe(
			debounce(() => timer(50)),
			tap(checkUnsavedChanges),
		).subscribe();

		return () => {
			changeListener.unsubscribe();
		};
	}, [originalFormData, formRef.current]);

	useEffect(() => {
		if (unsavedChanges) {
			dispatch(preventPageLeave({ toggle: true }));
		} else {
			dispatch(preventPageLeave({ toggle: false }));
		}
	}, [unsavedChanges]);

	return { unsavedChanges, resetFormData };
};

export default useTrackUnsavedChanges;
