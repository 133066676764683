import React from 'react';
import styled from 'styled-components';

import Container from 'app/src/components/ui/Container';
import Spinner from 'app/src/components/ui/Spinner';

const StyledSpinner = styled(Spinner)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale3d(0.3, 0.3, 1);
`;

const FontPreloader = styled.div`
	font-family: ${props => props.font};
	opacity: 0;
	position: absolute;
	top: 0;

	::after {
		content: 'Preloading font...'; /* This text is never rendered, fonts just need content to get loaded */
	}
`;

const PageLoader = () => (
	<Container>
		<StyledSpinner />
		<FontPreloader font="Proxima Nova" />
		<FontPreloader font="Torus" />
	</Container>
);

export default PageLoader;
