import React from 'react';
import styled from 'styled-components';
import lodash from 'lodash';

import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';

import Tooltip from 'shared/components/Tooltip';

import _ from 'shared/copy';

const StyledTooltip = styled(Tooltip)`
	color: ${props => props.theme.grey3};
	border-bottom: 2px dotted ${props => props.theme.grey5};
`;

const GamesTooltipContent = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: nowrap;
`;

const GamesTooltipGame = styled.div`
	display: flex;
	align-items: center;
	margin-top: 8px;
`;

const GamesTooltipTitle = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const GamesTooltipThumnbnail = styled.div`
	position: relative;
	border-radius: 4px;
	background: ${props => (props.url ? `url("${props.url}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 20px;
	width: 20px;
	margin-right: 8px;
`;

const GamesTooltip = ({ games = [] }) => {
	const gamesCount = games.length;
	const gamesCountText = gamesCount === 1 ? `${gamesCount} game` : `${gamesCount} games`;

	return (
		<StyledTooltip
			arrow
			placement="top-end"
			content={(
				<>
					<strong>{_`games`}</strong>
					<GamesTooltipContent>
						{lodash.chunk(games, 10).map((chunk, chunkIdx) => (
							<div key={`chunk-${chunkIdx}`}> { /* eslint-disable-line react/no-array-index-key */}
								{chunk.map(game => (
									<GamesTooltipGame key={`game-${game.id}`}>
										<GamesTooltipThumnbnail url={getGameThumbnailUrl(game.thumbnail_url, 20)} />
										<GamesTooltipTitle>{game.title}</GamesTooltipTitle>
									</GamesTooltipGame>
								))}
							</div>
						))}
					</GamesTooltipContent>
				</>
			)}
		>
			{gamesCountText}
		</StyledTooltip>
	);
};

export default GamesTooltip;
