import React from 'react';
import { Helmet } from 'react-helmet';

import { useSelectActiveTeam } from 'app/src/selectors/team';

import GameGeneralSettingsModule from 'app/src/components/modules/GameGeneralSettingsModule';
import GameBeaconSnippetModule from 'app/src/components/modules/GameBeaconSnippetModule';
import GameUploadTokenModule from 'app/src/components/modules/GameUploadTokenModule';
import GridContainer from 'app/src/components/ui/GridContainer';

const GameSettingsSubPage = props => {
	const { game } = props;

	const activeTeam = useSelectActiveTeam();

	return (
		<GridContainer cols={1}>
			<Helmet key={`GameSettingsSubPage-${game.id}-${activeTeam.code}`}>
				<title>Settings - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GameGeneralSettingsModule game={game} />
			{activeTeam.verified && (
				<>
					<GameUploadTokenModule game={game} />
					<GameBeaconSnippetModule game={game} />
				</>
			)}
		</GridContainer>
	);
};

export default GameSettingsSubPage;
