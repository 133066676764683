import React from 'react';
import styled from 'styled-components';

import { useSelectToasts } from 'app/src/selectors/client';
import Toast from 'app/src/components/ui/Toast';
import { navigationWidth } from 'shared/vars';

const Container = styled.div`
	position: fixed;
	top: 0;
	height: 100vh;
	left: ${navigationWidth}px;
	right: 0;
	z-index: 5;
	pointer-events: none;
`;

const ToastContainer = () => {
	const toasts = useSelectToasts();

	return (
		<Container>
			{
				toasts
					.filter(toast => toast !== null)
					.map(({ index, body, type }) => <Toast key={index} index={index} body={body} type={type} />)
			}
		</Container>
	);
};

export default ToastContainer;
