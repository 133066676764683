import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { fromEvent, tap } from 'rxjs';

import CalendarIcon from 'shared/designTokens/icons/ui/small/CalendarIcon';

import { motionSpeed02, motionOut, dayMonthYearFormat } from 'shared/vars';
import _ from 'shared/copy';
import DatePicker from 'app/src/components/input/DatePicker';
import Card from 'app/src/components/ui/Card';

const PickerContainer = styled.div`
	position: absolute;
	top: 88px;
`;

const PickerCard = styled(Card)`
	width: 300px;
`;

const StyledInput = styled.input`
	display: block;
	padding: 0 16px;
	line-height: 48px;
	height: 48px;
	width: 100%;
	background: ${props => props.theme.grey7};
	color: ${props => props.theme.denimBlue};
	font-size: 16px;
	border: 1px solid ${props => props.theme.grey5};
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	border-radius: 8px;

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	${props => props.focus && `
	outline: none;
	border-color: ${props.theme.pokiBlue};
	box-shadow: inset 0 0 0 2px ${props.theme.pokiBlue};
	background: ${props.theme.grey9};
	`}

	${props => (props.disabled ? `
	color: ${props.theme.grey3};
	cursor: not-allowed;
	border: none;
	padding: 0 17px;
	` : `
	&:hover {
		background: ${props.theme.grey9};
	}
	`)}

	${props => props.error && `
	border-color: ${props.theme.rose1};
	background: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}

	${props => props.valid && `
	border-color: ${props.theme.green1};
	background: ${props.theme.green9};
	`}
`;

const InputContainer = styled.div`
	position: relative;
	margin-bottom: 8px;

	> svg {
		position: absolute;
		top: 12px;
		right: 12px;
		pointer-events: none;

		[fill] {
			fill: ${props => props.theme.grey5};
		}
	}
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;

	${props => props.$active && `
	z-index: 2;
	`}
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${StyledInput} {
		margin-top: 4px;
	}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;

	${props => props.shortDesc && `
	min-height: 18px;
	text-align: right;
	margin-top: 4px;
	`}
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Prefix = styled.div`
	position: absolute;
	pointer-events: none;
	left: 0;
	top: 0;
	padding: 0 0 0 16px;
	font-weight: 400;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const DateInput = props => {
	const { className, name, prefix, errors = [], description, descriptionHTML, disabled, label, value = '', valueSetter, onChange, maxLength, shortDesc, valid, required, minDate, maxDate } = props;

	const containerRef = useRef();
	const [active, setActive] = useState(false);

	const valueAsMoment = value ? (typeof value === 'string' ? moment.utc(value, dayMonthYearFormat) : moment.utc(value)) : null;

	const handleChange = v => {
		const date = moment.utc(v, dayMonthYearFormat).toDate();

		if (valueSetter) valueSetter(date);
		if (onChange) onChange(date);
	};

	const onSet = date => {
		setActive(false);

		handleChange(date);
	};

	const handleFocus = () => {
		setActive(true);
	};

	// Exit on clicking outside
	useEffect(() => {
		if (!active || !containerRef.current) return;

		const click$ = fromEvent(document.body, 'click').pipe(
			tap(e => {
				if (!containerRef.current.contains(e.target)) {
					setActive(false);
				}
			}),
		);

		const subscription = click$.subscribe();

		return () => {
			if (subscription) subscription.unsubscribe();
		};
	}, [active, containerRef]);

	const prefixRef = useRef();
	const inputRef = useRef();

	const input = (
		<InputContainer>
			<CalendarIcon />
			<StyledInput
				ref={inputRef}
				id={name}
				type="text"
				name={name}
				disabled={disabled}
				readOnly
				placeholder={dayMonthYearFormat.toLowerCase()}
				value={valueAsMoment ? valueAsMoment.format(dayMonthYearFormat) : ''}
				onChange={handleChange}
				maxLength={maxLength}
				onFocus={handleFocus}
				error={errors.length > 0}
				valid={valid}
				focus={active}
			/>
			{prefix && <Prefix ref={prefixRef}>{prefix}</Prefix>}
		</InputContainer>
	);

	useLayoutEffect(() => {
		if (!prefixRef.current || !inputRef.current) return;

		inputRef.current.style.paddingLeft = `${prefixRef.current.getBoundingClientRect().width + 4}px`;
	}, [prefixRef, inputRef]);

	return (
		<StyledFieldset className={className} ref={containerRef} $active={active}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0} valid={valid}>{label}{input}</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					descriptionHTML ? (
						<Description shortDesc={shortDesc} dangerouslySetInnerHTML={{ __html: description }} />
					) : (
						<Description shortDesc={shortDesc}>{description}</Description>
					)
				)
			)}
			{active && (
				<PickerContainer>
					<PickerCard elevated noPadding>
						<DatePicker moment={valueAsMoment} minMoment={minDate && moment.utc(minDate)} maxMoment={maxDate && moment.utc(maxDate)} onSet={onSet} />
					</PickerCard>
				</PickerContainer>
			)}
		</StyledFieldset>
	);
};

export default DateInput;
