import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, tap, ignoreElements } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import moment from 'moment';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import LabeledValue from 'app/src/components/ui/LabeledValue';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import { cancelReviewRequest, getReviewById } from 'app/src/epics/review';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import getReviewStatusText from 'app/src/utils/getReviewStatusText';
import getTimeSince from 'app/src/utils/getTimeSince';
import ExitLinkIcon from 'shared/designTokens/icons/ui/small/ExitLinkIcon';
import UserImage from 'app/src/components/ui/UserImage';
import { useSelectReviewById } from 'app/src/selectors/review';
import { useSelectUser } from 'app/src/selectors/user';
import { dayMonthYearTimeFormat } from 'shared/vars';

const StyledModal = styled(Modal)`
	${props => (props.$status === 'rejected' ? `
	border: 6px solid ${props.theme.rose5};
	` : props.$status === 'approved' ? `
	border: 6px solid ${props.theme.green1};
	` : '')}
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;

	*:only-child {
		margin-right: auto;
	}
`;

const Thumbnail = styled.div`
	position: relative;
	border-radius: 8px;
	background: ${props => (props.thumbnailUrl ? `url("${props.thumbnailUrl}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 64px;
	width: 64px;
	float: right;
	margin: 0 0 24px 24px;
`;

const ReviewStatusText = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 24px;
`;

const ReviewStatus = styled.div`
	font-weight: bold;

	${props => (props.$status === 'pending' ? `
	color: ${props.theme.pokiBlue};
	` : props.$status === 'approved' ? `
	color: ${props.theme.green1};
	` : props.$status === 'rejected' ? `
	color: ${props.theme.rose5};
	` : props.$status === 'closed' ? `
	color: ${props.theme.grey3};
	` : '')}
`;

const ReviewStatusTime = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: ${props => props.theme.grey3};
`;

const ReviewMessage = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	white-space: pre-wrap;
`;

const ReviewMessageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

const ReviewMessageBottom = styled.div`
	display: flex;
	margin-top: 8px;
`;

const ReviewMessageChatBubble = styled.div`
	position: relative;
	border-radius: 16px;
	background: ${props => props.theme.grey7};
	color: ${props => props.theme.grey3};
	padding: 12px 16px;
	font-size: 14px;
	line-height: 20px;
	margin-left: 12px;
	flex-grow: 1;
	max-height: 244px;
	overflow: auto;

	&:before {
		content: '';
		border: 10px solid transparent;
		border-right-color: ${props => props.theme.grey7};
		position: absolute;
		left: -20px;
		top: 8px;
		transform: scaleY(0.5);
	}
`;

const ReviewerName = styled.span`
	font-weight: bold;
`;

const ViewReviewRequestModal = props => {
	const { data: { game, version, review } } = props;

	const [exit$] = useState(new Subject());
	const [confirmCancel, setConfirmCancel] = useState(false);

	const user = useSelectUser();

	const cancelReviewRequestStatus = useSelectApiStatus(cancelReviewRequest.id);
	const getReviewByIdStatus = useSelectApiStatus(getReviewById.id);
	const { meta: { submitter_name: reviewerName, submitter_picture: reviewerPicture } = {} } = useSelectReviewById(review.id);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getReviewById.fetch({ gameId: game.id, versionId: version.id, reviewId: review.id }));
	}, [review.id, user]);

	const handleCancel = () => {
		if (!confirmCancel) {
			setConfirmCancel(true);
			return;
		}

		dispatch(cancelReviewRequest.fetch({ gameId: game.id, versionId: version.id, reviewId: review.id }, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					exit$.next();
				}),
				ignoreElements(),
			)
		)));
	};

	return (
		<StyledModal exit$={exit$} $status={review.status} isLoading={!getReviewByIdStatus.done}>
			<Thumbnail thumbnailUrl={getGameThumbnailUrl(game.thumbnail_url, 64)} />

			<h2>{_`reviewRequest`}</h2>

			<LabeledValue label={_`version`}>{getVersionLabel(version)}</LabeledValue>
			<LabeledValue label={_`yourNotes`}>{review.developer_notes}</LabeledValue>

			{review.status === 'approved' || review.status === 'rejected' ? (
				<ReviewMessage>
					<ReviewMessageHeader>
						<ReviewStatus $status={review.status}>
							{getReviewStatusText(review.status)}
						</ReviewStatus>
						<ReviewStatusTime title={moment(review.report_submitted_at * 1000).format(dayMonthYearTimeFormat)}>{_`timeAgo${{ timeSince: getTimeSince(review.report_submitted_at * 1000) }}`} by <ReviewerName>{reviewerName}</ReviewerName></ReviewStatusTime>
					</ReviewMessageHeader>
					<ReviewMessageBottom>
						<UserImage src={reviewerPicture} /><ReviewMessageChatBubble>{review.personal_message}</ReviewMessageChatBubble>
					</ReviewMessageBottom>
				</ReviewMessage>
			) : (
				<ReviewStatusText>
					<ReviewStatus $status={review.status}>
						{getReviewStatusText(review.status)}
					</ReviewStatus>
					{review.status !== 'closed' && (
						<ReviewStatusTime title={moment((review.status === 'pending' ? review.created_at : review.report_submitted_at) * 1000).format(dayMonthYearTimeFormat)}>{_`timeAgo${{ timeSince: getTimeSince((review.status === 'pending' ? review.created_at : review.report_submitted_at) * 1000) }}`}</ReviewStatusTime>
					)}
				</ReviewStatusText>
			)}

			<Buttons>
				<Button secondary onClick={() => exit$.next()}>{_`close`}</Button>
				{review.status === 'approved' || review.status === 'rejected' || review.status === 'closed' ? (
					review.report_url ? <Button primary icon={ExitLinkIcon} iconSide="right" to={review.report_url} openInNewTab>{_`viewReport`}</Button> : null
				) : (
					<Button secondary={!confirmCancel} negative onClick={handleCancel}>{cancelReviewRequestStatus.pending ? _`cancelling` : confirmCancel ? _`confirmCancellation` : _`cancelRequest`}</Button>
				)}
			</Buttons>
		</StyledModal>
	);
};

registerModal('view-review-request', ViewReviewRequestModal);
