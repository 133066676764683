import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { createGetGameplaysForGameId, createGetTotalGameplaysForGameId } from 'app/src/epicCreators/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { getTablesLastUpdatedAt } from 'app/src/epics/data';
import { useSelectTheme } from 'app/src/selectors/session';
import useComponentId from 'app/src/hooks/useComponentId';
import getTimeSince from 'app/src/utils/getTimeSince';
import useDataEpic from 'app/src/hooks/useDataEpic';

import Chart from 'app/src/components/ui/charts/Chart';
import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';

import QuestionIcon from 'shared/designTokens/icons/ui/small/QuestionIcon';
import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';
import InfoPoint from 'app/src/components/ui/InfoPoint';

import _ from 'shared/copy';

const GameGameplaysChartModule = props => {
	const { game, startDate, endDate, deviceCategories, countries, contexts, versions } = props;

	const componentId = useComponentId();

	const getGameplaysForGameId = useDataEpic(createGetGameplaysForGameId(componentId), []);
	const getTotalGameplaysForGameId = useDataEpic(createGetTotalGameplaysForGameId(componentId), []);
	const getTotalGameplaysPrevPeriodForGameId = useDataEpic(createGetTotalGameplaysForGameId(`prev-${componentId}`), []);

	const dispatch = useDispatch();
	const theme = useSelectTheme();

	useEffect(() => {
		if (!game) return;

		const options = {
			gameId: game.id,
			teamId: game.team_id,
			from: startDate,
			to: endDate,
			deviceCategories,
			countries,
			contexts,
			versions,
		};

		dispatch(getTablesLastUpdatedAt.fetch());

		dispatch(getGameplaysForGameId.fetch(options));
		dispatch(getTotalGameplaysForGameId.fetch(options));

		// Previous period
		const fromMoment = moment.utc(startDate);
		const toMoment = moment.utc(endDate);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		dispatch(getTotalGameplaysPrevPeriodForGameId.fetch({
			...options,
			from: prevPeriodFrom,
			to: prevPeriodTo,
		}));
	}, [game?.id, startDate, endDate, deviceCategories, countries, contexts, versions]);

	const getGameplaysForGameIdStatus = useSelectApiStatus(getGameplaysForGameId.id);
	const getTotalGameplaysForGameIdStatus = useSelectApiStatus(getTotalGameplaysForGameId.id);
	const getTotalGameplaysPrevPeriodForGameIdStatus = useSelectApiStatus(getTotalGameplaysPrevPeriodForGameId.id);

	const apiStatus = combinedApiStatus(getGameplaysForGameIdStatus, getTotalGameplaysForGameIdStatus, getTotalGameplaysPrevPeriodForGameIdStatus);

	const gameplaysForGame = useSelectDataByEpic(getGameplaysForGameId.id).rows;
	const [totalGameplays] = useSelectDataByEpic(getTotalGameplaysForGameId.id).rows;
	const [totalGameplaysPrevPeriod] = useSelectDataByEpic(getTotalGameplaysPrevPeriodForGameId.id).rows;

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_gameplays');

	return (
		<Card
			title={_`gameplays`}
			buttons={(
				<>
					<InfoPoint
						icon={QuestionIcon}
					>
						{_`gameplayMetricExplanation`}
					</InfoPoint>
					<InfoPoint
						disabled={!lastUpdatedAt}
						icon={GraphIcon}
					>
						{_`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`}
					</InfoPoint>
				</>
			)}
		>
			{apiStatus.done && (
				<Stat
					value={Math.round(totalGameplays?.gameplays || 0)}
					valueDesc={_`gameplaysThisPeriod`}
					previous={Math.round(totalGameplaysPrevPeriod?.gameplays || 0)}
					previousDesc={_`fromPreviousPeriod`}
				/>
			)}
			<Chart
				type="bar"
				legend
				stacked
				apiStatus={apiStatus}
				data={gameplaysForGame}
				getGroupedBy={d => d.device_category}
				getGroupName={d => {
					switch (d.device_category) {
						case 'desktop':
							return _`desktop`;

						case 'mobile':
							return _`mobile`;

						case 'tablet':
							return _`tablet`;

						default:
							return d.device_category;
					}
				}}
				keyOrder={['desktop', 'mobile', 'tablet']}
				getGroupColor={v => {
					if (v === 'mobile') {
						return theme.dataBlue3;
					} else if (v === 'tablet') {
						return theme.dataBlue1;
					}

					return theme.dataBlue5;
				}}
				xRange={[startDate, endDate]}
				xAxis={{
					type: 'date',
					displayName: _`date`,
					field: 'date',
				}}
				yAxis={{
					displayName: _`gameplays`,
					field: 'gameplays',
				}}
			/>
		</Card>
	);
};

export default GameGameplaysChartModule;
