import { useEffect, useState } from 'react';
import moment from 'moment';

function todayMoment() {
	return moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

const useToday = () => {
	const [today, setToday] = useState(todayMoment());

	useEffect(() => {
		const intervalId = setInterval(() => {
			const currentMoment = todayMoment();
			if (!currentMoment.isSame(today, 'day')) {
				setToday(currentMoment);
			}
		}, 1000 * 60); // Check every minute

		return () => clearInterval(intervalId);
	}, [today]);

	return today;
};

export default useToday;
