import React from 'react';

import styled, { keyframes } from 'styled-components';

const animation = keyframes`
	100% {
		background-position: -480px
	}
`;

const Image = styled.div`
	width: 96px;
	height: 96px;
	animation: ${animation} 1.6s steps(5) infinite;
	background: url("/images/loader-charts.png");
	flex-shrink: 0;
`;

const ChartLoader = ({ className }) => (
	<Image className={className} />
);

export default ChartLoader;
