import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Subject } from 'rxjs';

import { registerModal } from 'app/src/modals';
import { preventPageLeave } from 'app/src/actions/client';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import Modal from 'app/src/components/ui/Modal';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const ChangeEmailModal = props => {
	const dispatch = useDispatch();

	const { data } = props;

	const [exit$] = useState(new Subject());
	const [email, setEmail] = useState('');

	const { confirm$ } = data;

	return (
		<Modal
			exit$={exit$}
		>
			<h2>{_`confirmEmail`}</h2>
			<p dangerouslySetInnerHTML={{ __html: _`confirmEmailDescription${{ email: data.email }}` }} />
			<form
				onSubmit={evt => {
					evt.preventDefault();

					dispatch(preventPageLeave({ toggle: false }));

					confirm$.next();
					exit$.next();
				}}
			>
				<TextInput
					name="emailConfirm"
					placeholder={_`exampleEmail`}
					value={email}
					valueSetter={setEmail}
				/>
				<Buttons>
					<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
					<Button submit disabled={data.email !== email}>{_`sendNewSignInLink`}</Button>
				</Buttons>
			</form>
		</Modal>
	);
};

registerModal('change-email', ChangeEmailModal);
