import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Subject, map, merge, of, switchMap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import styled from 'styled-components';

import DownloadIcon from 'shared/designTokens/icons/ui/small/DownloadIcon';

import { exportUpfrontPaymentsCsv } from 'app/src/epics/additionalPayments';
import downloadFromDataBlob from 'app/src/utils/downloadFromDataBlob';
import { openToast } from 'app/src/actions/client';
import { registerModal } from 'app/src/modals';

import { ToastTypes } from 'app/src/components/ui/Toast';
import SelectInput from 'app/src/components/input/SelectInput';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';

import _ from 'shared/copy';

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const getYearsFrom2020 = () => {
	const currentYear = new Date().getFullYear();
	const startYear = 2020;
	const years = [];

	for (let year = currentYear; year >= startYear; year--) {
		years.push({
			value: year,
			desc: year,
		});
	}

	return years;
};

const ExportCSVModal = () => {
	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());
	const [year, setYear] = useState('');
	const [CSVData, setCSVData] = useState('');

	const { pending } = useSelectApiStatus(exportUpfrontPaymentsCsv.id);

	const handleExportCSV = () => {
		dispatch(exportUpfrontPaymentsCsv.fetch({ year }, ({ success$, error$ }) => merge(
			success$.pipe(
				map(({ payload: { result: { response } } }) => setCSVData(response)),
			),
			error$.pipe(
				switchMap(({ payload: { result: { response: { errors: [error] } } } }) => of(
					exit$.next(),
					openToast({ body: error.detail, toastType: ToastTypes.WARNING }),
				)),
			),
		)));
	};

	const handleDownloadCSV = () => {
		downloadFromDataBlob([CSVData.data], `upfront-payments-${CSVData.filename}`, 'text/csv');
		exit$.next();
	};

	return (
		<Modal
			exit$={exit$}
		>
			<h2>{_`exportCsv`}</h2>
			<p>{_`exportCsvDescription`}</p>
			<SelectInput
				label={_`selectYear`}
				name="year"
				disabled={pending || CSVData}
				value={year}
				valueSetter={setYear}
				placeholder="Select the year to export the CSV for"
				values={getYearsFrom2020()}
				required
			/>
			<Buttons>
				<Button disabled={pending} secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
				{CSVData ? (
					<Button icon={DownloadIcon} onClick={handleDownloadCSV}>{_`download`}</Button>
				) : (
					<Button onClick={handleExportCSV} disabled={pending}>{pending ? _`exporting` : _`export`}</Button>
				)}
			</Buttons>
		</Modal>
	);
};

registerModal('export-csv', ExportCSVModal);
