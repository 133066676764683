import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';

import Link from 'app/src/components/Link';
import PokiLogo from 'shared/components/svg/PokiLogo';
import { isMobile, navigationWidth, headerHeight, motionSpeed02, motionOut, customScrollbar, darkColors } from 'shared/vars';
import AdminMenu from 'app/src/components/AdminMenu';
import { useSelectNavigationIsActive } from 'app/src/selectors/client';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import Key from 'app/src/components/ui/Key';
import ListMenu from 'app/src/components/ui/ListMenu';
import VisitedTeamsMenu from 'app/src/components/VisitedTeamsMenu';
import UserMenu from 'app/src/components/UserMenu';
import { openModal } from 'app/src/actions/client';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';

const Container = styled.div`
	width: ${props => (props.collapsed ? '48px' : `${navigationWidth}px`)};
	height: 100%;
	background: ${darkColors.grey9};
	position: relative;
	border-right: ${props => (props.collapsed ? 0 : '1px')} solid ${props => props.theme.static.grey1};
	z-index: 2;
	transition: transform ${motionSpeed02} ${motionOut};
	display: flex;
	flex-direction: column;

	${isMobile && `
	position: fixed;
	top: ${headerHeight};
	height: calc(100% - ${headerHeight});
	padding-top: 20px;
	box-shadow: ${props => props.theme.boxShadowSmall};
	`}

	${props => !props.isActive && `
	transform: translateX(-100%);
	`}
`;

const StyledPokiLogo = styled(PokiLogo)`
	height: auto;
	width: 197px;
	vertical-align: bottom;
`;

const StyledLink = styled(Link)`
	display: block;
`;

const FixedTop = styled.div`
	padding: 14px 24px 0;
	height: 80px;
	flex-shrink: 0;
`;

const ScrollArea = styled.div`
	margin-top: 30px;
	padding: 14px ${props => (props.collapsed ? 12 : 24)}px 0;
	flex-grow: 1;
	margin-right: 2px;

	${isMobile ? `
	overflow: auto;
	` : `
	overflow: hidden;

	&:hover {
		overflow: auto;
	}
	`}

	${customScrollbar}
`;

const FixedBottom = styled.div`
	padding: 24px ${props => (props.collapsed ? 12 : 24)}px;

	${props => !props.collapsed && `
		border-top: 2px solid ${props.theme.static.grey1};
	`}
`;

const AdminNavigation = props => {
	const { collapsed } = props;

	const activeTeam = useSelectActiveTeam();
	const dispatch = useDispatch();
	const isActive = useSelectNavigationIsActive();
	const permissions = useSelectPermissions();

	return (
		<Container isActive={isActive} collapsed={collapsed}>
			{!isMobile && (
				<FixedTop>
					{!collapsed && <StyledLink to={`/${activeTeam.code}`}><StyledPokiLogo light /></StyledLink>}
				</FixedTop>
			)}

			<ScrollArea collapsed={collapsed}>
				<AdminMenu collapsed={collapsed} />
				{checkPermissions(permissions, [['can_read_all_teams']]) && (
					<VisitedTeamsMenu collapsed={collapsed} />
				)}
			</ScrollArea>

			{checkPermissions(permissions, [['can_search_all']]) && (
				<FixedBottom collapsed={collapsed}>
					<ListMenu
						light
						collapsed={collapsed}
						buttons={[
							{
								icon: SearchIcon,
								onClick: () => dispatch(openModal({ key: 'search' })),
								name: <>Press <Key>/</Key> to search</>,
							},
						]}
					/>
					{!collapsed && <UserMenu isAdminNavigation />}
				</FixedBottom>
			)}
		</Container>
	);
};

export default AdminNavigation;
