import * as React from 'react';
import styled from 'styled-components';

import ExclamationIcon from 'shared/designTokens/icons/ui/tiny/ExclamationIcon';

const Container = styled.div`
	display: flex;
	color: ${props => props.theme.yellow3};

	&:not(:last-child) {
		margin-bottom: 12px;
	}

	svg {
		flex-shrink: 0;
		margin-right: 4px;

		${props => (props.$hasTitle ? `
		margin-top: 3px;
		` : '')}

		[fill] {
			fill: ${props => props.theme.yellow3};
		}
	}
`;

const Message = styled.div`
	font-size: 13px;
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	font-weight: bold;
`;

const WarningMessage = props => {
	const { className, title, message } = props;

	return (
		<Container className={className}>
			<ExclamationIcon $hasTitle={!!title} />
			<Message>
				{title && <Title>{title}</Title>}
				{message}
			</Message>
		</Container>
	);
};

export default WarningMessage;
