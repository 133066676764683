import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { useSelectApiStatus } from '@poki/rx-api';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import { patchPlaytestRecording, getPlaytestRecordingsByGameId } from 'app/src/epics/playtests';
import { isRequired, validate } from 'app/src/utils/validate';
import { startScreenShake } from 'app/src/actions/effects';

const Form = styled.form`
	width: 100%;
	margin-top: 16px;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const EditPlaytestNotesModal = props => {
	const { data: { playtest } } = props;

	const dispatch = useDispatch();
	const formRef = useRef();
	const [notes, setNotes] = useState(playtest.notes);
	const patchPlaytestRecordingStatus = useSelectApiStatus(patchPlaytestRecording.id);
	const [exit$] = useState(new Subject());
	const [errors, setErrors] = useState({});

	const handleSubmit = e => {
		e.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'playtest_notes',
				value: notes,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(patchPlaytestRecording.fetch({ gameId: playtest.game_id, recordingId: playtest.id, data: { notes } }, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					exit$.next();
				}),
				map(() => getPlaytestRecordingsByGameId.fetch({ gameId: playtest.game_id })),
			)
		)));
	};

	return (
		<Modal exit$={exit$}>
			<>
				<h2>{_`editPlaytestNotes`}</h2>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<TextAreaInput
						label={_`notes`}
						name="playtest_notes"
						errors={errors.playtest_notes}
						value={notes}
						valueSetter={setNotes}
						required={isRequired('playtest_notes')}
					/>
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={patchPlaytestRecordingStatus.pending}>{patchPlaytestRecordingStatus.pending ? _`saving` : _`save`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('edit-playtest-notes', EditPlaytestNotesModal);
