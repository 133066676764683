import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled, { css, keyframes } from 'styled-components';
import { ignoreElements, map, merge, of, switchMap, tap } from 'rxjs';
import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';

import { fundWiseInvoices, getWiseStatus, patchInvoice, prepareWiseInvoices, resetWiseInvoice } from 'app/src/epics/invoice';
import { openModal, openToast, uncaughtServerError } from 'app/src/actions/client';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import formatNumber from 'app/src/utils/formatNumber';
import GridContainer from 'app/src/components/ui/GridContainer';
import Card from 'app/src/components/ui/Card';
import Table from 'app/src/components/ui/Table';
import Button from 'app/src/components/ui/Button';
import Tooltip from 'app/src/components/ui/Tooltip';
import GeneralNotification from 'app/src/components/ui/GeneralNotification';
import ChecklistIcon from 'shared/designTokens/icons/ui/small/ChecklistIcon';
import CheckIcon from 'shared/designTokens/icons/ui/small/CheckIcon';
import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';
import RefreshIcon from 'shared/designTokens/icons/ui/small/RefreshIcon';
import CoinIcon from 'shared/designTokens/icons/ui/small/CoinIcon';
import _ from 'shared/copy';

const StyledButtonWrapper = styled.div`
	display: flex;
	gap: 20px;
	margin-left: auto;
	padding: 10px;
`;

const StyledAmount = styled.div`
	${props => props.success && `color: ${props.theme.green3};`}
	${props => props.error && `color: ${props.theme.rose3};`}
`;

const StatusContainer = styled.div`
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${props => `${props.theme.pureWhite}9F`};
	border-radius: 8px;
	width: 100%;
	height: 100%;

	${props => props.isActive && css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 3;
	`}
`;

const ActionsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
`;

const Icon = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1em;
	width: 26px;
	height: 26px;
	padding: 3px;
	border: 2px solid ${props => props.theme.grey3};
	border-radius: 50%;
	cursor: pointer;

	[fill] {
		fill: ${props => props.theme.grey3};
	}

	${props => props.cancel && `
		border-color: ${props.theme.rose3};

		[fill] {
			fill: ${props.theme.rose3};
		}

		&:hover {
			border-color: ${props.theme.rose2};
			background-color: ${props.theme.rose2};

			[fill] {
				fill: #fff;
			}
		}
	`}

	${props => props.confirm && `
		border-color: ${props.theme.green3};

		[fill] {
			fill: ${props.theme.green3};
		}

		&:hover {
			border-color: ${props.theme.green2};
			background-color: ${props.theme.green2};

			[fill] {
				fill: #fff;
			}
		}
	`}

	${props => props.disabled && `
		border-color: ${props.theme.grey5};
		pointer-events: none;

		[fill] {
			fill: ${props.theme.grey5};
		}
	`}

	${props => props.active && `
		border-color: ${props.theme.green3};
		background: ${props.theme.green3};
		pointer-events: none;

		[fill] {
			fill: #fff;
		}
	`}

`;

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div`
	border-width: 8px;
	border-style: solid;
	border-color: ${props => `${props.theme.grey3} ${props.theme.grey3} ${props.theme.grey3} ${props.theme.grey5}`};
	width: 100px;
	height: 100px;
	border-radius: 50%;
	animation: ${rotate} 1.5s infinite;
	position: relative;
	margin: 48px auto;

	&:before, &:after {
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: ${props => props.theme.grey3};
		position: absolute;
		left: 5px;
	}

	&:before {
		top: 6px;
	}

	&:after {
		bottom: 6px;
	}
`;

const StyledTooltip = styled(Tooltip)`
	width: max-content;
`;

const getCurrencySymbol = currency => {
	switch (currency) {
		case 'eur':
			return '€';
		case 'gbp':
			return '£';
		default:
			return '$';
	}
};

const ActionButtons = props => {
	const { item: { invoice_id }, disabled, handleGetWiseStatus } = props;
	const dispatch = useDispatch();

	const [toConfirm, setToConfirm] = useState(false);
	const [actionSelected, setActionSelected] = useState(null);
	const [isManuallyPaid, setIsManuallyPaid] = useState(false);

	const handleActionSelected = action => {
		setToConfirm(true);
		setActionSelected(action);
	};

	const handleResetInvoice = () => {
		dispatch(resetWiseInvoice.fetch({ invoiceId: invoice_id }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					map(() => {
						setToConfirm(false);
						handleGetWiseStatus();
					}),
				),
				error$.pipe(
					switchMap(action => of(
						uncaughtServerError({ action }),
						setToConfirm(false),
					)),
				),
			)
		)));
	};

	const handleManuallyPaid = () => {
		dispatch(patchInvoice.fetch({ invoiceId: invoice_id, attributes: { paid: true } }, ({ success$ }) => (
			merge(
				success$.pipe(
					map(() => {
						setIsManuallyPaid(true);
						handleGetWiseStatus();
						openToast({ body: _`invoiceMarkedAsPaidSuccessfully` });
					}),
					ignoreElements(),
				),
			)
		)));
	};

	const handleConfirm = () => {
		switch (actionSelected) {
			case 'reset':
				handleResetInvoice();
				break;
			case 'manual':
				handleManuallyPaid();
				break;
			default:
				break;
		}
	};

	return (

		<ActionsWrapper>
			{toConfirm ? (
				<>
					<Icon cancel onClick={() => setToConfirm(false)}>
						<CloseIcon />
					</Icon>
					<Icon confirm onClick={handleConfirm}>
						<CheckIcon />
					</Icon>
				</>
			) : (
				<>
					<Icon title="Reset Invoice" disabled={disabled || isManuallyPaid} onClick={() => handleActionSelected('reset')}>
						<RefreshIcon />
					</Icon>
					<Icon title="Mark as manually paid" disabled={disabled} active={isManuallyPaid} onClick={() => handleActionSelected('manual')}>
						<CoinIcon />
					</Icon>
				</>
			)}
		</ActionsWrapper>
	);
};

const AdminPaymentsWisePayoutSubPage = () => {
	const dispatch = useDispatch();
	const timeoutRef = useRef();

	const [wiseStatus, setWiseStatus] = useState();
	const [firstLoad, setFirstLoad] = useState(false);
	const [isFirstProcessing, setIsFirstProcessing] = useState(false);

	const [isReadyToPayout, setIsReadyToPayout] = useState(false);
	const [isReadyToPrepare, setIsReadyToPrepare] = useState(false);
	const [insufficientFunds, setInsufficientFunds] = useState(false);

	const permissions = useSelectPermissions();

	const getPrepareWiseInvoicesStatus = useSelectApiStatus(prepareWiseInvoices.id);
	const getFundWiseInvoicesStatus = useSelectApiStatus(fundWiseInvoices.id);
	const getResetWiseInvoiceStatus = useSelectApiStatus(resetWiseInvoice.id);

	const isActionsDisabled = combinedApiStatus(getPrepareWiseInvoicesStatus, getFundWiseInvoicesStatus);
	const isPreparing = useMemo(() => isFirstProcessing || wiseStatus?.invoices_waiting > 0, [wiseStatus, isFirstProcessing]);

	const handleGetWiseStatus = () => {
		dispatch(getWiseStatus.fetch(null, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					tap(({ payload: { result: { response } } }) => {
						setWiseStatus(response);
					}),
					ignoreElements(),
				),
				error$.pipe(
					switchMap(action => of(uncaughtServerError({ action }))),
				),
			)
		)));
	};

	const handleStatusChange = () => {
		setIsFirstProcessing(true);

		timeoutRef.current = setTimeout(() => {
			handleGetWiseStatus();
		}, 3000);
	};

	const handlePrepareInvoices = () => {
		dispatch(prepareWiseInvoices.fetch(null, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					map(() => {
						openToast({ body: _`invoicesPreparedSuccessfully` });
						handleStatusChange();
					}),
				),
				error$.pipe(
					switchMap(action => of(uncaughtServerError({ action }))),
				),
			)
		)));
	};

	const handleFundInvoices = () => {
		dispatch(fundWiseInvoices.fetch(null, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					map(() => {
						(openToast({ body: _`invoicesFundedSuccessfully` }));
						handleStatusChange();
					}),
				),
				error$.pipe(
					switchMap(action => of(uncaughtServerError({ action }))),
				),
			)
		)));
	};

	useEffect(() => clearTimeout(timeoutRef.current), []);

	useEffect(() => {
		if (!firstLoad) {
			setFirstLoad(true);
			handleGetWiseStatus();
		}

		if (isFirstProcessing && wiseStatus?.invoices_waiting > 0) {
			setIsFirstProcessing(false);
		}

		if (!isPreparing) return;

		const timeout = setTimeout(() => {
			handleGetWiseStatus();
		}, 3000);

		return () => clearTimeout(timeout);
	}, [wiseStatus]);

	useEffect(() => {
		const balanceAccounts = wiseStatus?.balance_accounts;
		let hasPreparableInvoices = false;
		let hasAmountRequired = false;
		let hasInsufficientBalance = false;

		if (!balanceAccounts) {
			setIsReadyToPrepare(false);
			setIsReadyToPayout(false);
			return;
		}

		balanceAccounts.forEach(account => {
			if (!hasPreparableInvoices && wiseStatus?.preparable_invoices[account.currency].Count > 0) {
				hasPreparableInvoices = true;
			}

			if (!hasAmountRequired && wiseStatus?.invoices_prepared_in_wise[account.currency].Total) {
				hasAmountRequired = true;
			}

			if (!hasInsufficientBalance && account.balance < wiseStatus?.invoices_prepared_in_wise[account.currency].Total) {
				hasInsufficientBalance = true;
			}
		});

		setIsReadyToPrepare(hasPreparableInvoices);

		if (hasInsufficientBalance) {
			setIsReadyToPayout(false);
			setInsufficientFunds(true);
		} else if (!hasInsufficientBalance && !hasAmountRequired) {
			setIsReadyToPayout(false);
		} else {
			setIsReadyToPayout(true);
			setInsufficientFunds(false);
		}
	}, [wiseStatus]);

	return (
		<>
			<Helmet>
				<title>Wise Payout - Admin - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				{insufficientFunds && (
					<GeneralNotification
						state="warning"
						icon={ChecklistIcon}
						title={_`inbalanceInWise`}
						description={(
							<span dangerouslySetInnerHTML={{ __html: _`inbalanceInWiseDescription` }} />
						)}
					/>
				)}
				<Card title="Account Balances at Wise" noPadding>
					<StatusContainer isActive={isPreparing}>
						<LoadingSpinner />
						<h3>Processing invoices...</h3>
					</StatusContainer>
					<Table
						items={wiseStatus?.balance_accounts.sort((a, b) => a.currency.localeCompare(b.currency))}
						columns={[
							{
								title: _`currency`,
								content: ({ item }) => item.currency.toUpperCase(),
							},
							{
								title: 'Available in Wise',
								content: ({ item }) => {
									const count = wiseStatus?.invoices_prepared_in_wise[item.currency].Count;
									const amountRequired = wiseStatus?.invoices_prepared_in_wise[item.currency].Total;
									const amountAvailable = item.balance;

									if (!count) {
										return `${getCurrencySymbol(item.currency)} ${formatNumber(item.balance)}`;
									}

									if (count > 0 && amountAvailable >= amountRequired) {
										return (
											<StyledAmount success>
												{`${getCurrencySymbol(item.currency)} ${formatNumber(item.balance)}`}
											</StyledAmount>
										);
									} else {
										return (
											<StyledAmount error>
												{`${getCurrencySymbol(item.currency)} ${formatNumber(item.balance)}`}
											</StyledAmount>
										);
									}
								},
							},
							{
								title: 'Amount to add',
								content: ({ item }) => {
									const amountRequired = wiseStatus?.invoices_prepared_in_wise[item.currency].Total;
									const amountAvailable = item.balance;
									const amountToAdd = amountRequired - amountAvailable;

									if (amountRequired === 0 || amountAvailable >= amountRequired) {
										return (
											`${getCurrencySymbol(item.currency)} 0`
										);
									}

									return `${getCurrencySymbol(amountRequired)} ${formatNumber(amountToAdd)}`;
								},
							},
							{
								title: 'Invoices Ready',
								content: ({ item }) => {
									const count = wiseStatus?.preparable_invoices[item.currency]?.Count;

									if (count > 0) {
										return (
											<StyledTooltip
												title="Total amount"
												body={`${getCurrencySymbol(item.currency)} ${wiseStatus?.preparable_invoices[item.currency]?.Total}`}
											>
												{wiseStatus?.preparable_invoices[item.currency]?.Count}
											</StyledTooltip>
										);
									} else {
										return '-';
									}
								},
							},
							{
								title: 'Invoices Prepared',
								content: ({ item }) => {
									const count = wiseStatus?.invoices_prepared_in_wise[item.currency]?.Count;

									if (count > 0) {
										return wiseStatus?.invoices_prepared_in_wise[item.currency]?.Count;
									} else {
										return '-';
									}
								},
							},
						]}
					/>
					{checkPermissions(permissions, [['can_process_wise_payments']]) && (
						<StyledButtonWrapper>
							<Button disabled={isActionsDisabled.pending || !isReadyToPrepare} onClick={handlePrepareInvoices}>Prepare</Button>
							<Button disabled={isActionsDisabled.pending || !isReadyToPayout} onClick={handleFundInvoices}>Payout</Button>
						</StyledButtonWrapper>
					)}
				</Card>
				<Card title="Failed invoices" noPadding>
					<StatusContainer isActive={getResetWiseInvoiceStatus.pending}>
						<h4>Reseting...</h4>
					</StatusContainer>
					<Table
						items={wiseStatus?.failed_invoices}
						columns={[
							{
								title: 'Recipient',
								content: ({ item }) => <Link to={`/${item.team_id}`}>{item.recipient_name}</Link>,
							},
							{
								title: 'Invoice',
								content: ({ item }) => (
									<a onClick={() => dispatch(openModal({ key: 'admin-preview-invoice', data: { invoice: { id: item.invoice_id }, viewOnly: true } }))}>
										{item.invoice_number}
									</a>
								),
							},
							{
								title: 'Status',
								content: ({ item }) => {
									const regex = /code:\s\d{3}/;
									const match = regex.exec(item.error);
									const status = match ? match[0].split(':')[1].trim() : '';

									// Some wise errors include a whole request/response JSON, which is not useful for the user.
									const body = item.error.replace(/\(req:.*/, '');

									return (
										<Tooltip
											title="Detailed error message"
											body={body}
											text={status ? `Unexpected Wise error (${status})` : 'Unexpected Wise error'}
										/>
									);
								},
							},
							{
								title: 'Actions',
								content: ({ item }) => <ActionButtons item={item} handleGetWiseStatus={handleGetWiseStatus} disabled={wiseStatus?.invoices_waiting > 0} />,
							},
						]}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default AdminPaymentsWisePayoutSubPage;
