import React, { useState } from 'react';
import styled from 'styled-components';

import ChevronDownIcon from 'shared/designTokens/icons/ui/small/ChevronDownIcon';
import ChevronRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';

const Title = styled.div`
	font-weight: bold;
	color: ${props => props.theme.grey3};
	display: flex;
	width: max-content;
	align-items: center;
	cursor: pointer;

	&:hover {
		color: ${props => props.theme.grey1};
	}
`;

const Icon = styled.div`
	svg {
		margin-left: 4px;
		margin-top: 6px;
	}

	[fill] {
		fill: ${props => props.theme.grey3};
	}
`;

const Content = styled.div`
	display: ${props => (props.$active ? 'block' : 'none')};
	margin-top: 8px;
`;

const SlideOut = props => {
	const { children, title } = props;

	const [active, setActive] = useState(false);

	return (
		<>
			<Title onClick={() => setActive(a => !a)}>
				{title}
				<Icon>
					{active ? (
						<ChevronDownIcon />
					) : (
						<ChevronRightIcon />
					)}
				</Icon>
			</Title>
			<Content $active={active}>{children}</Content>
		</>
	);
};

export default SlideOut;
