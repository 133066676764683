import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import styled, { css } from 'styled-components';
import moment from 'moment';

import { useSelectActiveVersionListByGameId } from 'app/src/selectors/game';
import { listActiveVersionsByGameId } from 'app/src/epics/game';
import { openModal } from 'app/src/actions/client';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import isVersionActive from 'app/src/utils/isVersionActive';

import VersionReviewStatus from 'app/src/components/ui/VersionReviewStatus';
import VersionActions from 'app/src/components/ui/VersionActions';
import WarningMessage from 'app/src/components/ui/WarningMessage';
import VersionLabel from 'app/src/components/ui/VersionLabel';
import VersionNotes from 'app/src/components/ui/VersionNotes';
import Tooltip from 'app/src/components/ui/Tooltip';
import Button from 'app/src/components/ui/Button';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import { dayMonthYearTimeFormat, loadingPulseAnimation } from 'shared/vars';
import _ from 'shared/copy';

const Status = styled.div`
	font-weight: bold;
	color: ${props => props.theme.grey3};

	${props => props.$active && `
	color: ${props.theme.green1};
	`}

	${props => props.$processing && css`
	color: ${props.theme.yellow3};
	animation: ${loadingPulseAnimation};
	`}
`;

const ActiveVersionTable = props => {
	const { game, actionCounter, setHasAnyLabelFocus } = props;

	const dispatch = useDispatch();

	const permissions = useSelectPermissions();
	const { data: activeVersions } = useSelectActiveVersionListByGameId(game.id);
	const listActiveVersionsByGameIdStatus = useSelectApiStatus(listActiveVersionsByGameId.id);

	const canEditOwnedGameTracks = checkPermissions(permissions, [['can_edit_owned_game_tracks']]);
	const canEditVersions = checkPermissions(permissions, [['can_edit_all_versions', 'can_edit_owned_versions']]);

	useEffect(() => {
		dispatch(listActiveVersionsByGameId.fetch({ gameId: game.id }));
	}, [game?.id, actionCounter]);

	return (
		<Card
			title={_`activeVersion`}
			noPadding
		>
			<Table
				isLoading={!listActiveVersionsByGameIdStatus.done}
				items={activeVersions}
				columns={[
					{
						title: _`uploadedAt`,
						width: 'max-content',
						content: ({ item }) => {
							const text = moment(item.created_at * 1000).format(dayMonthYearTimeFormat);
							const flags = item.flags ? item.flags.split(',') : [];

							if (flags.includes('google-analytics-replaced')) {
								return (
									<Tooltip
										text={text}
										body={<WarningMessage message={_`googleAnalyticsRemovedOnVersion`} />}
										direction="right"
									/>
								);
							}

							return text;
						},
						sortField: 'created_at',
					},
					{
						title: '',
						mobileTitle: _`versionNotes`,
						width: 'min-content',
						content: ({ item }) => <VersionNotes item={item} canEditVersions={canEditVersions} />,
						mobileContent: ({ item }) => (
							<Button
								secondary
								onClick={() => dispatch(openModal({ key: 'edit-version-notes', data: { version: item } }))}
							>
								{_`editVersionNotes`}
							</Button>
						),
					},
					{
						title: _`name`,
						content: ({ item }) => (
							item.state !== 'error' ? (
								<VersionLabel
									key={`label-${item.id}`}
									item={item}
									setHasAnyLabelFocus={setHasAnyLabelFocus}
								/>
							) : (
								<div>{_`invalidVersion`}</div>
							)
						),
					},
					{
						title: canEditOwnedGameTracks ? _`reviewStatus` : _`status`,
						content: ({ item }) => {
							const active = isVersionActive(item, game);

							if (canEditOwnedGameTracks) {
								return (
									<VersionReviewStatus game={game} item={item} />
								);
							}

							return (
								<Status $processing={item.state !== 'done'} $active={active}>
									{
										item.state !== 'done' ? (
											_`processing`
										) : (
											active ? (
												_`active`
											) : (
												_`inactive`
											)
										)
									}
								</Status>
							);
						},
					},
					{
						title: _`actions`,
						width: 'max-content',
						content: ({ item }) => <VersionActions game={game} item={item} />,
					},
				]}
			/>
		</Card>
	);
};

export default ActiveVersionTable;
