import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { getTablesLastUpdatedAt } from 'app/src/epics/data';

// Base generic selector
const parseData = data => {
	if (!data?.rows) {
		return {
			rows: [],
		};
	}

	return data;
};

const epicDataSelectors = {};

// Every epic needs its own selector for memoization to work
const getEpicDataSelector = (epicId, state) => {
	if (!epicDataSelectors[epicId]) {
		epicDataSelectors[epicId] = createSelector(
			_state => _state.data[epicId],

			data => parseData(data),
		);
	}

	return epicDataSelectors[epicId](state);
};

export const useSelectDataByEpic = epicId => useSelector(state => getEpicDataSelector(epicId, state));

// Specific selectors

export const selectDataLastUpdatedAtForTable = createSelector(
	tableName => tableName,
	(tableName, state) => state.data[getTablesLastUpdatedAt.id]?.rows || [],

	(tableName, rows) => (
		rows.find(row => row.table_name === tableName)?.last_updated_at
	),
);

export const useSelectDataLastUpdatedAtForTable = tableName => useSelector(state => selectDataLastUpdatedAtForTable(tableName, state));
