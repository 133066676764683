import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import measureAverageTimeSpentPerDailyActiveUser from 'app/src/measures/measureAverageTimeSpentPerDailyActiveUser';
import { createGetEngagementForGameId, createGetTotalTimeSpentForGameId } from 'app/src/epicCreators/data';
import { getEarliestGameplaysForGameId, getTablesLastUpdatedAt } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { useSelectTheme } from 'app/src/selectors/session';
import getTimeSince from 'app/src/utils/getTimeSince';
import useComponentId from 'app/src/hooks/useComponentId';
import useDataEpic from 'app/src/hooks/useDataEpic';
import usePivot from 'app/src/hooks/usePivot';
import formatTime from 'app/src/utils/formatTime';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';
import ExclamationIcon from 'shared/designTokens/icons/ui/tiny/ExclamationIcon';

import { ChartDetails, ChartDetailsInner, DataAvailableFrom, DetailNumber } from 'app/src/components/ChartSubComponents';
import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import Chart from 'app/src/components/ui/charts/Chart';
import InfoPoint from 'app/src/components/ui/InfoPoint';

import { dayMonthYearFormat } from 'shared/vars';
import _ from 'shared/copy';

const engagementDataAvailableFromMoment = moment('2020-06-17');

const GameEngagementChartModule = props => {
	const { game, startDate, endDate, deviceCategories, countries, contexts, versions } = props;

	const componentId = useComponentId();

	const getEngagementForGameId = useDataEpic(createGetEngagementForGameId(componentId), []);
	const getTotalTimeSpentForGameId = useDataEpic(createGetTotalTimeSpentForGameId(componentId), []);
	const getTotalTimeSpentPrevPeriodForGameId = useDataEpic(createGetTotalTimeSpentForGameId(`prev-${componentId}`), []);

	const dispatch = useDispatch();
	const theme = useSelectTheme();

	useEffect(() => {
		if (!game) return;

		const options = {
			gameId: game.id,
			teamId: game.team_id,
			from: startDate,
			to: endDate,
			deviceCategories,
			countries,
			contexts,
			versions,
		};

		dispatch(getTablesLastUpdatedAt.fetch());
		dispatch(getEarliestGameplaysForGameId.fetch(options));

		dispatch(getEngagementForGameId.fetch(options));
		dispatch(getTotalTimeSpentForGameId.fetch(options));

		// Previous period
		const fromMoment = moment.utc(startDate);
		const toMoment = moment.utc(endDate);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		dispatch(getTotalTimeSpentPrevPeriodForGameId.fetch({
			...options,
			from: prevPeriodFrom,
			to: prevPeriodTo,
		}));
	}, [game?.id, startDate, endDate, deviceCategories, countries, contexts, versions]);

	const getEngagementForGameIdStatus = useSelectApiStatus(getEngagementForGameId.id);
	const getTotalTimeSpentForGameIdStatus = useSelectApiStatus(getTotalTimeSpentForGameId.id);
	const getTotalTimeSpentPrevPeriodForGameIdStatus = useSelectApiStatus(getTotalTimeSpentPrevPeriodForGameId.id);

	const apiStatus = combinedApiStatus(getEngagementForGameIdStatus, getTotalTimeSpentForGameIdStatus, getTotalTimeSpentPrevPeriodForGameIdStatus);

	const engagementForGame = usePivot(
		measureAverageTimeSpentPerDailyActiveUser(useSelectDataByEpic(getEngagementForGameId.id).rows),
		row => {
			const { avg_play_time_per_dau, avg_pre_play_time_per_dau, avg_video_ad_visible_time_per_dau, ...remaining } = row;

			return [
				{
					...remaining,
					timeSpent: avg_play_time_per_dau,
					type: 'play_time',
				},
				{
					...remaining,
					timeSpent: avg_pre_play_time_per_dau,
					type: 'pre_play_time',
				},
				{
					...remaining,
					timeSpent: avg_video_ad_visible_time_per_dau,
					type: 'ad_time',
				},
			];
		},
	);
	const [totalTimeSpent] = measureAverageTimeSpentPerDailyActiveUser(useSelectDataByEpic(getTotalTimeSpentForGameId.id).rows);
	const [totalTimeSpentPrevPeriod] = measureAverageTimeSpentPerDailyActiveUser(useSelectDataByEpic(getTotalTimeSpentPrevPeriodForGameId.id).rows);
	const engagementLastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_engagement');

	const earliestGameplayDataMoment = moment(useSelectDataByEpic(getEarliestGameplaysForGameId.id)?.rows[0] || Date.now());

	return (
		<Card
			title={_`engagement`}
			buttons={(
				<InfoPoint
					disabled={!engagementLastUpdatedAt}
					warning={engagementDataAvailableFromMoment.isAfter(earliestGameplayDataMoment)}
					icon={GraphIcon}
				>
					{_`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(engagementLastUpdatedAt)) }}`}
					{engagementDataAvailableFromMoment.isAfter(earliestGameplayDataMoment) && (
						<DataAvailableFrom>
							<ExclamationIcon /> {_`dataOnlyAvailableFromX${{ availableFrom: engagementDataAvailableFromMoment.format(dayMonthYearFormat) }}`}
						</DataAvailableFrom>
					)}
				</InfoPoint>
			)}
		>
			{apiStatus.done && (
				<Stat
					value={Math.round(totalTimeSpent?.avg_total_time_spent_per_dau || 0)}
					valueDesc={_`timeSpentPerDAU`}
					previous={Math.round(totalTimeSpentPrevPeriod?.avg_total_time_spent_per_dau || 0)}
					previousDesc={_`fromPreviousPeriod`}
					isTime
				/>
			)}
			<Chart
				type="bar"
				legend
				stacked
				apiStatus={apiStatus}
				data={engagementForGame}
				getGroupedBy={d => d.type}
				getGroupName={d => {
					if (d.type === 'play_time') {
						return _`playTime`;
					} else if (d.type === 'pre_play_time') {
						return _`prePlayTime`;
					}

					return _`adTime`;
				}}
				keyOrder={['play_time', 'ad_time', 'pre_play_time']}
				getGroupColor={v => {
					if (v === 'pre_play_time') {
						return theme.dataGrey1;
					} else if (v === 'ad_time') {
						return theme.dataGrey3;
					}

					return theme.dataBlue5;
				}}
				xRange={[startDate, endDate]}
				xAxis={{
					type: 'date',
					displayName: _`date`,
					field: 'date',
				}}
				yAxis={{
					type: 'time',
					displayName: _`timeSpent`,
					field: 'timeSpent',
				}}
			/>
			<ChartDetails>
				{apiStatus.done && totalTimeSpent && (
					<ChartDetailsInner>
						{
							_`engagementWhatDoesThisMean${{
								avgTotalTimeSpent: <DetailNumber>{formatTime(totalTimeSpent.avg_total_time_spent_per_dau)}</DetailNumber>,
								avgPrePlayTime: <DetailNumber $color={theme.dataGrey1}>{formatTime(totalTimeSpent.avg_pre_play_time_per_dau)}</DetailNumber>,
								avgAdTime: <DetailNumber $color={theme.dataGrey3}>{formatTime(totalTimeSpent.avg_video_ad_visible_time_per_dau)}</DetailNumber>,
								avgPlayTime: <DetailNumber $color={theme.dataBlue5}>{formatTime(totalTimeSpent.avg_play_time_per_dau)}</DetailNumber>,
							}}`
						}
					</ChartDetailsInner>
				)}
			</ChartDetails>
		</Card>
	);
};

export default GameEngagementChartModule;
