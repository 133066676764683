import preventNaNAndInfinity from 'app/src/utils/preventNaNAndInfinity';

const measureAverageTimeSpentPerDailyActiveUser = data => {
	const fields = [
		'play_time',
		'pre_play_time',
		'video_ad_visible_time',
	];

	return data.map(({ ...row }) => {
		let total = 0;

		fields.forEach(field => {
			row[`avg_${field}_per_dau`] = preventNaNAndInfinity(row[field] / row.daily_active_users);
			total += row[field];
		});

		row.avg_total_time_spent_per_dau = preventNaNAndInfinity(total / row.daily_active_users);

		return row;
	});
};

export default measureAverageTimeSpentPerDailyActiveUser;
