import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { openModal } from 'app/src/actions/client';

import DocumentIcon from 'shared/designTokens/icons/ui/small/DocumentIcon';
import PencilIcon from 'shared/designTokens/icons/ui/tiny/PencilIcon';

import Tooltip from 'app/src/components/ui/Tooltip';

import _ from 'shared/copy';

const VersionNotesContent = styled.div`
	white-space: pre-wrap;
	max-height: 250px;
	overflow: auto;
`;

const NoVersionNotes = styled.span`
	font-style: italic;
`;

const VersionNotesIcon = styled(DocumentIcon)`
	position: relative;
	left: 10px;
	margin-left: -18px;

	${props => (!props.$notesAvailable ? `
	[fill] {
		fill: ${props.theme.grey5};
	}
	` : '')}
`;

const VersionNotes = props => {
	const { item, canEditVersions } = props;

	const dispatch = useDispatch();

	return (
		<Tooltip
			title={_`versionNotes`}
			body={item.notes ? <VersionNotesContent>{item.notes}</VersionNotesContent> : <NoVersionNotes>{_`noVersionNotes`}</NoVersionNotes>}
			icon={(
				canEditVersions && (
					<PencilIcon
						onClick={() => dispatch(openModal({ key: 'edit-version-notes', data: { version: item } }))}
					/>
				)
			)}
		>
			<VersionNotesIcon $notesAvailable={!!item.notes} />
		</Tooltip>
	);
};

export default VersionNotes;
