import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';
import styled from 'styled-components';
import moment from 'moment';

import { getEarningsForTeamId, getGameplaysForTeamId, getTablesLastUpdatedAt, getTotalEarningsForTeamId, getTotalEarningsPrevPeriodForTeamId, getTotalGameplaysForTeamId, getTotalGameplaysPrevPeriodForTeamId } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { useSelectTheme } from 'app/src/selectors/session';
import getTimeSince from 'app/src/utils/getTimeSince';
import useURLState from 'app/src/hooks/useURLState';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';

import DateRangeInput from 'app/src/components/input/DateRangeInput';
import GridContainer from 'app/src/components/ui/GridContainer';
import Container from 'app/src/components/ui/Container';
import Chart from 'app/src/components/ui/charts/Chart';
import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import InfoPoint from 'app/src/components/ui/InfoPoint';
import AdminGamesOverviewModule from 'app/src/components/modules/AdminGamesOverviewModule';
import FilterMultiSelectInput from 'app/src/components/input/FilterMultiSelectInput';

import { dayMonthYearFormat, earliestMomentInP4D } from 'shared/vars';
import _ from 'shared/copy';

const Filters = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (min-width: 550px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const AdminDashboardPage = () => {
	const dispatch = useDispatch();
	const theme = useSelectTheme();

	const [endDate, setEndDate] = useURLState(
		'endDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(1, 'day');
			date.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
			return date;
		})(),
	);

	const [startDate, setStartDate] = useURLState(
		'startDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(30, 'days');
			date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			return date;
		})(),
	);

	const [deviceCategories, setDeviceCategories] = useURLState('deviceCategories');

	const getGameplaysForTeamIdStatus = useSelectApiStatus(getGameplaysForTeamId.id);
	const getTotalEarningsForTeamIdStatus = useSelectApiStatus(getTotalEarningsForTeamId.id);
	const getTotalEarningsPrevPeriodForTeamIdStatus = useSelectApiStatus(getTotalEarningsPrevPeriodForTeamId.id);
	const getEarningsForTeamIdStatus = useSelectApiStatus(getEarningsForTeamId.id);
	const getTotalGameplaysForTeamIdStatus = useSelectApiStatus(getTotalGameplaysForTeamId.id);
	const getTotalGameplaysPrevPeriodForTeamIdStatus = useSelectApiStatus(getTotalGameplaysPrevPeriodForTeamId.id);

	const { rows: gameplays } = useSelectDataByEpic(getGameplaysForTeamId.id);
	const { rows: earnings } = useSelectDataByEpic(getEarningsForTeamId.id);
	const { rows: totalEarnings } = useSelectDataByEpic(getTotalEarningsForTeamId.id);
	const { rows: totalEarningsPrevPeriod } = useSelectDataByEpic(getTotalEarningsPrevPeriodForTeamId.id);
	const { rows: totalGameplays } = useSelectDataByEpic(getTotalGameplaysForTeamId.id);
	const { rows: totalGameplaysPrevPeriod } = useSelectDataByEpic(getTotalGameplaysPrevPeriodForTeamId.id);
	const gameplaysLastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_gameplays');
	const earningsLastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_developer_earnings');

	useEffect(() => {
		const currency = 'eur';

		dispatch(getTablesLastUpdatedAt.fetch());

		// Gameplays
		dispatch(getGameplaysForTeamId.fetch({
			teamId: null,
			from: startDate,
			to: endDate,
			deviceCategories,
		}));
		dispatch(getTotalGameplaysForTeamId.fetch({
			teamId: null,
			from: startDate,
			to: endDate,
			deviceCategories,
		}));
		dispatch(getTotalGameplaysPrevPeriodForTeamId.fetch({
			teamId: null,
			from: startDate,
			to: endDate,
			deviceCategories,
		}));
		// Earnings
		dispatch(getEarningsForTeamId.fetch({
			teamId: null,
			from: startDate,
			to: endDate,
			currency,
			deviceCategories,
		}));
		dispatch(getTotalEarningsForTeamId.fetch({
			teamId: null,
			from: startDate,
			to: endDate,
			currency,
			deviceCategories,
		}));
		dispatch(getTotalEarningsPrevPeriodForTeamId.fetch({
			teamId: null,
			from: startDate,
			to: endDate,
			currency,
			deviceCategories,
		}));
	}, [startDate, endDate, deviceCategories]);

	const gameplaysStatus = combinedApiStatus(getGameplaysForTeamIdStatus, getTotalGameplaysForTeamIdStatus, getTotalGameplaysPrevPeriodForTeamIdStatus);
	const earningsStatus = combinedApiStatus(getEarningsForTeamIdStatus, getTotalEarningsForTeamIdStatus, getTotalEarningsPrevPeriodForTeamIdStatus);

	return (
		<Container>
			<Helmet key="AdminDashboardPage">
				<title>Admin Dashboard - Poki for Developers</title>
			</Helmet>
			<Filters>
				<FilterMultiSelectInput
					name="filter-device-category"
					value={deviceCategories}
					values={[
						{
							value: 'mobile',
							desc: _`mobile`,
							sortValue: 2,
						},
						{
							value: 'tablet',
							desc: _`tablet`,
							sortValue: 1,
						},
						{
							value: 'desktop',
							desc: _`desktop`,
							sortValue: 0,
						},
					]}
					title={_`device`}
					valueSetter={setDeviceCategories}
					light
					cantSelectNone
				/>
				<DateRangeInput
					minDate={earliestMomentInP4D}
					maxDate={moment.utc().subtract(1, 'day')}
					value={[startDate.format(dayMonthYearFormat), endDate.format(dayMonthYearFormat)]}
					valueSetter={([_startDate, _endDate]) => {
						setStartDate(_startDate);
						setEndDate(_endDate);
					}}
					format={dayMonthYearFormat}
					prefix={`${_`dateRange`}:`}
					light
				/>
			</Filters>
			<GridContainer cols={2} responsive>
				<Card
					title={_`gameplays`}
					buttons={(
						<InfoPoint
							disabled={!gameplaysLastUpdatedAt}
							icon={GraphIcon}
						>
							{_`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(gameplaysLastUpdatedAt)) }}`}
						</InfoPoint>
					)}
				>
					{gameplaysStatus.done && (
						<Stat
							value={totalGameplays[0]?.gameplays || 0}
							valueDesc={_`gameplaysThisPeriod`}
							previous={totalGameplaysPrevPeriod[0]?.gameplays || 0}
							previousDesc={_`fromPreviousPeriod`}
						/>
					)}
					<Chart
						type="bar"
						stacked
						legend
						apiStatus={gameplaysStatus}
						data={gameplays}
						getGroupName={d => {
							switch (d.device_category) {
								case 'desktop':
									return _`desktop`;

								case 'mobile':
									return _`mobile`;

								case 'tablet':
									return _`tablet`;

								default:
									return d.device_category;
							}
						}}
						getGroupedBy={d => d.device_category}
						keyOrder={['desktop', 'mobile', 'tablet']}
						getGroupColor={v => {
							if (v === 'mobile') {
								return theme.dataBlue3;
							} else if (v === 'tablet') {
								return theme.dataBlue1;
							}

							return theme.dataBlue5;
						}}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: _`date`,
							field: 'date',
						}}
						yAxis={{
							displayName: _`gameplays`,
							field: 'gameplays',
						}}
					/>
				</Card>
				<Card
					title={_`earnings`}
					buttons={(
						<InfoPoint
							disabled={!earningsLastUpdatedAt}
							icon={GraphIcon}
						>
							{_`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(earningsLastUpdatedAt)) }}`}
						</InfoPoint>
					)}
				>
					{earningsStatus.done && (
						<Stat
							value={totalEarnings[0]?.developer_earnings || 0}
							valueDesc={_`earnedThisPeriod`}
							previous={totalEarningsPrevPeriod[0]?.developer_earnings || 0}
							previousDesc={_`fromPreviousPeriod`}
							isCurrency="eur"
						/>
					)}
					<Chart
						type="bar"
						stacked
						legend
						apiStatus={earningsStatus}
						data={earnings}
						getGroupName={d => {
							switch (d.device_category) {
								case 'desktop':
									return _`desktop`;

								case 'mobile':
									return _`mobile`;

								case 'tablet':
									return _`tablet`;

								default:
									return d.device_category;
							}
						}}
						getGroupedBy={d => d.device_category}
						keyOrder={['desktop', 'mobile', 'tablet']}
						getGroupColor={v => {
							if (v === 'mobile') {
								return theme.dataGreen3;
							} else if (v === 'tablet') {
								return theme.dataGreen1;
							}

							return theme.dataGreen5;
						}}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: _`date`,
							field: 'date',
						}}
						yAxis={{
							type: 'eur',
							displayName: _`earnings`,
							field: 'developer_earnings',
						}}
					/>
				</Card>
			</GridContainer>
			<GridContainer cols={1}>
				<AdminGamesOverviewModule startDate={startDate} endDate={endDate} deviceCategories={deviceCategories} />
			</GridContainer>
		</Container>
	);
};

export default AdminDashboardPage;
