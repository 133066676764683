import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ignoreElements, tap, map } from 'rxjs';
import { Controller, useForm } from 'react-hook-form';
import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';

import { preventPageLeave, openModal } from 'app/src/actions/client';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectPermissions } from 'app/src/selectors/user';
import { getGameEngineAnnotations, patchGame, patchGameAnnotations, listGamesByTeamId } from 'app/src/epics/game';
import { useSelectGameAnnotations } from 'app/src/selectors/game';
import checkPermissions from 'app/src/utils/checkPermissions';
import { pushEvent } from 'app/src/utils/tracking';

import Button, { ButtonText, ButtonTextContainer } from 'app/src/components/ui/Button';
import GridContainer from 'app/src/components/ui/GridContainer';
import TextInput from 'app/src/components/input/TextInput';
import SelectInput from 'app/src/components/input/SelectInput';
import MultiSelectInput from 'app/src/components/input/MultiSelectInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import Card from 'app/src/components/ui/Card';
import FileInput from 'app/src/components/input/FileInput';
import CopyToClipboard from 'app/src/components/CopyToClipboard';

import _ from 'shared/copy';
import categories from 'shared/utils/categories';

const GameIDWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 4px;
	margin: 32px 0 0;
`;

const GameIDTitle = styled.div`
	font-size: 14px;
	font-weight: 700;
	color: ${props => props.theme.grey3};
`;

const GameIDContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	width: 100%;
	max-width: 500px;
	gap: 4px;
	background: ${props => props.theme.grey7};
	border-radius: 4px;
`;

const GameID = styled.span`
	color: ${props => props.theme.grey3};
	overflow-wrap: anywhere;
`;

const ThumbnailTips = styled.div`
	max-width: 500px;
	color: ${props => props.theme.grey3};
	font: 400 14px/20px "Proxima Nova";
	margin: 0 0 16px;

	ul {
		font-size: 14px;
		padding: 0 16px;
		margin: 8px 0 0;
	}
`;

const validationSchema = yup.object().shape({
	title: yup.string().min(2, 'Game title should be at least 2 characters').max(40, 'Game title should be at most 40 characters'),
	suggestedDescription: yup.string().max(1000, 'Description should be at most 1000 characters'),
});

const MAX_CATEGORIES = 4;

const GameGeneralSettingsModule = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const { engine: engines = [] } = useSelectGameAnnotations();
	const permissions = useSelectPermissions();
	const [thumbnail, setThumbnail] = useState('');
	const [thumbnailData, setThumbnailData] = useState({});

	const patchGameStatus = useSelectApiStatus(patchGame.id);
	const patchGameAnnotationsStatus = useSelectApiStatus(patchGameAnnotations.id);
	const apiStatus = combinedApiStatus(patchGameStatus, patchGameAnnotationsStatus);

	const alreadySynced = game?.content_metadata?.content_game_id;

	const canEditGameTitle = checkPermissions(permissions, [['can_edit_game_title_unless_synced']]);
	const canEditSelectedFields = checkPermissions(permissions, [['can_edit_game_selected_fields']]);
	const canEditAllGames = checkPermissions(permissions, [['can_edit_all_games']]);

	const { handleSubmit, reset, control, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			title: game?.title || '',
			engine: game?.annotations?.engine || '',
			suggestedDescription: game?.suggested_description || '',
			suggestedCategories: game?.suggested_categories ? game?.suggested_categories.split(',') : [],
			thumbnail: game?.thumbnail_url || '',
		},
		resolver: yupResolver(validationSchema),
	});

	const handlePreviewThumbnail = () => {
		const url = typeof thumbnail === 'string' ? thumbnail : thumbnailData.base64;
		dispatch(openModal({ key: 'thumbnail-preview', data: { url } }));
	};

	const handleUpload = data => {
		setThumbnailData(data);
	};

	const thumbnailValidator = ({ base64 }, cb) => {
		const image = new Image();
		image.src = base64;
		image.onload = () => {
			if (image.width !== image.height) {
				cb(_`imageShouldBeSquareError`);
			} else if (image.width < 628) {
				cb(_`imageMinResolutionError${{ minWidth: 628, minHeight: 628 }}`);
			} else {
				cb();
			}
		};
	};

	useEffect(() => {
		dispatch(getGameEngineAnnotations.fetch());
	}, []);

	useEffect(() => {
		reset({
			title: game?.title || '',
			engine: game?.annotations?.engine || '',
			suggestedDescription: game?.suggested_description || '',
			suggestedCategories: game?.suggested_categories ? game?.suggested_categories.split(',') : [],
			thumbnail: game?.thumbnail_url || '',
		});
		setThumbnail(game ? game.thumbnail_url : '');
	}, [game]);

	useEffect(() => {
		dispatch(preventPageLeave({ toggle: isDirty }));

		return () => {
			dispatch(preventPageLeave({ toggle: false }));
		};
	}, [isDirty]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	const onSubmit = data => {
		const { title, suggestedDescription, suggestedCategories, engine } = data;

		const gameData = {
			title,
			suggested_description: suggestedDescription,
			suggested_categories: suggestedCategories.join(','),
			annotations: {
				engine,
			},
		};

		if (thumbnailData && Object.keys(thumbnailData).length > 0) {
			gameData.thumbnail = thumbnailData.base64.replace(/^(.*base64,)/, '');

			// If we have thumbnailData it means we uploaded a new one
			pushEvent('gameSettings', 'changeThumbnail');
		}

		dispatch(patchGame.fetch({ gameId: game.id, data: gameData }, ({ success$ }) => (
			success$.pipe(
				map(({ payload: { result: { response } } }) => {
					const { data: { attributes: { thumbnail_url } } } = response;

					if (thumbnail_url) {
						setThumbnail(thumbnail_url);
					}

					// Also update the thumbnail in the games menu on the left.
					dispatch(listGamesByTeamId.fetch({ teamId: game.team.id }));
				}),
				tap(() => reset(data, { keepDirty: false })),
				ignoreElements(),
			)
		)));
	};

	return (
		<Card
			title={_`gameSettings`}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<GridContainer cols={2}>
					<div>
						<Controller
							control={control}
							name="title"
							render={({ field }) => (
								<TextInput
									{...field}
									label={_`gameTitle`}
									disabled={apiStatus.pending || ((!canEditGameTitle || alreadySynced) && !canEditAllGames)}
									errors={errors?.title?.message ? [errors?.title?.message] : []}
								/>
							)}
						/>
						<Controller
							control={control}
							name="suggestedCategories"
							render={({ field }) => (
								<MultiSelectInput
									{...field}
									label={_`suggestedCategories${{ maxCategories: MAX_CATEGORIES }}`}
									maxSelection={MAX_CATEGORIES}
									enableSearch
									disabled={apiStatus.pending || (!canEditSelectedFields && !canEditAllGames)}
									values={[
										...categories.map(t => ({
											value: t,
											desc: t,
										})),
									]}
								/>
							)}
						/>
						<Controller
							control={control}
							name="engine"
							render={({ field }) => (
								<SelectInput
									{...field}
									label={_`gameEngine`}
									placeholder={_`selectAGameEngine`}
									disabled={apiStatus.pending || (!canEditSelectedFields && !canEditAllGames)}
									values={[
										...engines.map(t => ({
											value: t,
											desc: t,
										})),
									]}
								/>
							)}
						/>
						<Controller
							control={control}
							name="suggestedDescription"
							render={({ field }) => (
								<TextAreaInput
									{...field}
									resize={false}
									label={_`suggestedDescription`}
									errors={errors?.suggestedDescription?.message ? [errors?.suggestedDescription?.message] : []}
									disabled={apiStatus.pending || (!canEditSelectedFields && !canEditAllGames)}
								/>
							)}
						/>
					</div>
					<div>
						<Controller
							control={control}
							name="thumbnail"
							render={({ field }) => (
								<FileInput
									{...field}
									label={_`thumbnail`}
									accept="image/png, image/jpeg"
									showImagePreview
									value={thumbnail}
									valueSetter={setThumbnail}
									validator={thumbnailValidator}
									errors={errors.thumbnail}
									disabled={apiStatus.pending || (alreadySynced && !canEditAllGames) || (!canEditSelectedFields && !canEditAllGames)}
									disableRemove
									onUpload={files => {
										handleUpload(files);
										field.onChange(files);
									}}
								/>
							)}
						/>

						<ThumbnailTips>
							<ul dangerouslySetInnerHTML={{ __html: _`thumbnailTipsList` }} />
						</ThumbnailTips>
						<Button
							secondary
							onClick={handlePreviewThumbnail}
							disabled={!thumbnail}
						>
							{_`previewSizes`}
						</Button>

						<GameIDWrapper>
							<GameIDTitle>{_`gameID`}</GameIDTitle>
							<GameIDContent>
								<GameID>{game?.id}</GameID>
								<CopyToClipboard text={game?.id} />
							</GameIDContent>
						</GameIDWrapper>
					</div>
				</GridContainer>
				<ButtonTextContainer>
					<Button submit disabled={apiStatus.pending || !isDirty} primary>{_`saveSettings`}</Button>
					<ButtonText warning={!apiStatus.pending && isDirty}>
						{apiStatus.pending ? _`saving` : isDirty ? _`unsavedChanges` : ''}
					</ButtonText>
				</ButtonTextContainer>
			</form>
		</Card>
	);
};

export default GameGeneralSettingsModule;
