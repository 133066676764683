/**
 * uncaughtErrorApiEpicPatch
 *
 * Patches createApiEpic to show an "uncaught error" toast if
 * the created epic emits an error which goes unhandled
 *
 * Note that this only works if an error stream was never attached to the store.
 * An error that for example waits for 500ms before doing anything, or an error handler that filters based on a response,
 * both count as a handled error. Even if in the end nothing happens.
 * So if an error handler is manually added to any epic or action, it should handle all errors, or dispatch
 * an uncaughtServerError action.
 */

import * as rxApi from '@poki/rx-api';
import { Subject, of, EMPTY, delay, tap, switchMap, takeUntil, map } from 'rxjs';

import epicMiddleware from 'app/src/middlewares/epicMiddleware';
import { uncaughtServerError } from 'app/src/actions/client';

const patched = {};

const originalApiEpic = rxApi.createApiEpic;

rxApi.createApiEpic = (id, handler, getCBStream, options) => {
	// Patched getCBStream - Should return an observable
	const getCBStreamPatch = original => streams => {
		if (!patched[id]) {
			const cancelUnexpectedError$ = new Subject();

			// After an error emits, if not canceled, emit an uncaught error action
			const uncaughtErrorEpic = () => (
				streams.error$.pipe(
					switchMap(action => (
						of(true).pipe(
							delay(0), // Moves this to the end of the stream stack, so the cancel below will always trigger before it
							map(() => uncaughtServerError({ action })),
							takeUntil(cancelUnexpectedError$),
						)
					)),
				)
			);

			// Attach our uncaughtErrorEpic to the store so it will always emit
			epicMiddleware.run(uncaughtErrorEpic);

			// Take the provided error$ stream (if any) and if it emits, cancel our uncaught error stream
			// This will never emit if the stream has not been attached to the store (which it only should be if it was implemented)
			streams.error$ = streams.error$.pipe(
				tap(() => cancelUnexpectedError$.next()),
			);

			// We should only patch each epic once as getCBStream is called for every action
			patched[id] = true;
		}

		// If a getCBStream was provided, ensure we call it as expected and return it
		if (original) return original(streams);

		return EMPTY;
	};

	// Patch epic level cb stream
	const epic = originalApiEpic(id, handler, getCBStreamPatch(getCBStream), options);

	// Patch fetch level cb stream
	const originalFetch = epic.fetch;
	epic.fetch = (_options, _getCbStream) => originalFetch(_options, getCBStreamPatch(_getCbStream));

	return epic;
};
