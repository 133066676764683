import React from 'react';

import styled, { keyframes, css } from 'styled-components';

const SpinnerContainer = styled.div`
	position: relative;
	width: 612px;
	height: 598px;
`;

const SparkleKeyframes = keyframes`
	35%	{
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
`;

const HandKeyframes = keyframes`
	0% {
		transform: translate3d(0,0,0) rotateZ(0) scale3d(1,1,1);
		animation-timing-function: cubic-bezier(1,0,.58,1);
	}
	12.5% {
		transform: translate3d(0,200px,0) scale3d(1.2,.8,1);
		animation-timing-function: cubic-bezier(.42,0,0,1);
	}
	25%	{
		transform: translate3d(0,0,0) scale3d(1,1,1);
		animation-timing-function: cubic-bezier(1,0,.58,1);
	}
	37.5% {
		transform: translate3d(0,200px,0) scale3d(1.2,.8,1);
		animation-timing-function: cubic-bezier(.42,0,0,1);
	}
	50%	{
		transform: translate3d(0,0,0) scale3d(1,1,1);
		animation-timing-function: cubic-bezier(1,0,.58,1);
	}
	62.5% {
		transform: translate3d(0,200px,0) scale3d(1.2,.8,1);
		animation-timing-function: cubic-bezier(.42,0,0,1);
	}
	75%	{
		transform: translate3d(0,0,0) scale3d(1,1,1);
		animation-timing-function: cubic-bezier(1,0,.58,1);
	}
	87.5% {
		transform: translate3d(0,200px,0) rotateZ(0) scale3d(1.2,.8,1);
		animation-timing-function: cubic-bezier(.42,0,0,1);
	}
	100% {
		transform: translate3d(0,0,0) rotateZ(360deg) scale3d(1,1,1);
		animation-timing-function: linear;
	}
`;

const Hand = styled.img`
	position: absolute;
	transform-origin: 284px 284px;
	height: 568px;
	width: 568px;
	left: 0;
	top: 0;
	animation: ${HandKeyframes} 4s infinite;
`;

const ShadowKeyframes = keyframes`
	0% {
		opacity: 0.2;
		transform: scale3d(.5,.8,1);
		animation-timing-function: cubic-bezier(1,0,.58,1);
	}
	50% {
		opacity: 0.5;
		transform: scale3d(1,.8,1);
		animation-timing-function: cubic-bezier(.42,0,0,1);
	}
	100% {
		opacity: .2;
		transform: scale3d(.5,.8,1);
		animation-timing-function: linear;
	}
`;

const Shadow = styled.img`
	position: relative;
	width: 568px;
	height: 604px;
	opacity: 0.2;
	transform: scale3d(.5, .8, 1);
	top: 55px;
	left: 0;
	animation: ${ShadowKeyframes} 1s infinite;
`;

const Sparkle = styled.img`
	position: absolute;
	left: 0;
	top: 0;
	width: 612px;
	height: 568px;
	opacity: 0;
	transform: translate3d(0, 0, 0);

	${props => css`
	animation: ${SparkleKeyframes} 2s infinite step-end;
	animation-delay: ${props.idx * 0.05 * 2}s;
	`}
`;

const Spinner = ({ className }) => (
	<SpinnerContainer className={className}>
		<Sparkle idx={0} src="/svg/sparkle-1.svg" />
		<Sparkle idx={1} src="/svg/sparkle-2.svg" />
		<Sparkle idx={2} src="/svg/sparkle-3.svg" />
		<Sparkle idx={3} src="/svg/sparkle-4.svg" />
		<Sparkle idx={4} src="/svg/sparkle-5.svg" />
		<Sparkle idx={5} src="/svg/sparkle-6.svg" />
		<Sparkle idx={6} src="/svg/sparkle-7.svg" />
		<Hand src="/svg/hand-fingers-isolated.svg" />
		<Shadow src="/svg/hand-loader-shadow.svg" />
	</SpinnerContainer>
);

export default Spinner;
