import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { of, switchMap, tap } from 'rxjs';

import { setPreviousRefreshToken, setRefreshToken } from 'app/src/actions/session';
import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';
import { refreshAuth } from 'app/src/epics/session';
import { useSelectPreviousRefreshToken } from 'app/src/selectors/session';
import UserImage from 'app/src/components/ui/UserImage';
import _ from 'shared/copy';

const Container = styled.div`
	padding: 26px 24px;
	border-top: 2px solid ${props => props.theme.grey7};
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0 0 26px;

	> svg {
		cursor: pointer;

		[fill] {
			fill: ${props => props.theme.grey3};
		}

		&:hover {
			[fill] {
				fill: ${props => props.theme.pokiBlue};
			}
		}
	}
`;

const User = styled.div`
	display: flex;
	align-items: flex-start;
	font-size: 16px;
	font-weight: 700;
	color: ${props => props.theme.green1};
`;

const StyledUserImage = styled(UserImage)`
	width: 24px;
	height: 24px;
	margin-right: 14px;
`;

const ViewAs = ({ impersonator }) => {
	const dispatch = useDispatch();
	const previousRefreshToken = useSelectPreviousRefreshToken();

	const handleClose = () => {
		dispatch(refreshAuth.fetch({ refreshToken: previousRefreshToken }, ({ success$ }) => (
			success$.pipe(
				switchMap(() => (
					of(
						setRefreshToken({ refreshToken: previousRefreshToken }),
						setPreviousRefreshToken(null),
					)
				)),
				tap(() => (
					setTimeout(() => (
						window.location.reload()
					), 0)
				)),
			)
		)));
	};

	return (
		<Container>
			<Header>
				<div>{_`beingImpersonatedBy`}</div>
				<CloseIcon onClick={handleClose} />
			</Header>
			<User>
				<StyledUserImage src={impersonator.picture} />
				<div>{impersonator.name}</div>
			</User>
		</Container>
	);
};

export default ViewAs;
