import React from 'react';
import styled from 'styled-components';

import Block2 from 'shared/components/svg/symbols/Block2';
import Coin3 from 'shared/components/svg/symbols/Coin3';
import Diamond2 from 'shared/components/svg/symbols/Diamond2';
import Heart3 from 'shared/components/svg/symbols/Heart3';
import Star3 from 'shared/components/svg/symbols/Star3';

import { isMobile } from 'shared/vars';
import _ from 'shared/copy';

const Container = styled.div`
	background: ${props => props.theme.pureWhite};
	position: relative;
	box-shadow: ${props => props.theme.boxShadowSmall};
	border-radius: 8px;
	display: flex;
	width: 100%;
	overflow: hidden;

	${isMobile && `
	height: auto;
	align-items: center;
	padding: 16px 8px;
	`}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 24px;

	overflow: hidden;

	${isMobile && `
	padding: 0 12px 0 16px;
	`}
`;

const Title = styled.div`
	font-family: Torus;
	font-size: 21px;
	font-weight: bold;
	color: ${props => props.theme.denimBlue};
	white-space: nowrap;
	line-height: 34px;
	text-overflow: ellipsis;
	overflow: hidden;

	${isMobile && `
	font-size: 18px;
	line-height: 20px;
	`}
`;

const TeamID = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 4px;

	font-weight: bold;
	font-size: 16px;
`;

const Code = styled.span`
	font-weight: normal;
`;

const SymbolContainer = styled.div`
	position: absolute;
	right: 0;
	width: 300px;
	height: 300px;
	top: 50%;
	transform: translate(40%, -30%)
`;

const HeroTeam = props => {
	const { data } = props;

	if (!data) return <Container />;

	const { code, name } = data;

	const number = Number(data.id.replace(/[^0-9]/g, ''));
	const symbols = [Coin3, Diamond2, Star3, Heart3, Block2];
	const Symbol = symbols[number % symbols.length];

	return (
		<Container>
			<Info>
				<Title>{name}</Title>
				<TeamID>
					{_`teamCode`}: <Code>{code}</Code>
				</TeamID>
			</Info>
			{!isMobile && <SymbolContainer><Symbol /></SymbolContainer>}
		</Container>
	);
};

export default HeroTeam;
