import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelectApiStatus } from '@poki/rx-api';
import { EMPTY, fromEvent, switchMap, tap, map } from 'rxjs';
import styled from 'styled-components';
import moment from 'moment';

import FeedbackIcon from 'shared/designTokens/icons/ui/small/FeedbackIcon';

import { getAllPendingReviews, getAllReportsForToday, getAllReportsForYesterday, getAllReviewsForToday, getCompletedReviews, getPendingReviews, sendReviewReport, setReviewReportPrivateNotes } from 'app/src/epics/review';
import { useSelectAllPendingReviews, useSelectAllReportsToday, useSelectAllReportsYesterday, useSelectAllReviewsToday, useSelectCompletedReviews, useSelectPendingReviews } from 'app/src/selectors/review';
import { openModal, openToast } from 'app/src/actions/client';
import { useSelectPermissions } from 'app/src/selectors/user';
import useEpic from 'app/src/hooks/useEpic';
import useActionCounter from 'app/src/hooks/useActionCounter';

import getTimeSince from 'app/src/utils/getTimeSince';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import getReviewStatusText from 'app/src/utils/getReviewStatusText';
import checkPermissions from 'app/src/utils/checkPermissions';

import IconButton from 'app/src/components/ui/IconButton';
import GridContainer from 'app/src/components/ui/GridContainer';
import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import Button from 'app/src/components/ui/Button';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import Tooltip from 'app/src/components/ui/Tooltip';

import { isMobile, motionOut, motionSpeed02, teamCustomerSegments, dayMonthYearTimeFormat } from 'shared/vars';
import _, { getReleaseStatusCopy } from 'shared/copy';

const TwoThirdsCard = styled(Card)`
	grid-column: span 2;
`;

const GameThumbnail = styled.div`
	position: relative;
	border-radius: 4px;
	background: ${props => (props.url ? `url("${props.url}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 20px;
	width: 20px;
	margin-right: 8px;
	flex-shrink: 0;
`;

const GameName = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
`;

const Game = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const GameExclusivity = styled.span`
	text-transform: capitalize;
`;

const GameLink = styled(Link)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Team = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const TeamCustomerSegment = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
`;

const Actions = styled.div`
	display: flex;
	flex-wrap: wrap;

	> *:not(first-child) {
		margin-right: 12px;
	}
`;

const ReviewRequested = styled.div`
	display: flex;
	flex-direction: column;
`;

const TimeAgo = styled.div``;

const Subtitle = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
`;

const PrivateNotesContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	${!isMobile ? `
	min-height: 48px;
	` : ''}
`;

const LabelInput = styled.textarea`
	background: transparent;
	display: block;
	padding: 0 16px;
	width: 100%;
	border: 1px solid transparent;
	color: ${props => props.theme.denimBlue};
	font-size: 16px;
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	border-radius: 8px;
	margin-left: -17px;
	margin-top: -6px;
	height: 55px;
	padding-top: 5px;
	line-height: 1.5em;

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	${props => (!props.disabled ? `
	&:hover,
	&:focus {
		border: 1px solid ${props.theme.grey5};
	}

	&:focus {
		outline: none;
		border-color: ${props.theme.pokiBlue};
		box-shadow: inset 0 0 0 2px ${props.theme.pokiBlue};
		background: ${props.theme.grey9};
	}
	` : '')}
`;

const LabelFieldset = styled.fieldset`
	position: relative;
	max-width: 500px;
	width: 100%;
`;

const ReviewStatusContainer = styled.div`
	display: flex;
`;

const ReviewStatusText = styled.div`
	display: flex;
	flex-direction: column;

	&:not(:first-child) {
		margin-left: 10px;
	}
`;

const ReviewStatus = styled.div`
	font-weight: bold;

	${props => (props.$status === 'pending' ? `
	color: ${props.theme.pokiBlue};
	` : props.$status === 'approved' ? `
	color: ${props.theme.green1};
	` : props.$status === 'rejected' ? `
	color: ${props.theme.rose5};
	` : props.$status === 'closed' ? `
	color: ${props.theme.grey3};
	` : '')}
`;

const ReviewStatusTime = styled.div`
	font-size: 12px;
	line-height: 20px;
	color: ${props => props.theme.grey3};
`;

const ApprovedRejected = styled.div`
	font-weight: bold;
	display: flex;
	font-size: 20px;
`;

const Approved = styled.div`
	color: ${props => props.theme.green1};
	margin-right: 4px;
`;

const Rejected = styled.div`
	color: ${props => props.theme.rose5};
	margin: 0 4px;
`;

const ApprovedRejectedText = styled.div`
	font-weight: normal;
	font-size: 14px;
	color: ${props => props.theme.grey3};
`;

const WarningStat = styled(Stat)`
	color: ${props => props.theme.rose3};
`;

const StyledTooltip = styled(Tooltip)`
	width: max-content;
`;

const GameSubtitle = styled(Subtitle)`
	display: flex;
	gap: 4px;
	white-space: nowrap;
`;

const pendingReviewsPerPage = 20;
const completedReviewsPerPage = 20;

const GameColumn = ({ item }) => {
	const { version } = item;
	const { game } = version;
	const { team } = game;

	if (!game || !team) return _`gameOrTeamNotFound`;

	const numDomainsLive = Object.keys(game.content_metadata?.urls || {}).length;
	const exclusivity = game?.annotations?.exclusivity;

	return (
		<Game>
			<GameName>
				<GameThumbnail url={getGameThumbnailUrl(game.thumbnail_url, 20)} />
				<GameLink to={`/${team.code}/games/${game.id}`}>{game.title}</GameLink>
			</GameName>
			<GameSubtitle>
				<StyledTooltip
					text={_([getReleaseStatusCopy(game?.content_metadata?.release_status, true, true)])}
					title="Live on"
					body={`${numDomainsLive} domain${numDomainsLive !== 1 ? 's' : ''}`}
				/>
				{exclusivity && <GameExclusivity>{`- ${exclusivity}`}</GameExclusivity>}
			</GameSubtitle>
		</Game>
	);
};

const PrivateNotes = props => {
	const { review, disabled } = props;

	const dispatch = useDispatch();
	const inputRef = useRef();
	const [notes, setNotes] = useState(review.poki_private_notes || '');
	const [hasFocus, setHasFocus] = useState(false);

	const setReviewReportPrivateNotesStatus = useSelectApiStatus(setReviewReportPrivateNotes.id);

	const handleBlur = () => {
		if (!hasFocus) return;

		setHasFocus(false);

		if (review.poki_private_notes === notes) return; // Don't save if label didn't change

		dispatch(setReviewReportPrivateNotes.fetch({ gameId: review.version.game.id, versionId: review.version.id, reviewId: review.id, data: { pokiPrivateNotes: notes } }, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					// Shortcut for ensuring item is updated without having to re-retrieve it
					// usually would not recommend (who knows what the server does), but we're only changing one field and keeping
					// this part fast is worth the shortcut
					review.poki_private_notes = notes;
				}),
				map(() => openToast({ body: 'Private notes saved' })),
			)
		)));
	};

	// close shortcut
	useEpic(() => {
		if (!inputRef.current) return EMPTY;
		return fromEvent(inputRef.current, 'keydown').pipe(
			switchMap(e => {
				const { keyCode } = e;

				if (keyCode === 13 && !e.getModifierState('Shift')) { // Enter - save
					handleBlur();
				} else if (keyCode === 27) { // Esc - cancel
					setNotes(review.poki_private_notes);
					inputRef.current.blur();
				}

				return EMPTY;
			}),
		);
	}, [hasFocus, notes, inputRef.current]);

	return (
		<PrivateNotesContainer>
			{isMobile ? (
				notes
			) : (
				<LabelFieldset>
					<LabelInput
						ref={inputRef}
						disabled={setReviewReportPrivateNotesStatus.pending || disabled}
						name={`notes-${review.id}`}
						placeholder="Set a note"
						value={notes}
						onBlur={handleBlur}
						onFocus={() => setHasFocus(true)}
						onChange={evt => setNotes(evt.target.value)}
						maxLength={256}
					/>
				</LabelFieldset>
			)}
		</PrivateNotesContainer>
	);
};

const AdminReviewsPage = () => {
	const dispatch = useDispatch();

	const [pendingReviewsPage, setPendingReviewsPage] = useState(1);
	const [pendingReviewsSort, setPendingReviewsSort] = useState({ field: 'queue_time', direction: 1 });
	const [completedReviewsPage, setCompletedReviewsPage] = useState(1);
	const [completedReviewsSort, setCompletedReviewsSort] = useState({ field: 'report_submitted_at', direction: -1 });

	const getPendingReviewsStatus = useSelectApiStatus(getPendingReviews.id);
	const getCompletedReviewsStatus = useSelectApiStatus(getCompletedReviews.id);

	const { data: pendingReviews, meta: { total: totalPendingReviews } = {} } = useSelectPendingReviews();
	const { data: completedReviews, meta: { total: totalCompletedReviews } = {} } = useSelectCompletedReviews();
	const { meta: { total: totalReviewsToday } = {} } = useSelectAllReviewsToday();
	const { data: allReportsToday = [], meta: { total: totalReportsToday } = {} } = useSelectAllReportsToday();
	const { meta: { total: totalReportsYesterday } = {} } = useSelectAllReportsYesterday();
	const { data: allPendingReviews = [] } = useSelectAllPendingReviews();
	const permissions = useSelectPermissions();

	// @todo use useMemo
	const numReviewsInQueueOver24Hours = allPendingReviews.filter(review => moment().diff(moment(review.queue_time * 1000), 'hours') > 24).length;
	const numApprovedToday = allReportsToday.filter(review => review.status === 'approved').length;
	const numRejectedToday = allReportsToday.filter(review => review.status === 'rejected').length;

	const canEditAllTeams = checkPermissions(permissions, [['can_edit_all_teams']]);

	const actionCounter = useActionCounter(
		sendReviewReport.success.type,
	);

	useEffect(() => {
		dispatch(getAllPendingReviews.fetch());
		dispatch(getAllReviewsForToday.fetch());
		dispatch(getAllReportsForToday.fetch());
		dispatch(getAllReportsForYesterday.fetch());
	}, [actionCounter]);

	useEffect(() => {
		dispatch(getPendingReviews.fetch({ sortField: pendingReviewsSort.field, sortDirection: pendingReviewsSort.direction, page: pendingReviewsPage, perPage: pendingReviewsPerPage }));
	}, [actionCounter, pendingReviewsSort, pendingReviewsPage]);

	useEffect(() => {
		dispatch(getCompletedReviews.fetch({ sortField: completedReviewsSort.field, sortDirection: completedReviewsSort.direction, page: completedReviewsPage, perPage: completedReviewsPerPage }));
	}, [actionCounter, completedReviewsSort, completedReviewsPage]);

	return (
		<>
			<Helmet key="AdminReviewsPage">
				<title>Reviews - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={3}>
				<Card
					title="Real-time"
				>
					<Stat
						value={totalPendingReviews}
						valueDesc="reviews in queue"
					/>
					<WarningStat
						value={numReviewsInQueueOver24Hours}
						valueDesc="reviews in queue over 24 hours"
					/>
				</Card>
				<TwoThirdsCard
					title="Today"
				>
					<GridContainer cols={2}>
						<div>
							<Stat
								value={totalReviewsToday}
								valueDesc="reviews requested today"
							/>
						</div>
						<div>
							<Stat
								value={totalReportsToday}
								valueDesc="reports submitted today"
								previousAsAbsolute
								previous={totalReportsYesterday}
								previousDesc="reports submitted yesterday"
							/>
							<ApprovedRejected>
								<Approved>{numApprovedToday}</Approved> / <Rejected>{numRejectedToday}</Rejected>
								<ApprovedRejectedText>approved / rejected</ApprovedRejectedText>
							</ApprovedRejected>
						</div>
					</GridContainer>
				</TwoThirdsCard>
			</GridContainer>
			<GridContainer cols={1}>
				<Card
					title={_`pendingReviews`}
					noPadding
				>
					<Table
						isLoading={!getPendingReviewsStatus.done}
						items={pendingReviews}
						itemAlign="top"
						setSort={setPendingReviewsSort}
						sortField={pendingReviewsSort.field}
						sortDirection={pendingReviewsSort.direction}
						setPagination={setPendingReviewsPage}
						page={pendingReviewsPage}
						perPage={pendingReviewsPerPage}
						totalItems={totalPendingReviews}
						columns={[
							{
								title: _`requested`,
								width: 'max-content',
								content: ({ item }) => (
									<ReviewRequested>
										<TimeAgo title={moment(item.queue_time * 1000).format(dayMonthYearTimeFormat)}>{_`timeAgo${{ timeSince: getTimeSince(item.queue_time * 1000) }}`}</TimeAgo>
										<Subtitle>by {item.created_by.name || item.created_by.email}</Subtitle>
									</ReviewRequested>
								),
								sortField: 'queue_time',
								defaultSortDirection: 1,
							},
							{
								title: _`game`,
								content: ({ item }) => <GameColumn item={item} />,
							},
							{
								title: _`team`,
								content: ({ item }) => {
									const { version } = item;
									const { game } = version;
									const { team } = game;

									if (!team) return _`teamNotFound`;

									return (
										<Team>
											<TeamNameWithFlag team={team} />
											<TeamCustomerSegment>{teamCustomerSegments.find(t => t.value === team.customer_segment)?.desc}</TeamCustomerSegment>
										</Team>
									);
								},
							},
							{
								title: _`version`,
								content: ({ item }) => {
									const { version } = item;

									return getVersionLabel(version);
								},
							},
							{
								title: _`privateNotes`,
								width: '300px',
								content: ({ item }) => <PrivateNotes review={item} disabled={!canEditAllTeams} />,
							},
							canEditAllTeams && (
								{
									title: _`actions`,
									width: 'max-content',
									content: ({ item }) => (
										<Actions>
											<Button
												primary
												onClick={() => dispatch(openModal({ key: 'create-review-report', data: { review: item } }))}
											>
												{_`report`}
											</Button>
										</Actions>
									),
								}),
						]}
					/>
				</Card>
				<Card
					title={_`completedReviews`}
					expandable
					noPadding
				>
					<Table
						isLoading={!getCompletedReviewsStatus.done}
						items={completedReviews}
						itemAlign="top"
						setSort={setCompletedReviewsSort}
						sortField={completedReviewsSort.field}
						sortDirection={completedReviewsSort.direction}
						setPagination={setCompletedReviewsPage}
						page={completedReviewsPage}
						perPage={completedReviewsPerPage}
						totalItems={totalCompletedReviews}
						columns={[
							{
								title: _`status`,
								content: ({ item }) => (
									<ReviewStatusContainer>
										{item.status !== 'closed' && (
											<IconButton
												icon={FeedbackIcon}
												title={_`viewRequest`}
												onClick={() => dispatch(openModal({ key: 'view-review-request', data: { game: item.version.game, version: item.version, review: item } }))}
											/>
										)}
										<ReviewStatusText>
											<ReviewStatus $status={item.status}>
												{getReviewStatusText(item.status)}
											</ReviewStatus>
											<ReviewStatusTime title={moment(item.report_submitted_at * 1000).format(dayMonthYearTimeFormat)}>{_`timeAgo${{ timeSince: getTimeSince(item.report_submitted_at * 1000) }}`}</ReviewStatusTime>
										</ReviewStatusText>
									</ReviewStatusContainer>
								),
								sortField: 'report_submitted_at',
								defaultSortDirection: -1,
							},
							{
								title: _`game`,
								content: ({ item }) => <GameColumn item={item} />,
								sortField: 'title',
								defaultSortDirection: 1,
							},
							{
								title: _`team`,
								content: ({ item }) => {
									const { version } = item;
									const { game } = version;
									const { team } = game;

									if (!team) return _`teamNotFound`;

									return (
										<Team>
											<TeamNameWithFlag team={team} />
											<TeamCustomerSegment>{teamCustomerSegments.find(t => t.value === team.customer_segment)?.desc}</TeamCustomerSegment>
										</Team>
									);
								},
								sortField: 'pokifordevs_teams.name',
								defaultSortDirection: 1,
							},
							{
								title: _`version`,
								content: ({ item }) => {
									const { version } = item;

									return getVersionLabel(version);
								},
							},
							{
								title: _`reviewRequested`,
								width: 'max-content',
								content: ({ item }) => (
									<ReviewRequested>
										<TimeAgo title={moment(item.queue_time * 1000).format(dayMonthYearTimeFormat)}>{_`timeAgo${{ timeSince: getTimeSince(item.queue_time * 1000) }}`}</TimeAgo>
										<Subtitle>by {item.created_by.name || item.created_by.email}</Subtitle>
									</ReviewRequested>
								),
								sortField: 'queue_time',
								defaultSortDirection: 1,
							},
						]}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default AdminReviewsPage;
