import React, { useState } from 'react';
import styled from 'styled-components';

import { motionSpeed02, motionOut } from 'shared/vars';

const Img = styled.img`
	opacity: 0;
	transition: opacity ${motionSpeed02} ${motionOut};

	${props => props.$loaded && `
	opacity: 1;
	`};
`;

const SignoutAnimation = props => {
	const { className } = props;

	const [loaded, setLoaded] = useState(false);

	return (
		<Img
			alt="Sign out"
			className={className}
			src="/svg/signout-animation.svg"
			onLoad={() => setLoaded(true)}
			$loaded={loaded}
		/>
	);
};

export default SignoutAnimation;
