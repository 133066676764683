import React from 'react';
import styled from 'styled-components';

const StyledNotificationCard = styled.div`
	width: 100%;
	border-radius: 8px;
	box-shadow: ${props => props.theme.boxShadowSmall};
`;

const StyledH4 = styled.h4`
	font-size: 16px;
	margin: 0;
	color: #ffffff;
`;

const StyledParagraph = styled.p`
	font-size: 14px;
	margin: 0;
`;

const NotificationTitle = styled.div`
	width: 100%;
	height: fit-content;
	border-radius: 8px 8px 0 0;
	padding: 8px 16px;
	display: flex;
	align-items: center;
	gap: 10px;

	.icon-styled {
		[fill] {
			fill: #ffffff;
		}
	}

	${props => props.state === 'error' && `
		background: ${props.theme.rose5};
	`}

	${props => props.state === 'warning' && `
		background: ${props.theme.yellow3};
	`}
`;
const NotificationContent = styled.div`
	padding: 24px;
`;

const GeneralNotification = props => {
	const { state, icon: Icon, title, description } = props;

	return (
		<StyledNotificationCard>
			<NotificationTitle state={state}>
				<Icon className="icon-styled" />
				<StyledH4>{title}</StyledH4>
			</NotificationTitle>
			<NotificationContent>
				<StyledParagraph>{description}</StyledParagraph>
			</NotificationContent>
		</StyledNotificationCard>
	);
};

export default GeneralNotification;
