import React from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';

import GlitchPage from 'app/src/components/pages/GlitchPage';
import _ from 'shared/copy';

const messages = [
	_`errorPageMessage1`,
	_`errorPageMessage2`,
	_`errorPageMessage3`,
	_`errorPageMessage4`,
	_`errorPageMessage5`,
];

class ErrorBoundary extends React.PureComponent {
	constructor(...args) {
		super(...args);

		this.state = {};
	}

	static getDerivedStateFromError(error) {
		return { error };
	}

	static componentDidCatch(error, errorInfo) {
		Sentry.withScope(scope => {
			scope.setExtras(errorInfo);
			Sentry.captureException(error);
		});
	}

	render() {
		const { children } = this.props;
		const { error } = this.state;

		if (error) {
			return (
				<GlitchPage
					title="ERROR"
					message={messages[Math.floor(Math.random() * messages.length)]}
					subMessage={_`errorHelpMessage`}
				/>
			);
		}

		return children;
	}
}

export default connect(state => ({ state }))(ErrorBoundary);
