import React, { useMemo, useContext } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';

/* Styled Components */

const Container = styled.div`
	border-bottom: 1px solid ${props => (props.theme.isDarkMode ? props.theme.static.grey3 : props.theme.static.dataBlue1)};
`;

const SVG = styled.svg`
	display: inline-block;
	vertical-align: bottom;
`;

const Bar = styled.rect`
	fill: ${props => (props.theme.isDarkMode ? props.theme.static.dataBlue1 : props.theme.static.dataBlue5)};
`;

const Line = styled.path`
	fill: none;
	stroke-width: 1px;
	stroke: ${props => (props.theme.isDarkMode ? props.theme.static.dataBlue1 : props.theme.static.dataBlue5)};
`;

const Area = styled.path`
	opacity: 0.2;
	stroke-width: 0.5px;
	fill: ${props => (props.theme.isDarkMode ? props.theme.static.dataBlue1 : props.theme.static.dataBlue5)};
	stroke: ${props => (props.theme.isDarkMode ? props.theme.static.dataBlue1 : props.theme.static.dataBlue5)};
`;

/* Configuration */

const ChartContext = React.createContext({});

/* Methods */

const getXRange = ({ data, xRange }) => xRange || d3.extent(data, (d, idx) => idx);

const getXScale = props => {
	const { width, type, data } = props;

	// For a bar chart we need to leave space at the end, not for others
	const rangeEnd = type === 'bar' ? (width / (data.length + 1)) * data.length : width;

	const scale = d3.scaleLinear()
		.domain(getXRange(props))
		.range([0, rangeEnd]);

	return scale;
};

const buildYScale = (props, range) => {
	const { height } = props;

	const scale = d3.scaleLinear()
		.domain(range)
		.range([height, 1]);

	return scale;
};

const getYRange = props => {
	const { data } = props;

	if (data.length === 0) return [0, 0];

	const entries = data;

	const extent = d3.extent(entries, d => d);

	const min = 0;
	const max = Math.max(0.01, extent[1]);

	return [min, max];
};

const getYScale = props => buildYScale(props, getYRange(props));

const AreaChart = React.memo(() => {
	const {
		xScale,
		yScale,
		data,
	} = useContext(ChartContext);

	const useableData = data
		.filter(d => typeof d !== 'undefined' && d !== null)
		.map((d, idx) => ({
			x: idx,
			y: d,
		}));

	const area = d3.area()
		.defined(d => !Number.isNaN(d.y))
		.x(d => xScale(d.x))
		.y0(yScale(0))
		.y1(d => yScale(d.y));

	return (
		<>
			<Area d={area(useableData)} />
			{/* Also draw the line for visual reasons */}
			<LineChart />
		</>
	);
});

const LineChart = React.memo(() => {
	const {
		xScale,
		yScale,
		data,
	} = useContext(ChartContext);

	const useableData = data
		.filter(d => typeof d !== 'undefined' && d !== null)
		.map((d, idx) => ({
			x: idx,
			y: d,
		}));

	const line = d3.line()
		.defined(d => !Number.isNaN(d.y))
		.x(d => xScale(d.x))
		.y(d => yScale(d.y));

	return <Line d={line(useableData)} />;
});

const BarChart = React.memo(() => {
	const {
		width,
		height,
		xScale,
		yScale,
		data,
	} = useContext(ChartContext);

	const barWidth = (width / data.length) - 2;

	return data
		.filter(d => !Number.isNaN(d))
		.map((d, idx) => (
			<Bar
				key={idx} /* eslint-disable-line react/no-array-index-key */
				x={xScale(idx)}
				y={yScale(d)}
				width={barWidth}
				height={height - (yScale(d) || 0)}
			/>
		));
});

const TinyChart = props => {
	const { type = 'bar', width, height, data } = props;

	/* Dependencies */

	const xScale = useMemo(() => getXScale(props), [props]);
	const yScale = useMemo(() => getYScale(props), [props]);
	const yRange = useMemo(() => getYRange(props), [props]);

	/* Select correct sub component to render chart */
	let ChartComponent;
	if (type === 'bar') {
		ChartComponent = BarChart;
	} else if (type === 'line') {
		ChartComponent = LineChart;
	} else if (type === 'area') {
		ChartComponent = AreaChart;
	}

	return (
		<ChartContext.Provider
			value={useMemo(() => ({
				width,
				height,
				xScale,
				yScale,
				yRange,
				data,
			}), [props])}
		>
			<Container>
				<SVG
					viewBox={`0 0 ${width} ${height}`}
					style={{
						width: `${width}px`,
						height: `${height}px`,
					}}
				>
					<ChartComponent />
				</SVG>
			</Container>
		</ChartContext.Provider>
	);
};

TinyChart.defaultProps = {
	width: 200,
	height: 100,
	data: [],
};

export default TinyChart;
