import React, { useRef } from 'react';
import styled from 'styled-components';

import BestPracticesIcon from 'shared/designTokens/icons/ui/small/BestPracticesIcon';
import CodeIcon from 'shared/designTokens/icons/ui/small/CodeIcon';

import Link from 'app/src/components/Link';
import PokiLogo from 'shared/components/svg/PokiLogo';
import { isMobile, navigationWidth, headerHeight, motionSpeed02, motionOut, customScrollbar } from 'shared/vars';
import DeveloperMenu from 'app/src/components/DeveloperMenu';
import GamesMenu from 'app/src/components/GamesMenu';
import { useSelectNavigationIsActive } from 'app/src/selectors/client';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import ListMenu from 'app/src/components/ui/ListMenu';
import _ from 'shared/copy';
import { useSelectImpersonator, useSelectUser } from 'app/src/selectors/user';
import UserMenu from 'app/src/components/UserMenu';
import ViewAs from 'app/src/components/ViewAs';

const Container = styled.div`
	position: relative;
	width: ${navigationWidth}px;
	height: 100%;
	background: ${props => props.theme.pureWhite};
	border-right: 1px solid ${props => props.theme.grey7};
	z-index: 2;
	transition: transform ${motionSpeed02} ${motionOut};
	display: flex;
	flex-direction: column;

	${props => (isMobile ? `
	position: fixed;
	left: ${props.$adminMenuActive && props.$isActive ? '48px' : '0'};
	top: ${headerHeight};
	height: calc(100% - ${headerHeight});
	padding-top: 20px;
	box-shadow: ${props.theme.boxShadowSmall};
	` : '')}

	${props => (!props.$isActive ? `
	transform: translateX(-100%);
	` : '')}
`;

const StyledPokiLogo = styled(PokiLogo)`
	height: auto;
	width: 197px;
	vertical-align: bottom;
`;

const StyledLink = styled(Link)`
	display: block;
`;

const ResourcesMenu = styled(ListMenu)`
	margin-top: auto;
`;

const FixedTop = styled.div`
	padding: 14px 24px 0;
	height: 80px;
	flex-shrink: 0;
`;

const ScrollArea = styled.div`
	padding: 14px 24px 0;
	flex-grow: 1;
	margin-right: 2px;

	${isMobile ? `
	overflow: auto;
	` : `
	overflow: hidden;

	&:hover {
		overflow: auto;
	}
	`}

	${customScrollbar}
`;

const FixedBottom = styled.div`
	padding: 24px;
	border-top: 2px solid ${props => props.theme.grey7};
`;

const Navigation = props => {
	const { adminMenuActive } = props;

	const activeTeam = useSelectActiveTeam();
	const user = useSelectUser();
	const scrollAreaRef = useRef();
	const isActive = useSelectNavigationIsActive();
	const impersonator = useSelectImpersonator();

	return (
		<Container $adminMenuActive={adminMenuActive} $isActive={isActive}>
			{!isMobile && (
				<FixedTop>
					<StyledLink to={`/${activeTeam.code}`}><StyledPokiLogo /></StyledLink>
				</FixedTop>
			)}

			<ScrollArea ref={scrollAreaRef}>
				<DeveloperMenu />
				<GamesMenu scrollContainerRef={scrollAreaRef} />
			</ScrollArea>

			<FixedBottom>
				<ResourcesMenu
					buttons={[
						{
							icon: BestPracticesIcon,
							to: 'https://developers.poki.com/guide',
							name: _`developerGuide`,
						},
						{
							icon: CodeIcon,
							to: 'https://sdk.poki.com/',
							name: _`pokiSDK`,
						},
					]}
				/>
				{user && <UserMenu />}
			</FixedBottom>
			{impersonator && <ViewAs impersonator={impersonator} />}
		</Container>
	);
};

export default Navigation;
