import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fromEvent, tap } from 'rxjs';
import styled from 'styled-components';

import { toggleDarkMode } from 'app/src/actions/session';
import ToggleInput from 'app/src/components/input/ToggleInput';
import FloatingMenu from 'app/src/components/ui/FloatingMenu';
import UserImage from 'app/src/components/ui/UserImage';
import GearIcon from 'shared/designTokens/icons/ui/small/GearIcon';
import PencilIcon from 'shared/designTokens/icons/ui/small/PencilIcon';
import { useSelectIsDarkMode, useSelectRefreshToken } from 'app/src/selectors/session';
import { useSelectUser } from 'app/src/selectors/user';
import { darkColors, isMobile } from 'shared/vars';
import PreviewIcon from 'shared/designTokens/icons/ui/small/PreviewIcon';
import LinkIcon from 'shared/designTokens/icons/ui/small/LinkIcon';
import UsersIcon from 'shared/designTokens/icons/ui/small/UsersIcon';
import LogoutIcon from 'shared/designTokens/icons/ui/small/LogoutIcon';
import ChevronRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';
import { openModal, copyPermalink } from 'app/src/actions/client';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { impersonateUser } from 'app/src/epics/user';
import _ from 'shared/copy';
import { pushEvent } from 'app/src/utils/tracking';

const Username = styled.div`
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;
`;

const StyledUserImage = styled(UserImage)`
	width: 24px;
	height: 24px;
	margin-right: 14px;
`;

const StyledToggleInput = styled(ToggleInput)`
	margin-left: auto;
`;

const UserSettings = styled.div`
	position: relative;
	display: flex;
	margin-left: auto;

	> svg {
		cursor: pointer;
	}
`;

const StyledGearIcon = styled(GearIcon)`
	&& {
		&:hover {
			> path {
				fill: ${props => props.theme.pokiBlue};
			}
		}
	}
`;

const User = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin: 24px 0 9px;
	height: 24px;
	color: ${props => (props.$isAdminNavigation ? darkColors.grey1 : props.theme.grey3)};

	${UserSettings} {
		> svg {
			cursor: pointer;

			[fill] {
				fill: ${props => (props.$isAdminNavigation ? darkColors.grey1 : props.theme.grey3)};
			}

			&:hover {
				[fill] {
					fill: ${props => props.theme.pokiBlue};
				}
			}
		}
	}

	${Username} {
		color: ${props => (props.$isAdminNavigation ? darkColors.grey1 : props.theme.grey3)};
	}
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
	margin-left: auto;
`;

const UserMenu = props => {
	const { isAdminNavigation } = props;

	const [active, setActive] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const activeTeam = useSelectActiveTeam();
	const filteredActiveUsers = (activeTeam?.users || []).filter(filterUser => filterUser.role.startsWith('developer'));
	const user = useSelectUser();
	const isDarkMode = useSelectIsDarkMode();
	const refreshToken = useSelectRefreshToken();
	const darkModeRef = useRef();
	const userSettingsRef = useRef();
	const handleToggleDarkMode = toggle => {
		dispatch(toggleDarkMode({ toggle }));
	};

	const handleImpersonateUser = teamUser => {
		if (teamUser) {
			pushEvent('user', 'viewAs', { teamId: teamUser.team_id, userRole: teamUser.role });
			dispatch(impersonateUser.fetch({ refreshToken, targetId: teamUser.id }));
		}
	};

	// Disable user settings on clicking outside
	useEffect(() => {
		if (!active || !userSettingsRef.current) return;

		const click$ = fromEvent(document.body, 'click').pipe(
			tap(e => {
				if (!userSettingsRef.current.contains(e.target)) {
					setActive(false);
				}
			}),
		);

		const subscription = click$.subscribe();

		return () => {
			if (subscription) subscription.unsubscribe();
		};
	}, [active, userSettingsRef.current]);

	return user && (
		<User $isAdminNavigation={isAdminNavigation}>
			<StyledUserImage src={user.picture} />
			<Username>{user.name}</Username>
			<UserSettings ref={userSettingsRef}>
				<StyledGearIcon
					onClick={() => setActive(t => !t)}
				/>
				<FloatingMenu
					active={active}
					xDirection={isMobile ? 'left' : 'right'}
					buttons={[
						{
							icon: PencilIcon,
							name: _`userSettings`,
							onClick: () => navigate('/user-settings'),
							needsPermissions: [['can_edit_all_teams', 'can_edit_owned_teams']],
						},
						{
							icon: PreviewIcon,
							name: _`darkMode`,
							contents: () => (
								<StyledToggleInput ref={darkModeRef} checked={isDarkMode} onChange={handleToggleDarkMode} />
							),
							onClick: () => darkModeRef.current.click(),
						},
						{
							icon: LinkIcon,
							name: _`copyPermalink`,
							onClick: () => dispatch(copyPermalink()),
						},
						!isAdminNavigation && !isMobile && {
							icon: UsersIcon,
							name: _`viewAs`,
							needsPermissions: [['can_impersonate_user']],
							contents: () => <StyledChevronRightIcon />,
							children: filteredActiveUsers.length > 0 ? filteredActiveUsers.map(teamUser => (
								{
									name: teamUser?.name,
									picture: teamUser?.picture,
									onClick: () => handleImpersonateUser(teamUser),
								}
							)) : [
								{
									name: _`teamHasNoUsers`,
								},
							],
						},
						{
							icon: LogoutIcon,
							name: _`signout`,
							onClick: () => dispatch(openModal({ key: 'signout' })),
						},
					]}
				/>
			</UserSettings>
		</User>
	);
};

export default UserMenu;
