import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Subject } from 'rxjs';

import { syncGame } from 'app/src/epics/game';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';

const StyledModal = styled(Modal)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Buttons = styled.div`
	width: 100%;
	margin-top: auto;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const SyncToConsoleModal = props => {
	const { data: { gameId } } = props;

	const dispatch = useDispatch();
	const [exit$] = useState(new Subject());
	const [confirmCountdown, setConfirmCountdown] = useState(3);
	const syncGameStatus = useSelectApiStatus(syncGame.id);

	useEffect(() => {
		if (confirmCountdown <= 0) return;

		const timeout = setTimeout(() => {
			setConfirmCountdown(confirmCountdown - 1);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [confirmCountdown]);

	const handleSubmit = e => {
		e.preventDefault();

		dispatch(syncGame.fetch({ gameId }));

		exit$.next();
	};

	return (
		<StyledModal exit$={exit$}>
			<h2>{_`warning`}!</h2>
			<p>{_`noRevShareWarning`}</p>
			<Buttons>
				<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
				<Button disabled={confirmCountdown || syncGameStatus.pending} submit onClick={handleSubmit}>{_`yesImSure`}</Button>
			</Buttons>
		</StyledModal>
	);
};

registerModal('sync-to-console', SyncToConsoleModal);
