import { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { addPermalinkKeyValue } from 'app/src/actions/client';

const dateEncoder = date => date.getTime() / 1000;
const dateDecoder = timestamp => new Date(timestamp * 1000);

const objectEncoder = object => JSON.stringify(object);
const objectDecoder = string => JSON.parse(string);

const numEncoder = int => int;
const numDecoder = string => Number(string);

const momentEncoder = momentObj => momentObj.format('YYYY-MM-DDTHH:mm:ss');
const momentDecoder = string => moment(string);

const useURLState = (id, defaultValue = null, encoder = null, decoder = null) => {
	const dispatch = useDispatch();

	if (!encoder || !decoder) {
		if (typeof defaultValue === 'number') {
			encoder = numEncoder;
			decoder = numDecoder;
		} else if (defaultValue instanceof Date) {
			encoder = dateEncoder;
			decoder = dateDecoder;
		} else if (moment.isMoment(defaultValue)) {
			encoder = momentEncoder;
			decoder = momentDecoder;
		} else {
			encoder = objectEncoder;
			decoder = objectDecoder;
		}
	}

	const [value, valueSetter] = useState(() => {
		// Try to get value from URL
		try {
			const urlSearchParams = new URLSearchParams(window.location.hash.replace('#', ''));
			const params = Object.fromEntries(urlSearchParams.entries());

			if (params[id]) {
				if (decoder) return decoder(params[id]);
				return params[id];
			}
		} catch (e) { /* eslint-disable-line no-empty */ }

		// Otherwise return default
		// But first store it
		const valueToStore = defaultValue instanceof Function ? defaultValue(value) : defaultValue;
		const encodedValue = encoder ? encoder(valueToStore) : valueToStore;
		dispatch(addPermalinkKeyValue({ key: id, value: encodedValue }));

		return defaultValue;
	});

	const valueSetterAdapter = data => {
		const valueToStore = data instanceof Function ? data(value) : data;
		const encodedValue = encoder ? encoder(valueToStore) : valueToStore;
		dispatch(addPermalinkKeyValue({ key: id, value: encodedValue }));

		return valueSetter(valueToStore);
	};

	return [value, valueSetterAdapter];
};

export default useURLState;
