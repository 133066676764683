import React from 'react';
import moment from 'moment';

import GridContainer from 'app/src/components/ui/GridContainer';
import Container from 'app/src/components/ui/Container';
import Chart from 'app/src/components/ui/charts/Chart';
import Card from 'app/src/components/ui/Card';

const ChartTestPage = () => {
	const data = [];

	const groups = [
		{
			id: 1335,
			title: 'Bad game',
			indexModifier: 10,
			randomModifier: 5,
		},
		{
			id: 1336,
			title: 'Great game',
			indexModifier: 500,
			randomModifier: 500,
		},
		{
			id: 1337,
			title: 'Game with zero values',
			indexModifier: 200,
			randomModifier: 200,
		},
		{
			id: 1338,
			title: 'Game with missing data',
			indexModifier: 300,
			randomModifier: 300,
		},
		{
			id: 1339,
			title: 'Recently released game',
			indexModifier: 400,
			randomModifier: 400,
		},
	];

	groups.forEach(group => {
		for (let i = group.id === 1339 ? 15 : 0; i < 25; i++) {
			if (group.id === 1338 && i === 10) i = 20;

			let value = (group.indexModifier * i) + (Math.random() * group.randomModifier);
			if (group.id === 1337 && ((i > 5 && i < 8) || (i > 14 && i < 22))) {
				value = 0;
			}

			data.push({
				date: new Date(2023, 0, i),
				value,
				game: group,
			});
		}
	});

	const ungroupedData = [];
	for (let i = 0; i < 25; i++) {
		const value = (250 * i) + (Math.random() * 500);

		ungroupedData.push({
			date: new Date(2019, 0, i),
			value,
		});
	}

	const startDate = moment().subtract(30, 'days');
	const endDate = moment();

	const zeroValuesGrouped = [];
	for (let i = 0; i < 25; i++) {
		const value = 0;

		zeroValuesGrouped.push({
			date: moment().format('YYYY-MM-DD'),
			device_category: 'desktop',
			value,
		});

		zeroValuesGrouped.push({
			date: moment().format('YYYY-MM-DD'),
			device_category: 'mobile/tablet',
			value,
		});
	}

	const zeroValuesUngrouped = [];
	for (let i = 0; i < 25; i++) {
		const value = 0;

		zeroValuesUngrouped.push({
			date: moment().format('YYYY-MM-DD'),
			value,
		});
	}

	const doneStatus = { done: true, pending: false, error: null };
	const pendingStatus = { done: false, pending: true, error: null };
	const errorStatus = { done: true, pending: false, error: { message: 'Server error: Handsome programmer not found' } };

	return (
		<Container>
			<h2>Chart States</h2>
			<GridContainer cols={3}>
				<Card title="Empty Data Chart">
					<Chart
						type="bar"
						apiStatus={doneStatus}
						data={[]}
						legend
						stacked
						getGroupedBy={d => d.game.id}
						getGroupName={d => d.game.title}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Pending Data Chart">
					<Chart
						type="bar"
						apiStatus={pendingStatus}
						data={[]}
						legend
						stacked
						getGroupedBy={d => d.game.id}
						getGroupName={d => d.game.title}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Error Chart">
					<Chart
						type="bar"
						apiStatus={errorStatus}
						data={[]}
						legend
						stacked
						getGroupedBy={d => d.game.id}
						getGroupName={d => d.game.title}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
			</GridContainer>
			<h2>Chart Types</h2>
			<GridContainer cols={2}>
				<Card title="Stacked Bar Chart">
					<Chart
						type="bar"
						apiStatus={doneStatus}
						data={data}
						legend
						stacked
						getGroupedBy={d => d.game.id}
						getGroupName={d => d.game.title}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Bar Chart">
					<Chart
						type="bar"
						apiStatus={doneStatus}
						data={data}
						legend
						getGroupedBy={d => d.game.id}
						getGroupName={d => d.game.title}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Stacked Line Chart">
					<Chart
						type="line"
						apiStatus={doneStatus}
						showXAxis={false}
						data={data}
						stacked
						legend
						getGroupedBy={d => d.game.id}
						getGroupName={d => d.game.title}
						margins={{
							bottom: 10,
						}}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Line Chart">
					<Chart
						type="line"
						apiStatus={doneStatus}
						showXAxis={false}
						data={data}
						legend
						getGroupedBy={d => d.game.id}
						getGroupName={d => d.game.title}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Ungrouped Stacked Bar Chart">
					<Chart
						type="bar"
						apiStatus={doneStatus}
						data={ungroupedData}
						stacked
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Ungrouped Bar Chart">
					<Chart
						type="bar"
						apiStatus={doneStatus}
						data={ungroupedData}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Ungrouped Stacked Line Chart">
					<Chart
						type="line"
						stacked
						apiStatus={doneStatus}
						data={ungroupedData}
						showXAxis={false}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
				<Card title="Ungrouped Line Chart">
					<Chart
						type="line"
						apiStatus={doneStatus}
						data={ungroupedData}
						showXAxis={false}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Value',
							field: 'value',
						}}
					/>
				</Card>
			</GridContainer>
			<h2>Chart Data Variations</h2>
			<GridContainer cols={2}>
				<Card title="Default range, zero values, grouped">
					<Chart
						type="bar"
						legend
						stacked
						apiStatus={doneStatus}
						data={zeroValuesGrouped}
						getGroupedBy={d => d.device_category}
						getGroupName={d => d.device_category}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Daily Active Users',
							field: 'users',
						}}
					/>
				</Card>
				<Card title="Default range, zero values, ungrouped">
					<Chart
						type="bar"
						apiStatus={doneStatus}
						data={zeroValuesUngrouped}
						getGroupName={() => 'Daily Active Users'}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Daily Active Users',
							field: 'users',
						}}
					/>
				</Card>
				<Card title="Default range, almost no data, grouped">
					<Chart
						type="bar"
						legend
						stacked
						apiStatus={doneStatus}
						data={[{
							date: moment().format('YYYY-MM-DD'),
							users: 5,
						}]}
						getGroupedBy={d => d.device_category}
						getGroupName={d => d.device_category}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Daily Active Users',
							field: 'users',
						}}
					/>
				</Card>
				<Card title="Default range, almost no data, ungrouped">
					<Chart
						type="bar"
						apiStatus={doneStatus}
						data={[{
							date: moment().format('YYYY-MM-DD'),
							users: 5,
						}]}
						getGroupName={() => 'Daily Active Users'}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: 'Date',
							field: 'date',
						}}
						yAxis={{
							displayName: 'Daily Active Users',
							field: 'users',
						}}
					/>
				</Card>
			</GridContainer>
		</Container>
	);
};

export default ChartTestPage;
