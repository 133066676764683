import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, ignoreElements, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import DownloadIcon from 'shared/designTokens/icons/ui/small/DownloadIcon';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import { fetchPdf, downloadPdf, patchInvoice } from 'app/src/epics/invoice';
import IconButton from 'app/src/components/ui/IconButton';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const RightButtons = styled.div`
	display: grid;
	grid-auto-flow: column;
	gap: 8px;
`;

const PreviewContainer = styled.div`
	width: 100%;
	height: 60vh;
	min-height: 500px;
	border: 1px solid ${props => props.theme.grey7};
	border-radius: 4px;
	overflow: hidden;
`;

const Preview = styled.object`
	width: 100%;
	height: 100%;
`;

const AdminPreviewInvoiceModal = props => {
	const { data: { invoice, viewOnly = false } } = props;

	const dispatch = useDispatch();
	const [exit$] = useState(new Subject());
	const [objectURL, setObjectURL] = useState();

	const downloadPdfStatus = useSelectApiStatus(downloadPdf.id);
	const patchInvoiceStatus = useSelectApiStatus(patchInvoice.id);

	const handleApprove = () => {
		dispatch(patchInvoice.fetch({
			invoiceId: invoice.id,
			attributes: {
				status: 'approved',
			},
		}, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	const handleHandleManually = () => {
		dispatch(patchInvoice.fetch({
			invoiceId: invoice.id,
			attributes: {
				status: 'manual',
			},
		}, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	const handleDownload = () => {
		dispatch(downloadPdf.fetch({ invoiceId: invoice.id }));
	};

	useEffect(() => {
		dispatch(fetchPdf.fetch({ invoiceId: invoice.id }, ({ success$ }) => (
			success$.pipe(
				tap(({ payload: { result: { response } } }) => {
					setObjectURL(response.data.attributes.data);
				}),
				ignoreElements(),
			)
		)));
	}, [invoice]);

	const disableStatusChange = invoice.status !== 'open' || patchInvoiceStatus.pending;

	return (
		<Modal wide exit$={exit$}>
			<h2>{_`previewInvoice`}</h2>
			<PreviewContainer>
				{objectURL && (
					<Preview data={`data:application/pdf;base64,${objectURL}`} width="100%" height="500" title="preview-invoice" />
				)}
			</PreviewContainer>
			{!viewOnly && (
				<Buttons>
					<IconButton
						icon={DownloadIcon}
						onClick={handleDownload}
						disabled={downloadPdfStatus.pending}
						title={downloadPdfStatus.pending ? _`downloading` : _`downloadPdf`}
					/>
					<RightButtons>
						<Button
							secondary
							disabled={disableStatusChange}
							onClick={handleHandleManually}
						>
							{_`handleManually`}
						</Button>
						<Button
							disabled={disableStatusChange}
							onClick={handleApprove}
						>
							{_`approveInvoice`}
						</Button>
					</RightButtons>
				</Buttons>
			)}
		</Modal>
	);
};

registerModal('admin-preview-invoice', AdminPreviewInvoiceModal);
