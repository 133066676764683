import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { getGameErrorBrowserNames, getGameErrorDeviceCategories, getGameErrorVersions, getTablesLastUpdatedAt } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { getGameErrorsPerMinute } from 'app/src/epics/game';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectPermissions } from 'app/src/selectors/user';
import { UnauthorizedError } from 'app/src/errors';
import capitalizeFirstLetter from 'app/src/utils/capitalizeFirstLetter';
import checkPermissions from 'app/src/utils/checkPermissions';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import getTimeSince from 'app/src/utils/getTimeSince';
import useURLState from 'app/src/hooks/useURLState';

import GameErrorsChartModule from 'app/src/components/modules/GameErrorsChartModule';
import ErrorDetailsModule from 'app/src/components/modules/ErrorDetailsModule';
import GridContainer from 'app/src/components/ui/GridContainer';
import InfoPoint from 'app/src/components/ui/InfoPoint';
import Card from 'app/src/components/ui/Card';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';
import _ from 'shared/copy';

const GameErrorsSubPage = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const permissions = useSelectPermissions();

	if (game?.annotations?.license_fee === 'yes' && !checkPermissions(permissions, [['can_see_all_clickhouse_data']])) {
		throw new UnauthorizedError();
	}

	const [activeFilters, setActiveFilters] = useURLState('filters', {});

	const activeTeam = useSelectActiveTeam();

	const { rows: gameErrorVersions } = useSelectDataByEpic(getGameErrorVersions.id);
	const { rows: gameErrorDeviceCategories } = useSelectDataByEpic(getGameErrorDeviceCategories.id);
	const { rows: gameErrorBrowserNames } = useSelectDataByEpic(getGameErrorBrowserNames.id);

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_game_errors_per_user');

	// Error details are floored to the hour, so we request 25 hours to get 24 hours of data.
	const detailsFrom = moment().subtract(25, 'hours');
	const detailsTo = moment();

	useEffect(() => {
		dispatch(getTablesLastUpdatedAt.fetch());
	}, []);

	const versionFilterValues = useMemo(() => {
		if (!game || !gameErrorVersions) return [];

		return gameErrorVersions.map(row => {
			const version = game.versions.find(v => v.id === row.p4d_game_version_id);
			if (!version) return null;

			return {
				value: row.p4d_game_version_id,
				desc: getVersionLabel(version),
				count: row.users,
			};
		}).filter(v => v);
	}, [game, gameErrorVersions]);

	const deviceCategoryFilterValues = useMemo(() => {
		if (!game || !gameErrorDeviceCategories) return [];

		return gameErrorDeviceCategories.map(row => ({
			value: row.device_category,
			desc: capitalizeFirstLetter(row.device_category),
			count: row.users,
		}));
	}, [game, gameErrorDeviceCategories]);

	const browserNameFilterValues = useMemo(() => {
		if (!game || !gameErrorBrowserNames) return [];

		return gameErrorBrowserNames.map(row => ({
			value: row.browser_name,
			desc: capitalizeFirstLetter(row.browser_name),
			count: row.users,
		}));
	}, [game, gameErrorBrowserNames]);

	const filters = useMemo(() => ([
		{
			title: _`version`,
			field: 'p4d_game_version_id',
			type: 'multiselect',
			values: versionFilterValues,
		},
		{
			title: _`device`,
			field: 'device_category',
			type: 'multiselect',
			values: deviceCategoryFilterValues,
		},
		{
			title: _`browser`,
			field: 'browser_name',
			type: 'multiselect',
			values: browserNameFilterValues,
		},
	]), [versionFilterValues, deviceCategoryFilterValues, browserNameFilterValues]);

	useEffect(() => {
		if (!game || !game.versions) return;

		dispatch(getGameErrorVersions.fetch({ gameId: game.id, teamId: game.team.id, from: detailsFrom, to: detailsTo, filters: activeFilters }));
		dispatch(getGameErrorBrowserNames.fetch({ gameId: game.id, teamId: game.team.id, from: detailsFrom, to: detailsTo, filters: activeFilters }));
		dispatch(getGameErrorDeviceCategories.fetch({ gameId: game.id, teamId: game.team.id, from: detailsFrom, to: detailsTo, filters: activeFilters }));

		const gameId = game.id;
		// Only get the first 10 versions and sort them by created_at
		const sortedVersions = game.versions.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
		const slicedVersions = sortedVersions.map(v => v.id).slice(0, 20);

		dispatch(getGameErrorsPerMinute.fetch({ gameId, versionIds: slicedVersions }));
	}, [game]);

	return (
		<>
			<Helmet key={`GameErrorsSubPage-${game.id}-${activeTeam.code}`}>
				<title>Errors - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={2}>
				<GameErrorsChartModule game={game} />
			</GridContainer>
			<GridContainer cols={1}>
				<Card
					title={_`errorDetails`}
					description={_`errorDetailsDesc`}
					noPadding
					buttons={(
						<InfoPoint
							disabled={!lastUpdatedAt}
							icon={GraphIcon}
						>
							{_`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`}
						</InfoPoint>
					)}
				>
					<ErrorDetailsModule
						game={game}
						team={activeTeam}
						to={detailsTo}
						from={detailsFrom}
						filters={filters}
						activeFilters={activeFilters}
						setActiveFilters={setActiveFilters}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default GameErrorsSubPage;
