import React, { useEffect, useState, useRef, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fromEvent, tap } from 'rxjs';
import styled from 'styled-components';
import moment from 'moment';

import { toggleDateRangeInput } from 'app/src/actions/client';
import useToday from 'app/src/hooks/useToday';

import CalendarIcon from 'shared/designTokens/icons/ui/small/CalendarIcon';
import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';
import DatePicker from 'app/src/components/input/DatePicker';
import Card from 'app/src/components/ui/Card';
import Button from 'app/src/components/ui/Button';

import { motionSpeed02, motionOut, isMobile, dayMonthYearFormat, _small, _medium } from 'shared/vars';
import _ from 'shared/copy';

const PickerContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: auto;

	${_small} {
		position: absolute;
		bottom: 0;
		right: 0;
		height: auto;
		width: auto;
		overflow: visible;
		transform: translateY(100%);
	}

	${props => props.hangingSide === 'right' && `
	left: auto;
	right: 0;
	`}
`;

const PickerCard = styled(Card)`
	width: auto;
	height: 100%;
	border-radius: 0;

	${_small} {
		height: auto;
		border-radius: 8px;
	}

	${!isMobile && `
		width 488px;
	`}
`;

const PickerBottom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid ${props => props.theme.grey7};
	padding: 12px 24px;
	background: ${props => props.theme.pureWhite};

	${isMobile && `
		flex-direction: column;
		align-items: center;
	`}
`;

const PickerTop = styled.div`
	display: flex;
	background: ${props => props.theme.pureWhite};

	${isMobile && `
		flex-direction: column-reverse;
	`}
`;

const PickerRight = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
	padding-top: 24px;
	border-left: 1px solid ${props => props.theme.grey7};

	${_medium} {
		display: flex;
		flex-direction: column;
		text-wrap: nowrap;
	}

	${props => isMobile && `
		align-items: center;
		padding-top: 12px;
		padding-bottom: 12px;
		border-bottom: 1px solid ${props.theme.grey7};
		border-left: 0;
	`}
`;

const Preset = styled.div`
	font-size: 14px;
	height: 36px;
	display: flex;
	align-items: center;
	padding: 0 24px;
	cursor: pointer;

	&:hover {
		color: ${props => props.theme.pokiBlue};
	}

	${props => props.active && `
		font-weight: bold;
		color: ${props.theme.pokiBlue};
	`}
`;

const PickerStatusText = styled.div`
	${isMobile && `
		margin-bottom: 12px;
	`}
`;

const StyledInput = styled.input`
	display: block;
	padding: 0 16px;
	line-height: 48px;
	height: 48px;
	width: 100%;
	background: ${props => props.theme.grey7};
	color: ${props => props.theme.denimBlue};
	font-size: 16px;
	border: 1px solid ${props => props.theme.grey5};
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	border-radius: 8px;
	cursor: pointer;
	padding-right: 40px;

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	${props => props.small && `
		line-height: 36px;
		height: 36px;
	`}

	${props => props.light && `
		background: ${props.theme.pureWhite};
	`}

	${props => props.focus && `
		outline: none;
		border-color: ${props.theme.pokiBlue};
		box-shadow: inset 0 0 0 2px ${props.theme.pokiBlue};
		background: ${props.theme.grey9};
	`}

	${props => (props.disabled ? `
		color: ${props.theme.grey3};
		cursor: not-allowed;
		border: none;
		padding: 0 17px;
		` : `
		&:hover {
			background: ${props.theme.grey9};
		}
	`)}

	${props => props.error && `
		border-color: ${props.theme.rose1};
		background: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}

	${props => props.valid && `
		border-color: ${props.theme.green1};
		background: ${props.theme.green9};
	`}
`;

const InputContainer = styled.div`
	position: relative;
	margin-bottom: 8px;

	> svg {
		position: absolute;
		top: 12px;
		right: 12px;
		pointer-events: none;

		${props => props.small && `
			top: 6px;
			right: 6px;
		`}

		[fill] {
			fill: ${props => props.theme.grey5};
		}
	}
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
	z-index: 2;

	${props => (props.$active ? `
	z-index: 3;
	` : '')}

	@media (max-width: 550px) {
		width: 100%;
	}
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${StyledInput} {
		margin-top: 4px;
	}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;

	${props => props.shortDesc && `
		min-height: 18px;
		text-align: right;
		margin-top: 4px;
	`}
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Prefix = styled.div`
	position: absolute;
	pointer-events: none;
	left: 0;
	top: 0;
	padding: 0 0 0 16px;
	font-weight: bold;
	height: 48px;
	line-height: 48px;
	font-size: 16px;

	${props => props.small && `
		line-height: 36px;
		height: 36px;
	`}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const StyledButton = styled(Button)`
	width: 100%;

	${_small} {
		width: auto;
	}
`;

const ExitButton = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 8px 16px 0;

	[fill] {
		fill: ${props => props.theme.static.grey5};
	}

	${_small} {
		display: none;
	}
`;

const DateRangeInput = props => {
	const { className, name, allTimeStartDate, small, prefix, light, errors = [], description, descriptionHTML, disabled, label, value = '', valueSetter, format = dayMonthYearFormat, onChange, maxLength, shortDesc, valid, required, minDate, maxDate } = props;

	const dispatch = useDispatch();
	const containerRef = useRef();

	const [active, setActive] = useState(false);
	const [tmpRange, setTmpRange] = useState(value.map(val => moment(val, format)));
	const [hangingSide, setHangingSide] = useState('left');
	const today = useToday();

	const extendedPresets = useMemo(() => {
		const yesterday = today.clone().subtract(1, 'days').endOf('day');
		const result = [
			{
				title: 'Last 7 days',
				start: today.clone().subtract(7, 'days').startOf('day'),
				end: yesterday,
			},
			{
				title: 'Last 14 days',
				start: today.clone().subtract(14, 'days').startOf('day'),
				end: yesterday,
			},
			{
				title: 'Last 30 days',
				start: today.clone().subtract(30, 'days').startOf('day'),
				end: yesterday,
			},
			{
				title: 'Last 90 days',
				start: today.clone().subtract(90, 'days').startOf('day'),
				end: yesterday,
			},
			{
				title: 'Last 365 days',
				start: today.clone().subtract(365, 'days').startOf('day'),
				end: yesterday,
			},
			{
				title: 'Last month',
				start: today.clone().subtract(1, 'months').startOf('month'),
				end: today.clone().subtract(1, 'months').endOf('month'),
			},
			{
				title: 'Current month',
				start: today.clone().startOf('month'),
				end: today.clone().subtract(1, 'days'),
			},
		];

		if (!allTimeStartDate) return result;

		result.push({
			title: 'All-time',
			start: allTimeStartDate,
			end: yesterday,
		});

		return result;
	}, [allTimeStartDate, today]);

	const valueAsText = useMemo(() => {
		if (!value) return null;
		if (!Array.isArray(value)) return null;

		return `${value[0]} - ${value[1]}`;
	}, [value]);

	const handleToggleDateRangeInput = ({ toggle }) => {
		setActive(toggle);
		dispatch(toggleDateRangeInput({ toggle }));
	};

	const handleChange = (startDate, endDate) => {
		const range = [startDate, endDate];

		setTmpRange([startDate, endDate]); // Ensure tmpRange is up to date

		if (valueSetter) valueSetter(range);
		if (onChange) onChange(range);
	};

	const onSet = range => {
		setTmpRange(range);
	};

	const handleFocus = () => {
		handleToggleDateRangeInput({ toggle: true });
	};

	const handleApply = () => {
		if (tmpRange[0] && tmpRange[1]) {
			handleChange(tmpRange[0], tmpRange[1]);
			handleToggleDateRangeInput({ toggle: false });
		}
	};

	const dayDifference = tmpRange[0] && tmpRange[1] ? tmpRange[1].diff(tmpRange[0], 'days') + 1 : 0;

	// Exit on clicking outside
	useEffect(() => {
		if (!active || !containerRef.current) return;

		const click$ = fromEvent(document.body, 'click', true).pipe(
			tap(e => {
				if (!containerRef.current.contains(e.target)) {
					handleToggleDateRangeInput({ toggle: false });
				}
			}),
		);

		const subscription = click$.subscribe();

		return () => {
			if (subscription) subscription.unsubscribe();
		};
	}, [active, containerRef]);

	const prefixRef = useRef();
	const inputRef = useRef();

	useEffect(() => {
		if (active) {
			inputRef.current.blur();
		}
	}, [active]);

	const input = (
		<InputContainer small={small}>
			<CalendarIcon />
			<StyledInput
				ref={inputRef}
				id={name}
				type="text"
				name={name}
				disabled={disabled}
				readOnly
				placeholder={`${format.toLowerCase()} - ${format.toLowerCase()}`}
				value={valueAsText}
				onChange={handleChange}
				maxLength={maxLength}
				onFocus={handleFocus}
				error={errors.length > 0}
				valid={valid}
				focus={active}
				small={small}
				light={light}
			/>
			{prefix && <Prefix ref={prefixRef} small={small}>{prefix}</Prefix>}
		</InputContainer>
	);

	useLayoutEffect(() => {
		if (!prefixRef.current || !inputRef.current) return;

		inputRef.current.style.paddingLeft = `${prefixRef.current.getBoundingClientRect().width + 4}px`;
	}, [prefixRef, inputRef]);

	useLayoutEffect(() => {
		if (!containerRef.current) return;

		setHangingSide(containerRef.current.getBoundingClientRect().left < window.innerWidth / 2 ? 'left' : 'right');
	}, [containerRef]);

	return (
		<StyledFieldset className={className} ref={containerRef} $active={active}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0} valid={valid}>{label}{input}</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					descriptionHTML ? (
						<Description shortDesc={shortDesc} dangerouslySetInnerHTML={{ __html: description }} />
					) : (
						<Description shortDesc={shortDesc}>{description}</Description>
					)
				)
			)}
			{active && (
				<PickerContainer hangingSide={hangingSide}>
					<PickerCard elevated noPadding>
						<ExitButton onClick={() => handleToggleDateRangeInput({ toggle: false })}>
							<CloseIcon />
						</ExitButton>
						<PickerTop>
							<DatePicker
								isRange
								moment={tmpRange}
								minMoment={minDate && moment.utc(minDate)}
								maxMoment={maxDate && moment.utc(maxDate)}
								onSet={onSet}
							/>
							<PickerRight>
								{extendedPresets.map(({ title, start, end }) => (
									<Preset
										key={`days-${title}`}
										onClick={() => {
											handleChange(start, end);
											handleToggleDateRangeInput({ toggle: false });
										}}
										active={start.isSame(tmpRange[0], 'day') && end.isSame(tmpRange[1], 'day')}
									>
										{title}
									</Preset>
								))}
							</PickerRight>
						</PickerTop>
						<PickerBottom>
							<PickerStatusText>
								{tmpRange[0] && tmpRange[1] ? (
									<>
										<strong>{_`xDays${{ amount: dayDifference }}`}:</strong> {`${tmpRange[0].format(format)} - ${tmpRange[1].format(format)}`}
									</>
								) : ''}
							</PickerStatusText>
							<StyledButton
								disabled={!tmpRange[1]}
								onClick={handleApply}
							>
								{_`apply`}
							</StyledButton>
						</PickerBottom>
					</PickerCard>
				</PickerContainer>
			)}
		</StyledFieldset>
	);
};

export default DateRangeInput;
