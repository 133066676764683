import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useSelectIsAuthenticated } from 'app/src/selectors/session';
import { useSelectUser } from 'app/src/selectors/user';

const SurveyPage = props => {
	const { match } = props;
	const { params } = match;
	const { version } = params;
	const user = useSelectUser();

	if (useSelectIsAuthenticated()) {
		if (!user) return null;
		return (
			<Navigate to={{ pathname: `/${user.team.code}` }} />
		);
	}

	let survey = 1;
	if (version === 'two') {
		survey = 2;
	} else if (version === 'three') {
		survey = 3;
	}

	const src = `https://pokifordev${survey}.launchaco.com/`;

	return (
		<>
			<Helmet key="SurveyPage">
				<title>Poki for Developers - Reach the world on web</title>
			</Helmet>
			<iframe src={src} width="100%" height="100%" frameBorder="0" title="Survey Page" />
		</>
	);
};

export default SurveyPage;
