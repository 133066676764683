import React from 'react';

const GoogleIcon = props => {
	const { className } = props;

	return (
		<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.9999 12.2067C20.9999 11.5949 20.9493 10.9798 20.8413 10.3779H12.1804V13.8437H17.1401C16.9343 14.9614 16.273 15.9502 15.3047 16.5786V18.8273H18.2636C20.0012 17.2598 20.9999 14.9449 20.9999 12.2067Z" fill="#4285F4" />
			<path d="M12.1805 21C14.657 21 16.7455 20.203 18.2671 18.8273L15.3082 16.5785C14.4849 17.1275 13.4221 17.4384 12.1839 17.4384C9.78841 17.4384 7.75729 15.8543 7.02852 13.7246H3.9751V16.0428C5.53386 19.0819 8.70874 21 12.1805 21Z" fill="#34A853" />
			<path d="M7.02509 13.7246C6.64047 12.6068 6.64047 11.3964 7.02509 10.2787V7.96045H3.97505C2.67271 10.5035 2.67271 13.4997 3.97505 16.0428L7.02509 13.7246Z" fill="#FBBC04" />
			<path d="M12.1805 6.56176C13.4896 6.54192 14.7548 7.02474 15.7029 7.91101L18.3245 5.34147C16.6645 3.81364 14.4613 2.97366 12.1805 3.00012C8.70874 3.00012 5.53386 4.91818 3.9751 7.96062L7.02514 10.2788C7.75054 8.14581 9.78503 6.56176 12.1805 6.56176Z" fill="#EA4335" />
		</svg>
	);
};

export default GoogleIcon;
