import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, ignoreElements, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import moment from 'moment';

import { listGamesByTeamId } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import Modal from 'app/src/components/ui/Modal';
import { validate, isRequired } from 'app/src/utils/validate';
import { registerModal } from 'app/src/modals';
import { useSelectTeamByCode, useSelectTeamCurrency } from 'app/src/selectors/team';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import GridContainer from 'app/src/components/ui/GridContainer';
import { createMinimumGuaranteeForTeamId } from 'app/src/epics/additionalPayments';
import SelectInput from 'app/src/components/input/SelectInput';
import WarningMessage from 'app/src/components/ui/WarningMessage';
import _ from 'shared/copy';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const InternalNotesTextArea = styled(TextAreaInput)`
	max-width: 100%;
`;

const FieldSplit = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const AddMinimumGuaranteeModal = props => {
	const { data: { teamCode, hasActiveUpfrontPayments } } = props;

	const team = useSelectTeamByCode(teamCode);

	const dispatch = useDispatch();
	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const createMinimumGuaranteeForTeamIdStatus = useSelectApiStatus(createMinimumGuaranteeForTeamId.id);
	const [exit$] = useState(new Subject());

	const [monthlyAmount, setMonthlyAmount] = useState('');
	const [startDate, setStartDate] = useState();
	const [internalNotes, setInternalNotes] = useState();
	const [months, setMonths] = useState();

	const teamCurrency = useSelectTeamCurrency(team);

	const setAndFormatMonthlyAmount = value => {
		if (!value) {
			setMonthlyAmount('');
			return;
		}

		const numValue = Number(value);
		if (!Number.isNaN(numValue)) {
			setMonthlyAmount(numValue);
		}
	};

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: team.id }));
	}, []);

	const handleSubmit = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'minimumGuarantee_team',
				value: team,
			},
			{
				field: 'minimumGuarantee_monthlyAmount',
				value: monthlyAmount,
			},
			{
				field: 'minimumGuarantee_startDate',
				value: startDate,
			},
			{
				field: 'minimumGuarantee_internalNotes',
				value: internalNotes,
			},
			{
				field: 'minimumGuarantee_months',
				value: months,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(createMinimumGuaranteeForTeamId.fetch({ teamId: team.id, monthlyAmount, startDate: moment.utc(startDate).toDate(), internalNotes, months: Number(months) }, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	if (!team) return null;

	return (
		<Modal wide exit$={exit$}>
			<>
				<h2>{_`addMinimumGuarantee`}</h2>
				<p>{_`addMinimumGuaranteeDescription`}</p>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<GridContainer cols={2}>
						<TextInput
							label={_`team`}
							disabled
							name="minimumGuarantee_team"
							value={team.name}
						/>
					</GridContainer>
					<GridContainer cols={2}>
						<FieldSplit>
							<TextInput
								label={_`monthlyAmount`}
								autoFocus
								disabled={createMinimumGuaranteeForTeamId.pending}
								name="minimumGuarantee_monthlyAmount"
								prefix={teamCurrency === 'usd' ? '$' : '€'}
								placeholder={500}
								description={_`minimumGuaranteeMonthlyAmountDescription`}
								errors={errors.minimumGuarantee_monthlyAmount}
								value={monthlyAmount}
								valueSetter={setAndFormatMonthlyAmount}
								required={isRequired('minimumGuarantee_monthlyAmount')}
							/>
							<SelectInput
								label={_`startMonth`}
								name="minimumGuarantee_startDate"
								disabled={createMinimumGuaranteeForTeamId.pending}
								errors={errors.minimumGuarantee_startDate}
								value={startDate}
								valueSetter={setStartDate}
								required={isRequired('minimumGuarantee_startDate')}
								placeholder={_`selectADate`}
								values={Array(24).fill(0).map((__, val) => {
									const date = moment.utc().add(val, 'months').startOf('month');

									return {
										value: date.format(),
										desc: date.format('MMM YYYY'),
									};
								})}
							/>
						</FieldSplit>
						<FieldSplit>
							<SelectInput
								label={_`minimumGuaranteeMonths`}
								name="minimumGuarantee_months"
								disabled={createMinimumGuaranteeForTeamId.pending}
								errors={errors.minimumGuarantee_months}
								value={months}
								valueSetter={setMonths}
								placeholder={_`minimumGuaranteeMonthsPlaceholder`}
								values={Array(24).fill(0).map((__, val) => ({
									value: val + 1,
									desc: _`xMonths${{ amount: val + 1 }}`,
								}))}
								required={isRequired('minimumGuarantee_months')}
							/>
						</FieldSplit>
					</GridContainer>
					<InternalNotesTextArea
						label={_`internalNotes`}
						name="minimumGuarantee_internalNotes"
						disabled={createMinimumGuaranteeForTeamId.pending}
						description={_`minimumGuaranteeNotesDescription`}
						errors={errors.minimumGuarantee_internalNotes}
						value={internalNotes}
						valueSetter={setInternalNotes}
						required={isRequired('minimumGuarantee_internalNotes')}
					/>
					{hasActiveUpfrontPayments && (
						<WarningMessage title={_`warning`} message={_`warningMiniumGuaranteeMessage`} />
					)}
					<Buttons>
						<Button disabled={createMinimumGuaranteeForTeamId.pending} secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={createMinimumGuaranteeForTeamIdStatus.pending}>{createMinimumGuaranteeForTeamIdStatus.pending ? _`creating` : _`create`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('add-minimum-guarantee', AddMinimumGuaranteeModal);
