import moment from 'moment';

export const getCurrentDate = () => moment().tz('Europe/Amsterdam');

export const getCurrentDay = () => getCurrentDate().date();
export const getCurrentMonth = () => getCurrentDate().format('MMMM');
export const getCurrentYear = () => getCurrentDate().format('YYYY');

export const getIsFirstWeekOfMonth = () => {
	const currentDayOfMonth = getCurrentDay();
	return currentDayOfMonth >= 1 && currentDayOfMonth <= 7;
};
