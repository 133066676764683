import React from 'react';
import styled from 'styled-components';

import { motionSpeed02, motionOut } from 'shared/vars';

const Details = styled.div`
	position: absolute;
	top: -12px;
	width: 260px;
	padding: 16px;
	font-size: 14px;
	line-height: 18px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.boxShadowSmall};
	background: ${props => props.theme.pureWhite};
	opacity: 0;
	transform: translate(0, -96%);
	transition: all ${motionSpeed02} ${motionOut};
	pointer-events: none;
	text-align: ${props => props.$textAlignment};

	&:after {
		content: '';
		border: 8px solid transparent;
		border-top-color: ${props => props.theme.pureWhite};
		position: absolute;
		bottom: -16px;
	}

	&:before {
		${/* hitbox */''}
		content: '';
		background: transparent;
		position: absolute;
		bottom: -20px;
		width: 60px;
		height: 60px;
		transform: rotate(45deg);
	}

	${props => (props.$direction === 'left' ? `
	right: calc(50% - 32px);

	&:before {
		right: 10px;
	}

	&:after {
		right: 24px;
	}
	` : `
	left: calc(50% - 32px);

	&:before {
		left: 10px;
	}

	&:after {
		left: 24px;
	}
	`)}
`;

const Container = styled.span`
	width: max-content;
	position: relative;

	&:hover {
		${Details} {
			opacity: 1;
			transform: translate(0, -100%);
			pointer-events: all;
		}
	}
`;

const TooltipText = styled.span`
	color: ${props => props.theme.grey3};
	border-bottom: 2px dotted ${props => props.theme.grey5};
	padding-bottom: 4px;
`;

const Title = styled.div`
	font-weight: bold;
	color: ${props => props.theme.grey1};
	margin-bottom: 4px;
`;

const Body = styled.div`
	color: ${props => props.theme.grey3};
`;

const IconContainer = styled.div`
	position: absolute;
	top: 16px;
	right: 16px;

	svg {
		cursor: pointer;

		[fill] {
			fill: ${props => props.theme.grey3};
		}
	}

	svg:hover {
		[fill] {
			fill: ${props => props.theme.pokiBlue};
		}
	}
`;

const Tooltip = props => {
	const { className, text, title, body, children, icon, direction = 'left', textAlignment = 'left' } = props;

	return (
		<Container>
			<Details className={className} $direction={direction} $textAlignment={textAlignment}>
				{title && <Title>{title}</Title>}
				<Body>{body}</Body>
				{icon && <IconContainer>{icon}</IconContainer>}
			</Details>
			{text && <TooltipText>{text}</TooltipText>}
			{children}
		</Container>
	);
};

export default Tooltip;
