import moment from 'moment';
import { InvalidParameterError } from 'app/src/errors';

const getInvoiceDueDate = invoice => {
	let days = 0;

	if (invoice.payment_term === 'net60') {
		days = 60;
	} else if (invoice.payment_term === 'net30') {
		days = 30;
	} else if (invoice.payment_term === 'net5') {
		days = 5;
	} else {
		throw new InvalidParameterError(`Unknown payment term ${invoice.payment_term}`);
	}

	return moment(invoice.created_at * 1000).add(days, 'days');
};

export default getInvoiceDueDate;
