import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import DiscordIcon from 'shared/components/svg/DiscordIcon';
import GoogleIcon from 'shared/components/svg/GoogleIcon';
import GithubIcon from 'shared/components/svg/GithubIcon';
import PokiLogo from 'shared/components/svg/PokiLogo';
import Button from 'app/src/components/ui/Button';
import FullPageSymbolBackground from 'shared/components/FullPageSymbolBackground';

import { useSelectIsAuthenticated } from 'app/src/selectors/session';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectImpersonator, useSelectUser } from 'app/src/selectors/user';
import ThemeProvider from 'app/src/utils/ThemeProvider';
import getApiUrl from 'app/src/utils/getApiUrl';

import { domain, isMobile } from 'shared/vars';
import _ from 'shared/copy';

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${props => props.theme.static.pureWhite};
	padding: 50px;

	${!isMobile ? `
	position: absolute;
	left: 50%;
	top: 50%;
	width: 450px;
	transform: translate(-50%, -50%);
	border-radius: 24px;
	box-shadow: ${props => props.theme.static.boxShadowSmall};
	justify-content: space-evenly;
	min
	` : `
	min-height: 100vh;
	`}
`;

const StyledPokiLogo = styled(PokiLogo)`
	width: 300px;
	max-width: 100%;

	${!isMobile ? `
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin: 20px;
	transform: translateX(-50%);
	` :	`
	margin-bottom: 20px;
	`}
`;

const ButtonIcon = styled.div`
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
	margin-left: -12px;
	position: relative;
	top: 1px;
`;

const SSOButton = styled(Button)`
	&:hover ${ButtonIcon} {
		[fill] {
			fill: ${props => props.theme.static.pureWhite};
		}
	}
`;

const SSOButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	> ${SSOButton}:not(:last-of-type) {
		margin-bottom: 12px;
	}

	${isMobile && `
	margin-top: 48px;
	`}
`;

const Error = styled.div`
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	margin: 0 0 8px;
	color: ${props => props.theme.static.rose1};
`;

const StyledTitle = styled.h1`
	font: 700 36px/40px Torus;
	color: ${props => props.theme.denimBlue};
	margin: 0 0 8px;
	text-align: center;

	${isMobile && `
	font-size: 34px;
	`}
`;

const StyledSubTitle = styled.h2`
	font: 400 21px/32px Proxima Nova;
	color: ${props => props.theme.denimBlue};
	text-align: center;
`;

const StyledDisclaimer = styled.div`
	width: 440px;
	max-width: 100%;
	text-align: center;

	${!isMobile ? `
	position: absolute;
	top: 100%;
	left: 50%;
	margin: 20px 0;
	transform: translateX(-50%);
	` :	`
	margin-top: 32px;
	`}
`;

const SignUpPage = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const user = useSelectUser();
	const impersonator = useSelectImpersonator();
	const isAuthenticated = useSelectIsAuthenticated();

	const signupError = location?.state?.errorCode;
	const type = location?.pathname === '/signup-game-jam' ? 'game-jam' : 'self-service';

	const getSSOURL = provider => getApiUrl('auth', `signin/${provider}?return=${encodeURIComponent(`${domain}/auth/${provider}`)}&service=pokifordevs&signup=${type}`);

	useEffect(() => {
		if (signupError) {
			dispatch(startScreenShake());
		}
	}, [!!signupError]);

	if (user && isAuthenticated) {
		if (user?.team && !user.team.verified && !impersonator && user.team.read_privacy_addendum === 'not-accepted') {
			return (
				<Navigate to={{ pathname: '/complete-signup' }} />
			);
		}

		return (
			<Navigate to={{ pathname: `/${user.team.code}` }} />
		);
	}

	const errorMessages = {
		'forbidden-profile': _`forbiddenProfileError`,
		'discord-email-not-verified': _`discordNotVerified`,
		'github-missing-email': _`githubMissingEmail`,
	};

	return (
		<ThemeProvider forceLightMode>
			{!isMobile && <FullPageSymbolBackground symbol="coins" />}
			<Helmet key="SignUpPage">
				<title>Create account - Poki for Developers</title>
			</Helmet>
			<InnerContainer>
				<StyledPokiLogo dark />
				<StyledTitle>{_`createAccount`}</StyledTitle>
				<StyledSubTitle>{_`signupPreferredPlatform`}</StyledSubTitle>
				{signupError && (
					<Error dangerouslySetInnerHTML={{ __html: errorMessages[signupError] || _`unexpectedServerErrorShort` }} />
				)}

				<SSOButtons>
					<SSOButton
						marketingButton
						tertiary
						to={getSSOURL('google')}
					>
						<ButtonIcon>
							<GoogleIcon />
						</ButtonIcon>
						{_`signUpWithX${{ provider: 'Google' }}`}
					</SSOButton>
					<SSOButton
						marketingButton
						tertiary
						to={getSSOURL('discord')}
					>
						<ButtonIcon>
							<DiscordIcon />
						</ButtonIcon>
						{_`signUpWithX${{ provider: 'Discord' }}`}
					</SSOButton>
					<SSOButton
						marketingButton
						tertiary
						to={getSSOURL('github')}
					>
						<ButtonIcon>
							<GithubIcon />
						</ButtonIcon>
						{_`signUpWithX${{ provider: 'Github' }}`}
					</SSOButton>
				</SSOButtons>
				<StyledDisclaimer dangerouslySetInnerHTML={{ __html: _`seePrivacyStatement` }} />
			</InnerContainer>

		</ThemeProvider>
	);
};

export default SignUpPage;
