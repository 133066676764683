import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, tap, ignoreElements } from 'rxjs';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';

import Button from 'app/src/components/ui/Button';
import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import { setTeamRevenueSharesToZero, getTeamRevenueShares } from 'app/src/epics/team';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import TextInput from 'app/src/components/input/TextInput';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const ConfirmRemoveRevShareModal = props => {
	const { data } = props;
	const { team } = data;

	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());
	const [confirm, setConfirm] = useState('');

	const setTeamRevenueSharesToZeroStatus = useSelectApiStatus(setTeamRevenueSharesToZero.id);
	const getTeamRevenueSharesStatus = useSelectApiStatus(getTeamRevenueShares.id);

	const handleSubmit = e => {
		e.preventDefault();

		if (confirm !== 'remove') return;

		dispatch(setTeamRevenueSharesToZero.fetch({ teamId: team.id }, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	const apiStatus = combinedApiStatus(setTeamRevenueSharesToZeroStatus, getTeamRevenueSharesStatus);

	return (
		<Modal
			exit$={exit$}
			canExit={source => (source === EXIT_SOURCES.SUBJECT || !apiStatus.pending)}
		>
			<h2>{_`confirmRemoveRevenueShares`}</h2>
			<p dangerouslySetInnerHTML={{ __html: _`confirmRemoveRevenueSharesDesc${{ teamName: team.name }}` }} />
			<form onSubmit={handleSubmit}>
				<TextInput
					disabled={apiStatus.pending}
					value={confirm}
					valueSetter={setConfirm}
				/>
				<Buttons>
					<Button disabled={apiStatus.pending} secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
					<Button submit negative disabled={confirm !== 'remove' || apiStatus.pending}>{apiStatus.pending ? _`removing` : _`confirmRemoval`}</Button>
				</Buttons>
			</form>
		</Modal>
	);
};

registerModal('confirm-remove-revenue-share', ConfirmRemoveRevShareModal);
