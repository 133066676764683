import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject } from 'rxjs';
import { tap, ignoreElements } from 'rxjs/operators';
import { useSelectApiStatus } from '@poki/rx-api';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import { patchInvoice } from 'app/src/epics/invoice';
import { isRequired, validate } from 'app/src/utils/validate';
import { startScreenShake } from 'app/src/actions/effects';

const Form = styled.form`
	width: 100%;
	margin-top: 16px;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const EditInvoiceInternalNotesModal = props => {
	const { data: { invoice } } = props;

	const dispatch = useDispatch();
	const formRef = useRef();
	const [internalNotes, setInternalNotes] = useState(invoice.internal_notes || '');
	const patchInvoiceStatus = useSelectApiStatus(patchInvoice.id);
	const [exit$] = useState(new Subject());
	const [errors, setErrors] = useState({});

	const handleSubmit = e => {
		e.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'invoice_internal_notes',
				value: internalNotes,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(patchInvoice.fetch({ invoiceId: invoice.id, attributes: { internal_notes: internalNotes } }, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					exit$.next();
				}),
				ignoreElements(),
			)
		)));
	};

	return (
		<Modal exit$={exit$}>
			<>
				<h2>{_`editInvoiceInternalNotes`}</h2>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<TextAreaInput
						label={_`internalNotes`}
						name="invoice_internal_notes"
						errors={errors.invoice_internal_notes}
						value={internalNotes}
						valueSetter={setInternalNotes}
						required={isRequired('invoice_internal_notes')}
					/>
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={patchInvoiceStatus.pending}>{patchInvoiceStatus.pending ? _`saving` : _`save`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('edit-invoice-internal-notes', EditInvoiceInternalNotesModal);
