import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';

import Card from 'app/src/components/ui/Card';
import GridContainer from 'app/src/components/ui/GridContainer';
import { listAllOneOffPayments } from 'app/src/epics/additionalPayments';
import { useSelectAllOneOffPayments } from 'app/src/selectors/additionalPayments';
import _ from 'shared/copy';
import OneOffPaymentsTable from 'app/src/components/tables/OneOffPaymentsTable';

const perPage = 20;

const AdminPaymentsOneOffPaymentsSubPage = () => {
	const dispatch = useDispatch();

	const { data: oneOffPayments, meta: { total: totalOneOffPayments } = {} } = useSelectAllOneOffPayments();
	const listAllOneOffPaymentsStatus = useSelectApiStatus(listAllOneOffPayments.id);

	const [sort, setSort] = useState({ field: 'send_date', direction: -1 });
	const [page, setPage] = useState(1);

	useEffect(() => {
		dispatch(listAllOneOffPayments.fetch({ sortField: sort.field, sortDirection: sort.direction, page, perPage }));
	}, [sort, page]);

	return (
		<>
			<Helmet key="AdminPaymentsOneOffPaymentsSubPage">
				<title>One-Off Payments - Admin - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title={_`oneOffPayments`}
					noPadding
				>
					<OneOffPaymentsTable
						oneOffPayments={oneOffPayments}
						isLoading={!listAllOneOffPaymentsStatus.done}
						adminView
						setSort={setSort}
						sortField={sort.field}
						sortDirection={sort.direction}
						setPagination={setPage}
						page={page}
						perPage={perPage}
						totalItems={totalOneOffPayments}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default AdminPaymentsOneOffPaymentsSubPage;
