import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import UsersIcon from 'shared/designTokens/icons/ui/small/UsersIcon';

import ListMenu from 'app/src/components/ui/ListMenu';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { listGamesByTeamId } from 'app/src/epics/game';
import { useSelectVisitedTeams } from 'app/src/selectors/session';
import Flag from 'app/src/components/ui/Flag';

const StyledFlag = styled(Flag)`
	font-size: 17px;
	left: 3px;
	top: 2px;
`;

const VisitedTeamsMenu = props => {
	const { collapsed } = props;

	const activeTeam = useSelectActiveTeam();
	const teams = useSelectVisitedTeams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
	}, [activeTeam.id]);

	const buttons = useMemo(() => (
		(teams || []).map(({ name, code, primaryCountry }) => ({
			icon: primaryCountry ? () => <StyledFlag countryCode={primaryCountry} /> : UsersIcon,
			name,
			to: `/${code}`,
		}))
	), [teams]);

	if (collapsed) return null;

	return (
		<ListMenu
			title="Recent Teams"
			buttons={buttons}
			collapsed={collapsed}
			light
		/>
	);
};

export default VisitedTeamsMenu;
