export default function formatSlug(input, trim = false) {
	if (!input) return '';

	// 1. Lowercase
	let output = input.toLowerCase();

	// 2. Normalize (remove accents etc.)
	output = output.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

	// 3. Trim
	if (trim) {
		output = output.replace(/(^[^a-z0-9]+)|([^a-z0-9]+$)/g, '');
	}

	// 4. Replace remaining special characters
	return output.replace(/([^a-z0-9]+)/g, '-');
}
