import React from 'react';

const Block2 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M32.64 29.1992L83.24 42.0792L102.2 53.2792L120 81.7592L98.32 110.959L55.24 105.919L25.96 88.9992L20 70.5992L32.64 29.1992Z" fill="#DCA7F2" />
			<path d="M73.1597 69.5592L81.5197 57.3992L83.2797 42.0792L32.6797 29.1992L34.3997 54.2792L51.2797 66.7192L73.1597 69.5592Z" fill="#E0AEF5" />
			<path d="M83.2395 42.1211L102.2 53.3211L120 81.8011L91.7995 73.8011L81.5195 57.4411L83.2395 42.1211Z" fill="#EBCCFF" />
			<path d="M34.399 54.2812L25.959 89.0013L51.319 66.7213L34.399 54.2812Z" fill="#C977E8" />
			<path d="M32.64 29.1992L20 70.5992L25.96 89.0392L34.4 54.2792L32.64 29.1992Z" fill="#D490F2" />
			<path d="M25.959 88.9988L55.239 105.919L73.599 79.5987L73.159 69.5587L51.319 66.7188L25.959 88.9988Z" fill="#DA9EF3" />
			<path d="M55.2402 105.921L98.3202 111.001L120 81.8008L91.8002 73.8008L73.6002 79.6008L55.2402 105.921Z" fill="#D490F2" />
			<path d="M73.1602 69.5584L81.5202 57.3984L73.6002 79.5984L73.1602 69.5584Z" fill="#EBCCFF" />
			<path d="M81.4796 57.3984L73.5996 79.5984L91.7596 73.7984L81.4796 57.3984Z" fill="#C977E8" />
		</svg>
	);
};

export default Block2;
