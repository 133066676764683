import React from 'react';

import { useSelectTheme } from 'app/src/selectors/session';

const ApprovedIcon = props => {
	const { className, active, disabled } = props;
	const theme = useSelectTheme();

	return (
		<svg className={className} width="36" height="36" xmlns="http://www.w3.org/2000/svg" fill={active ? theme.green1 : 'none'} fillRule="evenodd">
			<g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
				<path d="M18 6c6.627 0 12 5.373 12 12s-5.373 12-12 12S6 24.627 6 18 11.373 6 18 6h0z" stroke={active ? theme.green1 : disabled ? theme.grey7 : theme.grey3} />
				<path d="M12.667 17.792L17.009 22l6.324-6.667" stroke={active ? theme.pureWhite : disabled ? theme.grey7 : theme.grey3} />
			</g>
		</svg>
	);
};

export default ApprovedIcon;
