import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { ignoreElements, tap } from 'rxjs';
import styled from 'styled-components';
import moment from 'moment';

import { openModal } from 'app/src/actions/client';
import { listArchivedVersionsByGameId, unarchiveVersion } from 'app/src/epics/game';
import { useSelectArchivedVersionListByGameId } from 'app/src/selectors/game';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';

import WarningMessage from 'app/src/components/ui/WarningMessage';
import VersionNotes from 'app/src/components/ui/VersionNotes';
import VersionLabel from 'app/src/components/ui/VersionLabel';
import Tooltip from 'app/src/components/ui/Tooltip';
import Button from 'app/src/components/ui/Button';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import { dayMonthYearTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const Actions = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
`;

const PER_PAGE = 10;

const ArchivedVersionsTable = props => {
	const { game, actionCounter } = props;

	const dispatch = useDispatch();

	const permissions = useSelectPermissions();

	const [busy, setBusy] = useState(false);
	const [sort, setSort] = useState({ field: 'created_at', direction: -1 });
	const [expanded, setExpanded] = useState(false);
	const [page, setPage] = useState(1);

	const listArchivedVersionsByGameIdStatus = useSelectApiStatus(listArchivedVersionsByGameId.id);
	const { data: versions, meta: { total: totalVersions } = {} } = useSelectArchivedVersionListByGameId(game.id);

	const canEditVersions = checkPermissions(permissions, [['can_edit_all_versions', 'can_edit_owned_versions']]);

	const onToggleArchivedExpand = toggle => setExpanded(toggle);

	useEffect(() => {
		if (expanded) {
			dispatch(listArchivedVersionsByGameId.fetch({ gameId: game.id, sortField: sort.field, sortDirection: sort.direction, page, perPage: PER_PAGE }));
		}
	}, [expanded, sort, page, actionCounter]);

	const doUnarchive = item => {
		setBusy(true);

		dispatch(unarchiveVersion.fetch({ gameId: game.id, versionId: item.id }, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					setBusy(false);
				}),
				ignoreElements(),
			)
		)));
	};

	return (
		<Card
			title={_`archivedVersions`}
			noPadding
			expandable
			onToggleExpand={onToggleArchivedExpand}
		>
			<Table
				isLoading={!listArchivedVersionsByGameIdStatus.done || busy}
				items={versions}
				itemAlign="top"
				setSort={setSort}
				sortField={sort.field}
				sortDirection={sort.direction}
				setPagination={setPage}
				page={page}
				perPage={PER_PAGE}
				totalItems={totalVersions}
				columns={[
					{
						title: _`uploadedAt`,
						width: 'max-content',
						content: ({ item }) => {
							const text = moment(item.created_at * 1000).format(dayMonthYearTimeFormat);
							const flags = item.flags ? item.flags.split(',') : [];

							if (flags.includes('google-analytics-replaced')) {
								return (
									<Tooltip
										text={text}
										body={<WarningMessage message={_`googleAnalyticsRemovedOnVersion`} />}
										direction="right"
									/>
								);
							}

							return text;
						},
						sortField: 'created_at',
					},
					{
						title: '',
						mobileTitle: _`versionNotes`,
						width: 'min-content',
						content: ({ item }) => <VersionNotes item={item} canEditVersions={canEditVersions} />,
						mobileContent: ({ item }) => (
							<Button
								secondary
								onClick={() => dispatch(openModal({ key: 'edit-version-notes', data: { version: item } }))}
							>
								{_`editVersionNotes`}
							</Button>
						),
					},
					{
						title: _`name`,
						content: ({ item }) => (
							item.state !== 'error' ? (
								<VersionLabel
									readOnly
									key={`label-${item.id}`}
									item={item}
								/>
							) : (
								<div>{_`invalidVersion`}</div>
							)
						),
					},
					{
						title: _`actions`,
						width: 'min-content',
						content: ({ item }) => (
							<Actions>
								<Button
									secondary
									onClick={() => doUnarchive(item)}
								>
									{_`unarchive`}
								</Button>
							</Actions>
						),
					},
				]}
			/>
		</Card>
	);
};

export default ArchivedVersionsTable;
