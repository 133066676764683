import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

export const selectSearchResults = createSelector(
	state => (state.search.searchResults ? state.search.searchResults.data : null),

	results => {
		if (!results) return null;
		return results.filter((_, idx) => idx < 10);
	},
);

export const useSelectSearchResults = () => useSelector(selectSearchResults);
