import React, { useEffect, useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { ignoreElements, switchMap, of } from 'rxjs';
import { useDispatch } from 'react-redux';

import Button from 'app/src/components/ui/Button';
import Card from 'app/src/components/ui/Card';
import SelectInput from 'app/src/components/input/SelectInput';
import { getGameById, getGameEngineAnnotations, patchGameAnnotations } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectGameAnnotations } from 'app/src/selectors/game';
import { validate } from 'app/src/utils/validate';
import TextInput from 'app/src/components/input/TextInput';
import formatSlug from 'app/src/utils/formatSlug';
import _ from 'shared/copy';

const GameAnnotationsModule = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const [errors, setErrors] = useState({});
	const [gameEngine, setGameEngine] = useState();
	const [exclusivity, setExclusivity] = useState();
	const [licenseFee, setLicenseFee] = useState();
	const [newGameEngine, setNewGameEngine] = useState();

	useEffect(() => {
		dispatch(getGameEngineAnnotations.fetch());

		setGameEngine(game?.annotations?.engine);
		setLicenseFee(game?.annotations?.license_fee);
		setExclusivity(game?.annotations?.exclusivity);
	}, [game]);

	const finalEngine = newGameEngine || gameEngine;

	const getGameEngineAnnotationsStatus = useSelectApiStatus(getGameEngineAnnotations.id);
	const patchGameStatus = useSelectApiStatus(patchGameAnnotations.id);

	const { engine: engines = [] } = useSelectGameAnnotations();

	const setNewGameEngineAndFormat = e => {
		setNewGameEngine(formatSlug(e, false));
	};

	const setNewGameEngineAndTrim = () => setNewGameEngine(formatSlug(newGameEngine, true));

	const handleSave = async evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'gameAnnotations_engine',
				value: finalEngine,
			},
			{
				field: 'gameAnnotations_exclusivity',
				value: exclusivity,
			},
			{
				field: 'gameAnnotations_licenseFee',
				value: licenseFee,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.values(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		const data = {
			annotations: {
				engine: finalEngine,
				exclusivity,
				license_fee: licenseFee,
			},
		};

		dispatch(patchGameAnnotations.fetch({ gameId: game.id, data }, ({ success$ }) => success$.pipe(
			switchMap(() => (
				of(
					getGameById.fetch(game.id),
					setGameEngine(finalEngine),
				)
			)),
			ignoreElements(),
		)));
	};

	return (
		<Card
			title={_`gameAnnotations`}
		>
			<form onSubmit={handleSave}>
				<SelectInput
					label={_`exclusivity`}
					name="gameAnnotations_exclusivity"
					disabled={getGameEngineAnnotationsStatus.pending || patchGameStatus.pending}
					values={[
						{
							value: null,
							desc: '',
						},
						{
							value: 'exclusive',
							desc: _`exclusive`,
						},
						{
							value: 'non-exclusive',
							desc: _`nonExclusive`,
						},
					]}
					errors={errors.gameAnnotations_exclusivity}
					value={exclusivity}
					valueSetter={setExclusivity}
				/>
				<SelectInput
					label={_`licenseFee`}
					name="gameAnnotations_licenseFee"
					disabled={getGameEngineAnnotationsStatus.pending || patchGameStatus.pending}
					values={[
						{
							value: 'no',
							desc: _`no`,
						},
						{
							value: 'yes',
							desc: _`yes`,
						},
					]}
					errors={errors.gameAnnotations_licenseFee}
					value={licenseFee}
					valueSetter={setLicenseFee}
				/>
				<SelectInput
					label={_`gameEngine`}
					name="gameAnnotations_engine"
					disabled={getGameEngineAnnotationsStatus.pending || patchGameStatus.pending}
					values={[
						...engines.map(t => ({
							value: t,
							desc: t,
						})),
						{
							value: '',
							desc: '',
						},
						{
							value: 'new-game-engine',
							desc: _`addNewGameEngine`,
						},
					]}
					errors={errors.gameAnnotations_engine}
					value={gameEngine}
					valueSetter={setGameEngine}
					placeholder={_`selectAGameEngine`}
				/>
				{gameEngine === 'new-game-engine' && (
					<TextInput
						label={_`newGameEngine`}
						name="gameAnnotations_newEngine"
						disabled={patchGameStatus.pending}
						errors={errors.gameAnnotations_newEngine}
						value={newGameEngine}
						valueSetter={setNewGameEngineAndFormat}
						onBlur={setNewGameEngineAndTrim}
					/>
				)}
				<Button
					disabled={finalEngine === '' || patchGameStatus.pending}
					submit
					primary
				>
					{_`save`}
				</Button>
			</form>
		</Card>
	);
};

export default GameAnnotationsModule;
