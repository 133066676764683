import React, { useEffect, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';

import { useSelectIsDarkMode, useSelectTheme } from 'app/src/selectors/session';

const QRCode = props => {
	const { width = 300, height = 300, data, image = '' } = props;

	const qrContainerRef = useRef();
	const theme = useSelectTheme();
	const isDarkMode = useSelectIsDarkMode();

	useEffect(() => {
		if (!qrContainerRef.current) return;

		const qrCode = new QRCodeStyling({
			width,
			height,
			data,
			image,
			imageOptions: {
				margin: 5,
			},
			dotsOptions: {
				color: !isDarkMode ? theme.static.pokiBlue : theme.static.pureWhite,
				type: 'extra-rounded',
			},
			cornersSquareOptions: {
				color: theme.denimBlue,
				type: 'dot',
			},
			backgroundOptions: {
				color: theme.pureWhite,
			},
		});

		qrContainerRef.current.innerHTML = ''; // Shitty hack to empty container div, but the qr code library only appends doesnt replace
		qrCode.append(qrContainerRef.current);
	}, [qrContainerRef.current]);

	return <div ref={qrContainerRef} />; // Don't add anything to this container, it is managed by the above side effect / the qr library
};

export default QRCode;
