const measureTotalVideoMonetization = data => (
	data.map(({ ...row }) => {
		const totalVideoImpressions = row.preroll_video_impressions + row.midroll_video_impressions + row.rewarded_video_impressions;
		const totalVideoEarnings = row.preroll_video_developer_earnings + row.midroll_video_developer_earnings + row.rewarded_video_developer_earnings;

		row.video_ads_per_dau = totalVideoImpressions / row.daily_active_users;
		row.video_earnings = totalVideoEarnings;

		return row;
	})
);

export default measureTotalVideoMonetization;
