import { useSelector } from 'react-redux';

export const selectReviewsByGameId = (state, gameId) => (state.review.reviewsByGameId[gameId] ? state.review.reviewsByGameId[gameId] : {});
export const selectPendingReviews = state => state.review.pendingReviews;
export const selectCompletedReviews = state => state.review.completedReviews;
export const selectReviewById = (state, reviewId) => (state.review.reviewsById[reviewId] ? state.review.reviewsById[reviewId] : {});
export const selectAllReviewsToday = state => state.review.allReviewsToday;
export const selectAllReportsToday = state => state.review.allReportsToday;
export const selectAllReportsYesterday = state => state.review.allReportsYesterday;
export const selectAllPendingReviews = state => state.review.allPendingReviews;

export const useSelectReviewsByGameId = gameId => useSelector(state => selectReviewsByGameId(state, gameId));
export const useSelectPendingReviews = () => useSelector(state => selectPendingReviews(state));
export const useSelectCompletedReviews = () => useSelector(state => selectCompletedReviews(state));
export const useSelectReviewById = reviewId => useSelector(state => selectReviewById(state, reviewId));
export const useSelectAllReviewsToday = () => useSelector(state => selectAllReviewsToday(state));
export const useSelectAllReportsToday = () => useSelector(state => selectAllReportsToday(state));
export const useSelectAllReportsYesterday = () => useSelector(state => selectAllReportsYesterday(state));
export const useSelectAllPendingReviews = () => useSelector(state => selectAllPendingReviews(state));
