import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
	height: 40px;
`;

const PageDivider = () => <Divider />;

export default PageDivider;
