import React from 'react';
import styled from 'styled-components';

import CopyToClipboard from 'app/src/components/CopyToClipboard';

import { _medium } from 'shared/vars';

const CodeSnippetContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	padding: 16px;

	background: ${props => props.theme.static.grey1};
	color: ${props => props.theme.grey7};
	font-family: Courier, Courier new, mono;
	border-radius: 8px;
`;

const CodeSnippetContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;

	color: #fff;
	overflow-x: scroll;

	${_medium} {
		width: fit-content;
		overflow: auto;
	}
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
	cursor: pointer;
	svg {
		[fill] {
			fill: ${props => props.theme.static.grey7};
		}
		&:hover {
			[fill] {
				fill: ${props => props.theme.grey5};
			}
		}
	}
`;

const CodeSnippet = props => {
	const { content, textToCopy = '', hasCopy = false } = props;

	return (
		<CodeSnippetContainer>
			<CodeSnippetContent>{content}</CodeSnippetContent>
			{hasCopy && <StyledCopyToClipboard text={textToCopy} />}
		</CodeSnippetContainer>
	);
};

export default CodeSnippet;
