import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { map, merge, of, switchMap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import { openToast, uncaughtServerError } from 'app/src/actions/client';
import { patchGameExternalDomain } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';

import Card from 'app/src/components/ui/Card';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';

import _ from 'shared/copy';

const GameExternalDomainModule = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const patchGameExternalDomainStatus = useSelectApiStatus(patchGameExternalDomain.id);

	const [externalDomain, setExternalDomain] = useState();

	useEffect(() => {
		setExternalDomain(game?.external_prefix);
	}, [game]);

	const handleSave = async evt => {
		evt.preventDefault();

		// remove http(s):// and query params
		let domain = externalDomain.replace(/^https?:\/\//, '');
		[domain] = domain.split('?');

		dispatch(patchGameExternalDomain.fetch({ gameId: game.id, data: { external_prefix: domain } }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					map(() => openToast({ body: _`externalDomainSavedSuccessfully` })),
				),
				error$.pipe(
					switchMap(action => (
						of(
							startScreenShake(),
							uncaughtServerError({ action }),
						)
					)),
				),
			)
		)));
	};

	return (
		<Card
			title={_`externalDomain`}
		>
			<TextInput
				label={_`domain`}
				name="external_domain"
				placeholder={_`domainPlaceholder`}
				value={externalDomain}
				valueSetter={setExternalDomain}
				disabled={false}
			/>
			<Button
				disabled={patchGameExternalDomainStatus.pending}
				onClick={handleSave}
				submit
				primary
			>
				{_`save`}
			</Button>
		</Card>
	);
};

export default GameExternalDomainModule;
