import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';

import Card from 'app/src/components/ui/Card';
import GridContainer from 'app/src/components/ui/GridContainer';
import Table from 'app/src/components/ui/Table';
import { createMinimumGuaranteeForTeamId, listAllMinimumGuarantees } from 'app/src/epics/additionalPayments';
import { useSelectAllMinimumGuarantees } from 'app/src/selectors/additionalPayments';
import formatNumber from 'app/src/utils/formatNumber';
import Tooltip from 'app/src/components/ui/Tooltip';
import _ from 'shared/copy';
import moment from 'moment';
import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import Flag from 'app/src/components/ui/Flag';
import useActionCounter from 'app/src/hooks/useActionCounter';

const Status = styled.div`
	font-weight: bold;
	color: ${props => props.theme.grey3};

	${props => props.active && `
		color: ${props.theme.green1};
	`}
`;

const perPage = 16;

const AdminPaymentsMinimumGuaranteesSubPage = () => {
	const dispatch = useDispatch();

	const { data: minimumGuarantees, meta: { total: totalMinimumGuarantees } = {} } = useSelectAllMinimumGuarantees();
	const listAllMinimumGuaranteesStatus = useSelectApiStatus(listAllMinimumGuarantees.id);

	const [sort, setSort] = useState({ field: 'start_date', direction: -1 });
	const [page, setPage] = useState(1);

	const actionCounter = useActionCounter(
		createMinimumGuaranteeForTeamId.success.type,
	);

	useEffect(() => {
		dispatch(listAllMinimumGuarantees.fetch({ sortField: sort.field, sortDirection: sort.direction, page, perPage }));
	}, [actionCounter, sort, page]);

	return (
		<>
			<Helmet key="AdminPaymentsMinimumGuaranteesSubPage">
				<title>Monthly MGs - Admin - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title={_`minimumGuarantees`}
					noPadding
				>
					<Table
						isLoading={!listAllMinimumGuaranteesStatus.done}
						items={minimumGuarantees}
						setSort={setSort}
						sortField={sort.field}
						sortDirection={sort.direction}
						setPagination={setPage}
						page={page}
						perPage={perPage}
						totalItems={totalMinimumGuarantees}
						columns={[
							{
								title: _`startMonth`,
								content: ({ item }) => moment.utc(item.start_date * 1000).format('MMM YYYY'),
								sortField: 'start_date',
							},
							{
								title: _`endMonth`,
								content: ({ item }) => moment.utc(item.end_date * 1000).subtract(1, 'day').format('MMM YYYY'),
								sortField: 'end_date',
							},
							{
								title: _`team`,
								content: ({ item }) => <TeamNameWithFlag team={item.team} overrideLink={`/${item.team.code}/settings/additional-payments`} />,
							},
							{
								title: _`amount`,
								content: ({ item }) => {
									const amountString = `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.monthly_amount / 100), { allowDecimals: true })}`;

									return (
										<>
											<Flag countryCode={item.team.billing?.country} />
											{item.internal_notes ? (
												<Tooltip
													text={amountString}
													title={_`internalNotes`}
													body={item.internal_notes}
												/>
											) : amountString}
										</>
									);
								},
								sortField: 'monthly_amount',
							},
							{
								title: _`status`,
								content: ({ item }) => {
									const now = moment.utc();
									const startMoment = moment.utc(item.start_date * 1000);
									const endMoment = moment.utc(item.end_date * 1000);
									const active = startMoment.isBefore(now) && endMoment.isAfter(now);

									return (
										<Status active={active}>
											{active ? _`active` : startMoment.isAfter(now) ? _`notStarted` : _`expired`}
										</Status>
									);
								},
								sortField: '_status',
							},
						]}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default AdminPaymentsMinimumGuaranteesSubPage;
