import { createAuthorizedApiEpic, formatDateAsString } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import { clickhouseDataCacheSeconds } from 'shared/vars';

/**
 * The following data epics are not initialized at startup, because multiple versions of
 * the same one might run in parallel.
 *
 * In order to avoid collisions, reducers overwriting each other etc, they need to have
 * a unique identifier. In order to have a unique identifier, they need to be created at runtime.
 */

export const createGetGameplaysForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_gameplays_game_id/${id}`,
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
					{
						field: 'date',
					},
					{
						field: 'device_category',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
				group: [
					'date',
					'device_category',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const createGetTotalGameplaysForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_total_gameplays_for_game/${id}`,
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
				group: [],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

// also gets total gameplays, but gets it from the per_referrer_group data source
export const createGetTotalGameplaysPerReferrerForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_total_gameplays_for_game_per_referrer/${id}`,
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays_per_referrer_group',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
				group: [],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const createGetTotalEarningsForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_total_earnings_game_id/${id}`,
	(callApi, { gameId, teamId, from, to, currency, deviceCategories, countries, contexts }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

// also gets total earnings, but gets it from the per_referrer_group data source
export const createGetTotalEarningsPerReferrerForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_total_earnings_game_id/${id}`,
	(callApi, { gameId, teamId, from, to, currency, deviceCategories, countries, contexts }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings_per_referrer_group',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const createGetUsersForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_users_game_id/${id}`,
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_users',
				select: [
					{
						field: 'daily_playing_users',
						aggregate: 'sum',
					},
					{
						field: 'daily_not_playing_users',
						aggregate: 'sum',
					},
					{
						field: 'date',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
				group: [
					'date',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const createGetTotalUsersForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_total_users_for_game/${id}`,
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_users',
				select: [
					{
						field: 'daily_active_users',
						aggregate: 'sum',
					},
					{
						field: 'daily_playing_users',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const createGetEngagementForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_engagement_game_id/${id}`,
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_engagement',
				select: [
					{
						field: 'daily_active_users',
						aggregate: 'sum',
					},
					{
						field: 'video_ad_visible_time',
						aggregate: 'sum',
					},
					{
						field: 'play_time',
						aggregate: 'sum',
					},
					{
						field: 'pre_play_time',
						aggregate: 'sum',
					},
					{
						field: 'date',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
				group: [
					'date',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const createGetTotalTimeSpentForGameId = (id = 0) => createAuthorizedApiEpic(
	`data/get_total_time_spent_for_game/${id}`,
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_engagement',
				select: [
					{
						field: 'daily_active_users',
						aggregate: 'sum',
					},
					{
						field: 'video_ad_visible_time',
						aggregate: 'sum',
					},
					{
						field: 'play_time',
						aggregate: 'sum',
					},
					{
						field: 'pre_play_time',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);
