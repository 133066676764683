import React from 'react';

const GithubIcon = props => {
	const { className } = props;

	return (
		<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12 3C7.0274 3 3 7.08882 3 12.1373C3 16.4314 5.90221 19.9953 9.81779 21C9.77259 20.8857 9.75038 20.7255 9.75038 20.5661V19.0124H8.62519C8.01779 19.0124 7.45481 18.7386 7.2074 18.2355C6.91481 17.6873 6.86962 16.842 6.1274 16.3163C5.90221 16.1335 6.08221 15.9508 6.32962 15.9733C6.80221 16.1102 7.18443 16.4306 7.54443 16.9096C7.90443 17.3894 8.06221 17.5037 8.73702 17.5037C9.05183 17.5037 9.54664 17.4812 9.99702 17.412C10.2444 16.772 10.6718 16.2012 11.1896 15.9275C8.17481 15.562 6.73481 14.0541 6.73481 11.9981C6.73481 11.1069 7.11702 10.2616 7.7474 9.53061C7.54519 8.82218 7.27481 7.36022 7.83779 6.78943C9.18817 6.78943 9.99779 7.68061 10.2 7.90845C10.8748 7.67983 11.6178 7.54296 12.3822 7.54296C13.1696 7.54296 13.8896 7.67983 14.5644 7.90845C14.7666 7.67983 15.577 6.78943 16.9266 6.78943C17.4666 7.33767 17.2192 8.82218 16.994 9.53061C17.6244 10.239 17.9844 11.1069 17.9844 11.9981C17.9844 14.0541 16.5666 15.562 13.5748 15.8816C14.4074 16.3155 14.9926 17.5488 14.9926 18.4626V20.5435C14.9926 20.612 14.9704 20.6804 14.9704 20.7488C18.48 19.5155 21 16.1351 21 12.1373C21 7.08882 16.9726 3 12 3Z" fill="#24292E" />
		</svg>
	);
};

export default GithubIcon;
