import React from 'react';
import styled from 'styled-components';

import { parseEmoji } from 'shared/copy';
import { getCountryByCode } from 'app/src/utils/countries';
import getFlagForCountryCode from 'app/src/utils/getFlagForCountryCode';

const Container = styled.span`
	position: relative;
	top: 0.5px;
	margin-right: 4px;
`;

const Flag = ({ className, countryCode }) => {
	if (!countryCode) return null;

	return (
		<Container
			className={className}
			title={getCountryByCode(countryCode)?.name}
			dangerouslySetInnerHTML={{ __html: parseEmoji(getFlagForCountryCode(countryCode)) }}
		/>
	);
};

export default Flag;
