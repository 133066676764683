import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';
import LabsIcon from 'shared/designTokens/icons/ui/small/LabsIcon';

import { motionSpeed01, motionOut } from 'shared/vars';
import { persistHideMessageBox } from 'app/src/actions/session';
import { useSelectIsPersistedHiddenMessageBox } from 'app/src/selectors/session';
import { InvalidPropError } from 'app/src/errors';

export const MessageBoxIntents = {
	NEUTRAL: 0,
	NEGATIVE: 1,
	LABS: 2,
};

const Title = styled.div`
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 4px;
`;

const Description = styled.div`
	font-size: 14px;
`;

const CloseButton = styled.div`
	float: right;
	cursor: pointer;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	top: -24px;
	right: -24px;

	path {
		transition: fill ${motionSpeed01} ${motionOut};
	}
`;

const Container = styled.div`
	border-radius: 4px;
	box-shadow: ${props => props.theme.boxShadowMedium};
	padding: 24px;
	margin-bottom: 24px;
	position: relative;
	display: flex;
	flex-shrink: 0;

	${props => {
		if (props.intent === MessageBoxIntents.NEGATIVE) {
			return `
				background: ${props.theme.isDarkMode ? props.theme.static.rose7 : props.theme.static.rose9};
				color: ${props.theme.isDarkMode ? props.theme.static.denimBlue : props.theme.static.denimBlue};

				${CloseButton} {
					path {
						fill: ${props.theme.rose5};
					}

					&:hover path {
						fill: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose1};
					}
				}
			`;
		} else if (props.intent === MessageBoxIntents.LABS) {
			return `
				background: ${props.theme.purple8};

				${CloseButton} {
					path {
						fill: ${props.theme.purple5};
					}

					&:hover path {
						fill: ${props.theme.static.purple1};
					}
				}
			`;
		} else if (props.intent === MessageBoxIntents.NEUTRAL) {
			return `
				background: ${props.theme.isDarkMode ? props.theme.static.denimBlue : props.theme.static.yellow9};

				${CloseButton} {
					path {
						fill: ${props.theme.isDarkMode ? props.theme.static.grey5 : props.theme.static.yellow5};
					}

					&:hover path {
						fill: ${props.theme.isDarkMode ? props.theme.static.pureWhite : props.theme.static.yellow1};
					}
				}
			`;
		}

		throw new InvalidPropError('MessageBox.intent must be one of MessageBoxIntents');
	}}
`;

const IconContainer = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${props => props.theme.pureWhite};
	margin-right: 16px;
	border-radius: 100%;
	flex-shrink: none;

	svg [fill] {
		fill: ${props => (props.theme.isDarkMode ? props.theme.static.pureWhite : props.theme.static.pokiBlue)};
	}
`;

const Body = styled.div`
	width: 100%;
`;

const MessageBox = props => {
	const { className, id, title, description, intent = MessageBoxIntents.NEUTRAL, unclosable = false, persistHide = false } = props;

	if (persistHide && !id) throw new Error('You need to provide an ID for persistHide to work on MessageBox');

	const dispatch = useDispatch();
	const [isHidden, setIsHidden] = useState(!unclosable && id && useSelectIsPersistedHiddenMessageBox(id));

	const handleHide = () => {
		if (persistHide) {
			dispatch(persistHideMessageBox({ id }));
		}

		setIsHidden(true);
	};

	let Icon = null;
	if (intent === MessageBoxIntents.LABS) {
		Icon = LabsIcon;
	}

	return !isHidden && (
		<Container className={className} intent={intent}>
			{Icon && <IconContainer><Icon /></IconContainer>}
			<Body>
				{!unclosable && (
					<CloseButton onClick={handleHide}>
						<CloseIcon />
					</CloseButton>
				)}
				<Title>{title}</Title>
				<Description>{description}</Description>
			</Body>
		</Container>
	);
};

export default MessageBox;
