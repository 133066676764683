import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, ignoreElements, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import moment from 'moment';

import { listGamesByTeamId } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import Modal from 'app/src/components/ui/Modal';
import { validate, isRequired } from 'app/src/utils/validate';
import { registerModal } from 'app/src/modals';
import { useSelectTeamByCode, useSelectTeamCurrency } from 'app/src/selectors/team';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import GridContainer from 'app/src/components/ui/GridContainer';
import { createRecurringMonthlyPaymentForTeamId } from 'app/src/epics/additionalPayments';
import SelectInput from 'app/src/components/input/SelectInput';
import _ from 'shared/copy';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const InternalNotesTextArea = styled(TextAreaInput)`
	max-width: 100%;
`;

const FieldSplit = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const AddRecurringMonthlyPaymentModal = props => {
	const { data: { teamCode } } = props;

	const team = useSelectTeamByCode(teamCode);

	const dispatch = useDispatch();
	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const createRecurringMonthlyPaymentForTeamIdStatus = useSelectApiStatus(createRecurringMonthlyPaymentForTeamId.id);
	const [exit$] = useState(new Subject());

	const [type, setType] = useState();
	const [monthlyAmount, setMonthlyAmount] = useState('');
	const [startDate, setStartDate] = useState();
	const [internalNotes, setInternalNotes] = useState();

	const validatorData = { // Data used by validators to determine which fields need validation
		type,
	};

	const teamCurrency = useSelectTeamCurrency(team);

	const setAndFormatMonthlyAmount = value => {
		if (!value) {
			setMonthlyAmount('');
			return;
		}

		const numValue = Number(value);
		if (!Number.isNaN(numValue)) {
			setMonthlyAmount(numValue);
		}
	};

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: team.id }));
	}, []);

	const handleSubmit = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'recurringMonthlyPayment_type',
				value: type,
			},
			{
				field: 'recurringMonthlyPayment_monthlyAmount',
				value: monthlyAmount,
			},
			{
				field: 'recurringMonthlyPayment_startDate',
				value: startDate,
			},
			{
				field: 'recurringMonthlyPayment_internalNotes',
				value: internalNotes,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value, validatorData);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(createRecurringMonthlyPaymentForTeamId.fetch({ type, teamId: team.id, monthlyAmount, startDate: moment.utc(startDate).toDate(), internalNotes }, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	if (!team) return null;

	return (
		<Modal wide exit$={exit$}>
			<>
				<h2>{_`addRecurringMonthlyPayment`}</h2>
				<p>{_`addRecurringMonthlyPaymentDescription`}</p>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<GridContainer cols={2}>
						<TextInput
							label={_`team`}
							disabled
							name="recurringMonthlyPayment_team"
							value={team.name}
						/>
					</GridContainer>
					<GridContainer cols={2}>
						<FieldSplit>
							<SelectInput
								label={_`type`}
								name="recurringMonthlyPayment_type"
								disabled={createRecurringMonthlyPaymentForTeamId.pending}
								errors={errors.recurringMonthlyPayment_type}
								value={type}
								valueSetter={setType}
								required={isRequired('recurringMonthlyPayment_type')}
								placeholder={_`selectAType`}
								values={[
									{ value: 'poki-community-ambassador', desc: _`pokiCommunityAmbassador` },
									{ value: 'industry-sponsorship', desc: _`industrySponsorship` },
								]}
							/>
							<TextInput
								label={_`monthlyAmount`}
								autoFocus
								disabled={createRecurringMonthlyPaymentForTeamId.pending}
								name="recurringMonthlyPayment_monthlyAmount"
								prefix={teamCurrency === 'usd' ? '$' : '€'}
								placeholder={500}
								description={_`recurringMonthlyPaymentMonthlyAmountDescription`}
								errors={errors.recurringMonthlyPayment_monthlyAmount}
								value={monthlyAmount}
								valueSetter={setAndFormatMonthlyAmount}
								required={isRequired('recurringMonthlyPayment_monthlyAmount')}
							/>
							<SelectInput
								label={_`startMonth`}
								name="recurringMonthlyPayment_startDate"
								disabled={createRecurringMonthlyPaymentForTeamId.pending}
								errors={errors.recurringMonthlyPayment_startDate}
								value={startDate}
								valueSetter={setStartDate}
								required={isRequired('recurringMonthlyPayment_startDate')}
								placeholder={_`selectADate`}
								values={Array(24).fill(0).map((__, val) => {
									const date = moment.utc().add(val, 'months').startOf('month');

									return {
										value: date.format(),
										desc: date.format('MMM YYYY'),
									};
								})}
							/>
						</FieldSplit>
					</GridContainer>
					<InternalNotesTextArea
						label={_`internalNotes`}
						name="recurringMonthlyPayment_internalNotes"
						disabled={createRecurringMonthlyPaymentForTeamId.pending}
						description={_`recurringMonthlyPaymentNotesDescription`}
						errors={errors.recurringMonthlyPayment_internalNotes}
						value={internalNotes}
						valueSetter={setInternalNotes}
						required={isRequired('recurringMonthlyPayment_internalNotes')}
					/>
					<Buttons>
						<Button disabled={createRecurringMonthlyPaymentForTeamId.pending} secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={createRecurringMonthlyPaymentForTeamIdStatus.pending}>{createRecurringMonthlyPaymentForTeamIdStatus.pending ? _`creating` : _`create`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('add-recurring-monthly-payment', AddRecurringMonthlyPaymentModal);
