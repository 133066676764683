import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, merge, of, ignoreElements, switchMap, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import moment from 'moment';

import PreviewIcon from 'shared/designTokens/icons/ui/small/PreviewIcon';
import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';
import DownloadIcon from 'shared/designTokens/icons/ui/small/DownloadIcon';

import { downloadZip, gameSetVersionActive } from 'app/src/epics/game';
import { isRequired, validate } from 'app/src/utils/validate';
import { trackInspectorEvent } from 'app/src/utils/tracking';
import { startScreenShake } from 'app/src/actions/effects';
import { sendReviewReport } from 'app/src/epics/review';
import { useSelectUser } from 'app/src/selectors/user';
import { openToast } from 'app/src/actions/client';
import { registerModal } from 'app/src/modals';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import getTimeSince from 'app/src/utils/getTimeSince';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import LabeledValue from 'app/src/components/ui/LabeledValue';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import IconButton from 'app/src/components/ui/IconButton';
import TextInput from 'app/src/components/input/TextInput';
import UserImage from 'app/src/components/ui/UserImage';
import { ToastTypes } from 'app/src/components/ui/Toast';
import ToggleInput from 'app/src/components/input/ToggleInput';
import QRCode from 'app/src/components/ui/QRCode';

import { _small, isMobile, motionOut, motionSpeed02, dayMonthYearTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;

	*:only-child,
	*:nth-child(2n) {
		margin-left: auto;
	}

	*:nth-child(3n) {
		margin-left: 0;
		min-width: 100%;

		${_small} {
			min-width: 0;
		}
	}
`;

const Thumbnail = styled.div`
	position: relative;
	border-radius: 8px;
	background: ${props => (props.thumbnailUrl ? `url("${props.thumbnailUrl}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 64px;
	width: 64px;
	float: right;
	margin: 0 0 24px 24px;
`;

const Actions = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 24px;

	> *:not(first-child) {
		margin-right: 12px;
	}
`;

const Reviewer = styled.div`
	display: flex;
	align-items: center;

	> *:first-child {
		margin-right: 12px;
	}
`;

const QRContainer = styled.div`
	position: absolute;
	right: 0;
	transform: translateX(30px);
	z-index: -1;
	padding: 10px;
	border-radius: 0 8px 8px 0;
	background: ${props => props.theme.pureWhite};
	transition: transform ${motionSpeed02} ${motionOut};
	text-align: center;
	color: ${props => props.theme.grey3};

	&:hover {
		transform: translateX(100%);
	}
`;

const CreateReviewReportModal = props => {
	const { data: { review } } = props;
	const { version } = review;
	const { game } = version;
	const { team } = game;

	const user = useSelectUser();

	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());
	const isAdmin = user.role === 'admin' || user.role === 'read-only-admin';

	const [personalMessage, setPersonalMessage] = useState('');
	const [reportUrl, setReportUrl] = useState('');

	const [errors, setErrors] = useState({});
	const [confirming, setConfirming] = useState(false);
	const [approved, setApproved] = useState();
	const [setActiveOnSend, setSetActiveOnSend] = useState(false);

	const sendReviewReportStatus = useSelectApiStatus(sendReviewReport.id);

	const handleSubmit = _approved => {
		const err = { ...errors };

		const checks = [
			{
				field: 'review_personalMessage',
				value: personalMessage,
			},
			{
				field: 'review_reportUrl',
				value: reportUrl,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		setApproved(_approved);
		setConfirming(true);
	};

	const handleConfirm = evt => {
		evt.preventDefault();

		dispatch(sendReviewReport.fetch({ gameId: game.id, versionId: version.id, reviewId: review.id, data: { status: approved ? 'approved' : 'rejected', personalMessage, reportUrl } }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					tap(() => {
						exit$.next();

						if (setActiveOnSend) {
							dispatch(gameSetVersionActive.fetch({ gameId: game.id, versionId: version.id }));
						}
					}),
					ignoreElements(),
				),
				error$.pipe(
					switchMap(({ payload: { result: { response: { errors: [error] } } } }) => of(
						exit$.next(),
						openToast({ body: error.detail, toastType: ToastTypes.WARNING }),
					)),
				),
			)
		)));
	};

	return (
		<Modal exit$={exit$}>
			{confirming ? (
				<>
					<h2>{approved ? _`confirmApproved` : _`confirmChangesRequested`}</h2>
					<p>
						Once you press send, this report will be sent by email to <strong>{review.created_by.email}</strong> and over Discord to <strong>#{team.code}</strong> (if the channel exists).
					</p>
					{approved && (
						<ToggleInput checked={setActiveOnSend} onChange={setSetActiveOnSend} label="Immediately set version active on send" />
					)}
					<Buttons>
						<Button secondary onClick={() => setConfirming(false)}>{_`back`}</Button>
						<Button primary onClick={handleConfirm}>{sendReviewReportStatus.pending ? _`sending` : _`send`}</Button>
					</Buttons>
				</>
			) : (
				<>
					{game && !isMobile && (
						<QRContainer>
							<QRCode
								width={300}
								height={300}
								data={`https://poki.com/en/preview/${game.id}/${version.id}`}
							/>
							Game Preview QR Code
						</QRContainer>
					)}

					<Thumbnail thumbnailUrl={getGameThumbnailUrl(game.thumbnail_url, 64)} />

					<h2>{_`createReport`}</h2>

					<Actions>
						<IconButton
							title={_`openInInspector`}
							icon={SearchIcon}
							onClick={() => {
								trackInspectorEvent('p4d', 'open-in-inspector', { versionId: version.id, isAdmin, role: user.role });
								window.open(`https://inspector.poki.dev/?game=poki-${version.id}${isAdmin ? '&analytics=false' : ''}`, '_blank');
							}}
						/>
						<IconButton
							to={`https://poki.com/en/preview/${game.id}/${version.id}`}
							openInNewTab
							title={_`preview`}
							icon={PreviewIcon}
						/>
						<IconButton
							title={_`downloadProcessedFiles`}
							icon={DownloadIcon}
							onClick={() => dispatch(downloadZip.fetch({ gameId: game.id, versionId: version.id, type: 'hosted' }))}
						/>
						{moment(version.created_at * 1000).isAfter(moment('2021-02-17')) && (
							<IconButton
								title={_`downloadOriginalZip`}
								icon={DownloadIcon}
								onClick={() => dispatch(downloadZip.fetch({ gameId: game.id, versionId: version.id, type: 'source' }))}
							/>
						)}
					</Actions>

					<Form onSubmit={evt => evt.preventDefault()}>
						<LabeledValue label={_`reviewRequested`}><span title={moment(review.queue_time * 1000).format(dayMonthYearTimeFormat)}>{_`timeAgo${{ timeSince: getTimeSince(review.queue_time * 1000) }}`}</span> by {review.created_by.name || review.created_by.email}</LabeledValue>
						<LabeledValue label={_`version`}>{getVersionLabel(version)}</LabeledValue>
						<LabeledValue label={_`developerNotes`}>{review.developer_notes}</LabeledValue>

						<TextAreaInput
							label={_`personalMessage`}
							name="review_personalMessage"
							errors={errors.review_personalMessage}
							value={personalMessage}
							valueSetter={setPersonalMessage}
							required={isRequired('review_personalMessage')}
							small
						/>

						<TextInput
							label={_`reportUrl`}
							name="review_reportUrl"
							errors={errors.review_reportUrl}
							value={reportUrl}
							valueSetter={setReportUrl}
							required={isRequired('review_reportUrl')}
						/>

						<LabeledValue label={_`reviewedBy`}>
							<Reviewer><UserImage src={user.picture} /> {user.name}</Reviewer>
						</LabeledValue>

						<Buttons>
							<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
							<Button negative onClick={() => handleSubmit(false)}>{_`requestChanges`}</Button>
							<Button positive onClick={() => handleSubmit(true)}>{_`approve`}</Button>
						</Buttons>
					</Form>
				</>
			)}
		</Modal>
	);
};

registerModal('create-review-report', CreateReviewReportModal);
