import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, ignoreElements, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import DownloadIcon from 'shared/designTokens/icons/ui/small/DownloadIcon';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import { fetchPdf, downloadPdf } from 'app/src/epics/invoice';
import { pushEvent } from 'app/src/utils/tracking';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: flex-end;
`;

const PreviewContainer = styled.div`
	width: 100%;
	height: 60vh;
	min-height: 500px;
	border: 1px solid ${props => props.theme.grey7};
	border-radius: 4px;
	overflow: hidden;
`;

const Preview = styled.object`
	width: 100%;
	height: 100%;
`;

const PreviewInvoiceModal = props => {
	const { data: { invoice } } = props;

	const dispatch = useDispatch();
	const [exit$] = useState(new Subject());
	const [objectURL, setObjectURL] = useState();

	const downloadPdfStatus = useSelectApiStatus(downloadPdf.id);

	const handleDownload = () => {
		pushEvent('invoice', 'download');
		dispatch(downloadPdf.fetch({ invoiceId: invoice.id }));
	};

	useEffect(() => {
		dispatch(fetchPdf.fetch({ invoiceId: invoice.id }, ({ success$ }) => (
			success$.pipe(
				tap(({ payload: { result: { response } } }) => {
					setObjectURL(response.data.attributes.data);
				}),
				ignoreElements(),
			)
		)));
	}, [invoice]);

	return (
		<Modal wide exit$={exit$}>
			<h2>{_`invoice`}</h2>
			<PreviewContainer>
				{objectURL && (
					<Preview data={`data:application/pdf;base64,${objectURL}`} width="100%" height="500" title="preview-invoice" />
				)}
			</PreviewContainer>
			<Buttons>
				<Button
					icon={DownloadIcon}
					onClick={handleDownload}
					disabled={downloadPdfStatus.pending}
					title={downloadPdfStatus.pending ? _`downloading` : _`downloadPdf`}
				>
					{downloadPdfStatus.pending ? _`downloading` : _`downloadPdf`}
				</Button>
			</Buttons>
		</Modal>
	);
};

registerModal('preview-invoice', PreviewInvoiceModal);
