import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';

import DiamondIcon from 'shared/designTokens/icons/ui/medium/DiamondIcon';
import UploadIcon from 'shared/designTokens/icons/ui/medium/UploadIcon';
import GameIcon from 'shared/designTokens/icons/ui/medium/GameIcon';
import RocketIcon from 'shared/designTokens/icons/ui/medium/RocketIcon';

import { useSelectGameChecklistLevelStatus } from 'app/src/selectors/session';

import ChecklistStep from 'app/src/components/ui/ChecklistStep';

import { _small, isMobile } from 'shared/vars';

const CardContainer = styled.div`
	width: 100%;
	position: relative;
	background: ${props => props.theme.pureWhite};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	box-shadow: ${props => props.theme.boxShadowSmall};
	min-width: 0;

	& & {
		box-shadow: none;
		border: 1px solid ${props => props.theme.grey7};
	}


	p {
		font-size: 16px;
		line-height: 24px;
		color: ${props => props.theme.grey3};
	}

	p + h3,
	p + & {
		margin-top: 54px;
	}

	h1 + p,
	h2 + p,
	h3 + p,
	h4 + p,
	h5 + p {
		margin-top: -16px;
	}
`;

const Level = styled.h4`
	font-size: 12px;
	line-height: 16px;
	margin: 0;
	color: ${props => props.theme.grey3};
`;

const Title = styled.h3`
	font-size: 21px;
	margin: 0;
	display: flex;

	${props => props.expandPreview && `
	color: ${props.theme.pokiBlue};
	`}
`;

const Checkbox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	padding: 2px;
	border-radius: 36px;
	background: ${props => (props.current ? props.theme.purple6 : props.theme.grey7)};

	${_small}{
		width: 40px;
		height: 40px;
	}
`;

const CheckboxIcon = Icon => styled(Icon)`
  width: 24px;
  height: 24px;
  & > * {
    fill: ${props => ((props.active || props.current) ? props.theme.denimBlue : props.theme.grey5)};
  }
`;

const CheckboxDiamondIcon = CheckboxIcon(DiamondIcon);
const CheckboxUploadIcon = CheckboxIcon(UploadIcon);
const CheckboxGameIcon = CheckboxIcon(GameIcon);
const CheckboxRocketIcon = CheckboxIcon(RocketIcon);

const Head = styled.div`
	display: flex;
	padding: 24px 39px 0 34px;
	align-items: center;
	gap: 39px;
	flex: 1 0 0;
	padding: 16px 16px 0 16px;

	${props => !props.active && `
		cursor: pointer;
		padding-bottom: 16px;
	`}

	${isMobile && `
		gap: 12px;
		padding: 16px;
	`}

	${props => !props.active && !props.current && `

		${Title}, ${Level} {
			color: ${props.theme.grey5};
		}

		${CheckboxDiamondIcon}, ${CheckboxUploadIcon}, ${CheckboxGameIcon}, ${CheckboxRocketIcon}  {
			& > *{
				fill: ${props.theme.grey5};
			}
		}

		${!isMobile && `
			&:hover {
				${Title}, ${Level} {
					color: ${props.theme.denimBlue};
				}
				${CheckboxDiamondIcon}, ${CheckboxUploadIcon}, ${CheckboxGameIcon}, ${CheckboxRocketIcon}  {
					& > *{
						fill: ${props.theme.denimBlue};
					}
				}
			}
		`}
	`}
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
`;

const OuterFrame = styled.div`
	display: flex;
	padding: 24px;
	align-items: flex-start;
	gap: 40px;
	align-self: stretch;

	${isMobile && `
		padding: 0 16px 16px 16px;
	`}

	${props => !props.active && `
		height: 0;
		overflow: hidden;
		padding-top: 0;
	`}
`;

const Frame = styled.div`
	display: flex;
	padding: 0px 0px 0 94px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;

	${isMobile && `
		padding: 0;
		overflow: hidden;
	`}
`;

const Description = styled.div`
	font-size: 16px;
	font-family: Proxima Nova;
	color: ${props => props.theme.grey3};
	align-self: stretch;
`;

const Steps = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	position: relative;

	${_small} {
		width: 664px;
	}
`;

const ChecklistCard = props => {
	const { className, title, description, subComponent, level, steps, checkType, game, active, current, onClick, icon = 'upload' } = props;

	const stepsContainerRef = useRef(null);

	const [checkedItems, setCheckedItems] = useState([]);
	const [step, setStep] = useState(-1);

	const levelStatus = useSelectGameChecklistLevelStatus(game?.id, level);

	useEffect(() => {
		if (levelStatus) {
			let lastCompletedStep = -1;
			const newCheckedItems = steps.map(() => false);
			for (let i = 0; i < levelStatus.length; i++) {
				if (levelStatus[i]?.status === 'completed') {
					newCheckedItems[i] = true;
					lastCompletedStep = i;
				}
			}
			setCheckedItems(newCheckedItems);

			if (lastCompletedStep >= 0) {
				setStep(lastCompletedStep + 1);
			}
		} else if (current) {
			setStep(0);
		}
	}, [levelStatus, current]);

	const handleSelectStep = index => {
		setStep(index);
	};

	const handleCheck = () => {
		const nextStep = Math.min(step + 1, steps.length - 1);
		handleSelectStep(nextStep);
	};

	const GetCheckboxIcon = ({ active: checkboxActive, current: checkboxCurrent }) => {
		switch (icon) {
			case 'upload':
				return <CheckboxUploadIcon active={checkboxActive} current={checkboxCurrent} />;
			case 'game':
				return <CheckboxGameIcon active={checkboxActive} current={checkboxCurrent} />;
			case 'rocket':
				return <CheckboxRocketIcon active={checkboxActive} current={checkboxCurrent} />;
			default:
				return <CheckboxDiamondIcon active={checkboxActive} current={checkboxCurrent} />;
		}
	};

	const activateClick = active ? () => {} : onClick;

	return (
		<CardContainer className={className} onClick={activateClick}>
			{(title || description || subComponent) && (
				<Head active={active} current={current}>
					<Checkbox active={active} current={current}>
						<GetCheckboxIcon active={active} current={current} />
					</Checkbox>
					<Left>
						<Level>
							{`LEVEL ${level}`}
						</Level>
						<Title>
							{title}
						</Title>
					</Left>
				</Head>
			)}
			{active && (
				<OuterFrame active={active}>
					<Frame>
						<Description>{description || subComponent}</Description>
						{steps.length > 0 && (
							<Steps ref={stepsContainerRef}>
								{steps.map((data, i) => (
									<ChecklistStep
										key={`step-${data.title}`}
										title={data.title}
										contentGenerator={data.contentGenerator}
										onSubmitCallback={handleCheck}
										onClick={() => handleSelectStep(i)}
										checkType={checkType}
										active={i === step}
										disabled={!current || (i > 0 && !checkedItems[i - 1])}
										checked={checkedItems[i]}
										current={i === 0 || checkedItems[i - 1]}
										game={game}
									/>
								))}
							</Steps>
						)}
					</Frame>
				</OuterFrame>
			)}
		</CardContainer>
	);
};

ChecklistCard.propTypes = {
	className: PT.string,
	title: PT.string,
	level: PT.string,
	description: PT.string,
	steps: PT.arrayOf(PT.shape({
		title: PT.string,
		children: PT.string,
	})),
};

ChecklistCard.defaultProps = {
	className: null,
	title: null,
	level: null,
	description: null,
	steps: [],
};

export default ChecklistCard;
