import React from 'react';

const Coin2 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M118.479 31.3598L105.159 70.9998L85.2792 102.44L61.5992 124.32L49.1192 129.2L31.5992 119.28L21.1992 94.6798L38.9192 53.8398L64.6392 19.5998L82.9992 10.8398L104.679 18.7598L118.479 31.3598Z" fill="#FFD100" />
			<path d="M85.2396 102.439L61.5996 124.319L49.1196 129.199L31.5996 119.279L60.8796 101.719L85.2396 102.439Z" fill="#FF9E00" />
			<path d="M21.1992 94.6808L38.9192 53.8408L64.6392 19.6008L82.9192 10.8008L98.9192 20.0408L91.3992 59.0008L60.8792 101.721L31.5992 119.281L21.1992 94.6808Z" fill="#FFDC00" />
			<path d="M45.0005 69.0816L37.5605 91.5216L41.5605 98.5216L62.8805 78.4016L83.5205 42.6416L82.3205 33.6016L73.8805 31.6016L45.0005 69.0816Z" fill="#FFE200" />
			<path d="M81.959 30.4781L98.879 20.0781L91.399 59.0381L83.519 42.6381L81.959 30.4781Z" fill="#FFC500" />
			<path d="M62.8796 78.4414L60.8396 101.761L31.5996 119.281L41.5596 98.5214L62.8796 78.4414Z" fill="#FFC500" />
			<path d="M21.1992 94.6798L34.5192 90.9198L40.4392 68.5598L38.9192 53.8398L21.1992 94.6798Z" fill="#FFC500" />
			<path d="M52.239 48.1602L38.959 53.8402L40.479 68.5602L52.239 48.1602Z" fill="#FFE200" />
			<path d="M82.9206 10.8789L64.6406 19.5589L70.4806 28.5989L82.9206 10.8789Z" fill="#FFD100" />
			<path d="M37.5595 91.5216L44.9995 69.0816L73.9195 31.6416L70.4795 28.6016L52.2395 48.1616L40.4395 68.5616L34.5195 90.9216L37.5595 91.5216Z" fill="#FFAF00" />
			<path d="M81.9605 30.4786L82.3605 33.5986L73.9205 31.5986L70.4805 28.5586L81.9605 30.4786Z" fill="#FF9E00" />
			<path d="M34.5195 90.9219L37.5595 91.5219L41.5595 98.5219L34.5195 90.9219Z" fill="#FFED85" />
			<path d="M60.8809 101.72L85.2809 102.44L105.161 70.9998L118.481 31.3598L104.721 18.7598L83.0009 10.8398L98.9209 20.0798L91.4009 59.0798L60.8809 101.72Z" fill="#FFD100" />
		</svg>
	);
};

export default Coin2;
