import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const Preview = styled.iframe`
	width: 100%;
	height: 100%;
	border: 0;
`;

const PrivacyAddendumPage = () => (
	<>
		<Helmet key="PrivacyAddendumPage">
			<title>Privacy Addendum - Poki for Developers</title>
		</Helmet>
		<Preview src="/2023.11.17_Poki_Privacy_Addendum.pdf" width="100%" height="100vh" title="preview-privacy-addendum" />
	</>
);

export default PrivacyAddendumPage;
