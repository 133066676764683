import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import AuthPage from 'app/src/components/pages/AuthPage';
import GamePage from 'app/src/components/pages/GamePage';
import GameOverviewSubPage from 'app/src/components/pages/game/GameOverviewSubPage';
import GameVersionsSubPage from 'app/src/components/pages/game/GameVersionsSubPage';
import GameSettingsSubPage from 'app/src/components/pages/game/GameSettingsSubPage';
import RecordedPlaytest from 'app/src/components/pages/RecordedPlaytest';
import GamePlaytestSubPage from 'app/src/components/pages/game/GamePlaytestSubPage';
import GameErrorsSubPage from 'app/src/components/pages/game/GameErrorsSubPage';
import AdminGameSettingsSubPage from 'app/src/components/pages/game/AdminGameSettingsSubPage';
import GamePlayerFeedbackSubPage from 'app/src/components/pages/game/GamePlayerFeedbackSubPage';
import GameEventsSubPage from 'app/src/components/pages/game/GameEventsSubPage';
import DashboardPage from 'app/src/components/pages/DashboardPage';
import AdminDashboardPage from 'app/src/components/pages/AdminDashboardPage';
import AdminDomainsPage from 'app/src/components/pages/AdminDomainsPage';
import AdminTeamsPage from 'app/src/components/pages/AdminTeamsPage';
import TeamSettingsPage from 'app/src/components/pages/TeamSettingsPage';
import TeamSettingsUsersSubPage from 'app/src/components/pages/settings/TeamSettingsUsersSubPage';
import TeamSettingsGeneralSettingsSubPage from 'app/src/components/pages/settings/TeamSettingsGeneralSettingsSubPage';
import TeamSettingsBillingPageContainer from 'app/src/components/pages/settings/TeamSettingsBillingPageContainer';
import TeamSettingsAdditionalPaymentsSubPage from 'app/src/components/pages/settings/TeamSettingsAdditionalPaymentsSubPage';
import TeamSettingsRevenueSharingSubPage from 'app/src/components/pages/settings/TeamSettingsRevenueSharingSubPage';
import TeamSettingsRevenueSharingSubPageLegacy from 'app/src/components/pages/settings/TeamSettingsRvenueSharingSubPageLegacy';
import AdminPaymentsInvoicesSubPage from 'app/src/components/pages/adminPayments/AdminPaymentsInvoicesSubPage';
import AdminPaymentsUpfrontPaymentsSubPage from 'app/src/components/pages/adminPayments/AdminPaymentsUpfrontPaymentsSubPage';
import AdminPaymentsMinimumGuaranteesSubPage from 'app/src/components/pages/adminPayments/AdminPaymentsMinimumGuaranteesSubPage';
import AdminPaymentsRecurringMonthlyPaymentsSubPage from 'app/src/components/pages/adminPayments/AdminPaymentsRecurringMonthlyPaymentsSubPage';
import AdminPaymentsOneOffPaymentsSubPage from 'app/src/components/pages/adminPayments/AdminPaymentsOneOffPaymentsSubPage';
import AdminPaymentsWisePayoutSubPage from 'app/src/components/pages/adminPayments/AdminPaymentsWisePayoutSubPage';
import AdminPaymentsPage from 'app/src/components/pages/AdminPaymentsPage';
import UserSettingsPage from 'app/src/components/pages/UserSettingsPage';
import SignInPage from 'app/src/components/pages/SignInPage';
import SignUpPage from 'app/src/components/pages/SignUpPage';
import CompleteSignupPage from 'app/src/components/pages/CompleteSignupPage';
import ChartTestPage from 'app/src/components/pages/ChartTestPage';
import NotFoundPage from 'shared/components/pages/NotFoundPage';
import SurveyPage from 'app/src/components/pages/SurveyPage';
import FinancePage from 'app/src/components/pages/FinancePage';
import PrivacyAddendumPage from 'app/src/components/pages/PrivacyAddendumPage';
import AdminReviewsPage from 'app/src/components/pages/AdminReviewsPage';
import RouteWrapper from 'app/src/components/RouteWrapper';
import GameCreatePage from 'app/src/components/pages/game/GameCreatePage';

// Import all Modals!
import 'app/src/components/modals/AcceptPrivacyAddendumModal';
import 'app/src/components/modals/AddMinimumGuaranteeModal';
import 'app/src/components/modals/AddOneOffPaymentModal';
import 'app/src/components/modals/AddRecurringMonthlyPaymentModal';
import 'app/src/components/modals/AddUpfrontPaymentModal';
import 'app/src/components/modals/AdminPreviewInvoiceModal';
import 'app/src/components/modals/BaseConfirmationModal';
import 'app/src/components/modals/ChangeEmailModal';
import 'app/src/components/modals/ChangeRevenueShareModal';
import 'app/src/components/modals/ConfirmRemoveGameModal';
import 'app/src/components/modals/ConfirmRemoveRevenueShareModal';
import 'app/src/components/modals/ConfirmSetPublicVersionModalNoRevShare';
import 'app/src/components/modals/CreateGameModal';
import 'app/src/components/modals/CreateReviewReportModal';
import 'app/src/components/modals/CreateReviewRequestModal';
import 'app/src/components/modals/CreateTeamModal';
import 'app/src/components/modals/CreateUserModal';
import 'app/src/components/modals/EditVersionNotesModal';
import 'app/src/components/modals/EditPlaytestNotesModal';
import 'app/src/components/modals/EditInvoiceInternalNotesModal';
import 'app/src/components/modals/ExportCSVModal';
import 'app/src/components/modals/GameCreateVersionModal';
import 'app/src/components/modals/GamePreviewQRModal';
import 'app/src/components/modals/LeavePageConfirmationModal';
import 'app/src/components/modals/ManageDomainCollectionModal';
import 'app/src/components/modals/PreviewInvoiceModal';
import 'app/src/components/modals/SearchModal';
import 'app/src/components/modals/SignOutModal';
import 'app/src/components/modals/SyncToConsole';
import 'app/src/components/modals/TempAuthIssuesModal';
import 'app/src/components/modals/ThumbnailPreviewModal';
import 'app/src/components/modals/ViewRawDataModal';
import 'app/src/components/modals/ViewReviewRequestModal';
import 'app/src/components/modals/StartNewPlaytestModal';

const routeConfig = [
	{
		path: '/',
		element: () => <Navigate to="/admin" />,
		options: {
			eventPath: 'landing',
		},
	},
	{
		path: '/beta-:version',
		exact: true,
		element: SurveyPage,
		options: {
			eventPath: 'survery',
			noContainer: true,
		},
	},
	{
		path: '/signin',
		exact: true,
		element: SignInPage,
		options: {
			eventPath: 'login',
			noContainer: true,
		},
	},
	{
		path: '/signup-gdc',
		exact: true,
		element: SignUpPage,
		options: {
			eventPath: 'signup',
			noContainer: true,
		},
	},
	{
		path: '/signup-earlyaccess',
		exact: true,
		element: SignUpPage,
		options: {
			eventPath: 'signup',
			noContainer: true,
		},
	},
	{
		path: '/signup-game-jam',
		exact: true,
		element: SignUpPage,
		options: {
			eventPath: 'signup',
			noContainer: true,
		},
	},
	{
		path: '/complete-signup',
		exact: true,
		element: CompleteSignupPage,
		options: {
			eventPath: 'complete-signup',
			noContainer: true,
			authRequired: true,
		},
	},
	{
		path: '/auth/:provider',
		exact: true,
		element: AuthPage,
		options: {
			eventPath: 'auth',
		},
	},
	{
		path: '/privacy-addendum',
		exact: true,
		element: PrivacyAddendumPage,
		options: {
			eventPath: 'privacy-addendum',
			noContainer: true,
		},
	},
	{
		path: '/user-settings',
		exact: true,
		element: UserSettingsPage,
		options: {
			eventPath: 'user-settings',
			authRequired: true,
			needsPermissions: [['can_edit_all_teams', 'can_edit_owned_teams']],
		},
	},

	// Admin routes
	{
		path: '/admin',
		exact: true,
		element: AdminDashboardPage,
		options: {
			eventPath: 'admin',
			authRequired: true,
			needsPermissions: [['can_read_all_games']],
		},
	},
	{
		path: '/admin/teams',
		exact: true,
		element: AdminTeamsPage,
		options: {
			eventPath: 'admin/teams',
			authRequired: true,
			needsPermissions: [['can_read_all_teams']],
		},
	},
	{
		path: '/admin/domains',
		exact: true,
		element: AdminDomainsPage,
		options: {
			exactPath: 'admin/domains',
			authRequired: true,
			needsPermissions: [['can_read_domain_collections']],
		},
	},
	{
		path: '/admin/payments/invoices',
		exact: true,
		element: AdminPaymentsPage,
		options: {
			eventPath: 'admin/paymnents/invoices',
			authRequired: true,
			needsPermissions: [['can_read_all_teams']],
			subComponent: AdminPaymentsInvoicesSubPage,
		},
	},
	{
		path: '/admin/payments/upfront-payments',
		exact: true,
		element: AdminPaymentsPage,
		options: {
			eventPath: 'admin/payments/upfront-payments',
			authRequired: true,
			needsPermissions: [['can_read_all_upfront_payments']],
			subComponent: AdminPaymentsUpfrontPaymentsSubPage,
		},
	},
	{
		path: '/admin/payments/minimum-guarantees',
		exact: true,
		element: AdminPaymentsPage,
		options: {
			eventPath: 'admin/payments/minimum-guarantees',
			authRequired: true,
			needsPermissions: [['can_read_all_minimum_guarantees']],
			subComponent: AdminPaymentsMinimumGuaranteesSubPage,
		},
	},
	{
		path: '/admin/payments/recurring-monthly-payments',
		exact: true,
		element: AdminPaymentsPage,
		options: {
			eventPath: 'admin/payments/recurring-monthly-payments',
			authRequired: true,
			needsPermissions: [['can_read_all_recurring_monthly_payments']],
			subComponent: AdminPaymentsRecurringMonthlyPaymentsSubPage,
		},
	},
	{
		path: '/admin/payments/one-off-payments',
		exact: true,
		element: AdminPaymentsPage,
		options: {
			eventPath: 'admin/payments/one-off-payments',
			authRequired: true,
			needsPermissions: [['can_read_all_one_off_payments']],
			subComponent: AdminPaymentsOneOffPaymentsSubPage,
		},
	},
	{
		path: '/admin/payments/wise-payout',
		exact: true,
		element: AdminPaymentsPage,
		options: {
			eventPath: 'admin/payments/wise-payout',
			authRequired: true,
			needsPermissions: [['can_read_wise_status']],
			subComponent: AdminPaymentsWisePayoutSubPage,
		},
	},
	{
		path: '/admin/reviews',
		exact: true,
		element: AdminReviewsPage,
		options: {
			eventPath: 'admin/reviews',
			authRequired: true,
			needsPermissions: [['can_read_all_reviews']],
		},
	},

	// Team-specific routes
	{
		path: '/:teamCode',
		exact: true,
		element: DashboardPage,
		options: {
			authRequired: true,
			eventPath: 'dashboard',
			needsPermissions: [['can_read_all_games', 'can_read_owned_games']],
		},
	},
	{
		path: '/:teamCode/games/create',
		exact: true,
		element: GameCreatePage,
		options: {
			eventPath: 'game/new',
			authRequired: true,
			needsPermissions: [['can_create_all_games', 'can_create_owned_games']],
		},
	},
	{
		path: '/:teamCode/games/:gameId',
		exact: true,
		element: GamePage,
		options: {
			eventPath: 'game',
			authRequired: true,
			needsPermissions: [['can_read_all_games', 'can_read_owned_games']],
			subComponent: GameOverviewSubPage,
		},
	},
	{
		path: '/:teamCode/games/:gameId/errors',
		exact: true,
		element: GamePage,
		options: {
			eventPath: 'game/errors',
			authRequired: true,
			needsPermissions: [['can_read_all_games', 'can_read_owned_games']],
			subComponent: GameErrorsSubPage,
		},
	},
	{
		path: '/:teamCode/games/:gameId/player-feedback',
		exact: true,
		element: GamePage,
		options: {
			eventPath: '/game/player-feedback',
			authRequired: true,
			needsPermissions: [['can_read_all_player_feedback', 'can_read_owned_player_feedback']],
			subComponent: GamePlayerFeedbackSubPage,
		},
	},
	{
		path: '/:teamCode/games/:gameId/versions',
		exact: true,
		element: GamePage,
		options: {
			eventPath: 'game/versions',
			authRequired: true,
			needsPermissions: [['can_read_all_versions', 'can_read_owned_versions']],
			subComponent: GameVersionsSubPage,
		},
	},
	{
		path: '/:teamCode/games/:gameId/playtests',
		exact: true,
		element: GamePage,
		options: {
			eventPath: 'game/playtests',
			authRequired: true,
			needsPermissions: [['can_edit_all_playtests', 'can_edit_owned_playtests']],
			subComponent: GamePlaytestSubPage,
		},
	},
	{
		path: '/:teamCode/games/:gameId/playtests/:playtestId',
		exact: true,
		element: GamePage,
		options: {
			eventPath: 'game/playtests/playtest',
			authRequired: true,
			needsPermissions: [['can_edit_all_playtests', 'can_edit_owned_playtests']],
			subComponent: RecordedPlaytest,
		},
	},
	{
		path: '/:teamCode/games/:gameId/game-events',
		exact: true,
		element: GamePage,
		options: {
			eventPath: '/game/game-events',
			authRequired: true,
			needsPermissions: [['can_read_all_game_events', 'can_read_owned_game_events']],
			subComponent: GameEventsSubPage,
		},
	},
	{
		path: '/:teamCode/games/:gameId/settings',
		exact: true,
		element: GamePage,
		options: {
			eventPath: 'game/settings',
			authRequired: true,
			subComponent: GameSettingsSubPage,
		},
	},
	{
		path: '/:teamCode/games/:gameId/admin-settings',
		exact: true,
		element: GamePage,
		options: {
			eventPath: 'game/admin-settings',
			authRequired: true,
			needsPermissions: [['can_edit_all_games', 'can_edit_owned_games']],
			subComponent: AdminGameSettingsSubPage,
		},
	},
	{
		path: '/:teamCode/finance',
		exact: true,
		element: FinancePage,
		options: {
			eventPath: 'finance',
			authRequired: true,
			needsPermissions: [['can_read_all_invoices', 'can_read_owned_invoices']],
		},
	},
	{
		path: '/:teamCode/settings',
		exact: true,
		element: TeamSettingsPage,
		options: {
			eventPath: 'settings',
			authRequired: true,
			needsPermissions: [['can_edit_all_teams', 'can_edit_owned_teams']],
			subComponent: TeamSettingsGeneralSettingsSubPage,
		},
	},
	{
		path: '/:teamCode/settings/users',
		exact: true,
		element: TeamSettingsPage,
		options: {
			eventPath: 'settings/users',
			authRequired: true,
			needsPermissions: [['can_read_all_users', 'can_read_owned_users']],
			subComponent: TeamSettingsUsersSubPage,
		},
	},
	{
		path: '/:teamCode/settings/billing',
		exact: true,
		element: TeamSettingsPage,
		options: {
			eventPath: 'settings/billing',
			authRequired: true,
			needsPermissions: [['can_edit_all_billing', 'can_edit_owned_billing']],
			subComponent: TeamSettingsBillingPageContainer,
		},
	},
	{
		path: '/:teamCode/settings/revenue-sharing',
		exact: true,
		element: TeamSettingsPage,
		options: {
			eventPath: 'settings/revenue-sharing',
			authRequired: true,
			needsPermissions: [['can_read_all_teams']],
			subComponent: TeamSettingsRevenueSharingSubPage,
		},
	},
	{
		path: '/:teamCode/settings/revenue-sharing-legacy',
		exact: true,
		element: TeamSettingsPage,
		options: {
			eventPath: 'settings/revenue-sharing-legacy',
			authRequired: true,
			needsPermissions: [['can_read_all_teams']],
			subComponent: TeamSettingsRevenueSharingSubPageLegacy,
		},
	},
	{
		path: '/:teamCode/settings/additional-payments',
		exact: true,
		element: TeamSettingsPage,
		options: {
			eventPath: 'settings/additional-payments',
			authRequired: true,
			needsPermissions: [['can_read_all_teams', 'can_read_all_minimum_guarantees', 'can_read_all_upfront_payments', 'can_read_all_one_off_payments', 'can_read_all_minimum_guarantees', 'can_read_all_upfront_payments', 'can_read_all_one_off_payments']],
			subComponent: TeamSettingsAdditionalPaymentsSubPage,
		},
	},

	// Debug routes
	{
		path: '/chart-test',
		exact: true,
		element: ChartTestPage,
		options: {
			authRequired: true,
		},
	},

	// 404
	{
		path: '*',
		element: NotFoundPage,
		options: {
			noContainer: true,
		},
	},
];

const parseRoutes = (routes = []) => (
	routes.map(route => {
		const { options, children, ...routeProps } = route;

		return {
			...routeProps,

			element: (
				<RouteWrapper
					eventPath={options.eventPath}
					noContainer={options.noContainer}
					authRequired={options.authRequired}
					needsPermissions={options.needsPermissions}
					routeConfig={routeProps}
				>
					<route.element eventPath={options.eventPath} subComponent={options.subComponent} />
				</RouteWrapper>
			),

			children: parseRoutes(children),
		};
	})
);

const Routes = React.memo(() => useRoutes(parseRoutes(routeConfig)));

export default Routes;
