import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, tap, ignoreElements } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import LabeledValue from 'app/src/components/ui/LabeledValue';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import { isRequired, validate } from 'app/src/utils/validate';
import { createReviewRequest, getReviewsForGame } from 'app/src/epics/review';
import { startScreenShake } from 'app/src/actions/effects';
import WarningMessage from 'app/src/components/ui/WarningMessage';
import { useSelectReviewsByGameId } from 'app/src/selectors/review';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;

	*:only-child {
		margin-left: auto;
	}
`;

const Intro = styled.div`
	margin-bottom: 28px;
	white-space: pre-wrap;
`;

const CreateReviewRequestModal = props => {
	const { data: { game, version } } = props;

	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());
	const [notes, setNotes] = useState(version.notes);
	const [errors, setErrors] = useState({});
	const [created, setCreated] = useState(false);

	const createReviewRequestStatus = useSelectApiStatus(createReviewRequest.id);
	const reviews = (useSelectReviewsByGameId(game.id).data || []);

	useEffect(() => {
		dispatch(getReviewsForGame.fetch({ gameId: game.id }));
	}, [game.id]);

	const gameHasPendingReviews = useMemo(() => (
		reviews.some(review => review.status === 'pending')
	), [reviews]);

	const handleSubmit = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'review_developerNotes',
				value: notes,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(createReviewRequest.fetch({ gameId: game.id, versionId: version.id, data: { developerNotes: notes } }, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					setCreated(true);
				}),
				ignoreElements(),
			)
		)));
	};

	return (
		<Modal disableOverlayClose exit$={exit$}>
			{created ? (
				<>
					<h2>{_`reviewRequestedTitle`}</h2>
					<p dangerouslySetInnerHTML={{ __html: _`reviewRequestedMessage` }} />
					<Buttons>
						<Button onClick={() => exit$.next()}>{_`close`}</Button>
					</Buttons>
				</>
			) : (
				<>
					<h2>{_`requestReview`}</h2>
					<Intro>{_`requestReviewIntro`}</Intro>
					<Form onSubmit={handleSubmit}>
						<LabeledValue label={_`version`}>{getVersionLabel(version)}</LabeledValue>
						<TextAreaInput
							label={_`notes`}
							name="review_developerNotes"
							errors={errors.review_developerNotes}
							value={notes}
							valueSetter={setNotes}
							required={isRequired('review_developerNotes')}
						/>
						{gameHasPendingReviews && (
							<WarningMessage
								title={_`gameAlreadyHasPendingReviewWarningTitle`}
								message={_`gameAlreadyHasPendingReviewWarningDescription`}
							/>
						)}
						<Buttons>
							<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
							<Button submit disabled={createReviewRequestStatus.pending}>{createReviewRequestStatus.pending ? _`submitting` : _`submit`}</Button>
						</Buttons>
					</Form>
				</>
			)}
		</Modal>
	);
};

registerModal('create-review-request', CreateReviewRequestModal);
