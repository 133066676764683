import React, { useRef } from 'react';
import styled from 'styled-components';

import _ from 'shared/copy';

const Radio = styled.div`
	display: flex;
	position: relative;
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	color: ${props => props.theme.denimBlue};
	cursor: pointer;
	align-items: center;
	padding: 4px 16px;
	margin: 2px;
	border-radius: 6px;
	font-weight: bold;
	color: ${props => props.theme.grey3};

	&:hover {
		color: ${props => props.theme.pokiBlue};
	}

	label {
		cursor: pointer;
		flex-grow: 1;
	}

	input {
		display: none;
	}

	${props => props.$checked && `
	background: ${props.theme.pokiBlue};
	color: ${props.theme.static.pureWhite};

	&:hover {
		color: ${props.theme.static.pureWhite};
	}
	`}
`;

const InputContainer = styled.div`
	display: flex;
	position: relative;
	margin-bottom: 8px;
	border: 3px solid ${props => props.theme.pokiBlue};
	box-sizing: border-box;
	border-radius: 10px;
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const MultiToggleInput = props => {
	const { className, required, label, name, values, value, valueSetter, errors = [], description } = props;

	const inputRef = useRef();

	const createHandleClick = val => () => {
		if (valueSetter) valueSetter(val);

		if (inputRef.current?.form) {
			const inputEvent = new Event('input', { bubbles: true });
			inputRef.current.form.dispatchEvent(inputEvent);
		}
	};

	const input = (
		<InputContainer>
			{values.map(v => {
				const checked = v.value.toString() === value.toString();
				const key = `radio-${name}-${v.value}`;

				return (
					<Radio key={key} onClick={createHandleClick(v.value)} $checked={checked}>
						<input id={key} type="radio" name={name} value={v.value} defaultChecked={checked} />
						<label htmlFor={key}>{v.desc}</label>
					</Radio>
				);
			})}
		</InputContainer>
	);

	return (
		<StyledFieldset className={className}>
			{/* This hidden input is used to determine the form that the radio buttons are in */}
			<input type="hidden" name="fake-ref" ref={inputRef} />
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0}>
					{label}
					{input}
				</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					<Description>{description}</Description>
				)
			)}
		</StyledFieldset>
	);
};

export default MultiToggleInput;
