import React from 'react';
import styled from 'styled-components';

import ChevronRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';

const DetailsItemContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	max-width: 900px;
`;

const Chevron = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

const DetailsItem = styled.details`
	&[open] ${Chevron} {
		transform: rotate(-90deg);
	}
`;

const DetailsTitle = styled.summary`
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 0;

	font-size: 16px;
	font-weight: 700;
	cursor: pointer;

	&::-webkit-details-marker {
		display: none;
	}
`;

const DetailsContent = styled.p`
	font: 400 16px "Proxima Nova";
	margin: 0;
	padding: 0 32px;
`;

const Details = props => {
	const { isInitiallyOpen = false, title, content, className } = props;

	return (
		<DetailsItemContainer className={className}>
			<DetailsItem open={isInitiallyOpen}>
				<DetailsTitle>
					<Chevron>
						<ChevronRightIcon />
					</Chevron>
					{title}
				</DetailsTitle>
				<DetailsContent>{content}</DetailsContent>
			</DetailsItem>
		</DetailsItemContainer>
	);
};

export default Details;
