import { keyframes } from 'styled-components';

export const VirtualKeyboardStyleKeyPressed = keyframes`
	0%, 50%{
		fill: #009CFF;
	}
	100%{
		fill: ${props => props.theme.grey1};
	}
`;

export class SyncedKeyboard {
	constructor() {
		this.el = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		fetch('/svg/keyboard.svg').then(res => res.text()).then(svg => {
			this.el.innerHTML = svg;
		});
	}

	update(keys) {
		keys.forEach(key => {
			const id = `PVK_${key}`;
			const currentKey = this.el.querySelector(`#${id}`);
			if (currentKey) {
				currentKey.classList.add('active');
				// reset animation
				const newSpaceKey = currentKey.cloneNode(true);
				currentKey.parentNode.replaceChild(newSpaceKey, currentKey);
			}
		});
	}
}
