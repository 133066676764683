import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';
import { Subject, tap, ignoreElements, map } from 'rxjs';
import { Helmet } from 'react-helmet';

import { signoutWithNewEmail } from 'app/src/actions/session';
import Button, { ButtonTextContainer, ButtonText } from 'app/src/components/ui/Button';
import Container from 'app/src/components/ui/Container';
import Card from 'app/src/components/ui/Card';
import { useSelectUser } from 'app/src/selectors/user';
import useTrackUnsavedChanges from 'app/src/hooks/useTrackUnsavedChanges';
import TextInput from 'app/src/components/input/TextInput';
import { validate, isRequired } from 'app/src/utils/validate';
import { startScreenShake } from 'app/src/actions/effects';
import { patchUser, emailExists } from 'app/src/epics/user';
import _ from 'shared/copy';
import { openModal } from 'app/src/actions/client';

const UserSettingsPage = () => {
	const dispatch = useDispatch();
	const user = useSelectUser();
	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const [confirm$] = useState(new Subject());

	const [placeholderName] = useState(Math.random() < 0.5 ? _`placeholderNameA` : _`placeholderNameB`);
	const [name, setName] = useState(user.name);
	const [email, setEmail] = useState(user.email);

	const { unsavedChanges, resetFormData } = useTrackUnsavedChanges(formRef);
	const patchUserStatus = useSelectApiStatus(patchUser.id);
	const emailExistsStatus = useSelectApiStatus(emailExists.id);

	useEffect(() => {
		const listen = confirm$.subscribe(() => {
			dispatch(
				patchUser.fetch({
					userId: user.id,
					data: {
						name,
						email,
					},
				}, ({ success$ }) => (
					success$.pipe(
						map(() => signoutWithNewEmail({ email })),
					)
				)),
			);
		});

		return () => {
			listen.unsubscribe();
		};
	}, [confirm$, name, email]);

	const handleSave = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				validate: 'user_name',
				errorKey: 'name',
				value: name,
			},
			{
				validate: 'user_email',
				errorKey: 'email',
				value: email,
			},
		];

		checks.forEach(c => {
			const check = validate(c.validate, c.value);

			if (!check.valid) {
				err[c.errorKey] = check.messages;
			} else {
				delete err[c.errorKey];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		if (email !== user.email) {
			// Email was changed, check if it exists
			dispatch(
				openModal({ key: 'change-email', data: { email, confirm$ } }),
			);
		} else {
			// Immediately change info
			dispatch(patchUser.fetch({
				userId: user.id,
				data: { name },
			}, ({ success$ }) => (
				success$.pipe(
					tap(resetFormData),
					ignoreElements(),
				)
			)));
		}
	};

	const disableFields = combinedApiStatus(patchUserStatus, emailExistsStatus).pending;

	return (
		<Container>
			<Helmet key="UserSettingsPage">
				<title>User Settings - Poki for Developers</title>
			</Helmet>
			<Card
				title={_`userSettings`}
			>
				<form ref={formRef} onSubmit={handleSave}>
					<TextInput
						label={_`fullName`}
						name="name"
						disabled={disableFields}
						errors={errors.name}
						placeholder={placeholderName}
						value={name}
						valueSetter={setName}
						required={isRequired('user_name')}
					/>
					<TextInput
						label={_`email`}
						name="email"
						disabled={disableFields}
						errors={errors.email}
						placeholder={_`exampleEmail`}
						value={email}
						valueSetter={setEmail}
						description={_`emailChangeRequiresSignIn`}
						required={isRequired('user_email')}
					/>
					<ButtonTextContainer>
						<Button submit disabled={disableFields || !unsavedChanges} primary>{patchUserStatus.pending ? _`saving` : _`save`}</Button>
						<ButtonText warning={unsavedChanges}>
							{patchUserStatus.pending ? _`saving` : unsavedChanges ? _`unsavedChanges` : ''}
						</ButtonText>
					</ButtonTextContainer>
				</form>
			</Card>
		</Container>
	);
};

export default UserSettingsPage;
