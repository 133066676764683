import React from 'react';

const Block3 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M104.84 10L118.16 48.48L107.16 76.88L92.2 93.64L83.84 91.56L86 100.16L57.48 124.4L26.4 130L22 93.8L59.6 50.48L67.56 38.44L81.48 17.36L104.84 10Z" fill="#DCA7F2" />
			<path d="M59.5996 50.48L67.5596 38.44L104.84 10L88.7596 44.24L59.5996 50.48Z" fill="#E0AEF5" />
			<path d="M81.5196 17.36L67.5996 38.44L104.84 10L81.5196 17.36Z" fill="#EBCCFF" />
			<path d="M48.52 93.6406L57.44 124.441L26.4 130.001L22 93.8006L48.52 93.6406Z" fill="#D490F2" />
			<path d="M104.84 10L118.16 48.48L107.16 76.88L88.7598 44.28L104.84 10Z" fill="#DA9EF3" />
			<path d="M48.5195 93.6406L57.4395 124.441L85.9595 100.201L61.1595 85.9606L55.8795 84.6406L48.5195 93.6406Z" fill="#E0AEF5" />
			<path d="M88.7608 44.2383L55.8809 84.5983L92.2009 93.6383L107.161 76.8783L88.7608 44.2383Z" fill="#C977E8" />
			<path d="M48.52 93.6413L22 93.8013L59.6 50.4813L88.76 44.2813L48.52 93.6413Z" fill="#DA9EF3" />
			<path d="M83.8401 91.5619L61.1602 85.9219L85.9601 100.162L83.8401 91.5619Z" fill="#D490F2" />
		</svg>
	);
};

export default Block3;
