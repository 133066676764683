import React, { useState, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ignoreElements, map, tap } from 'rxjs';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';

import GridContainer from 'app/src/components/ui/GridContainer';
import TextInput from 'app/src/components/input/TextInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import SelectInput from 'app/src/components/input/SelectInput';
import countries from 'app/src/utils/countries';
import Button, { ButtonTextContainer, ButtonText } from 'app/src/components/ui/Button';
import useTrackUnsavedChanges from 'app/src/hooks/useTrackUnsavedChanges';
import { validate, isRequired } from 'app/src/utils/validate';
import { startScreenShake } from 'app/src/actions/effects';
import { getTeamByCode, getTeamBillingSettings, patchTeamBillingSettings } from 'app/src/epics/team';
import { useSelectTeamBillingSettings, useSelectTeamIsCurrencyLocked } from 'app/src/selectors/team';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import _ from 'shared/copy';

const FormContainer = styled.div`
	display: flex;
`;

const TeamSettingsBillingSubPageLegacy = ({ team }) => {
	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const countryValues = useMemo(() => countries.map(c => ({ value: c.iso_2_code, desc: c.name })), []);
	const dispatch = useDispatch();

	const billingSettings = useSelectTeamBillingSettings(team.id);
	const getTeamBillingSettingsStatus = useSelectApiStatus(getTeamBillingSettings.id);

	const [placeholderName] = useState(Math.random() < 0.5 ? _`placeholderNameA` : _`placeholderNameB`);
	const [billingName, setBillingName] = useState();
	const [billingEmail, setBillingEmail] = useState();
	const [preferredCurrency, setPreferredCurrency] = useState();
	const [paypalEmail, setPaypalEmail] = useState();
	const [billingAddress, setBillingAddress] = useState();
	const [billingCountry, setBillingCountry] = useState();
	const [paymentMethod, setPaymentMethod] = useState();
	const [bankName, setBankName] = useState();
	const [bankAccountName, setBankAccountName] = useState();
	const [bankAccountNumber, setBankAccountNumber] = useState();
	const [bankBIC, setBankBIC] = useState();
	const [vatNumber, setVatNumber] = useState();

	const { unsavedChanges, resetFormData } = useTrackUnsavedChanges(formRef);
	const patchTeamBillingSettingsStatus = useSelectApiStatus(patchTeamBillingSettings.id);
	const getTeamByCodeStatus = useSelectApiStatus(getTeamByCode.id);
	const teamIsCurrencyLocked = useSelectTeamIsCurrencyLocked(team);
	const permissions = useSelectPermissions();

	const canChangeCurrency = checkPermissions(permissions, [['can_edit_team_currency']]);

	const setInitialFormData = data => {
		if (data) {
			setBillingName(data.name);
			setBillingEmail(data.email);
			setPreferredCurrency(data.currency);
			setPaypalEmail(data.paypal_email);
			setBillingAddress(data.address);
			setBillingCountry(data.country);
			setPaymentMethod(data.payment_method);
			setBankName(data.bank_name);
			setBankAccountName(data.bank_account_name);
			setBankAccountNumber(data.bank_account_number);
			setBankBIC(data.bank_bic);
			setVatNumber(data.vat_number);
		}
	};

	useEffect(() => {
		setInitialFormData(billingSettings);
	}, []);

	useEffect(() => {
		dispatch(getTeamBillingSettings.fetch({ teamId: team.id }, ({ success$ }) => (
			success$.pipe(
				map(({ payload: { result: { response } } }) => {
					const { data: { attributes } } = response;

					setInitialFormData(attributes);
				}),
				tap(() => resetFormData()),
				ignoreElements(),
			)
		)));
	}, [team]);

	useEffect(() => {
		if (!getTeamBillingSettingsStatus.done || !billingSettings) return;

		setInitialFormData(billingSettings);
	}, [getTeamBillingSettingsStatus.done, billingSettings]);

	const validatorData = { // Data used by validators to determine which fields need validation
		paymentMethod,
		billingCountry,
	};

	const handleSave = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				validate: 'team_billingName',
				errorKey: 'billingName',
				value: billingName,
			},
			{
				validate: 'team_billingEmail',
				errorKey: 'billingEmail',
				value: billingEmail,
			},
			canChangeCurrency && {
				validate: 'team_currency',
				errorKey: 'preferredCurrency',
				value: preferredCurrency,
			},
			{
				validate: 'team_billingAddress',
				errorKey: 'billingAddress',
				value: billingAddress,
			},
			{
				validate: 'team_billingCountry',
				errorKey: 'billingCountry',
				value: billingCountry,
			},
			{
				validate: 'team_paymentMethod',
				errorKey: 'paymentMethod',
				value: paymentMethod,
			},
			{
				validate: 'team_bankName',
				errorKey: 'bankName',
				value: bankName,
			},
			{
				validate: 'team_bankAccountName',
				errorKey: 'bankAccountName',
				value: bankAccountName,
			},
			{
				validate: 'team_bankAccountNumber',
				errorKey: 'bankAccountNumber',
				value: bankAccountNumber,
			},
			{
				validate: 'team_bankBIC',
				errorKey: 'bankBIC',
				value: bankBIC,
			},
			{
				validate: 'team_vatNumber',
				errorKey: 'vatNumber',
				value: vatNumber,
			},
			{
				validate: 'team_paypalEmail',
				errorKey: 'paypalEmail',
				value: paypalEmail,
			},
		];

		checks.forEach(c => {
			if (!c) return;

			const check = validate(c.validate, c.value, validatorData);

			if (!check.valid) {
				err[c.errorKey] = check.messages;
			} else {
				delete err[c.errorKey];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(
			patchTeamBillingSettings.fetch(
				{
					teamId: team.id,
					data: {
						name: billingName,
						email: billingEmail,
						currency: canChangeCurrency ? preferredCurrency : undefined,
						address: billingAddress,
						country: billingCountry,
						payment_method: paymentMethod,
						bank_name: bankName,
						bank_account_name: bankAccountName,
						bank_account_number: bankAccountNumber,
						bank_bic: bankBIC,
						vat_number: vatNumber,
						paypal_email: paypalEmail,
					},
				},
				({ success$ }) => success$.pipe(
					map(() => getTeamByCode.fetch(team.code)),
				),
			),
		);
	};

	const fieldsDisabled = combinedApiStatus(patchTeamBillingSettingsStatus, getTeamByCodeStatus).pending;

	return (
		<GridContainer cols={1}>
			<form ref={formRef} onSubmit={handleSave}>
				<FormContainer>
					<GridContainer cols={2}>
						<div>
							<TextInput
								label={_`billingName`}
								name="billingName"
								errors={errors.billingName}
								disabled={fieldsDisabled}
								placeholder={placeholderName}
								value={billingName}
								valueSetter={setBillingName}
								required={isRequired('team_billingName', validatorData)}
							/>
							<TextInput
								label={_`billingEmail`}
								name="billingEmail"
								errors={errors.billingEmail}
								disabled={fieldsDisabled}
								placeholder={_`exampleEmail`}
								value={billingEmail}
								valueSetter={setBillingEmail}
								required={isRequired('team_billingEmail', validatorData)}
							/>
							{canChangeCurrency && (
								<SelectInput
									label={_`preferredCurrency`}
									name="preferredCurrency"
									errors={errors.preferredCurrency}
									disabled={fieldsDisabled || teamIsCurrencyLocked}
									value={preferredCurrency}
									valueSetter={setPreferredCurrency}
									required={isRequired('team_currency', validatorData)}
									desc={_`preferredCurrencyDesc`}
									values={[
										{
											value: 'eur',
											desc: _`euro`,
										},
										{
											value: 'usd',
											desc: _`usd`,
										},
									]}
								/>
							)}
							<TextAreaInput
								label={_`billingAddress`}
								name="billingAddress"
								errors={errors.billingAddress}
								disabled={fieldsDisabled}
								placeholder={_`billingAddress`}
								value={billingAddress}
								valueSetter={setBillingAddress}
								required={isRequired('team_billingAddress', validatorData)}
							/>
							<SelectInput
								label={_`billingCountry`}
								name="billingCountry"
								errors={errors.billingCountry}
								disabled={fieldsDisabled}
								placeholder={_`selectACountry`}
								values={countryValues}
								value={billingCountry}
								valueSetter={setBillingCountry}
								required={isRequired('team_billingCountry', validatorData)}
							/>
						</div>
						<div>
							<SelectInput
								label={_`paymentMethod`}
								name="paymentMethod"
								errors={errors.paymentMethod}
								disabled={fieldsDisabled}
								placeholder={_`selectPaymentMethod`}
								values={[
									{
										value: 'wire',
										desc: _`wireTransfer`,
									},
									{
										value: 'paypal',
										desc: _`paypal`,
									},
								]}
								value={paymentMethod}
								valueSetter={setPaymentMethod}
								required={isRequired('team_paymentMethod', validatorData)}
							/>
							{paymentMethod === 'wire' ? (
								<>
									<TextInput
										label={_`bankName`}
										name="bankName"
										errors={errors.bankName}
										disabled={fieldsDisabled}
										placeholder={_`bankName`}
										value={bankName}
										valueSetter={setBankName}
										required={isRequired('team_bankName', validatorData)}
									/>
									<TextInput
										label={_`bankAccountName`}
										name="bankAccountName"
										errors={errors.bankAccountName}
										disabled={fieldsDisabled}
										placeholder={_`bankAccountName`}
										value={bankAccountName}
										valueSetter={setBankAccountName}
										required={isRequired('team_bankAccountName', validatorData)}
									/>
									<TextInput
										label={_`bankAccountNumber`}
										name="bankAccountNumber"
										errors={errors.bankAccountNumber}
										disabled={fieldsDisabled}
										placeholder={_`bankAccountNumber`}
										value={bankAccountNumber}
										valueSetter={setBankAccountNumber}
										required={isRequired('team_bankAccountNumber', validatorData)}
									/>
									<TextInput
										label={_`bankBIC`}
										name="bankBIC"
										errors={errors.bankBIC}
										disabled={fieldsDisabled}
										placeholder={_`bankBIC`}
										value={bankBIC}
										valueSetter={setBankBIC}
										required={isRequired('team_bankBIC', validatorData)}
									/>
								</>
							) : paymentMethod === 'paypal' ? (
								<TextInput
									label={_`paypalEmail`}
									name="paypalEmail"
									errors={errors.paypalEmail}
									disabled={fieldsDisabled}
									placeholder={_`exampleEmail`}
									value={paypalEmail}
									valueSetter={setPaypalEmail}
									required={isRequired('team_paypalEmail', validatorData)}
								/>
							) : null}
							{paymentMethod ? (
								<TextInput
									label={_`vatNumber`}
									name="vatNumber"
									errors={errors.vatNumber}
									disabled={fieldsDisabled}
									placeholder={_`vatNumber`}
									value={vatNumber}
									valueSetter={setVatNumber}
									required={isRequired('team_vatNumber', validatorData)}
								/>
							) : null}
						</div>
					</GridContainer>
				</FormContainer>
				<ButtonTextContainer>
					<Button submit disabled={patchTeamBillingSettingsStatus.pending || !unsavedChanges} primary>{patchTeamBillingSettingsStatus.pending ? _`saving` : _`save`}</Button>
					<ButtonText warning={unsavedChanges}>
						{patchTeamBillingSettingsStatus.pending ? _`saving` : unsavedChanges ? _`unsavedChanges` : !billingSettings ? _`loading` : ''}
					</ButtonText>
				</ButtonTextContainer>
			</form>
		</GridContainer>
	);
};

export default TeamSettingsBillingSubPageLegacy;
