import React, { useRef } from 'react';
import styled from 'styled-components';
import Tooltip from 'shared/components/Tooltip';

import CheckIcon from 'shared/designTokens/icons/ui/tiny/CheckIcon';

import _ from 'shared/copy';

const InputContainer = styled.div`
	position: relative;
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin: 8px 0 16px 0;

	${props => (props.$isBox ? `
		border: 2px solid ${props.theme.grey7};
		padding: 12px;
		border-radius: 8px;
		cursor: pointer;
	` : '')}
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};
`;

const StyledCheckIcon = styled(CheckIcon)`
	display: none;

	[fill] {
		fill: ${props => props.theme.pureWhite};
	}
`;

const CheckBox = styled.div`
	position: relative;
	width: 16px;
	height: 16px;
	background: ${props => props.theme.pureWhite};
	border: 3px solid ${props => props.theme.grey5};
	box-sizing: border-box;
	border-radius: 4px;
	flex-shrink: 0;

	${props => props.$checked && `
		background: ${props.theme.pokiBlue};
		border: transparent;

		${StyledCheckIcon} {
			display: block;
		}
	`}
`;

const CheckBoxContainer = styled.div`
	display: flex;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;

	${props => props.disabled && `
	cursor: not-allowed;

	${CheckBox} {
		background: ${props.theme.grey5};
	}
	`}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const Text = styled.label`
	margin-left: 8px;
	font-weight: bold;
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const StyledInput = styled.input`
	display: none;
`;

const CheckBoxInput = props => {
	const { className, disabled, required, label, name, text, value, valueSetter, errors = [], description, isBox = false, tooltipContent } = props;

	const labelRef = useRef();
	const inputRef = useRef();

	const handleChange = evt => {
		valueSetter(evt.target.checked);
	};

	const handleClick = evt => {
		if (disabled) return;
		if (evt.target !== labelRef.current) {
			valueSetter(!value);
		}

		if (inputRef.current?.form) {
			const inputEvent = new Event('input', { bubbles: true });
			inputRef.current.form.dispatchEvent(inputEvent);
		}
	};

	const textComponent = text && <Text ref={labelRef} htmlFor={`check-${name}`} disabled={disabled}>{text}</Text>;

	const input = (
		<InputContainer onChange={handleChange}>
			<CheckBoxContainer disabled={disabled}>
				<CheckBox $checked={!!value}>
					<StyledCheckIcon />
				</CheckBox>
				<StyledInput id={`check-${name}`} type="checkbox" name={name} defaultChecked={!!value} ref={inputRef} disabled={disabled} />
				{text && (
					tooltipContent ? (
						<Tooltip
							placement="top"
							arrow
							content={tooltipContent}
						>
							{textComponent}
						</Tooltip>
					) : (
						textComponent
					)
				)}
			</CheckBoxContainer>
		</InputContainer>
	);

	return (
		<StyledFieldset className={className} onClick={handleClick} $isBox={isBox}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0}>
					{label}
					{input}
				</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					<Description>{description}</Description>
				)
			)}
		</StyledFieldset>
	);
};

export default CheckBoxInput;
