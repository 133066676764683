/* eslint-disable */
import * as React from "react";

const MonetizationIcon = (props) => {
	const { className } = props;

	return (
		<svg className={className} width={24} height={25} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" fill="none" >
			<path fill="currentColor" fill-rule="evenodd" d="M20.327 9.682c-.14-.093-.927-.613-1.827-1.213V7.362c0-.753-.373-1.46-.993-1.886a2.302 2.302 0 0 0-2.127-.26c-.6.233-1.133.5-1.466.686a8.245 8.245 0 0 0-1.654-.173c-.546 0-1.066.067-1.573.167-.427-1.654-2.08-2.714-3.774-2.354a3.234 3.234 0 0 0-2.5 3.82c.16.754.58 1.4 1.18 1.874a6.978 6.978 0 0 0-.673 1.313.575.575 0 0 1-.193-.133.717.717 0 0 1-.187-.687 1.002 1.002 0 1 0-1.947-.48c-.233.94.034 1.9.72 2.58.334.333.74.553 1.174.667-.014.153-.02.3-.02.453 0 1.287.366 2.533 1.06 3.633a3.215 3.215 0 0 0-.174 1.74 3.181 3.181 0 0 0 2.534 2.567 3.196 3.196 0 0 0 2.847-.853c.98.18 1.973.186 2.973.013a3.2 3.2 0 0 0 2.82.847 3.205 3.205 0 0 0 2.533-2.507 3.208 3.208 0 0 0-.133-1.72c.087-.127.166-.267.247-.4l1.16-.793c.593-.36.966-1.014.966-1.714V11.39c0-.7-.373-1.353-.973-1.713v.006ZM7.113 4.516a2.228 2.228 0 0 1 2.64 1.727c.12.58.007 1.18-.313 1.68a2.228 2.228 0 0 1-3.087.64c-.5-.327-.84-.827-.96-1.407a2.238 2.238 0 0 1 1.727-2.64h-.007ZM19.3 13.509c0 .167-.08.32-.22.413l-.893.6a3.43 3.43 0 0 0-.927.934l-.713 1.06.386.6c.114.186.24.486.167.88-.093.466-.48.846-.933.933a1.193 1.193 0 0 1-1.24-.527l-.393-.606-.694.18a6.325 6.325 0 0 1-3.233-.02l-.707-.187-.393.62a1.21 1.21 0 0 1-1.254.54c-.466-.08-.82-.42-.92-.887-.093-.44.047-.773.18-.96l.414-.6-.44-.573c-.667-.88-1.02-1.9-1.02-2.96 0-1.127.4-2.167 1.08-3.027h.02a3.197 3.197 0 0 0 2.707-1.46 2.98 2.98 0 0 0 .26-.5 6.39 6.39 0 0 1 1.726-.24c.514 0 1.04.067 1.567.2l.413.107.36-.22s.68-.42 1.493-.727a.284.284 0 0 1 .28.034c.06.04.127.113.127.24v1.106c0 .654.327 1.267.867 1.634.713.48 1.38.926 1.713 1.146.14.094.22.247.22.414v1.853Z" clip-rule="evenodd"/>
			<path fill="currentColor" fill-rule="evenodd" d="M10.793 9.062c-1.326.414-2.386 1.354-2.833 2.527a.663.663 0 0 0 .387.86c.08.027.16.047.24.047.266 0 .52-.167.62-.434.3-.793 1.04-1.44 1.98-1.726a.665.665 0 0 0 .44-.834.665.665 0 0 0-.834-.44Z" clip-rule="evenodd"/>
			<path fill="currentColor" d="M15.46 12.756a1.167 1.167 0 1 0 0-2.334 1.167 1.167 0 0 0 0 2.334Z"/>
			<path fill="currentColor" fill-rule="evenodd" d="m7.487 7.982.02.1c.033.167.2.274.366.24a.311.311 0 0 0 .24-.366l-.02-.087c.234-.1.42-.26.554-.46.106-.153.053-.3-.087-.393-.153-.094-.293-.067-.387.08a.715.715 0 0 1-.193.206L7.7 5.976c.093.02.173.06.253.113.134.093.26.06.367-.087.093-.146.093-.3-.053-.393a1.185 1.185 0 0 0-.687-.193l-.02-.087a.311.311 0 0 0-.367-.24.311.311 0 0 0-.24.367l.02.1c-.526.253-.806.8-.68 1.406.127.607.6 1 1.187 1.02h.007ZM7.1 6.13l.267 1.273a.732.732 0 0 1-.494-.56.719.719 0 0 1 .227-.713Z" clip-rule="evenodd"/>
		</svg>
	);
}

export default MonetizationIcon;
