import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';
import { map, merge } from 'rxjs';
import moment from 'moment';

import { useSelectUserListByTeamId } from 'app/src/selectors/team';
import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { listUsersByTeamId, removeUserFromTeam } from 'app/src/epics/team';
import { openModal, openToast } from 'app/src/actions/client';
import { createUser } from 'app/src/epics/user';
import useURLState from 'app/src/hooks/useURLState';
import useActionCounter from 'app/src/hooks/useActionCounter';
import checkPermissions from 'app/src/utils/checkPermissions';

import TrashIcon from 'shared/designTokens/icons/ui/medium/TrashIcon';

import GridContainer from 'app/src/components/ui/GridContainer';
import IconButton from 'app/src/components/ui/IconButton';
import { ToastTypes } from 'app/src/components/ui/Toast';
import UserImage from 'app/src/components/ui/UserImage';
import Button from 'app/src/components/ui/Button';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import { dayMonthYearTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const perPage = 10;

const TeamSettingsUsersSubPage = props => {
	const { team } = props;

	const dispatch = useDispatch();

	const { data: users, meta: { total: totalUsers } = {} } = useSelectUserListByTeamId(team?.id);
	const listUsersByTeamIdStatus = useSelectApiStatus(listUsersByTeamId.id);
	const permissions = useSelectPermissions();
	const user = useSelectUser();

	const [sort, setSort] = useURLState('sort', { field: 'last_seen', direction: -1 });
	const [page, setPage] = useURLState('page', 1);

	const isDeveloperSupport = user.role === 'developer-support';
	const canRemoveUser = checkPermissions(permissions, [['can_remove_user']]);

	const actionCounter = useActionCounter(
		createUser.success.type,
		removeUserFromTeam.success.type,
	);

	useEffect(() => {
		if (!team) return;

		dispatch(listUsersByTeamId.fetch({ teamId: team.id, sortField: sort.field, sortDirection: sort.direction, page, perPage }));
	}, [actionCounter, team, sort, page]);

	return (
		<GridContainer cols={1}>
			<Helmet key={`TeamSettingsUsersSubPage-${team.code}`}>
				<title>Users - {team.name} - Poki for Developers</title>
			</Helmet>
			<Card
				title={_`users`}
				noPadding
				buttons={team && (
					!isDeveloperSupport && (
						<Button onClick={() => dispatch(openModal({ key: 'create-user', data: { teamCode: team.code } }))}>
							{_`createUser`}
						</Button>
					)
				)}
			>
				<Table
					isLoading={!listUsersByTeamIdStatus.done}
					items={users}
					setSort={setSort}
					sortField={sort.field}
					sortDirection={sort.direction}
					setPagination={setPage}
					page={page}
					perPage={perPage}
					totalItems={totalUsers}
					columns={[
						{
							title: '',
							width: 'min-content',
							content: ({ item }) => <UserImage src={item.picture} />,
						},
						{
							title: _`fullName`,
							content: ({ item }) => item.name,
							sortField: 'name',
						},
						{
							title: _`email`,
							content: ({ item }) => item.email,
							sortField: 'email',
						},
						{
							title: _`role`,
							content: ({ item }) => _([item.role]),
							sortField: 'role',
						},
						{
							title: _`lastVisited`,
							width: 'max-content',
							content: ({ item }) => (item.last_seen ? moment(item.last_seen * 1000).format(dayMonthYearTimeFormat) : _`never`),
							sortField: 'last_seen',
						},
						{
							title: _`userId`,
							width: 'max-content',
							content: ({ item }) => item.id,
						},
						canRemoveUser && (
							{
								title: _`actions`,
								width: 'max-content',
								content: ({ item }) => (
									<IconButton
										negative
										title={_`remove`}
										icon={TrashIcon}
										onClick={() => dispatch(openModal({
											key: 'base-confirmation-modal',
											data: {
												title: _`removeUserConfirmationTitle`,
												onConfirm: () => dispatch(removeUserFromTeam.fetch({ teamId: team.id, userId: item.id }, ({ success$, error$ }) => (
													merge(
														success$.pipe(
															map(() => openToast({ toastType: ToastTypes.DEFAULT, body: _`removeUserSuccess` })),
														),
														error$.pipe(
															map(() => openToast({ toastType: ToastTypes.WARNING, body: _`errorPageMessage2` })),
														),
													)
												))),
											},
										}))}
									/>
								),
							}),
					]}
				/>
			</Card>
		</GridContainer>
	);
};

export default TeamSettingsUsersSubPage;
