import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { signout } from 'app/src/actions/session';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const TempAuthIssuesModal = () => {
	const dispatch = useDispatch();

	return (
		<Modal
			canExit={() => false}
			hideExitButton
		>
			<h2>{_`tempAuthIssuesTitle`}</h2>
			<p>{_`tempAuthIssuesDesc`}</p>
			<Buttons>
				<Button
					secondary
					onClick={() => {
						dispatch(signout());
					}}
				>
					{_`signout`}
				</Button>
				<Button onClick={() => window.location.reload()}>{_`refreshPage`}</Button>
			</Buttons>
		</Modal>
	);
};

registerModal('temp-auth-issues', TempAuthIssuesModal);
