import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import Table from 'app/src/components/ui/Table';
import Tooltip from 'app/src/components/ui/Tooltip';
import formatNumber from 'app/src/utils/formatNumber';
import _ from 'shared/copy';
import { dayMonthYearFormat } from 'shared/vars';
import { openModal } from 'app/src/actions/client';
import Button from 'app/src/components/ui/Button';
import { useSelectUser } from 'app/src/selectors/user';

import PencilIcon from 'shared/designTokens/icons/ui/tiny/PencilIcon';
import DocumentIcon from 'shared/designTokens/icons/ui/small/DocumentIcon';

const InvoiceNumber = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	color: ${props => props.theme.pokiBlue};

	&:hover {
		color: ${props => props.theme.pokiHover};

		[fill] {
			fill: ${props => props.theme.pokiHover};
		}
	}
`;

const NoInvoiceInternalNotes = styled.span`
	font-style: italic;
`;

const InvoiceInternalNotes = styled.div`
	white-space: pre-wrap;
	max-height: 250px;
	overflow: auto;
`;

const InvoiceInternalNotesIcon = styled(DocumentIcon)`
	position: relative;
	left: 10px;
	margin-left: -18px;

	${props => (!props.$notesAvailable ? `
	[fill] {
		fill: ${props.theme.grey5};
	}
	` : '')}
`;

const InvoicesTable = props => {
	const { isLoading, invoices, page, perPage, sortField, sortDirection, setSort, setPagination, totalItems } = props;

	const dispatch = useDispatch();
	const user = useSelectUser();

	const isAdmin = user.role === 'admin';

	return (
		<Table
			isLoading={isLoading}
			items={invoices}
			setSort={setSort}
			sortField={sortField}
			sortDirection={sortDirection}
			setPagination={setPagination}
			page={page}
			perPage={perPage}
			totalItems={totalItems}
			columns={[
				{
					title: _`invoiceNumber`,
					content: ({ item }) => (
						item.status === 'manual'
							? item.number
							: (
								<InvoiceNumber
									onClick={() => {
										dispatch(openModal({ key: 'preview-invoice', data: { invoice: item } }));
									}}
								>
									{item.number}
								</InvoiceNumber>
							)
					),
					sortField: 'number',
				},
				(isAdmin ? {
					title: '',
					mobileTitle: _`invoiceInternalNotes`,
					width: 'min-content',
					content: ({ item }) => (
						<Tooltip
							title={_`invoiceInternalNotes`}
							body={item.notes ? <InvoiceInternalNotes>{item.notes}</InvoiceInternalNotes> : <NoInvoiceInternalNotes>{_`noInvoiceInternalNotes`}</NoInvoiceInternalNotes>}
							icon={(
								<PencilIcon
									onClick={event => {
										dispatch(openModal({ key: 'edit-invoice-internal-notes', data: { invoice: item } }));
										event.stopPropagation();
									}}
								/>
							)}
						>
							<InvoiceInternalNotesIcon $notesAvailable={!!item.notes} />
						</Tooltip>
					),
					mobileContent: ({ item }) => (
						<Button
							secondary
							onClick={event => {
								dispatch(openModal({ key: 'edit-invoice-internal-notes', data: { invoice: item } }));
								event.stopPropagation();
							}}
						>
							{_`editInvoiceInternalNotes`}
						</Button>
					),
				} : null),
				{
					title: _`invoicePeriod`,
					content: ({ item }) => {
						const dateFrom = moment.utc(item.from * 1000).format(dayMonthYearFormat);
						const dateTo = moment.utc(item.to * 1000).format(dayMonthYearFormat);

						if (dateFrom === dateTo) {
							return dateFrom;
						}

						return `${dateFrom} to ${dateTo}`;
					},
					sortField: 'to',
				},
				{
					title: _`amount`,
					content: ({ item }) => (item.status === 'manual' ? (
						<Tooltip
							text={_`unavailable`}
							title={_`invoiceAdjustedTitle`}
							body={_`invoiceAdjustedBody`}
						/>
					) : <>{item.currency === 'usd' ? '$' : '€'}{formatNumber(Number(item.total / 100), { allowDecimals: true })}</>),
					sortField: 'total',
				},
			]}
		/>
	);
};

export default InvoicesTable;
