import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CloseIcon from 'shared/designTokens/icons/ui/medium/CloseIcon';
import MenuIcon from 'shared/designTokens/icons/ui/medium/MenuIcon';
import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';

import { useSelectPermissions } from 'app/src/selectors/user';
import { navigationWidth, isMobile, maxWidth, headerHeight } from 'shared/vars';
import Link from 'app/src/components/Link';
import PokiLogo from 'shared/components/svg/PokiLogo';
import { useSelectNavigationIsActive } from 'app/src/selectors/client';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { toggleNavigation, openModal } from 'app/src/actions/client';
import checkPermissions from 'app/src/utils/checkPermissions';

const StyledPokiLogo = styled(PokiLogo)`
	width: auto;
	height: 24px;
	vertical-align: bottom;
`;

const StyledLink = styled(Link)`
	display: block;
`;

const ToggleMenu = styled.div`
	display: flex;
	align-items: center;
	margin-right: 16px;

	[fill] {
		fill: ${props => props.theme.grey3};
	}
`;

const Container = styled.header`
	flex-shrink: 0;
	left: ${navigationWidth}px;
	width: calc(100% - ${navigationWidth}px);
	height: ${headerHeight};
	background: ${props => props.theme.pureWhite};
	z-index: 2;
	position: fixed;
	border-bottom: 1px solid ${props => props.theme.grey7};
	display: flex;
	justify-content: center;

	${isMobile && `
	left: 0;
	width: 100%;
	`}
`;

const InnerContainer = styled.div`
	display: flex;
	flex-grow: 1;
	margin: 0 48px;
	align-items: center;
	max-width: ${maxWidth};

	${isMobile && `
	margin: 0 24px 0 8px;
	`}
`;

const SearchButton = styled.div`
	display: flex;
	width: 24px;
	height: 24px;
	margin-left: auto;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	svg [fill] {
		fill: ${props => props.theme.grey5};
	}

	&:hover {
		svg [fill] {
			fill: ${props => props.theme.pokiBlue};
		}
	}
`;

const Header = () => {
	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();

	const handleToggleNavigation = () => {
		dispatch(toggleNavigation());
	};

	const navigationIsActive = useSelectNavigationIsActive();

	return (
		<Container>
			<InnerContainer>
				{isMobile && (
					<>
						<ToggleMenu onClick={handleToggleNavigation}>
							{navigationIsActive ? <CloseIcon /> : <MenuIcon />}
						</ToggleMenu>
						<StyledLink to={`/${activeTeam.code}`}><StyledPokiLogo /></StyledLink>
					</>
				)}
				{checkPermissions(permissions, [['can_search_all']]) && (
					<SearchButton onClick={() => dispatch(openModal({ key: 'search' }))}>
						<SearchIcon />
					</SearchButton>
				)}
			</InnerContainer>
		</Container>
	);
};

export default Header;
