import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { ignoreElements, map, merge, tap } from 'rxjs';
import styled from 'styled-components';

import { getCountryByCode } from 'app/src/utils/countries';
import { getWiseDetails } from 'app/src/epics/team';
import flattenObject from 'app/src/utils/flattenObject';
import formatNumber from 'app/src/utils/formatNumber';

import TableLoader from 'app/src/components/ui/TableLoader';
import CopyToClipboard from 'app/src/components/CopyToClipboard';

const InvoiceDetailsWrapper = styled.div`
	padding: 32px 0;
`;

const InvoiceDetailsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;

	padding: 0 16px;
	text-wrap: nowrap;
`;

const InvoiceDetailsCopyToClipboardWrapper = styled.div`
	display: flex;
	align-items: left;
	flex-direction: column;

	width: 50%;
	gap: 8px;
	font-size: 14px;
`;

const InvoiceDetailsTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
	svg {
		[fill] {
			fill: ${props => props.theme.grey3};
		}
	}
`;

const InvoiceDetailsTitle = styled.span`
	color: ${props => props.theme.grey3};
	font-weight: 700;

	&:after {
		content: '-';
		margin: 0 4px 0 8px;
	}
`;

const InvoiceDetailsNotes = styled.div`
	margin-bottom: 32px;
	padding-bottom: 32px;
	border-bottom: 2px solid ${props => props.theme.grey7};
`;

const InvoiceDetailsNotesContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 0 16px;
`;

const InvoiceDetailsNotesLabel = styled.span`
	color: ${props => props.theme.grey3};
	font-weight: 700;
`;

const InvoiceDetailsLoader = styled.div`
	height: 180px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const InvoiceDetailsErrorMessage = styled.div`
	height: 180px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${props => props.theme.rose1};
	font-weight: 700;
`;

const InvoiceDetailsCopyToClipboard = ({ title, value, valueToCopy }) => {
	if (!valueToCopy) {
		valueToCopy = value;
	}

	return (
		value ? (
			<InvoiceDetailsCopyToClipboardWrapper>
				<InvoiceDetailsTitleWrapper>
					<InvoiceDetailsTitle>{title}</InvoiceDetailsTitle>
					<span>{value}</span>
					<StyledCopyToClipboard text={valueToCopy} />
				</InvoiceDetailsTitleWrapper>
			</InvoiceDetailsCopyToClipboardWrapper>
		) : null
	);
};

const InvoiceDetails = ({ invoice }) => {
	const { team_id: teamId, payment_method: paymentMethod } = invoice;

	const dispatch = useDispatch();

	const [invoiceDetails, setInvoiceDetails] = useState({});
	const [error, setError] = useState(null);

	const countryName = getCountryByCode(invoiceDetails.country)?.name;
	const getWiseDetailsStatus = useSelectApiStatus(getWiseDetails.id);

	useEffect(() => {
		dispatch(getWiseDetails.fetch({ teamId }, ({ success$, error$ }) => merge(
			success$.pipe(
				map(({ payload: { result: { response } } }) => {
					setInvoiceDetails(flattenObject(response));
				}),
			),
			error$.pipe(
				tap(({ payload: { result: { response: { errors: [err] } } } }) => {
					setError(err);
				}),
				ignoreElements(),
			),
		)));
	}, [dispatch]);

	return (
		getWiseDetailsStatus.done === false ? (
			<InvoiceDetailsLoader>
				<TableLoader />
			</InvoiceDetailsLoader>
		) : (
			error ? (
				<InvoiceDetailsErrorMessage>{error.title}</InvoiceDetailsErrorMessage>
			) : (
				<InvoiceDetailsWrapper>
					{invoiceDetails.publicNotes && (
						<InvoiceDetailsNotes>
							<InvoiceDetailsNotesContent>
								<InvoiceDetailsNotesLabel>Notes</InvoiceDetailsNotesLabel>
								<div>{invoiceDetails.publicNotes}</div>
							</InvoiceDetailsNotesContent>
						</InvoiceDetailsNotes>
					)}
					<InvoiceDetailsContainer>
						<InvoiceDetailsCopyToClipboard
							title="Billing Name"
							value={invoiceDetails.accountHolderName}
						/>
						<InvoiceDetailsCopyToClipboard
							title="Amount"
							value={`${formatNumber(Number(invoice.total / 100), { allowDecimals: true })} ${invoice.currency.toUpperCase()}`}
							valueToCopy={formatNumber(Number(invoice.total / 100), { allowDecimals: true }).replace(/,/g, '')}
						/>
						<InvoiceDetailsCopyToClipboard
							title="Country"
							value={countryName}
						/>
						{invoice.number && (
							<InvoiceDetailsCopyToClipboard
								title="Invoice Number"
								value={invoice.number}
							/>
						)}
						{paymentMethod === 'paypal' && (
							<InvoiceDetailsCopyToClipboard
								title="Paypal Email"
								value={invoiceDetails.email}
							/>
						)}
						{(paymentMethod === 'wise' || paymentMethod === 'wire') && (
							<>
								<InvoiceDetailsCopyToClipboard
									title="Address"
									value={invoiceDetails.firstLine}
								/>
								<InvoiceDetailsCopyToClipboard
									title="Post Code"
									value={invoiceDetails.postCode}
								/>
								<InvoiceDetailsCopyToClipboard
									title="City"
									value={invoiceDetails.city}
								/>
								<InvoiceDetailsCopyToClipboard
									title="Bank Account Number"
									value={invoiceDetails.iban || invoiceDetails.accountNumber}
								/>
								<InvoiceDetailsCopyToClipboard
									title="Bank Code"
									value={invoiceDetails.bic || invoiceDetails.swiftCode}
								/>
								{invoiceDetails.vatNumber && (
									<InvoiceDetailsCopyToClipboard
										title="VAT Number"
										value={invoiceDetails.vatNumber}
									/>
								)}
							</>
						)}
					</InvoiceDetailsContainer>
				</InvoiceDetailsWrapper>
			)
		)
	);
};

export default InvoiceDetails;
