import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Subject, ignoreElements, merge, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import styled from 'styled-components';

import { registerModal } from 'app/src/modals';
import { addTeamNewRevenueShares, getTeamNewRevenueShares } from 'app/src/epics/team';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';

import LockIcon from 'shared/designTokens/icons/ui/small/LockIcon';
import ReplayIcon from 'shared/designTokens/icons/ui/small/ReplayIcon';

import GridContainer from 'app/src/components/ui/GridContainer';
import ToggleInput from 'app/src/components/input/ToggleInput';
import SelectInput from 'app/src/components/input/SelectInput';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';

import { STANDARD_REVENUE_SHARE } from 'shared/vars';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const StyledGridContainer = styled(GridContainer)`
	& > div {
		align-items: flex-end;
	}
`;

const ChangeRevenueShareContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const ChangeRevenuShareHeader = styled.div`
	display: flex;
	gap: 16px;
	padding: 32px 40px;
	margin: 0 -40px;
	border-bottom: 1px solid ${props => props.theme.grey7};
	border-top: 1px solid ${props => props.theme.grey7};
`;

const ChangeRevenueShareGameTitle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
`;

const ChangeRevenueShareThumbnail = styled.div`
	position: relative;
	border-radius: 8px;
	background: ${props => (props.thumbnailUrl ? `url("${props.thumbnailUrl}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 46px;
	width: 46px;
`;

const StyledToggleInput = styled(ToggleInput)`
	label {
		color: ${props => props.theme.denimBlue};
		font-size: 16px;
	}
`;

const ChangeRevenueShareLock = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;
	gap: 8px;

	color: ${props => props.theme.pokiBlue};
	font-weight: 700;
	cursor: pointer;
`;

const getHighlight = values => {
	const fieldsToHighlightTemp = {};
	let isCustom = false;

	Object.keys(values).forEach(key => {
		if (Number(values[key]) !== Number(STANDARD_REVENUE_SHARE[key])) {
			fieldsToHighlightTemp[key] = true;
			isCustom = true;
		}
	});

	return { fieldsToHighlightTemp, isCustom };
};

// Determines shares
const determineShares = shares => {
	const result = Array.from({ length: 21 }, (_v, i) => ({
		value: i * 5,
		desc: `${i * 5}%`,
	}));

	// Add custom values to the array if not already present
	Object.keys(shares).forEach(val => {
		if (!result.some(entry => entry.value === shares[val])) {
			result.push({ value: shares[val], desc: `${shares[val]}%` });
		}
	});

	return result.sort((a, b) => a.value - b.value);
};

const ChangeRevenueShareModal = ({ data: { game } }) => {
	const { title, thumbnail_url, revenue_share: { attributes, category } } = game;

	const dispatch = useDispatch();

	const hasAttributes = Object.keys(attributes).length > 0 && category !== 'disabled';
	const shareValues = determineShares(attributes);

	const [exit$] = useState(new Subject());
	const [enableRevenuSharing, setEnableRevenueSharing] = useState(hasAttributes);
	const [enableCustomSettings, setEnableCustomSettings] = useState(false);
	const [isFormReset, setIsFormReset] = useState(false);
	const [fieldsToHighlight, setFieldsToHighlight] = useState({});
	const [isRevenueShareCustom, setIsRevenueShareCustom] = useState(category === 'custom');

	const { pending } = useSelectApiStatus(addTeamNewRevenueShares.id);

	const { handleSubmit, control, watch, setValue, formState: { isDirty } } = useForm({
		defaultValues: {
			ingame_poki_promotion: attributes.ingame_poki_promotion || STANDARD_REVENUE_SHARE.ingame_poki_promotion,
			ingame_organic_landing: attributes.ingame_organic_landing || STANDARD_REVENUE_SHARE.ingame_organic_landing,
			developer_domain: attributes.developer_domain || STANDARD_REVENUE_SHARE.developer_domain,
			external: attributes.external || STANDARD_REVENUE_SHARE.external,
			poki_platform_display: attributes.poki_platform_display || STANDARD_REVENUE_SHARE.poki_platform_display,
		},
	});

	const handleWatchedValues = () => {
		const watchAll = watch(values => {
			const { fieldsToHighlightTemp, isCustom } = getHighlight(values);

			setFieldsToHighlight(fieldsToHighlightTemp);
			setIsRevenueShareCustom(isCustom);
		});

		return () => watchAll.unsubscribe();
	};

	// get highlight on first render of modal
	useEffect(() => {
		if (!hasAttributes) {
			return;
		}

		const { fieldsToHighlightTemp, isCustom } = getHighlight(attributes);

		setFieldsToHighlight(fieldsToHighlightTemp);
		setIsRevenueShareCustom(isCustom);
	}, []);

	useEffect(() => {
		// set fields to highlight based on the watched values if values are diffenrent from the standard values
		handleWatchedValues();
	}, [watch]);

	const handleUnlockCustomSettings = () => {
		setEnableCustomSettings(true);
	};

	const handleResetCustomSettings = () => {
		const { ingame_poki_promotion, ingame_organic_landing, developer_domain, external, poki_platform_display } = STANDARD_REVENUE_SHARE;

		setValue('ingame_poki_promotion', ingame_poki_promotion);
		setValue('ingame_organic_landing', ingame_organic_landing);
		setValue('developer_domain', developer_domain);
		setValue('external', external);
		setValue('poki_platform_display', poki_platform_display);

		setEnableCustomSettings(false);
		setIsRevenueShareCustom(false);
		setIsFormReset(true);
	};

	const onSubmit = data => {
		let shares = data;

		if (!enableRevenuSharing) {
			shares = {
				ingame_poki_promotion: 0,
				ingame_organic_landing: 0,
				developer_domain: 0,
				external: 0,
				poki_platform_display: 0,
			};
		}

		// convert all values to numbers to make sure they are sent correctly
		Object.keys(shares).forEach(key => {
			shares[key] = Number(shares[key]);
		});

		dispatch(addTeamNewRevenueShares.fetch({ teamId: game.team.id, gameId: game.id, shares }, ({ success$ }) => merge(
			success$.pipe(
				tap(() => {
					dispatch(getTeamNewRevenueShares.fetch({ teamId: game.team.id }));
					exit$.next();
				}),
				ignoreElements(),
			),
		)));
	};

	return (
		<Modal
			exit$={exit$}
		>
			<h2>{_`changeRevenueShare`}</h2>
			<ChangeRevenueShareContent>
				<ChangeRevenuShareHeader>
					<ChangeRevenueShareThumbnail thumbnailUrl={getGameThumbnailUrl(thumbnail_url, 46)} />
					<ChangeRevenueShareGameTitle>
						{title}
						<StyledToggleInput checked={enableRevenuSharing} onChange={setEnableRevenueSharing} label="Enable revenue sharing" />
					</ChangeRevenueShareGameTitle>
				</ChangeRevenuShareHeader>
				{enableRevenuSharing && (
					<>
						<p>This shows how much of the game’s revenue you are sharing with the game developer, per traffic source or ad type.</p>
						{isRevenueShareCustom || enableCustomSettings ? (
							<ChangeRevenueShareLock onClick={handleResetCustomSettings}>
								<ReplayIcon /> Reset to default settings
							</ChangeRevenueShareLock>
						) : (
							<ChangeRevenueShareLock onClick={handleUnlockCustomSettings}>
								<LockIcon /> Unlock custom settings
							</ChangeRevenueShareLock>
						)}
					</>
				)}
				<form onSubmit={handleSubmit(onSubmit)}>
					{enableRevenuSharing && (
						<>
							<StyledGridContainer cols={3}>
								<Controller
									control={control}
									name="ingame_poki_promotion"
									render={({ field }) => (
										<SelectInput
											{...field}
											label={_`ingamePokiPromotion`}
											disabled={!isRevenueShareCustom && !enableCustomSettings}
											values={shareValues}
											isHighlighted={fieldsToHighlight.ingame_poki_promotion}
										/>
									)}
								/>
								<Controller
									control={control}
									name="ingame_organic_landing"
									render={({ field }) => (
										<SelectInput
											{...field}
											label={_`ingameOrganicLanding`}
											disabled={!isRevenueShareCustom && !enableCustomSettings}
											values={shareValues}
											isHighlighted={fieldsToHighlight.ingame_organic_landing}
										/>
									)}
								/>
								<Controller
									control={control}
									name="developer_domain"
									render={({ field }) => (
										<SelectInput
											{...field}
											label={_`developerDomain`}
											disabled={!isRevenueShareCustom && !enableCustomSettings}
											values={shareValues}
											isHighlighted={fieldsToHighlight.developer_domain}
										/>
									)}
								/>
							</StyledGridContainer>
							<StyledGridContainer cols={3}>
								<Controller
									control={control}
									name="external"
									render={({ field }) => (
										<SelectInput
											{...field}
											label={_`external`}
											disabled={!isRevenueShareCustom && !enableCustomSettings}
											values={shareValues}
											isHighlighted={fieldsToHighlight.external}
										/>
									)}
								/>
								<Controller
									control={control}
									name="poki_platform_display"
									render={({ field }) => (
										<SelectInput
											{...field}
											label={_`pokiPlatformDisplay`}
											disabled={!isRevenueShareCustom && !enableCustomSettings}
											values={shareValues}
											isHighlighted={fieldsToHighlight.poki_platform_display}
										/>
									)}
								/>
							</StyledGridContainer>
						</>
					)}
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={(!isFormReset && !isDirty && enableRevenuSharing && hasAttributes) || pending}>{pending ? _`saving` : _`saveChanges`}</Button>
					</Buttons>
				</form>
			</ChangeRevenueShareContent>
		</Modal>
	);
};

registerModal('change-revenue-share', ChangeRevenueShareModal);
