import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ignoreElements, tap } from 'rxjs';
import styled from 'styled-components';

import { addTeamRevenueShares, listUsersByTeamId, getTeamRevenueShares, getTeamBillingSettings, setTeamRevenueSharesToZero } from 'app/src/epics/team';
import { useSelectRevenueSharesByTeamId, useSelectActiveTeam, useSelectTeamHasBillingSettings } from 'app/src/selectors/team';
import { getShare, isThisShare, sharesContainNonZeroShares } from 'app/src/utils/revenueShares';
import { useSelectGamesByTeamId } from 'app/src/selectors/game';
import { useSelectPermissions } from 'app/src/selectors/user';
import { listGamesByTeamId } from 'app/src/epics/game';
import { openModal } from 'app/src/actions/client';
import useTrackUnsavedChanges from 'app/src/hooks/useTrackUnsavedChanges';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import useActionCounter from 'app/src/hooks/useActionCounter';
import checkPermissions from 'app/src/utils/checkPermissions';

import Button, { ButtonText, ButtonTextContainer } from 'app/src/components/ui/Button';
import MessageBox, { MessageBoxIntents } from 'app/src/components/ui/MessageBox';
import GridContainer from 'app/src/components/ui/GridContainer';
import ToggleInput from 'app/src/components/input/ToggleInput';
import SelectInput from 'app/src/components/input/SelectInput';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import _ from 'shared/copy';

const TeamRevenueShares = styled(GridContainer)`
	margin-top: 24px;
`;

const Thumbnail = styled.div`
	position: relative;
	border-radius: 8px;
	background: ${props => (props.thumbnailUrl ? `url("${props.thumbnailUrl}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 46px;
	width: 46px;
`;

// Determines shares
const determineShares = shares => {
	// Default shares
	const result = Array(11).fill(1).map((__, idx) => {
		const share = idx * 10;

		return {
			value: share,
			desc: `${share}%`,
		};
	});

	result.push({ value: 33, desc: '33%' });
	result.push({ value: 66, desc: '66%' });

	// Check if all given shares are represented, otherwise add them
	shares.forEach(share => {
		if (!share) return;
		if (!result.find(s => Number(s.value) === Number(share))) {
			result.push({
				value: share,
				desc: `${share}%`,
			});
		}
	});

	return result.sort((a, b) => a.value - b.value);
};

const TeamSettingsRevenueSharingSubPageLegacy = props => {
	const { team } = props;

	const dispatch = useDispatch();
	const location = useLocation();
	const formRef = useRef();

	const { unsavedChanges, resetFormData } = useTrackUnsavedChanges(formRef);

	const currentRevenueShares = useSelectRevenueSharesByTeamId(team.id);

	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();
	const { data: games = [] } = useSelectGamesByTeamId(activeTeam?.id);
	const teamHasBillingSettings = useSelectTeamHasBillingSettings(activeTeam);
	const getTeamBillingSettingsStatus = useSelectApiStatus(getTeamBillingSettings.id);

	const [revenueShares, setRevenueShares] = useState(null);

	const getTeamRevenueSharesStatus = useSelectApiStatus(getTeamRevenueShares.id);
	const listGamesByTeamIdStatus = useSelectApiStatus(listGamesByTeamId.id);
	const addTeamRevenueSharesStatus = useSelectApiStatus(addTeamRevenueShares.id);

	const showRevenueShares = sharesContainNonZeroShares(revenueShares) || sharesContainNonZeroShares(currentRevenueShares);
	const apiStatus = combinedApiStatus(getTeamRevenueSharesStatus, listGamesByTeamIdStatus);
	const shareValues = determineShares(revenueShares ? revenueShares.map(s => s.developer_share) : []);

	const canEditAllTeams = checkPermissions(permissions, [['can_edit_all_teams']]);

	const actionCounter = useActionCounter(
		setTeamRevenueSharesToZero.success.type,
		addTeamRevenueShares.success.type,
	);

	const setShare = useCallback(options => value => {
		const shares = [...revenueShares];
		const existingShareIdx = shares.findIndex(share => isThisShare(share, options));
		const currentShareIdx = currentRevenueShares.findIndex(share => isThisShare(share, options));
		let addNewShare = true;

		// '' is the placeholder value which should be converted to null.
		// 0 is also a valid value, so we can't just check for falsy values.
		const newValue = value === '' ? null : Number(value);

		if (existingShareIdx !== -1) {
			shares.splice(existingShareIdx, 1);

			if (currentShareIdx !== -1) {
				const currentShare = currentRevenueShares[currentShareIdx];

				if (currentShare.developer_share === newValue) {
					// If the newly selected value is exactly the same as the already existing value,
					// we just add the already existing share back to the list.
					shares.splice(currentShareIdx, 0, currentShare);
					addNewShare = false;
				}
			} else if (newValue === null) {
				// If we remove a share, and it didn't exist yet, we don't need to add a new one.
				addNewShare = false;
			}
		}

		if (addNewShare) {
			const { gameId, teamId, source, playedOn, specialCondition } = options;

			shares.push({
				start_on: Date.now() / 1000,
				developer_share: newValue,
				played_on: playedOn,
				trigger_source: source,
				special_condition: specialCondition,
				game_id: gameId,
				team_id: teamId,
			});
		}

		setRevenueShares(shares);
	}, [revenueShares]);

	const getPlaceholder = (source, playedOn, specialCondition, canReset) => {
		if (!canReset) {
			const currentRevenuShare = getShare({ teamId: activeTeam.id, source, playedOn, specialCondition }, currentRevenueShares);
			if (currentRevenuShare) {
				return undefined;
			}

			return _`noRevShare`;
		}

		const revenueShare = getShare({ teamId: activeTeam.id, source, playedOn, specialCondition }, revenueShares);

		if (typeof revenueShare === 'number') {
			return `${revenueShare}% (from team)`;
		}

		return _`noRevShare`;
	};

	useEffect(() => {
		dispatch(listUsersByTeamId.fetch({ teamId: team.id }));
	}, [team, location]);

	useEffect(() => {
		if (apiStatus.done) {
			// We store the revenue shares in a local state so we can edit them
			setRevenueShares(currentRevenueShares);
		}
	}, [apiStatus.done]);

	useEffect(() => {
		if (revenueShares !== null) {
			requestAnimationFrame(() => resetFormData());
		}
	}, [revenueShares === null]);

	useEffect(() => {
		dispatch(getTeamRevenueShares.fetch({ teamId: team.id }));
		dispatch(listGamesByTeamId.fetch({ teamId: team.id }));
		dispatch(getTeamBillingSettings.fetch({ teamId: team.id }));
	}, [actionCounter, team?.id]);

	const handleSubmit = e => {
		e.preventDefault();

		// Only shares without an ID are new
		const newShares = revenueShares.filter(share => !share.id);

		dispatch(addTeamRevenueShares.fetch({ teamId: team.id, shares: newShares }, ({ success$ }) => (
			success$.pipe(
				// Re-fetch revenue shares
				tap(() => resetFormData()),
				ignoreElements(),
			)
		)));
	};

	return (
		<GridContainer cols={1}>
			<Helmet key={`TeamSettingsRevenueSharingSubPage-${team.code}`}>
				<title>Revenue Sharing - {team.name} - Poki for Developers</title>
			</Helmet>
			{getTeamBillingSettingsStatus.done && !teamHasBillingSettings && (
				<MessageBox
					title={_`billingSettingsRequired`}
					unclosable
					intent={MessageBoxIntents.NEGATIVE}
					description={(
						<span dangerouslySetInnerHTML={{ __html: _`billingSettingsRequiredDesc` }} />
					)}
				/>
			)}
			<Card
				title={_`revenueSharing`}
				description={_`revenueSharingDesc`}
			>
				<h3>{_`teamRevenueSharingSettings`}</h3>
				<form
					onSubmit={revenueShares !== null ? handleSubmit : undefined}
					ref={formRef}
				>
					{revenueShares !== null ? (
						<>
							{canEditAllTeams && (
								<ToggleInput
									label={_`enableRevenueSharing`}
									disabled={!teamHasBillingSettings}
									checked={showRevenueShares}
									checkedSetter={checked => {
										if (checked) {
											// Upon toggling this checkbox, set the default revenue shares
											setShare({ teamId: activeTeam.id, source: 'game', playedOn: 'poki', specialCondition: 'none' })(50);
										} else if (!sharesContainNonZeroShares(currentRevenueShares)) {
											// You didn't have shares to begin with, go right ahead
											setRevenueShares([]);
										} else {
											// Confirm removal
											dispatch(openModal({ key: 'confirm-remove-revenue-share', data: { team: activeTeam } }));
										}
									}}
								/>
							)}

							{showRevenueShares && (
								<>
									<TeamRevenueShares cols={3}>
										<SelectInput
											name="ingameShare"
											label={_`ingameTriggeredAds`}
											value={getShare({ teamId: activeTeam.id, source: 'game', playedOn: 'poki', specialCondition: 'none' }, revenueShares)}
											valueSetter={setShare({ teamId: activeTeam.id, source: 'game', playedOn: 'poki', specialCondition: 'none' })}
											description={_`ingameTriggeredAdsDesc`}
											placeholder={getPlaceholder('game', 'poki', 'none', false)}
											values={shareValues}
											disabled={!canEditAllTeams}
										/>
										<SelectInput
											name="platformShare"
											label={_`platformTriggeredAds`}
											value={getShare({ teamId: activeTeam.id, source: 'playground', playedOn: 'poki', specialCondition: 'none' }, revenueShares)}
											valueSetter={setShare({ teamId: activeTeam.id, source: 'playground', playedOn: 'poki', specialCondition: 'none' })}
											description={_`platformTriggeredAdsDesc`}
											placeholder={getPlaceholder('playground', 'poki', 'none', false)}
											values={shareValues}
											disabled={!canEditAllTeams}
										/>
										<SelectInput
											name="externalShare"
											label={_`externalAds`}
											value={getShare({ teamId: activeTeam.id, source: 'game', playedOn: 'external', specialCondition: 'none' }, revenueShares)}
											valueSetter={setShare({ teamId: activeTeam.id, source: 'game', playedOn: 'external', specialCondition: 'none' })}
											description={_`externalAdsDesc`}
											placeholder={getPlaceholder('game', 'external', 'none', false)}
											values={shareValues}
											disabled={!canEditAllTeams}
										/>
										<SelectInput
											name="crosspromoShare"
											label={_`crosspromoAds`}
											value={getShare({ teamId: activeTeam.id, source: 'game', playedOn: 'poki', specialCondition: 'crosspromo' }, revenueShares)}
											valueSetter={setShare({ teamId: activeTeam.id, source: 'game', playedOn: 'poki', specialCondition: 'crosspromo' })}
											description={_`crosspromoAdsDesc`}
											placeholder={getPlaceholder('game', 'poki', 'crosspromo', false)}
											values={shareValues}
											disabled={!canEditAllTeams}
										/>
										<SelectInput
											name="landingShare"
											label={_`landingAds`}
											value={getShare({ teamId: activeTeam.id, source: 'game', playedOn: 'poki', specialCondition: 'landing' }, revenueShares)}
											valueSetter={setShare({ teamId: activeTeam.id, source: 'game', playedOn: 'poki', specialCondition: 'landing' })}
											description={_`landingAdsDesc`}
											placeholder={getPlaceholder('game', 'poki', 'landing', false)}
											values={shareValues}
											disabled={!canEditAllTeams}
										/>
									</TeamRevenueShares>

									<h3>{_`advancedRevenueShareSettings`}</h3>
									<p>{_`advancedRevenueShareSettingsDesc`}</p>

									<Card
										title={_`gameRevenueShareSettings`}
										noPadding
									>
										<Table
											items={games}
											columns={[
												{
													title: '',
													width: 'min-content',
													content: ({ item }) => <Thumbnail thumbnailUrl={getGameThumbnailUrl(item.thumbnail_url, 46)} />,
													hideOnMobile: true,
												},
												{
													title: _`gameTitle`,
													content: ({ item }) => item.title,
													sortValue: item => item.title.toLowerCase(),
												},
												{
													title: _`ingameTriggeredAdsShort`,
													width: '150px',
													content: ({ item }) => (
														<SelectInput
															name={`gameIngameShare${item.id}`}
															small
															value={getShare({ gameId: item.id, source: 'game', playedOn: 'poki', specialCondition: 'none' }, revenueShares)}
															valueSetter={setShare({ gameId: item.id, source: 'game', playedOn: 'poki', specialCondition: 'none' })}
															placeholder={getPlaceholder('game', 'poki', 'none', true)}
															values={shareValues}
															disabled={!canEditAllTeams}
														/>
													),
												},
												{
													title: _`platformTriggeredAdsShort`,
													width: '150px',
													content: ({ item }) => (
														<SelectInput
															name={`gamePlatformShare${item.id}`}
															small
															value={getShare({ gameId: item.id, source: 'playground', playedOn: 'poki', specialCondition: 'none' }, revenueShares)}
															valueSetter={setShare({ gameId: item.id, source: 'playground', playedOn: 'poki', specialCondition: 'none' })}
															placeholder={getPlaceholder('playground', 'poki', 'none', true)}
															values={shareValues}
															disabled={!canEditAllTeams}
														/>
													),
												},
												{
													title: _`externalAdsShort`,
													width: '150px',
													content: ({ item }) => (
														<SelectInput
															name={`gameExternalShare${item.id}`}
															small
															value={getShare({ gameId: item.id, source: 'game', playedOn: 'external', specialCondition: 'none' }, revenueShares)}
															valueSetter={setShare({ gameId: item.id, source: 'game', playedOn: 'external', specialCondition: 'none' })}
															placeholder={getPlaceholder('game', 'external', 'none', true)}
															values={shareValues}
															disabled={!canEditAllTeams}
														/>
													),
												},
												{
													title: _`crosspromoAdsShort`,
													width: '150px',
													content: ({ item }) => (
														<SelectInput
															name={`gameCrosspromoShare${item.id}`}
															small
															value={getShare({ gameId: item.id, source: 'game', playedOn: 'poki', specialCondition: 'crosspromo' }, revenueShares)}
															valueSetter={setShare({ gameId: item.id, source: 'game', playedOn: 'poki', specialCondition: 'crosspromo' })}
															placeholder={getPlaceholder('game', 'poki', 'crosspromo', true)}
															values={shareValues}
															disabled={!canEditAllTeams}
														/>
													),
												},
												{
													title: _`landingAdsShort`,
													width: '180px',
													content: ({ item }) => (
														<SelectInput
															name={`gameLandingShare${item.id}`}
															small
															value={getShare({ gameId: item.id, source: 'game', playedOn: 'poki', specialCondition: 'landing' }, revenueShares)}
															valueSetter={setShare({ gameId: item.id, source: 'game', playedOn: 'poki', specialCondition: 'landing' })}
															placeholder={getPlaceholder('game', 'poki', 'landing', true)}
															values={shareValues}
															disabled={!canEditAllTeams}
														/>
													),
												},
											]}
										/>
									</Card>
									{canEditAllTeams && (
										<ButtonTextContainer>
											<Button
												disabled={!unsavedChanges || addTeamRevenueSharesStatus.pending}
												submit
											>
												{_`save`}
											</Button>
											<ButtonText warning={unsavedChanges}>
												{addTeamRevenueSharesStatus.pending ? _`saving` : unsavedChanges ? _`unsavedChanges` : ''}
											</ButtonText>
										</ButtonTextContainer>
									)}
								</>
							)}
						</>
					) : _`loading`}
				</form>
			</Card>
		</GridContainer>
	);
};

export default TeamSettingsRevenueSharingSubPageLegacy;
