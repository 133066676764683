import React from 'react';

import { useSelectTheme } from 'app/src/selectors/session';

const PaidIcon = props => {
	const { className, active, disabled } = props;
	const theme = useSelectTheme();

	return (
		<svg className={className} width="36" height="36" xmlns="http://www.w3.org/2000/svg" fill={active ? theme.green1 : theme.pureWhite}>
			<g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
				<path d="M6 18c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12S6 24.627 6 18h0z" stroke={active ? theme.green1 : disabled ? theme.grey7 : theme.grey3} />
				<path d="M22.364 22.364a5.454 5.454 0 110-8.727M12.545 15.818h6.546M12.545 19.091h6.546" stroke={active ? theme.pureWhite : disabled ? theme.grey7 : theme.grey3} />
			</g>
		</svg>
	);
};

export default PaidIcon;
