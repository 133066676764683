import React from 'react';

const Block1 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M47.9196 124.24L35.2796 108.44L33.7996 97L23.3996 99.08L9.59961 90.4L30.1596 26.72L91.4396 10L130.64 55.04L105.2 106.28L64.4796 129.64L47.9196 124.24Z" fill="#DCA7F2" />
			<path d="M64.4395 129.679L105.199 106.279L96.5594 64.1992L79.1594 77.4792L64.4395 129.679Z" fill="#D490F2" />
			<path d="M130.6 55.04L96.5602 64.24L79.1602 77.52L30.1602 26.72L69.0802 39.68L91.4402 10L130.6 55.04Z" fill="#E0AEF5" />
			<path d="M30.1602 26.72L69.0802 39.68L91.4402 10L30.1602 26.72Z" fill="#EBCCFF" />
			<path d="M79.1599 77.4805L47.9199 124.24L64.4399 129.68L79.1599 77.4805Z" fill="#E1B5F7" />
			<path d="M30.1602 26.7188L44.0802 77.3988L35.2402 108.439L47.8802 124.239L79.1202 77.5188L30.1602 26.7188Z" fill="#DA9EF3" />
			<path d="M23.3596 99.0787L9.59961 90.3987L30.1596 26.7188L44.0796 77.3987L23.3596 99.0787Z" fill="#D490F2" />
			<path d="M105.2 106.28L130.64 55L96.5996 64.2L105.2 106.28Z" fill="#C977E8" />
			<path d="M33.8008 96.9984L44.1208 77.3984L35.2808 108.438L33.8008 96.9984Z" fill="#E1B5F7" />
			<path d="M23.3594 99.0794L44.0794 77.3594L33.7594 96.9594L23.3594 99.0794Z" fill="#C977E8" />
		</svg>
	);
};

export default Block1;
