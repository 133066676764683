import React from 'react';

const Diamond3 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M52.5992 26.2384L86.3992 12.3984L112.439 70.3184L27.1992 127.398L29.3592 35.7584L52.5992 26.2384Z" fill="#17F1D8" />
			<path d="M85.4406 72.5584L46.6406 49.3984L86.4006 12.3984L85.4406 72.5584Z" fill="#18E9DA" />
			<path d="M66.8392 89.9194L46.6392 49.3594L27.1992 127.399L66.8392 89.9194Z" fill="#17D7D8" />
			<path d="M46.6406 49.3594L69.6006 73.5994L66.8406 89.9194L46.6406 49.3594Z" fill="#18E9DA" />
			<path d="M85.4406 72.5594L69.6006 73.5994L46.6406 49.3594L85.4406 72.5594Z" fill="#4DFFE1" />
			<path d="M85.4398 72.5586L69.5998 73.5986L66.8398 89.9186L85.4398 72.5586Z" fill="#17F1D8" />
			<path d="M85.4392 72.5603L66.8392 89.9203L27.1992 127.4L112.479 70.3203L85.4392 72.5603Z" fill="#15C8D1" />
			<path d="M29.3594 35.7584L46.6394 49.3584L86.3994 12.3984L29.3594 35.7584Z" fill="#4DFFE1" />
			<path d="M86.3996 12.3984L52.5996 26.2384L67.0396 30.3984L86.3996 12.3984Z" fill="#A4FFEE" />
		</svg>
	);
};

export default Diamond3;
