export const deleteKeysWithEmptyValuesFromObject = object => {
	const obj = { ...object };

	if (obj) {
		for (const field in obj) {
			if (typeof obj[field] === 'object' && obj[field] !== null) {
				obj[field] = deleteKeysWithEmptyValuesFromObject(obj[field]);
			} else if (obj[field] === undefined || obj[field] === null || obj[field] === '' || (typeof obj[field] === 'object' && Object.keys(obj[field]).length === 0)) {
				delete obj[field];
			}
		}
	}

	return obj;
};
