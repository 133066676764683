const measureTotalDisplayMonetization = data => (
	data.map(({ ...row }) => {
		const totalDisplayImpressions = row.platform_display_impressions + row.ingame_display_impressions + row.gamebar_display_impressions;
		const totalDisplayEarnings = row.platform_display_developer_earnings + row.ingame_display_developer_earnings + row.gamebar_display_developer_earnings;

		row.display_ads_per_dau = totalDisplayImpressions / row.daily_active_users;
		row.display_earnings = totalDisplayEarnings;

		return row;
	})
);

export default measureTotalDisplayMonetization;
