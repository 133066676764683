import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getEarliestGameplaysForGameId, getMonetizationForGameId, getTablesLastUpdatedAt, getTotalMonetizationForGameId, getTotalMonetizationPrevPeriodForGameId } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { ChartDetails, ChartDetailsInner, DataAvailableFrom, DetailNumber } from 'app/src/components/ChartSubComponents';
import { useSelectTeamCurrency } from 'app/src/selectors/team';
import { useSelectTheme } from 'app/src/selectors/session';
import measureAverageAdsPerDailyActiveUser from 'app/src/measures/measureAverageAdsPerDailyActiveUser';
import measureTotalVideoMonetization from 'app/src/measures/measureTotalVideoMonetization';
import measureTotalDisplayMonetization from 'app/src/measures/measureTotalDisplayMonetization';
import getTimeSince from 'app/src/utils/getTimeSince';
import formatNumber from 'app/src/utils/formatNumber';
import usePivot from 'app/src/hooks/usePivot';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';
import ExclamationIcon from 'shared/designTokens/icons/ui/tiny/ExclamationIcon';

import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import Chart from 'app/src/components/ui/charts/Chart';
import InfoPoint from 'app/src/components/ui/InfoPoint';

import { dayMonthYearFormat } from 'shared/vars';
import _ from 'shared/copy';

const monetizationDataAvailableFromMoment = moment('2020-10-15');

const GameMonetizationChartModule = props => {
	const { game, startDate, endDate, deviceCategories, countries, contexts } = props;

	const dispatch = useDispatch();
	const theme = useSelectTheme();
	const teamCurrency = useSelectTeamCurrency(game?.team);

	useEffect(() => {
		if (!game) return;

		const options = {
			gameId: game.id,
			teamId: game.team_id,
			from: startDate,
			to: endDate,
			currency: teamCurrency,
			deviceCategories,
			countries,
			contexts,
		};

		dispatch(getTablesLastUpdatedAt.fetch());
		dispatch(getEarliestGameplaysForGameId.fetch(options));

		dispatch(getMonetizationForGameId.fetch(options));
		dispatch(getTotalMonetizationForGameId.fetch(options));
		dispatch(getTotalMonetizationPrevPeriodForGameId.fetch(options));
	}, [game?.id, startDate, endDate, deviceCategories, countries, contexts]);

	const getMonetizationForGameIdStatus = useSelectApiStatus(getMonetizationForGameId.id);
	const getTotalMonetizationForGameIdStatus = useSelectApiStatus(getTotalMonetizationForGameId.id);
	const getTotalMonetizationPrevPeriodForGameIdStatus = useSelectApiStatus(getTotalMonetizationPrevPeriodForGameId.id);

	const apiStatus = combinedApiStatus(getMonetizationForGameIdStatus, getTotalMonetizationForGameIdStatus, getTotalMonetizationPrevPeriodForGameIdStatus);

	const monetizationForGame = usePivot(
		measureAverageAdsPerDailyActiveUser(useSelectDataByEpic(getMonetizationForGameId.id).rows),
		row => {
			const { avg_ingame_display_impressions_per_dau, avg_gamebar_display_impressions_per_dau, avg_platform_display_impressions_per_dau, avg_preroll_video_impressions_per_dau, avg_midroll_video_impressions_per_dau, avg_rewarded_video_impressions_per_dau, ...remaining } = row;

			return [
				{
					...remaining,
					ads: avg_ingame_display_impressions_per_dau,
					type: 'ingame_display',
				},
				{
					...remaining,
					ads: avg_gamebar_display_impressions_per_dau,
					type: 'gamebar_display',
				},
				{
					...remaining,
					ads: avg_platform_display_impressions_per_dau,
					type: 'platform_display',
				},
				{
					...remaining,
					ads: avg_preroll_video_impressions_per_dau,
					type: 'preroll_video',
				},
				{
					...remaining,
					ads: avg_midroll_video_impressions_per_dau,
					type: 'midroll_video',
				},
				{
					...remaining,
					ads: avg_rewarded_video_impressions_per_dau,
					type: 'rewarded_video',
				},
			].filter(r => Math.round(r.ads * 100) > 0); // Make sure we don't show ad types that we have no impressions on
		},
	);
	const [totalMonetizationForGame] = measureAverageAdsPerDailyActiveUser(useSelectDataByEpic(getTotalMonetizationForGameId.id).rows);
	const [totalMonetizationPrevPeriodForGame] = measureAverageAdsPerDailyActiveUser(useSelectDataByEpic(getTotalMonetizationPrevPeriodForGameId.id).rows);
	const [totalVideoMonetization] = measureTotalVideoMonetization(useSelectDataByEpic(getTotalMonetizationForGameId.id).rows);
	const [totalDisplayMonetization] = measureTotalDisplayMonetization(useSelectDataByEpic(getTotalMonetizationForGameId.id).rows);
	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_monetization');

	const earliestGameplayDataMoment = moment(useSelectDataByEpic(getEarliestGameplaysForGameId.id)?.rows[0] || Date.now());

	return (
		<Card
			title={_`monetization`}
			buttons={(
				<InfoPoint
					disabled={!lastUpdatedAt}
					warning={monetizationDataAvailableFromMoment.isAfter(earliestGameplayDataMoment)}
					icon={GraphIcon}
				>
					{_`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`}
					{monetizationDataAvailableFromMoment.isAfter(earliestGameplayDataMoment) && (
						<DataAvailableFrom>
							<ExclamationIcon /> {_`dataOnlyAvailableFromX${{ availableFrom: monetizationDataAvailableFromMoment.format(dayMonthYearFormat) }}`}
						</DataAvailableFrom>
					)}
				</InfoPoint>
			)}
		>
			{apiStatus.done && (
				<Stat
					value={totalMonetizationForGame?.avg_total_ads_per_daily_active_user || 0}
					valueDesc={_`adsPerDAU`}
					previous={totalMonetizationPrevPeriodForGame?.avg_total_ads_per_daily_active_user || 0}
					previousDesc={_`fromPreviousPeriod`}
					isSmallNumber
				/>
			)}
			<Chart
				type="bar"
				legend
				stacked
				apiStatus={apiStatus}
				data={monetizationForGame}
				getGroupedBy={d => d.type}
				getGroupName={d => {
					if (d.type === 'ingame_display') {
						return _`ingameDisplay`;
					} else if (d.type === 'gamebar_display') {
						return _`gamebarDisplay`;
					} else if (d.type === 'platform_display') {
						return _`platformDisplay`;
					} else if (d.type === 'preroll_video') {
						return _`prerollVideo`;
					} else if (d.type === 'midroll_video') {
						return _`midrollVideo`;
					} else if (d.type === 'rewarded_video') {
						return _`rewardedVideo`;
					}

					return _`unknown`;
				}}
				keyOrder={['rewarded_video', 'midroll_video', 'preroll_video', 'ingame_display', 'gamebar_display', 'platform_display']}
				getGroupColor={v => {
					if (v === 'rewarded_video') {
						return theme.dataYellow5;
					} else if (v === 'midroll_video') {
						return theme.dataYellow3;
					} else if (v === 'preroll_video') {
						return theme.dataYellow1;
					} else if (v === 'ingame_display') {
						return theme.dataOrange3;
					} else if (v === 'gamebar_display') {
						return theme.dataOrange5;
					} else if (v === 'platform_display') {
						return theme.dataOrange1;
					}
				}}
				xRange={[startDate, endDate]}
				xAxis={{
					type: 'date',
					displayName: _`date`,
					field: 'date',
				}}
				yAxis={{
					type: 'smallNumber',
					displayName: _`adsPerDAU`,
					field: 'ads',
				}}
			/>
			<ChartDetails>
				{apiStatus.done && totalDisplayMonetization && (
					<ChartDetailsInner>
						{
							(totalDisplayMonetization.display_ads_per_dau > 0 || totalDisplayMonetization.display_earnings > 0) && (Math.round(totalDisplayMonetization.display_ads_per_dau * 100) >= 1 || Math.round(totalDisplayMonetization.display_earning * 100) >= 1)
								? (
									_`monetizationWhatDoesThisMeanVideoAndDisplay${{
										videoAdRatio: <DetailNumber $color={theme.dataYellow3}>{formatNumber(totalVideoMonetization.video_ads_per_dau, { allowDecimals: true })}</DetailNumber>,
										videoAdEarnings: <DetailNumber $light>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalVideoMonetization.video_earnings, { allowDecimals: true })}</DetailNumber>,
										displayAdRatio: <DetailNumber $color={theme.dataOrange5}>{formatNumber(totalDisplayMonetization.display_ads_per_dau, { allowDecimals: true })}</DetailNumber>,
										displayAdEarnings: <DetailNumber $light>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalDisplayMonetization.display_earnings, { allowDecimals: true })}</DetailNumber>,
										totalEarnings: <DetailNumber>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalVideoMonetization.video_earnings + totalDisplayMonetization.display_earnings, { allowDecimals: true })}</DetailNumber>,
									}}`
								) : (
									_`monetizationWhatDoesThisMeanVideoOnly${{
										videoAdRatio: <DetailNumber $color={theme.dataYellow3}>{formatNumber(totalVideoMonetization.video_ads_per_dau, { allowDecimals: true })}</DetailNumber>,
										videoAdEarnings: <DetailNumber $light>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalVideoMonetization.video_earnings, { allowDecimals: true })}</DetailNumber>,
									}}`
								)
						}
					</ChartDetailsInner>
				)}
			</ChartDetails>
		</Card>
	);
};

export default GameMonetizationChartModule;
