import React, { useState } from 'react';
import styled from 'styled-components';
import { Subject } from 'rxjs';

import { registerModal } from 'app/src/modals';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const BaseConfirmationModal = props => {
	const { data: { title, description, onConfirm } } = props;

	const [exit$] = useState(new Subject());

	const handleConfirm = () => {
		onConfirm();
		exit$.next();
	};

	return (
		<Modal
			exit$={exit$}
		>
			{title && <h2>{title}</h2>}
			{description && <p>{description}</p>}
			<Buttons>
				<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
				<Button submit disabled={false} onClick={handleConfirm}>{_`confirm`}</Button>
			</Buttons>
		</Modal>
	);
};

registerModal('base-confirmation-modal', BaseConfirmationModal);
