import React from 'react';

const Heart1 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M85.68 43.9216L91.88 43.7216L130 52.1616L127.8 66.0416L120.12 94.0416L80.92 126.482L10 111.162L18.24 54.7216L32.24 33.8816L84.32 13.6016L85.68 43.9216Z" fill="#FFA4BA" />
			<path d="M64.041 88.6398L79.401 95.0798L91.881 63.8398L64.561 67.7198L64.041 88.6398Z" fill="#FF99B1" />
			<path d="M84.321 13.6016L85.681 43.9216L65.041 44.6016L84.321 13.6016Z" fill="#FFC9DD" />
			<path d="M84.3209 13.6016L58.8809 31.5216L65.0409 44.6416L84.3209 13.6016Z" fill="#FFA9BE" />
			<path d="M58.8792 31.5216L32.1992 33.8816L84.3192 13.6016L58.8792 31.5216Z" fill="#FF99B1" />
			<path d="M91.8398 63.8002L120.12 94.0402L127.8 66.0402L130 52.1602L101.4 62.4402L91.8398 63.8002Z" fill="#FFAEC5" />
			<path d="M120.16 94.0398L80.9604 126.48L79.4004 95.0798L91.8804 63.8398L120.16 94.0398Z" fill="#FFA4BA" />
			<path d="M10 111.158L80.92 126.478L79.36 95.0781L10 111.158Z" fill="#FF8AA6" />
			<path d="M58.879 31.5195L31.679 47.0395L30.959 74.7995L65.079 44.5995L58.879 31.5195Z" fill="#FF99B1" />
			<path d="M32.2402 33.8795L18.2402 54.7195L58.9202 31.5195L32.2402 33.8795Z" fill="#FF8AA6" />
			<path d="M31.68 47.0391L18.2 54.7191L10 111.159L31.68 47.0391Z" fill="#FF7690" />
			<path d="M30.96 74.7991L79.4 95.0391L10 111.159L31.68 47.0391L30.96 74.7991Z" fill="#FFA9BE" />
			<path d="M65.039 44.6406L63.999 88.6406L30.959 74.8006L65.039 44.6406Z" fill="#FFA4BA" />
			<path d="M65.0395 44.6417L91.8795 43.7617L130 52.1617L101.4 62.4417L64.5195 67.6417L65.0395 44.6417Z" fill="#FFA9BE" />
		</svg>
	);
};

export default Heart1;
