import { useMemo } from 'react';

/**
 * usePivot
 */

export default function usePivot(data, transform) {
	return useMemo(() => {
		const output = [];

		data.forEach(row => {
			output.push(...transform(row));
		});

		return output;
	}, [data, transform]);
}
