import preventNaNAndInfinity from 'app/src/utils/preventNaNAndInfinity';

const measureAverageAdsPerDailyActiveUser = data => {
	const fields = [
		'ingame_display_impressions',
		'gamebar_display_impressions',
		'platform_display_impressions',
		'preroll_video_impressions',
		'midroll_video_impressions',
		'rewarded_video_impressions',
	];

	return data.map(({ ...row }) => {
		let total = 0;

		fields.forEach(field => {
			row[`avg_${field}_per_dau`] = preventNaNAndInfinity(row[field] / row.daily_active_users);
			total += row[field];
		});

		row.avg_total_ads_per_daily_active_user = preventNaNAndInfinity(total / row.daily_active_users);

		return row;
	});
};

export default measureAverageAdsPerDailyActiveUser;
