import React, { useState } from 'react';
import styled from 'styled-components';
import { Subject } from 'rxjs';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const ConfirmSetPublicVersionNoRevShareModal = props => {
	const { data: { onConfirm } } = props;

	const [exit$] = useState(new Subject());

	return (
		<Modal
			exit$={exit$}
		>
			<h2>{_`confirmSetPublicVersionNoRevShareTitle`}</h2>
			{_`confirmSetPublicVersionNoRevShareDesc`}
			<Buttons>
				<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
				<Button
					onClick={() => {
						onConfirm();
						exit$.next();
					}}
				>
					{_`setActive`}
				</Button>
			</Buttons>
		</Modal>
	);
};

registerModal('confirm-set-public-version-no-rev-share', ConfirmSetPublicVersionNoRevShareModal);
