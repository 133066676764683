import React from 'react';
import { Helmet } from 'react-helmet';

import GridContainer from 'app/src/components/ui/GridContainer';
import RecordedPlaytests from 'app/src/components/playtest/RecordedPlaytests';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';

const GamePlaytestSubPage = props => {
	const { game } = props;

	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();

	return (
		<>
			<Helmet key={`GamePlaytestSubPage-${game.id}-${activeTeam.code}`}>
				<title>Playtest - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				{checkPermissions(permissions, [['can_read_all_playtest_recordings', 'can_read_owned_playtest_recordings']]) && (
					<RecordedPlaytests game={game} />
				)}
			</GridContainer>
		</>
	);
};

export default GamePlaytestSubPage;
