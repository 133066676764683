export function screenSpaceToSVGSpace(svg, point) {
	const svgPoint = svg.createSVGPoint();

	svgPoint.x = point.x;
	svgPoint.y = point.y;

	return svgPoint.matrixTransform(svg.getScreenCTM().inverse());
}

export function svgSpaceToScreenSpace(svg, point) {
	const svgPoint = svg.createSVGPoint();

	svgPoint.x = point.x;
	svgPoint.y = point.y;

	return svgPoint.matrixTransform(svg.getScreenCTM());
}
