import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createGlobalStyle, css, keyframes } from 'styled-components';

import { retrieveUserDetails } from 'app/src/epics/user';
import { useSelectIsAuthenticated } from 'app/src/selectors/session';
import { useSelectUser } from 'app/src/selectors/user';
import { useSelectIsInScreenShake } from 'app/src/selectors/effects';
import { useSelectModal } from 'app/src/selectors/client';
import { getModal } from 'app/src/modals';
import ThemeProvider from 'app/src/utils/ThemeProvider';

import Routes from 'app/src/components/Routes';
import ErrorBoundary from 'app/src/components/ErrorBoundary';

const shakeAnimation = keyframes`
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
`;

const RootCSS = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

	html, body {
		width: 100%;
		height: 100%;
		margin: 0;
		min-width: 320px;
		line-height: 24px;
		background: ${props => props.theme.pureWhite};
		-webkit-font-smoothing: antialiased;
	}

	html, body, input, button, textarea {
		font-family: 'Proxima Nova', Arial;
		color: ${props => props.theme.denimBlue};
	}

	h2 {
		margin: 0 0 24px;
		font-size: 24px;
		line-height: 24px;
	}

	button {
		border: 0;
	}

	#app-root {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}

	a {
		color: ${props => props.theme.pokiBlue};
		text-decoration: none;
		cursor: pointer;

		&:hover {
			color: ${props => props.theme.pokiHover};
		}

		&:active {
			color: ${props => props.theme.denimBlue};
		}
	}

	/* Tooltips */
	[title] {
		position: relative;

		::before {
			content: attr(title);
			font-size: 12px;
			font-weight: bold;
			background: ${props => props.theme.denimBlue};
			color: ${props => props.theme.pureWhite};
			padding: 2px 16px 0;
			border-radius: 6px;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, calc(-100% - 8px));
			white-space: nowrap;
			display: none;
			line-height: 24px;
		}

		::after {
			content: '';
			border: 4px solid transparent;
			border-top-color: ${props => props.theme.denimBlue};
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, calc(-50% - 4px));
			display: none;
		}

		&:hover,
		&:focus {
			::before, ::after {
				display: block;
			}
		}
	}

	${props => props.screenShake && css`
	form {
		animation: ${shakeAnimation} 0.5s cubic-bezier(.36,.07,.19,.97) infinite;
	}
	`}

	img.emoji {
		height: 1em;
		width: 1em;
		margin: 0 .05em 0 .1em;
		vertical-align: -0.1em;
	}
`;

const Root = () => {
	const dispatch = useDispatch();
	const user = useSelectUser();
	const isAuthenticated = useSelectIsAuthenticated();
	const screenShake = useSelectIsInScreenShake();
	const modal = useSelectModal();

	const Modal = modal && getModal(modal.key);

	useEffect(() => {
		// prevent drag firing 0,0 events
		document.addEventListener('dragover', event => {
			event.preventDefault();
		}, false);
	}, []);

	useEffect(() => {
		// Retrieve user data if we're logged in
		if (!user && isAuthenticated) {
			dispatch(retrieveUserDetails.fetch());
		}
	}, [user, isAuthenticated]);

	return (
		<ThemeProvider>
			<RootCSS screenShake={screenShake} />
			<ErrorBoundary>
				<Routes />
				{Modal && <Modal data={modal.data} />}
			</ErrorBoundary>
		</ThemeProvider>
	);
};

export default Root;
