export default function loadStylesheet(src) {
	return new Promise(resolve => {
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = src;
		link.onload = resolve;

		document.head.appendChild(link);
	});
}
