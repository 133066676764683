import { useSelector } from 'react-redux';

export const selectPendingInvoicesList = state => (state.invoice.pendingInvoices ? state.invoice.pendingInvoices : {});
export const selectAllPendingInvoices = state => (state.invoice.allPendingInvoices ? state.invoice.allPendingInvoices : {});
export const selectInvoicesForTeam = (teamId, state) => (state.invoice.invoicesForTeam[teamId] ? state.invoice.invoicesForTeam[teamId] : {});
export const selectInvoicesForYearMonth = (state, { year, month }) => (state.invoice.invoicesForYearMonth[`${year}-${month}`] ? state.invoice.invoicesForYearMonth[`${year}-${month}`] : {});
export const selectAllInvoicesForYearMonth = (state, { year, month }) => (state.invoice.allInvoicesForYearMonth[`${year}-${month}`] ? state.invoice.allInvoicesForYearMonth[`${year}-${month}`] : {});

export const useSelectPendingInvoicesList = () => useSelector(selectPendingInvoicesList);
export const useSelectAllPendingInvoices = () => useSelector(selectAllPendingInvoices);
export const useSelectInvoicesForTeam = teamId => useSelector(state => selectInvoicesForTeam(teamId, state));
export const useSelectInvoicesForYearMonth = ({ year, month }) => useSelector(state => selectInvoicesForYearMonth(state, { year, month }));
export const useSelectAllInvoicesForYearMonth = ({ year, month }) => useSelector(state => selectAllInvoicesForYearMonth(state, { year, month }));
