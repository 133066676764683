import { useState } from 'react';
import { ofType } from 'redux-observable';
import { tap, ignoreElements } from 'rxjs';
import useEpic from 'app/src/hooks/useEpic';

/**
 * Counter that increments as the given actions are fired.
 * Useful for refreshing side effects upon firing actions
 */

const useActionCounter = (...actions) => {
	const [counter, setCounter] = useState(0);

	useEpic(action$ => action$.pipe(
		ofType(...actions),
		tap(() => setCounter(count => count + 1)),
		ignoreElements(),
	), []);

	return counter;
};

export default useActionCounter;
