import React from 'react';

import { useSelectTheme } from 'app/src/selectors/session';

const HandleManuallyIcon = props => {
	const { className, active, disabled } = props;
	const theme = useSelectTheme();

	return (
		<svg className={className} width="36" height="36" xmlns="http://www.w3.org/2000/svg" fill={active ? theme.grey3 : theme.pureWhite}>
			<g strokeWidth="2">
				<path d="M18 6c6.627 0 12 5.373 12 12s-5.373 12-12 12S6 24.627 6 18 11.373 6 18 6h0z" strokeLinecap="round" strokeLinejoin="round" stroke={active ? theme.grey3 : disabled ? theme.grey7 : theme.grey3} />
				<path strokeLinecap="round" strokeLinejoin="round" d="M12 24l.6-3 9-9 2.4 2.4-9 9z" stroke={active ? theme.pureWhite : disabled ? theme.grey7 : theme.grey3} />
				<path strokeLinecap="square" d="M20 15l1 1" stroke={active ? theme.pureWhite : disabled ? theme.grey7 : theme.grey3} />
			</g>
		</svg>
	);
};

export default HandleManuallyIcon;
