import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { useSelectPermissions } from 'app/src/selectors/user';
import { getCurrentMonth, getCurrentYear, getIsFirstWeekOfMonth } from 'app/src/utils/dates';
import checkPermissions from 'app/src/utils/checkPermissions';

import GridContainer from 'app/src/components/ui/GridContainer';
import ToggleInput from 'app/src/components/input/ToggleInput';
import MessageBox, { MessageBoxIntents } from 'app/src/components/ui/MessageBox';
import Card from 'app/src/components/ui/Card';
import TeamSettingsBillingSubPageLegacy from 'app/src/components/pages/settings/TeamSettingsBillingSubPageLegacy';
import TeamSettingsBillingSubPage from 'app/src/components/pages/settings/TeamSettingsBillingSubPage';

import _ from 'shared/copy';

const StyledToggleInput = styled(ToggleInput)`
	position: absolute;
	right: 20px;
	top: 20px;
`;

const TeamSettingsBillingPageContainer = props => {
	const { team } = props;

	const [isLegacyChecked, setIsLegacyChecked] = useState(false);
	const [hasInvoices, setHasInvoices] = useState(false);

	const permissions = useSelectPermissions();
	const canUseBothOldAndNewBillingPage = checkPermissions(permissions, [['can_edit_all_teams'], ['can_use_both_old_and_new_billing_page']]);

	const handleIsChecked = () => setIsLegacyChecked(!isLegacyChecked);

	const handleHasInvoices = value => {
		setHasInvoices(value);
	};

	return (
		<GridContainer cols={1}>
			<Helmet key={`TeamSettingsBillingSubPage-${team.code}`}>
				<title>Billing - {team.name} - Poki for Developers</title>
			</Helmet>
			{hasInvoices && getIsFirstWeekOfMonth() && (
				<MessageBox
					unclosable
					intent={MessageBoxIntents.NEGATIVE}
					title={_`paymentCycleInProgress`}
					description={(
						<span dangerouslySetInnerHTML={{ __html: _`paymentCycleInProgressText${{ currentMonth: getCurrentMonth(), currentYear: getCurrentYear() }}` }} />
					)}
				/>
			)}
			<Card
				title={_`billingInformation`}
				description={_`billingInformationDesc`}
			>
				{canUseBothOldAndNewBillingPage ? (
					<>
						<StyledToggleInput label="Legacy" checked={isLegacyChecked} onChange={handleIsChecked} />
						{isLegacyChecked ? <TeamSettingsBillingSubPageLegacy team={team} /> : <TeamSettingsBillingSubPage team={team} handleHasInvoices={handleHasInvoices} />}
					</>
				) : (
					<TeamSettingsBillingSubPage team={team} handleHasInvoices={handleHasInvoices} />
				)}
			</Card>
		</GridContainer>
	);
};

export default TeamSettingsBillingPageContainer;
