import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { setVideoJS, setVideoSeekId } from 'app/src/actions/playtest';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const VideoJSContainer = styled.div`
	width: 100%;
`;

export const VideoJS = props => {
	const dispatch = useDispatch();
	const videoRef = useRef(null);
	const playerRef = useRef(null);
	const { options, onReady } = props;

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = document.createElement('video-js');

			videoElement.classList.add('video-js');
			videoElement.setAttribute('webkit-playsinline', true);
			videoElement.setAttribute('playsinline', true);
			videoElement.setAttribute('controls', true);

			videoRef.current.appendChild(videoElement);

			const player = videojs(videoElement, options, () => {
				onReady?.(player);
			});

			player.on('seeking', () => {
				dispatch(setVideoSeekId());
			});

			playerRef.current = player;
		} else {
			const player = playerRef.current;

			player.autoplay(options.autoplay);
			player.src(options.sources);
		}

		dispatch(setVideoJS({ payload: playerRef.current }));
	}, [options, videoRef]);

	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;

				dispatch(setVideoJS({ payload: null }));
			}
		};
	}, [playerRef]);

	return (
		<VideoJSContainer data-vjs-player>
			<div ref={videoRef} />
		</VideoJSContainer>
	);
};

export default VideoJS;
