import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject } from 'rxjs';

import SignoutAnimation from 'shared/components/svg/SignoutAnimation';
import { signout } from 'app/src/actions/session';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const StyledModal = styled(Modal)`
	width: 400px;
	text-align: center;
`;

const StyledSignoutAnimation = styled(SignoutAnimation)`
	width: 300px;
	height: 300px;
	margin: auto;
`;

const SignOutModal = () => {
	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());

	return (
		<StyledModal
			exit$={exit$}
		>
			<h2>{_`signoutTitle`}</h2>
			<StyledSignoutAnimation />
			<Buttons>
				<Button secondary onClick={() => exit$.next()}>{_`signoutCancel`}</Button>
				<Button
					onClick={() => dispatch(signout())}
				>
					{_`signoutConfirm`}
				</Button>
			</Buttons>
		</StyledModal>
	);
};

registerModal('signout', SignOutModal);
