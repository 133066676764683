import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { of, Subject, map, switchMap, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import history from 'app/history';
import { createGame, listGamesByTeamId } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import getRandomGameTitle from 'app/src/utils/getRandomGameTitle';
import Modal from 'app/src/components/ui/Modal';
import { validate } from 'app/src/utils/validate';
import { registerModal } from 'app/src/modals';
import { useSelectPermissions } from 'app/src/selectors/user';
import SelectInput from 'app/src/components/input/SelectInput';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import dataFormatter from 'app/src/utils/dataFormatter';
import checkPermissions from 'app/src/utils/checkPermissions';
import _ from 'shared/copy';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const CreateGameModal = () => {
	const dispatch = useDispatch();

	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const createGameStatus = useSelectApiStatus(createGame.id);
	const [exit$] = useState(new Subject());
	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();

	const [description] = useState(_`gameTitleSuggestions${{ titles: [getRandomGameTitle(), getRandomGameTitle(), getRandomGameTitle()] }}`);

	const [gameTitle, setGameTitle] = useState('');
	const [teamId, setTeamId] = useState(activeTeam.id);

	const handleSubmit = e => {
		e.preventDefault();

		const err = { ...errors };

		const check = validate('game_title', gameTitle);
		if (!check.valid) {
			err.title = check.messages;
		} else {
			delete err.title;
		}

		setErrors(err);

		if (Object.values(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(
			createGame.fetch(
				{ team_id: teamId, title: gameTitle, approved: true },
				({ success$ }) => success$.pipe(
					tap(() => {
						exit$.next();
					}),
					map(({ payload: { result: { response } } }) => dataFormatter.deserialize(response)),
					switchMap(({ id: gameId, team: { code: teamCode } }) => {
						history.push(`/${teamCode}/games/${gameId}/versions`);

						return of(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
					}),
				),
			),
		);
	};

	return (
		<Modal exit$={exit$}>
			<>
				<h2>{_`createGame`}</h2>
				<Form ref={formRef} onSubmit={handleSubmit}>
					{checkPermissions(permissions, [['can_read_all_teams']]) && (
						<SelectInput
							label={_`team`}
							disabled
							name="team"
							placeholder={_`team`}
							values={[{
								value: activeTeam.id,
								desc: activeTeam.name,
							}]}
							value={teamId}
							valueSetter={setTeamId}
							errors={errors.team}
						/>
					)}
					<TextInput
						label={_`gameTitle`}
						autoFocus
						name="title"
						placeholder={_`gameTitle`}
						description={description}
						errors={errors.title}
						value={gameTitle}
						valueSetter={setGameTitle}
					/>
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={createGameStatus.pending}>{createGameStatus.pending ? _`creating` : _`create`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('create-game', CreateGameModal);
