import React from 'react';

import Container from 'app/src/components/ui/Container';

const AdminPaymentsPage = props => {
	const { subComponent: SubComponent } = props;

	return (
		<Container>
			<SubComponent />
		</Container>
	);
};

export default AdminPaymentsPage;
