import React from 'react';

const Heart3 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path fillRule="evenodd" clipRule="evenodd" d="M130 63.8016L109.36 45.7616L96.48 42.6816L80.8 43.8416L63.64 17.0416L34.28 13.6016L18.6 27.7616L10 46.0016L21.32 97.8416L40.12 126.482L77.6 118.802L122.4 94.6416L130 63.8016Z" fill="#FFA4BA" />
			<path d="M80.7598 43.8006L96.4398 42.6406L96.5198 64.6406L80.7598 43.8006Z" fill="#FF8AA6" />
			<path d="M49.9205 43.8398L83.8405 47.8798L96.5605 64.7198L68.7605 86.9198L49.4805 80.7598L49.9205 43.8398Z" fill="#FFA9BE" />
			<path d="M96.4805 42.6797L109.36 45.7597L96.5605 64.6797L96.4805 42.6797Z" fill="#FF99B1" />
			<path d="M49.9199 43.8391L83.8399 47.8791L80.7999 43.8391L63.6399 17.0391L49.9199 43.8391Z" fill="#FF99B1" />
			<path d="M34.2802 13.6016L63.6402 17.0416L49.9202 43.8416L28.1602 28.8816L34.2802 13.6016Z" fill="#FFA9BE" />
			<path d="M10 46.0016L28.12 28.8816L34.28 13.6016L18.6 27.7616L10 46.0016Z" fill="#FFA4BA" />
			<path d="M109.361 45.7617L130.001 63.8017L121.401 78.8817L96.5605 64.6817L109.361 45.7617Z" fill="#FFAEC5" />
			<path d="M122.36 94.6408L121.4 78.8808L130 63.8008L122.36 94.6408Z" fill="#FFC9DD" />
			<path d="M68.7598 86.8797L77.5598 118.8L96.5598 64.6797L68.7598 86.8797Z" fill="#FF99B1" />
			<path d="M77.5605 118.8L122.361 94.6397L121.401 78.8797L96.5605 64.6797L77.5605 118.8Z" fill="#FFA9BE" />
			<path d="M77.5601 118.799L40.0801 126.479L49.4401 80.7188L68.7201 86.8787L77.5601 118.799Z" fill="#FF8AA6" />
			<path d="M10 45.9989L21.32 97.8389L28.12 28.8789L10 45.9989Z" fill="#FF8AA6" />
			<path d="M40.1203 126.479L21.3203 97.8388L34.4003 86.6388L49.4403 80.7188L40.1203 126.479Z" fill="#FF99B1" />
			<path d="M21.3203 97.8389L34.4003 86.6389L49.4403 80.7189L49.8803 43.7989L28.1603 28.8789L21.3203 97.8389Z" fill="#FFA4BA" />
		</svg>
	);
};

export default Heart3;
