import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import history from 'app/history';
import Button from 'app/src/components/ui/Button';
import { setGameChecklistStatus } from 'app/src/actions/session';
import DiamondIcon from 'shared/designTokens/icons/ui/medium/DiamondIcon';
import _ from 'shared/copy';
import { _small } from 'shared/vars';

const Container = styled.div`
	background: ${props => props.theme.grey7};
	display: flex;
	width: 100%;
	max-width: 444px;
	padding: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
`;

const UpperContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 16px;
	align-self: stretch;

	${_small} {
		flex-direction: row;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;

	${_small} {
		order: 1;
	}
`;

const StyledHeader = styled.h4`
	font: 700 16px/20px "Proxima Nova";
	margin: 0;
`;

const Buttons = styled.div`
	display: flex;
	gap: 16px;
`;

const StyledDiamondIcon = styled(DiamondIcon)`
	width: 64px;
	height: 64px;

	${_small} {
		width: 108px;
		height: 108px;
		order: 2;
	}
`;

const Level3Step1 = ({ onSubmitCallback, disabled, game }) => {
	const dispatch = useDispatch();

	const thumbnailUrl = game.thumbnail_url || '';

	const onUploadThumbnailClick = () => {
		history.push(`/${game.team.code}/games/${game.id}/settings`);
	};

	const onGotItCoveredClick = () => {
		dispatch(setGameChecklistStatus({ gameId: game.id, level: 3, step: 1, status: 'completed' }));
		onSubmitCallback();
	};

	return (
		<Container>
			<UpperContainer>
				<StyledDiamondIcon />
				<LeftContainer>
					<StyledHeader>
						{_`uploadAttractiveThumbnail`}
					</StyledHeader>
					{_`greatThumbnail`}
				</LeftContainer>
			</UpperContainer>

			<Buttons>
				<Button onClick={onUploadThumbnailClick} disabled={disabled}>{_`uploadThumbnail`}</Button>
				{!disabled && thumbnailUrl && <Button onClick={onGotItCoveredClick} secondary disabled={disabled}>{_`haveItCovered`}</Button>}
			</Buttons>
		</Container>
	);
};

export default Level3Step1;
