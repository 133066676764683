import styled from 'styled-components';

export const DataAvailableFrom = styled.div`
	display: flex;
	color: ${props => props.theme.yellow3};
	max-width: 220px;

	svg {
		flex-shrink: 0;
		margin-top: 3px;
		margin-right: 5px;

		[fill] {
			fill: ${props => props.theme.yellow3};
		}
	}
`;

export const ChartDetailsInner = styled.div`
	font-size: 14px;
	line-height: 20px;
	margin-top: 8px;
	color: ${props => props.theme.grey3};
`;

export const ChartDetails = styled.div`
	border-top: 2px solid ${props => props.theme.grey9};
	padding-top: 12px;
	margin-bottom: -8px;
`;

export const DetailNumber = styled.span`
	font-weight: bold;

	${props => props.$light && `
	color: ${props.theme.grey3}CC;
	`}

	${props => props.$color && `
	&:before {
		content: '';
		display: inline-block;
		margin-right: 2px;
		width: 9px;
		height: 9px;
		border-radius: 100%;
		background: ${props.$color};
	}
	`}
`;
