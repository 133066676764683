import _ from 'shared/copy';

export default function getTimeSince(time) {
	const milliseconds = new Date().getTime() - new Date(time).getTime();

	const minutes = Math.max(1, (milliseconds / (1000 * 60)));
	const hours = (milliseconds / (1000 * 60 * 60));
	const days = (milliseconds / (1000 * 60 * 60 * 24));
	const weeks = (milliseconds / (1000 * 60 * 60 * 24 * 7));

	if (weeks >= 1) {
		const rounded = Math.round(weeks);

		if (rounded === 1) {
			return _`oneWeek`;
		}

		return _`weeks${{ amount: rounded }}`;
	} else if (days >= 1) {
		const rounded = Math.round(days);

		if (rounded === 1) {
			return _`oneDay`;
		}

		return _`days${{ amount: rounded }}`;
	} else if (hours >= 1) {
		const rounded = Math.round(hours);

		if (rounded === 1) {
			return _`oneHour`;
		}

		return _`hours${{ amount: rounded }}`;
	}

	const rounded = Math.round(minutes);

	if (rounded === 1) {
		return _`oneMinute`;
	}

	return _`minutes${{ amount: rounded }}`;
}
