import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject } from 'rxjs';

import { preventPageLeave } from 'app/src/actions/client';
import { registerModal } from 'app/src/modals';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const LeavePageConfirmationModal = ({ data: { path } }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [exit$] = useState(new Subject());

	return (
		<Modal
			exit$={exit$}
		>
			<h2>{_`leavePageConfirmationTitle`}</h2>
			<p>{_`leavePageConfirmationDesc`}</p>
			<Buttons>
				<Button
					secondary
					negative
					onClick={() => {
						dispatch(preventPageLeave({ toggle: false }));
						exit$.next();

						navigate(path);
					}}
				>
					{_`discardChanges`}
				</Button>
				<Button onClick={() => exit$.next()}>
					{_`reviewChanges`}
				</Button>
			</Buttons>
		</Modal>
	);
};

registerModal('leave-page-confirmation', LeavePageConfirmationModal);
