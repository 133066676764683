import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Flag from 'app/src/components/ui/Flag';

const TeamLink = styled(Link)`
	display: inline-flex;
`;

const TeamName = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const TeamNameWithFlag = ({ team, overrideName, overrideLink }) => (
	<TeamLink to={overrideLink || `/${team.code}`}>
		<Flag countryCode={team.primary_country} />
		<TeamName>{overrideName || team.name}</TeamName>
	</TeamLink>
);

export default TeamNameWithFlag;
