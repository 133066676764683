import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import LinkIcon from 'shared/designTokens/icons/ui/small/LinkIcon';

import Card from 'app/src/components/ui/Card';
import CodeSnippet from 'app/src/components/ui/CodeSnippet';

import _ from 'shared/copy';

const BeaconSnippetWrapper = styled.div``;

const BeaconSnippetList = styled.ul`
	margin: 0 0 16px 0;
`;

const BeaconSnippetListItem = styled.li`
	font-size: 16px;
	color: ${props => props.theme.grey3};
`;

const BeaconSnippetFunc = styled.span`
	color: ${props => props.theme.rose3};
`;

const BeaconSnippetString = styled.span`
	color: ${props => props.theme.green3};
`;

const BeaconSnippetLink = styled.div`
	margin-left: 8px;
	cursor: pointer;
`;

const StyledLinkIcon = styled(LinkIcon)`
	width: 18px;
	height: 18px;
`;

const PATHS = ['data', 'leaderboards', 'planes', 'animations', 'world-02', 'weapons'];

const GameBeaconSnippetModule = props => {
	const { game } = props;

	const randomPath = useMemo(() => PATHS[Math.floor(Math.random() * PATHS.length)], []);

	const beaconSnippetRef = useRef(null);

	const beaconOptions = `'https://leveldata.poki.io/${randomPath}', '${game.id}'`;
	const beaconSnippet = `navigator.sendBeacon(${beaconOptions})`;

	const handleAddHashToUrl = () => {
		window.location.hash = 'beacon-snippet';
	};

	useEffect(() => {
		const fragmentIdentifier = window.location.hash.substring(1);

		// Scroll to the beacon snippet module if the URL has an identifier
		if (fragmentIdentifier === 'beacon-snippet' && beaconSnippetRef.current) {
			beaconSnippetRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [window.location.hash]);

	return (
		<Card
			title={(
				<>
					{_`beaconSnippet`}
					<BeaconSnippetLink onClick={handleAddHashToUrl}>
						<StyledLinkIcon />
					</BeaconSnippetLink>
				</>
			)}
		>
			<BeaconSnippetWrapper ref={beaconSnippetRef}>
				<p>{_`beaconSnippetDesc`}</p>
				<h4>{_`beaconSnippetIntegrationProcess`}</h4>
				<p>{_`beaconSnippetIntegrationProcessDesc`}</p>
				<CodeSnippet
					hasCopy
					textToCopy={beaconSnippet}
					content={(
						<>
							navigator.<BeaconSnippetFunc>sendBeacon</BeaconSnippetFunc>
							(<BeaconSnippetString>{beaconOptions}</BeaconSnippetString>)
						</>
					)}
				/>
				<p dangerouslySetInnerHTML={{ __html: _`beaconSnippetHowToCall` }} />
				<h4>{_`beaconSnippetAdditionalInformation`}</h4>
				<BeaconSnippetList>
					<BeaconSnippetListItem>{_`beaconSnippetListItem01`}</BeaconSnippetListItem>
					<BeaconSnippetListItem>{_`beaconSnippetListItem02`}</BeaconSnippetListItem>
					<BeaconSnippetListItem>{_`beaconSnippetListItem03`}</BeaconSnippetListItem>
					<BeaconSnippetListItem dangerouslySetInnerHTML={{ __html: _`beaconSnippetListItem04${{ path: randomPath }}` }} />
				</BeaconSnippetList>
				<p>{_`beaconSnippetReachOut`}</p>
			</BeaconSnippetWrapper>
		</Card>
	);
};

export default GameBeaconSnippetModule;
