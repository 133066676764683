import React, { useEffect } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { syncGame, getGameById, createGoogleDriveFolderForGame } from 'app/src/epics/game';
import { useSelectActiveTeam, useSelectRevenueSharesByTeamId } from 'app/src/selectors/team';
import { getTeamRevenueShares } from 'app/src/epics/team';
import { useSelectPermissions } from 'app/src/selectors/user';
import { openModal } from 'app/src/actions/client';
import checkPermissions from 'app/src/utils/checkPermissions';

import Button, { ButtonTextContainer, ButtonText } from 'app/src/components/ui/Button';
import GameDomainAllowListModule from 'app/src/components/modules/GameDomainAllowListModule';
import AdminGameGeneralSettingsModule from 'app/src/components/modules/AdminGameGeneralSettingsModule';
import GameAnnotationsModule from 'app/src/components/modules/GameAnnotationsModule';
import GameExternalDomainModule from 'app/src/components/modules/GameExternalDomainModule';
import GridContainer from 'app/src/components/ui/GridContainer';
import Card from 'app/src/components/ui/Card';

import _ from 'shared/copy';

const SyncButtonTextContainer = styled(ButtonTextContainer)`
	margin: 0 0 14px;
`;

const AdminGameSettingsSubPage = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const permissions = useSelectPermissions();
	const activeTeam = useSelectActiveTeam();

	const syncGameStatus = useSelectApiStatus(syncGame.id);
	const getGameByIdStatus = useSelectApiStatus(getGameById.id);
	const createGoogleDriveFolderStatus = useSelectApiStatus(createGoogleDriveFolderForGame.id);
	const currentRevenueShares = useSelectRevenueSharesByTeamId(activeTeam.id);

	useEffect(() => {
		dispatch(getTeamRevenueShares.fetch({ teamId: activeTeam.id }));
	}, [activeTeam]);

	const createGoogleDriveFolder = () => {
		dispatch(createGoogleDriveFolderForGame.fetch({ gameId: game.id }));
	};

	const handleSync = () => {
		if (currentRevenueShares.length > 0 || !activeTeam.verified) {
			dispatch(syncGame.fetch({ gameId: game.id }));
		} else {
			dispatch(openModal({ key: 'sync-to-console', data: { gameId: game.id } }));
		}
	};

	const alreadySynced = game?.content_metadata?.content_game_id;

	return (
		<GridContainer cols={1}>
			<Helmet key={`GameSettingsSubPage-${game.id}-${activeTeam.code}`}>
				<title>Admin Settings - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<Card>
				<GridContainer cols={1}>
					<AdminGameGeneralSettingsModule game={game} />
				</GridContainer>
				{(
					game && (
						checkPermissions(permissions, [[
							'can_edit_all_games',
							'can_manual_sync_game',
							'can_read_whitelist',
							'can_edit_whitelist',
							'can_create_google_drive_folder',
						]])
					)
				) && (
					<>
						<GridContainer cols={2}>
							{checkPermissions(permissions, [['can_edit_all_games']]) && (
								<GameAnnotationsModule game={game} />
							)}
							{checkPermissions(permissions, [['can_manual_sync_game']]) && (
								<Card
									title="Console"
								>
									<SyncButtonTextContainer>
										<Button
											disabled={(!alreadySynced && syncGameStatus.pending) || getGameByIdStatus.pending}
											to={alreadySynced ? `https://console.poki.io/games/${game.content_metadata.content_game_id}/edit` : undefined}
											onClick={alreadySynced ? undefined : handleSync}
											openInNewTab
										>
											{getGameByIdStatus.pending ? 'Loading...' : alreadySynced ? 'Open in Console' : 'Sync to Console'}
										</Button>
										{!alreadySynced && <ButtonText>Syncing will create a game in console that is connected to this P4D game.</ButtonText>}
									</SyncButtonTextContainer>
								</Card>
							)}
							{checkPermissions(permissions, [['can_create_google_drive_folder']]) && (
								<Card
									title="Google Drive"
								>
									{game.google_drive_folder_id ? (
										<a href={`https://drive.google.com/drive/folders/${game.google_drive_folder_id}`} target="_blank" rel="noreferrer">
											Open Google Drive Folder
										</a>
									) : (
										<Button
											disabled={createGoogleDriveFolderStatus.pending}
											onClick={createGoogleDriveFolder}
										>
											{createGoogleDriveFolderStatus.pending ? _`creating` : 'Create Google Drive Folder'}
										</Button>
									)}
								</Card>
							)}
							<GameExternalDomainModule game={game} />
						</GridContainer>
						{checkPermissions(permissions, [['can_read_whitelist', 'can_edit_whitelist']]) && (
							<GridContainer cols={1}>
								<GameDomainAllowListModule game={game} />
							</GridContainer>
						)}
					</>
				)}
			</Card>
		</GridContainer>
	);
};

export default AdminGameSettingsSubPage;
