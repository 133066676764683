import React from 'react';

const Coin3 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M30.7596 106.36L17.5996 58.4L35.5596 20.84L85.5996 10L122.28 53.84L116.4 100.44L83.3996 124.68L60.9196 130L30.7596 106.36Z" fill="#FFD100" />
			<path d="M113 62.4398L122.28 53.8398L116.4 100.44L83.3999 124.68L60.9199 130L100.56 107.8L113 62.4398Z" fill="#FFAF00" />
			<path d="M35.5605 20.84L85.6005 10L78.0805 16.6L35.5605 20.84Z" fill="#FFC500" />
			<path d="M75.2408 99.88L50.0008 89.48L42.8008 43.8L70.7208 43L89.2008 76.64L75.2408 99.88Z" fill="#FFDC00" />
			<path d="M75.2395 99.8805L49.9995 89.4805L44.5195 92.8805L71.0395 106.92L75.2395 99.8805Z" fill="#FFED85" />
			<path d="M42.7603 43.7617L49.9603 89.4417L44.4803 92.8417L38.3203 43.8817L42.7603 43.7617Z" fill="#FFAF00" />
			<path d="M70.6797 43.0016L78.1197 16.6016L113 62.4416L89.1597 76.6416L70.6797 43.0016Z" fill="#FFC500" />
			<path d="M100.561 107.801L71.041 106.921L89.201 76.6414L113.041 62.4414L100.561 107.801Z" fill="#FFD100" />
			<path d="M60.9199 130.002L71.0399 106.922L100.56 107.802L60.9199 130.002Z" fill="#FFDC00" />
			<path d="M30.7598 106.359L44.5198 92.8789L71.0398 106.919L60.9198 129.999L30.7598 106.359Z" fill="#FFE200" />
		</svg>
	);
};

export default Coin3;
