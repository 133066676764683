import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import history from 'app/history';
import Button from 'app/src/components/ui/Button';
import MobileCheckIcon from 'shared/components/svg/MobileCheck';
import { setGameChecklistStatus } from 'app/src/actions/session';
import { useSelectUser } from 'app/src/selectors/user';
import { selectLastUploadedVersionByGame, useSelectAllVersionListByGameId } from 'app/src/selectors/game';
import _ from 'shared/copy';
import { _small } from 'shared/vars';

const Container = styled.div`
	background: ${props => props.theme.grey7};
	display: flex;
	width: 100%;
	max-width: 444px;
	padding: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
`;

const UpperContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	flex-direction: column;

	${_small} {
		flex-direction: row;
	}
`;

const MobileCheckIconStyled = styled(MobileCheckIcon)`
	width: 64px;
	height: 64px;

	${_small} {
		width: 108px;
		height: 108px;
		order: 2;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;

	${_small} {
		order: 1;
	}
`;

const StyledHeader = styled.h4`
	font: 700 16px/20px "Proxima Nova";
	margin: 0;
`;

const StyledList = styled.ul`
	padding: 0 12px;
`;

const Buttons = styled.div`
	display: flex;
	gap: 16px;
`;

const Level2Step2 = ({ onSubmitCallback, disabled, game }) => {
	const dispatch = useDispatch();
	const user = useSelectUser();
	const version = selectLastUploadedVersionByGame(game?.versions);
	const { data: versions = [] } = useSelectAllVersionListByGameId(game?.id);
	const isAdmin = user.role === 'admin';

	const onUploadVersionClick = () => {
		history.push(`/${game.team.code}/games/${game.id}/versions`);
	};

	const onGotItCoveredClick = () => {
		dispatch(setGameChecklistStatus({ gameId: game.id, level: 2, step: 2, status: 'completed' }));
		onSubmitCallback();
	};

	useEffect(() => {
		if (!disabled && versions.length > 1) {
			dispatch(setGameChecklistStatus({ gameId: game.id, level: 2, step: 2, status: 'completed' }));
		}
	}, [disabled, versions]);

	const href = version ? `https://inspector.poki.dev/?game=poki-${version.id}${isAdmin ? '&analytics=false' : ''}` : 'https://inspector.poki.dev';

	return (
		<Container>
			<UpperContainer>
				<MobileCheckIconStyled />
				<LeftContainer>
					<StyledHeader>
						{_`webChallenges`}
					</StyledHeader>

					<StyledList>
						<li dangerouslySetInnerHTML={{ __html: _`learnBestPractices` }} />
						<li dangerouslySetInnerHTML={{ __html: _`useInspector${{ href }}` }} />
					</StyledList>
				</LeftContainer>
			</UpperContainer>

			<Buttons>
				<Button onClick={onUploadVersionClick} disabled={disabled}>{_`uploadNewVersion`}</Button>
				{!disabled && <Button onClick={onGotItCoveredClick} secondary disabled={disabled}>{_`haveItCovered`}</Button>}
			</Buttons>
		</Container>
	);
};

export default Level2Step2;
