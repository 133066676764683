const flattenObject = (obj, parentKey = '') => {
	const result = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const newKey = parentKey ? `${key}` : key;

			if (typeof obj[key] === 'object' && obj[key] !== null) {
			// Recursively flatten nested objects
				Object.assign(result, flattenObject(obj[key], newKey));
			} else if (obj[key] !== null && obj[key] !== undefined) {
			// Only add non-null, non-undefined values
				result[newKey] = obj[key];
			}
		}
	}

	return result;
};

export default flattenObject;
