import React from 'react';
import styled from 'styled-components';
import Tooltip from 'app/src/components/ui/Tooltip';

import _ from 'shared/copy';

const Circle = styled.span`
	background: ${props => props.theme.pureWhite};
	border: 3px solid ${props => props.theme.grey5};
	border-radius: 100%;
	width: 20px;
	height: 20px;
	margin-right: 8px;
`;

const Radio = styled.div`
	display: flex;
	position: relative;
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	color: ${props => props.theme.denimBlue};
	margin: 8px 4px;
	align-items: center;

	label {
		cursor: pointer;
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	input {
		display: none;
	}

	${props => (props.disabled ? `
	cursor: not-allowed;

	${Circle} {
		border-color: ${props.theme.grey7};
	}
	` : `
	cursor: pointer;

	&:hover {
		${Circle} {
			border-color: ${props.theme.pokiBlue};
		}
	}
	`)}

	${props => props.checked && `
	${Circle} {
		background: ${props.theme.pokiBlue};
		border-color: ${props.theme.pokiBlue};
	}
	`}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: ${props => (props.renderAsRow ? 'row' : 'column')};
	position: relative;
	margin-bottom: 8px;
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const RadioInput = props => {
	const { className, required, label, name, values, value, valueSetter, errors = [], description, onChange, renderAsRow = false } = props;

	const handleChange = evt => {
		if (valueSetter) valueSetter(evt.target.value);
		if (onChange) onChange(evt);
	};

	const input = (
		<InputContainer renderAsRow={renderAsRow} onChange={handleChange}>
			{values.map(v => {
				const checked = v.value.toString() === value.toString();
				const key = `radio-${name}-${v.value}`;

				return (
					<Radio key={key} checked={checked} disabled={v.disabled}>
						<input id={key} type="radio" name={name} value={v.value} disabled={v.disabled} checked={checked} />
						<label htmlFor={key}>
							<Circle />
							{v.tooltip ? (
								<Tooltip
									title={v.tooltip.title}
									body={v.tooltip.body}
									text={v.desc}
								/>
							) : v.desc}
						</label>
					</Radio>
				);
			})}
		</InputContainer>
	);

	return (
		<StyledFieldset className={className}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0}>
					{label}
					{input}
				</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					<Description>{description}</Description>
				)
			)}
		</StyledFieldset>
	);
};

export default RadioInput;
