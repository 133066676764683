import React, { useRef } from 'react';
import styled from 'styled-components';

import { motionSpeed02, motionOut } from 'shared/vars';

const Toggle = styled.div`
	border-radius: 100%;
	background: ${props => props.theme.static.pureWhite};
	width: 12px;
	height: 12px;
	position: absolute;
	left: 2px;
	top: 2px;
	transition: ${motionSpeed02} ${motionOut};
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};
	margin-left: 8px;
`;

const InnerContainer = styled.div`
	position: relative;
	background: ${props => props.theme.grey5};
	border-radius: 16px;
	height: 16px;
	width: 32px;
	transition: ${motionSpeed02} ${motionOut};
`;

const StyledInput = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
`;

const Container = styled.div`
	display: flex;
	position: relative;
	width: max-content;

	${props => props.disabled && `
	cursor: not-allowed;
	opacity: 0.5;
	`}

	${props => !props.disabled && `
	cursor: pointer;

	&:hover ${InnerContainer} {
		background: ${props.theme.grey3};
	}
	`}

	${props => props.active && `
	${Toggle} {
		transform: translate(16px, 0);
	}

	${InnerContainer} {
		background: ${props.theme.pokiBlue};
	}

	&:hover ${InnerContainer} {
		background: ${props.theme.pokiHover};
	}
	`}
`;

const ToggleInput = React.forwardRef((props, ref) => {
	const { name, className, disabled, checked, label, onChange, checkedSetter } = props;

	const inputEl = useRef();

	const handleChange = toggle => {
		if (checkedSetter) checkedSetter(toggle);
		if (onChange) onChange(toggle);

		if (inputEl.current?.form) {
			const inputEvent = new Event('input', { bubbles: true });
			inputEl.current.form.dispatchEvent(inputEvent);
		}
	};

	const toggle = () => {
		if (!disabled) handleChange(!checked);
	};

	return (
		<Container className={className} disabled={disabled} active={checked} onClick={toggle}>
			<InnerContainer ref={ref}>
				<StyledInput ref={inputEl} name={name} type="checkbox" checked={!!checked} onChange={handleChange} />
				<Toggle />
			</InnerContainer>
			{label && <StyledLabel>{label}</StyledLabel>}
		</Container>
	);
});

export default ToggleInput;
