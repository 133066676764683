import React, { useState } from 'react';
import { Subject } from 'rxjs';
import styled from 'styled-components';

import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import Button from 'app/src/components/ui/Button';
import QRCode from 'app/src/components/ui/QRCode';

const StyledModal = styled(Modal)`
	width: min-content;
	display: flex;
	flex-direction: column;
`;

const StyledButton = styled(Button)`
	margin-left: auto;
`;

const PreviewQRModal = props => {
	const { data: { game, version } } = props;

	const [exit$] = useState(new Subject());

	return (
		<StyledModal
			exit$={exit$}
		>
			<QRCode
				width={300}
				height={300}
				data={`https://poki.com/en/preview/${game.id}/${version.id}`}
			/> <br />
			<StyledButton
				onClick={() => exit$.next()}
			>
				{_`close`}
			</StyledButton>
		</StyledModal>
	);
};

registerModal('game-preview-qr', PreviewQRModal);
