import React from 'react';
import styled from 'styled-components';

import Card from 'app/src/components/ui/Card';
import { isMobile } from 'shared/vars';

const Container = styled(Card)`
	padding: 12px 16px;
	font-size: 14px;
	display: none;
	position: absolute;
	top: -12px;
	width: max-content;
	color: ${props => props.theme.grey3};

	${isMobile && `
		left: auto;
		right: -10px;
	`}

	${props => props.$active && `
		display: block;
	`}

	${props => props && `
		&:after {
			left: 18px;
			content: '';
			border: 10px solid transparent;
			border-top-color: ${props.theme.pureWhite};
			position: absolute;
			bottom: -19px;

			${!props.theme.isDarkMode ? `
			filter: drop-shadow(0px 3px 2px rgba(9, 30, 66, 0.06));
			` : `
			filter: drop-shadow(0px 2px 1px rgba(150, 190, 255, 0.25));
			`}
		}
	`}

	${props => (props.$xDirection === 'right' ? `
	left: 50%;
	transform: translate(-28px, -100%);

	&:after {
		left: 18px;
		right: auto;
	}
	` : `
	right: 50%;
	transform: translate(28px, -100%);

	&:after {
		left: auto;
		right: 18px;
	}
	`)}
`;

const FloatingCard = props => {
	const { className, active, children, xDirection = 'right' } = props;

	return (
		<Container
			className={className}
			noPadding
			elevated
			$active={active}
			$xDirection={xDirection}
		>
			{children}
		</Container>
	);
};

export default FloatingCard;
