import React, { createRef, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import ChevronDownIcon from 'shared/designTokens/icons/ui/small/ChevronDownIcon';
import ChevronRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';
import ExitLinkIcon from 'shared/designTokens/icons/ui/tiny/ExitLinkIcon';

import Link from 'app/src/components/Link';
import { motionSpeed01, motionOut, loadingPulseAnimation, darkColors } from 'shared/vars';
import Label from 'app/src/components/ui/Label';
import { useSelectPermissions } from 'app/src/selectors/user';
import usePrevious from 'app/src/hooks/usePrevious';
import checkPermissions from 'app/src/utils/checkPermissions';

const ItemOutsideContainer = styled.div``;

const Container = styled.div`
	max-width: 222px;

	& + & {
		margin-top: 24px;
	}
`;

const Buttons = styled.div`
	display: grid;
	grid-auto-flow: row;
	justify-content: stretch;
	width: 100%;
	gap: 6px;
`;

const LoadingBar = styled.div`
	height: 30px;
	margin: 3px 0px;
	border-radius: 6px;
	background: ${props => props.theme.grey7};
	animation: ${loadingPulseAnimation};
`;

const ItemContainer = styled.div`
	display: flex;
	align-items: center;
`;

const itemStyle = css`
	position: relative;
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;
	color: ${props => (props.$light ? darkColors.grey3 : props.theme.grey3)};
	transition: all ${motionSpeed01} ${motionOut};
	display: flex;
	align-items: center;
	width: ${props => (props.$collapsed ? 'auto' : '220px')};
	padding: 9px 0;
	cursor: pointer;

	${props => props.$child && `
	padding-left: 6px;
	line-height: 24px;
	`}

	${props => props.$small && `
	font-size: 14px;
	font-weight: normal;
	`}

	svg {
		[fill] {
			fill: ${props => (props.$active ? props.theme.pokiBlue : (props.$light ? darkColors.grey3 : props.theme.grey3))};
		}
	}

	&:hover {
		color: ${props => props.theme.pokiBlue};

		svg {

			[fill] {
				fill: ${props => props.theme.pokiBlue};
			}
		}
	}

	${props => props.$active && `
	color: ${props.theme.pokiBlue};
	`}
`;

const StyledLink = styled(Link)`
	${itemStyle}
`;

const StyledItem = styled.div`
	${itemStyle}
`;

const ItemName = styled.div`
	margin-right: auto;
	max-width: 162px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-left: 14px;
`;

const StyledAnchor = styled.a`
	${itemStyle}
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 16px;
	color: ${props => props.theme.grey1};
	margin-bottom: 14px;
	width: 180px;

	${props => props.$light && `
	color: ${props.theme.static.grey5};
	`}

	${props => props.$small && `
	margin-bottom: 8px;
	`}

	${props => props.$collapsed && `
	visibility: hidden;
	`}
`;

const Thumbnail = styled.div`
	position: relative;
	border-radius: 6px;
	background: ${props => (props.src ? `url("${props.src}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 24px;
	width: 24px;
	margin-right: -2px;
	flex-shrink: 0;
`;

const ChildrenContainer = styled.div`
	margin-bottom: 12px;
`;

const Chevron = styled.div`
	position: relative;
	left: 8px;
	display: flex;
	align-items: center;
`;

const StyledExitLinkIcon = styled(ExitLinkIcon)`
	vertical-align: middle;
	margin-left: 4px;
`;

const isButtonActive = (button, location) => (button.isActiveFunction ? button.isActiveFunction(location.pathname) : (button.to === location.pathname));

const ListMenu = props => {
	const { className, apiStatus, buttons, title, small, alwaysShow, light, collapsed, scrollContainerRef } = props;

	const location = useLocation();
	const permissions = useSelectPermissions();
	const [activeButtons, setActiveButtons] = useState([]);
	const prevActiveButtons = usePrevious(activeButtons);

	const finalButtons = useMemo(() => (
		buttons
			.filter(button => checkPermissions(permissions, button.needsPermissions))
			.filter(button => button.hidden !== true)
			.filter(button => !button.customPermissionCheck || button.customPermissionCheck(permissions))
			.map(button => {
				if (!button.children) return button;

				// Make sure we have a least one child available, otherwise skip this section all together
				const availableChild = button.children.find(child => checkPermissions(permissions, child.needsPermissions));
				if (!availableChild) return null;

				return button;
			})
			.filter(button => button && button.name && (button.to || button.onClick || button.children))
			.map(button => ({
				...button,
				id: `${title}-${button.id || button.name}`,
				ref: createRef(),
			}))
	), [buttons]);

	// Ensure that menu starts expanded if a sub child is active (on external location switches, landing/search etc)
	useLayoutEffect(() => {
		const active = finalButtons.reduce((result, button) => {
			(button.children || []).forEach(child => {
				if (isButtonActive(child, location) && !result.includes(button.id)) {
					result.push(button.id);
				}
			});
			return result;
		}, activeButtons);

		setActiveButtons([...active]);
	}, [finalButtons, location?.pathname]);

	const toggleActive = buttonId => {
		setActiveButtons(curr => {
			if (curr.includes(buttonId)) {
				return curr.filter(id => id !== buttonId);
			}

			return [...curr, buttonId];
		});
	};

	// If this ListMenu has a scroll container, attempt to scroll towards the last active button, if it's not visible
	useEffect(() => {
		const parent = scrollContainerRef?.current;

		if (!parent) return;
		if (finalButtons.length === 0) return;
		if (prevActiveButtons && prevActiveButtons.length > activeButtons.length) return;

		const lastActiveButton = activeButtons[activeButtons.length - 1];
		const lastActiveButtonRef = finalButtons.find(button => button.id === lastActiveButton);
		const el = lastActiveButtonRef?.ref?.current;

		if (!el) return;

		const isVisible = !(
			((el.offsetTop - parent.offsetTop - parent.scrollTop) > (parent.offsetHeight - el.offsetHeight))
			|| parent.scrollTop > el.offsetTop
		);

		if (!isVisible) {
			el.scrollIntoView({ block: 'nearest' });
		}
	}, [scrollContainerRef, finalButtons, activeButtons]);

	// ListMenu's need to fully hide when there's no buttons
	if (finalButtons.length === 0 && !alwaysShow) return null;

	return (
		<Container className={className}>
			{title && (
				<Title $light={light} $collapsed={collapsed} $small={small}>
					{title}
				</Title>
			)}
			<Buttons>
				{apiStatus && apiStatus.pending ? (
					<>
						<LoadingBar />
						<LoadingBar />
					</>
				) : (
					finalButtons
						.map(({ ...button }) => {
							const filteredChildren = button.children ? button.children.filter(child => checkPermissions(permissions, child.needsPermissions)) : [];
							const isHeader = filteredChildren.length > 1 && !collapsed; // In collapsed mode, nothing's a header

							// Button with children, but none available? Don't show
							if (button.children && filteredChildren.length === 0) return null;

							const active = isHeader ? 0 : isButtonActive(button, location) ? 1 : 0;
							const showChildren = isHeader && activeButtons.includes(button.id) && filteredChildren.length > 1;

							// Button with children but we don't show them? Inherit the childs behaviour if we don't have one of ourselves
							if (!button.to && !button.onClick && (collapsed || filteredChildren.length === 1)) {
								button.to = filteredChildren[0].to;
								button.onClick = filteredChildren[0].onClick;
							}

							const isExternalLink = !isHeader && button.to && button.to.match(/^http/);
							const Component = button.to ? (isExternalLink ? StyledAnchor : StyledLink) : StyledItem;

							return (
								<ItemOutsideContainer key={button.id} ref={button.ref}>
									<ItemContainer active={active}>
										<Component
											to={button.to && !isExternalLink && !isHeader ? button.to : undefined}
											href={button.to && isExternalLink && !isHeader ? button.to : undefined}
											rel={isExternalLink ? 'noopen noreferrer' : ''}
											target={isExternalLink ? '_blank' : ''}
											onClick={isHeader ? () => toggleActive(button.id) : button.onClick}
											$active={active}
											$collapsed={collapsed}
											$light={light}
											$small={small}
										>
											{typeof button.icon === 'string' ? (
												<Thumbnail collapsed={collapsed} src={button.icon} />
											) : (button.icon && <button.icon />)}
											{!collapsed && <ItemName title={button.name}>{button.name}{button.isExit && <StyledExitLinkIcon />}</ItemName>}
											{!collapsed && button.label && <Label {...button.label} />}
											{isHeader && (
												<Chevron>
													{showChildren ? (
														<ChevronDownIcon />
													) : (
														<ChevronRightIcon />
													)}
												</Chevron>
											)}
										</Component>
									</ItemContainer>
									{showChildren ? (
										<ChildrenContainer>
											{filteredChildren.map(child => {
												const childIsExternalLink = child.to && child.to.match(/^http/);
												const childActive = (
													(child.isActiveFunction ? child.isActiveFunction(location.pathname) : (child.to === location.pathname))
												) ? 1 : 0;

												const ChildComponent = child.onClick ? StyledItem : (childIsExternalLink ? StyledAnchor : StyledLink);

												return (
													<ChildComponent
														key={child.to || child.name}
														to={child.to && !childIsExternalLink ? child.to : undefined}
														href={child.to && childIsExternalLink ? child.to : undefined}
														rel={childIsExternalLink ? 'noopen noreferrer' : ''}
														target={childIsExternalLink ? '_blank' : ''}
														onClick={child.onClick}
														$active={childActive}
														$child
														$light={light}
														$small={small}
													>
														<ItemName key={child.id} title={child.name}>{child.name}{child.isExit && <StyledExitLinkIcon />}</ItemName>
														{child.label && <Label {...child.label} />}
													</ChildComponent>
												);
											})}
										</ChildrenContainer>
									) : null}
								</ItemOutsideContainer>
							);
						})
				)}
			</Buttons>
		</Container>
	);
};

export default ListMenu;
