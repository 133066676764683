import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { interval, Subject, takeUntil, tap } from 'rxjs';

const ProgressContainer = styled.div`
	width: 24px;
	height: 24px;
`;

const SVG = styled.svg`
	width: 100%;
	height: 100%;
	transform: rotate(-90deg);
`;

const BGCircle = styled.circle`
	fill: ${props => props.theme.grey7};
`;

const ProgressCircle = styled.circle`
	stroke: ${props => props.theme.grey5};
	transition: stroke-dasharray .2s linear;
	fill: none;
`;

const tickSpeed = 200;

const Timer = props => {
	const { className, time, start$ } = props;

	const [progress, setProgress] = useState(0);
	const [clear$] = useState(new Subject());

	const startTimer = useCallback(() => {
		setProgress(0);
		clear$.next(); // Ensure only one interval runs at once

		const sub = interval(tickSpeed).pipe(
			tap(() => {
				setProgress(p => (p + (tickSpeed / time)));
			}),
			takeUntil(clear$),
		).subscribe();

		return () => {
			sub.unsubscribe();
		};
	}, [time, start$]);

	useEffect(() => {
		startTimer();

		const sub = start$.subscribe(() => {
			startTimer();
		});

		return () => {
			sub.unsubscribe();
		};
	}, [time, start$]);

	const size = 50;
	const circ = Math.PI * 2 * (size / 2);

	return (
		<ProgressContainer className={className}>
			<SVG viewBox={`0 0 ${size * 2} ${size * 2}`}>
				<BGCircle r={size} cx={size} cy={size} />
				<ProgressCircle
					r={size / 2}
					cx={size}
					cy={size}
					style={{
						strokeDasharray: `${circ * progress} ${circ}`,
						strokeWidth: size,
					}}
				/>
			</SVG>
		</ProgressContainer>
	);
};

export default Timer;
