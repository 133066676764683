import React from 'react';
import styled from 'styled-components';

import { motionOut } from 'shared/vars';
import _ from 'shared/copy';

const ProgressContainer = styled.div`
	margin-top: auto;
	width: 180px;
`;

const Progress = styled.div`
	position: relative;
	height: 8px;
	border-radius: 16px;
	overflow: hidden;
	background: ${props => props.theme.grey7};

	::after {
		content: '';
		display: block;
		transition: all ${motionOut} 0.3s;
		transform-origin: left;
		transform: scaleX(${props => props.progress});
		background: ${props => (props.progress >= 1 ? props.theme.green7 : props.theme.pokiBlue)};
		width: 100%;
		height: 100%;
	}
`;

const Details = styled.div`
	display: flex;
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
`;

const Description = styled.div`
	margin-right: auto;
`;

const ProgressText = styled.div`
	margin-left: auto;
	color: ${props => props.theme.grey3};
`;

const ProgressBar = props => {
	const { className, desc, progress, hideProgressIfFull = false, isPaused, noText = false } = props;

	const progressRound = Math.floor(progress * 100);

	return (
		<ProgressContainer className={className}>
			<Progress progress={progress} />
			<Details>
				{desc && <Description>{desc}</Description>}
				{isPaused ? (
					<ProgressText>{_`paused`}</ProgressText>
				) : (
					((!hideProgressIfFull || progressRound < 100) && !noText) && <ProgressText>{progressRound}%</ProgressText>
				)}
			</Details>
		</ProgressContainer>
	);
};

export default ProgressBar;
