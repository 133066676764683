import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { merge, Subject, of, tap, switchMap, ignoreElements } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { createUser } from 'app/src/epics/user';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectTeamByCode, useSelectActiveTeam } from 'app/src/selectors/team';
import { registerModal } from 'app/src/modals';
import { uncaughtServerError } from 'app/src/actions/client';

import Modal from 'app/src/components/ui/Modal';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import SelectInput from 'app/src/components/input/SelectInput';
import CheckBoxInput from 'app/src/components/input/CheckBoxInput';

import { userRoles } from 'shared/vars';
import _ from 'shared/copy';

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const validationSchema = yup.object().shape({
	name: yup.string().required(_`fieldRequired`),
	role: yup.string().required(_`fieldRequired`),
	email: yup.string().email('This field must be a valid email').required(_`fieldRequired`),
});

const CreateUserModal = ({ data: { teamCode } }) => {
	const dispatch = useDispatch();

	const team = useSelectTeamByCode(teamCode);
	const activeTeam = useSelectActiveTeam();
	const createUserStatus = useSelectApiStatus(createUser.id);

	const [placeholderName] = useState(Math.random() < 0.5 ? _`placeholderNameA` : _`placeholderNameB`);
	const [exit$] = useState(new Subject());

	const { handleSubmit, control, setError, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			team: team?.name || '',
			name: '',
			role: '',
			email: '',
			send_welcome_email: true,
		},
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	const onSubmit = data => {
		const { name, role, email, send_welcome_email } = data;

		dispatch(createUser.fetch({ name, role, email, teamId: activeTeam.id, sendWelcomeEmail: send_welcome_email }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					tap(() => {
						exit$.next();
					}),
					ignoreElements(),
				),
				error$.pipe(
					switchMap(action => {
						const { payload: { result: { response } } } = action;
						if (response?.errors?.find(err => err.code === 'user-exists')) {
							setError('email', { message: _`userAlreadyExistsError` });

							return of(startScreenShake());
						}

						return of(uncaughtServerError({ action }));
					}),
				),
			)
		)));
	};

	return (
		<Modal exit$={exit$}>
			<>
				<h2>{_`createUser`}</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextInput
						label={_`team`}
						name="team"
						value={team?.name}
						disabled
					/>
					<Controller
						control={control}
						name="name"
						render={({ field }) => (
							<TextInput
								{...field}
								label={_`fullName`}
								autoFocus
								placeholder={placeholderName}
								required
								errors={errors?.name?.message ? [errors?.name?.message] : []}
							/>
						)}
					/>
					<Controller
						control={control}
						name="role"
						render={({ field }) => (
							<SelectInput
								{...field}
								label={_`userRole`}
								values={userRoles}
								placeholder={_`selectARole`}
								required
								errors={errors?.role?.message ? [errors?.role?.message] : []}
							/>
						)}
					/>
					<Controller
						control={control}
						name="email"
						render={({ field }) => (
							<TextInput
								{...field}
								label={_`email`}
								placeholder={_`exampleEmail`}
								required
								errors={errors?.email?.message ? [errors?.email?.message] : []}
							/>
						)}
					/>
					<Controller
						control={control}
						name="send_welcome_email"
						render={({ field }) => (
							<CheckBoxInput
								{...field}
								text={_`sendWelcomeEmail`}
								valueSetter={field.onChange}
							/>
						)}
					/>
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={createUserStatus.pending || !isDirty}>{createUserStatus.pending ? _`creating` : _`create`}</Button>
					</Buttons>
				</form>
			</>
		</Modal>
	);
};

registerModal('create-user', CreateUserModal);
