const downloadFromDataBlob = (data, fileName, fileType) => {
	if (!data || !fileName || !fileType) {
		return;
	}

	try {
		const blob = new Blob([data], { type: fileType });
		const url = window.URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = fileName;

		document.body.appendChild(a);
		a.click();

		setTimeout(() => {
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		}, 1000);
	} catch (e) {
		console.error('Error downloading data blob', e);
	}
};

export default downloadFromDataBlob;
