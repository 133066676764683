// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export default function humanFileSize(bytes) {
	if (Math.abs(bytes) < 1024) return `${bytes}B`;

	const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let u = -1;
	do {
		bytes /= 1024;
		++u;
	} while (Math.abs(bytes) >= 1024 && u < units.length - 1);

	const decimals = units[u] === 'kB' ? 0 : 2;
	return `${bytes.toFixed(decimals)} ${units[u]}`;
}
