import React from 'react';

const Star1 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M108.04 61.4008L114.72 118.201L94.5596 100.521L90.5996 111.641L76.0796 100.961L49.5596 132.801L51.5596 89.6808L15.5996 97.2008L51.1196 60.4008L44.3596 6.80078L68.3996 22.3208L80.7996 12.5608L91.7996 27.4408L124.16 12.5608L108.04 61.4008Z" fill="#FFD100" />
			<path d="M76.0801 100.921L90.6001 111.601L85.4801 92.4812L84.1201 91.2812L76.0801 100.921Z" fill="#FF9E00" />
			<path d="M94.5605 100.48L90.6005 111.6L85.4805 92.4805L94.5605 100.48Z" fill="#FFAF00" />
			<path d="M68.4395 22.3186L80.7994 12.5586V30.2786L68.4395 22.3186Z" fill="#FFC500" />
			<path d="M91.7608 27.4786L80.8008 12.5586V30.2786L82.8008 31.5986L91.7608 27.4786Z" fill="#FFED85" />
			<path d="M73.3205 81.8008L49.5605 132.801L84.1205 91.2808L73.3205 81.8008Z" fill="#FFDC00" />
			<path d="M108.041 61.3984L82.8008 61.3984L114.721 118.198L108.041 61.3984Z" fill="#FFE200" />
			<path d="M82.8005 61.3984L63.7205 73.3584L51.5605 89.7184L49.5605 132.838L73.2805 81.8384L114.681 118.238L82.8005 61.3984Z" fill="#FFC500" />
			<path d="M82.7988 61.4008L63.7187 73.3608L75.7988 34.8008L82.7988 61.4008Z" fill="#FF9E00" />
			<path d="M75.8008 34.7986L124.121 12.5586L82.8008 61.3986L75.8008 34.7986Z" fill="#FFAF00" />
			<path d="M44.3594 6.80078L82.7994 31.6008L75.7994 34.8008L44.3594 6.80078Z" fill="#FFDC00" />
			<path d="M15.5996 97.2003L51.5596 89.6803L63.7196 73.3203L15.5996 97.2003Z" fill="#FFAF00" />
			<path d="M75.7996 34.8008L15.5996 97.2008L63.7196 73.3608L75.7996 34.8008Z" fill="#FFC500" />
		</svg>
	);
};

export default Star1;
