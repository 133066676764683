import React from 'react';

const Heart2 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M119.68 43.2008L123.84 97.8408L101.76 124.801L59.32 122.761L16 92.8408L17.52 65.9208L33.96 48.6008L60.16 50.6408L71.72 27.8408L90.32 14.8008L115.76 33.8808L119.68 43.2008Z" fill="#FFA4BA" />
			<path d="M55.199 60.5216L33.959 48.6016L60.159 50.6416L55.199 60.5216Z" fill="#FFC9DD" />
			<path d="M55.1602 100.919L83.8802 97.7591L64.8402 80.0391L55.1602 100.919Z" fill="#FFA4BA" />
			<path d="M119.679 43.1992L83.8792 97.7592L64.8392 80.0392L55.1992 60.5192L119.679 43.1992Z" fill="#FFA9BE" />
			<path d="M55.1992 60.5208L60.1592 50.6008L71.7192 27.8008L90.3192 14.8008L73.2792 55.6808L55.1992 60.5208Z" fill="#FF99B1" />
			<path d="M90.3216 14.8008L115.762 33.8808L76.6816 47.4408L90.3216 14.8008Z" fill="#FFA9BE" />
			<path d="M73.2402 55.6798L119.68 43.1998L115.76 33.8398L76.6802 47.3998L73.2402 55.6798Z" fill="#FF8AA6" />
			<path d="M83.8809 97.7592L123.841 97.8392L119.681 43.1992L83.8809 97.7592Z" fill="#FF99B1" />
			<path d="M33.9595 48.6016L17.5195 65.9216L43.3595 73.5616L55.1995 60.5216L33.9595 48.6016Z" fill="#FFA4BA" />
			<path d="M17.52 65.9219L16 92.8419L34.24 83.5219L43.32 73.5219L17.52 65.9219Z" fill="#FF8AA6" />
			<path d="M16 92.8395L59.36 122.76L55.16 100.92L64.88 80.0395L55.2 60.5195L34.28 83.5595L16 92.8395Z" fill="#FF99B1" />
			<path d="M83.8809 97.7617L101.761 124.802L123.841 97.8417L83.8809 97.7617Z" fill="#FF7690" />
			<path d="M101.76 124.802L83.8802 97.7617L55.1602 100.922L101.76 124.802Z" fill="#FFA9BE" />
			<path d="M59.3602 122.762L101.8 124.802L55.1602 100.922L59.3602 122.762Z" fill="#FFA4BA" />
		</svg>
	);
};

export default Heart2;
