import React, { useRef } from 'react';
import styled from 'styled-components';

import { motionSpeed02, motionOut } from 'shared/vars';
import _ from 'shared/copy';
import IconButton from 'app/src/components/ui/IconButton';
import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';
import Button from 'app/src/components/ui/Button';

const StyledInput = styled.input`
	display: block;
	padding: 0 16px;
	line-height: 48px;
	height: 48px;
	width: 100%;
	background: ${props => props.theme.grey7};
	color: ${props => props.theme.denimBlue};
	font-size: 16px;
	border: 1px solid ${props => props.theme.grey5};
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	border-radius: 8px;

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	&:focus {
		outline: none;
		border-color: ${props => props.theme.pokiBlue};
		box-shadow: inset 0 0 0 2px ${props => props.theme.pokiBlue};
		background: ${props => props.theme.grey9};
	}

	${props => (props.disabled ? `
	color: ${props.theme.grey3};
	cursor: not-allowed;
	border: none;
	padding: 0 17px;
	` : `
	&:hover {
		background: ${props.theme.grey9};
	}
	`)}

	${props => props.error && `
	border-color: ${props.theme.rose1};
	background: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}
`;

const InputContainer = styled.div`
	position: relative;
	margin-bottom: 8px;

	> svg {
		position: absolute;
		top: 11px;
		left: 11px;
		pointer-events: none;
	}

	> svg + ${StyledInput} {
		padding-left: 44px;
	}

	> svg [fill] {
		fill: ${props => props.theme.grey3};
	}

	&:focus-within {
		> svg [fill] {
			fill: ${props => props.theme.pokiBlue};
		}
	}
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${InputContainer} {
		margin-top: 4px;
	}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const InputRow = styled.div`
	padding: 8px 0;
	border-bottom: 1px solid ${props => props.theme.grey7};
	display: flex;
	align-items: center;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
	}

	${StyledInput} {
		margin-right: 8px;
	}
`;

const MultiRowInput = props => {
	const { className, name, errors = [], description, descriptionHTML, disabled, placeholder, label, value, valueSetter, maxLength, onBlur, required } = props;

	const createHandleChangeVal = idx => evt => {
		const val = evt.target.value;

		valueSetter(([...v]) => {
			v[idx] = val;
			return v;
		});
	};

	const createHandleRemove = idx => () => {
		valueSetter(([...curr]) => {
			curr.splice(idx, 1);
			return curr;
		});
	};

	const addNew = () => {
		valueSetter(v => [
			...v,
			'',
		]);
	};

	const inputRef = useRef();

	const inputs = (
		<InputContainer>
			{value.map((val, idx) => (
				<InputRow
					key={`val-${idx}`} /* eslint-disable-line react/no-array-index-key */
				>
					<StyledInput
						ref={inputRef}
						type="text"
						disabled={disabled}
						placeholder={placeholder}
						value={val}
						onChange={createHandleChangeVal(idx)}
						maxLength={maxLength}
						onBlur={onBlur}
						error={errors.length > 0}
					/>
					<IconButton
						title={_`remove`}
						icon={CloseIcon}
						onClick={createHandleRemove(idx)}
					/>
				</InputRow>
			))}
		</InputContainer>
	);

	return (
		<StyledFieldset className={className}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0}>{label}{inputs}</StyledLabel>
			) : inputs}
			<Button
				secondary
				onClick={() => addNew()}
			>
				{_`addNew`}
			</Button>
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					descriptionHTML ? (
						<Description dangerouslySetInnerHTML={{ __html: description }} />
					) : (
						<Description>{description}</Description>
					)
				)
			)}
		</StyledFieldset>
	);
};

export default MultiRowInput;
