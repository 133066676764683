const categories = [
	'Racing Games',
	'Sports Games',
	'Action Games',
	'Adventure Games',
	'Thinking Games',
	'Board Games',
	'Skill Games',
	'Battleship Games',
	'Pool Games',
	'Block Games',
	'Boxing Games',
	'Hidden Object Games',
	'Cooking Games',
	'Decoration Games',
	'Chess Games',
	'Music Games',
	'Horse Riding Games',
	'Soccer Games',
	'Dress Up Games',
	'Boat Games',
	'Checkers Games',
	'Drawing Games',
	'Math Games',
	'Mouse Games',
	'Maze Games',
	'Mahjong Games',
	'Make Up Games',
	'Pirate Games',
	'Platform Games',
	'Ragdoll Games',
	'Hair Games',
	'Motorbike Games',
	'Shopping Games',
	'Snake Games',
	'Sudoku Games',
	'Zombie Games',
	'RPG Games',
	'Animal Games',
	'War Games',
	'Strategy Games',
	'Point and Click Games',
	'Love Games',
	'Management Games',
	'Puzzle Games',
	'Mini Games',
	'Multiplayer Games',
	'Shooting Games',
	'Car Games',
	'Fighting Games',
	'Police Games',
	'Bicycle Games',
	'Geography Games',
	'Card Games',
	'Fashion Games',
	'Train Games',
	'Construction Games',
	'Christmas Games',
	'Simulation Games',
	'3D Games',
	'Word Games',
	'Robot Games',
	'Doctor Games',
	'Flying Games',
	'Bubble Games',
	'Arcade Games',
	'Farm Games',
	'Baseball Games',
	'Basketball Games',
	'BMX Games',
	'Bowling Games',
	'Hunting Games',
	'Cat Games',
	'Cricket Games',
	'Golf Games',
	'Halloween Games',
	'Hospital Games',
	'Makeover Games',
	'Easter Games',
	'Parking Games',
	'Firefighter Games',
	'Jigsaw Puzzle Games',
	'Rugby Games',
	'Escape Games',
	'Skateboarding Games',
	'Sniper Games',
	'Tank Games',
	'Typing Games',
	'Tennis Games',
	'Archery Games',
	'Bubble Shooter Games',
	'Tower Defense Games',
	'Bike Games',
	'Mini Golf Games',
	'Fishing Games',
	'Pizza Games',
	'Cake Games',
	'Princess Games',
	'Ninja Games',
	'Truck Games',
	'Taxi Games',
	'Airplane Games',
	'Tractor Games',
	'Dog Games',
	'Wrestling Games',
	'Dragon Games',
	'Gun Games',
	'Bus Games',
	'Restaurant Games',
	'Dinosaur Games',
	'Monster Truck Games',
	'Solitaire Games',
	'GTA Games',
	'Logic Games',
	'Fish Games',
	'Monkey Games',
	'Tattoo Games',
	'Flappy Bird Games',
	'World Cup Games',
	'Dirt Bike Games',
	'Minecraft Games',
	'Surgery Games',
	'2 Player Games',
	'Fire and Water games',
	'Car Racing Games',
	'Ball Games',
	'Ice Cream Games',
	'Princess Dress Up Games',
	'Penguin Games',
	'Penalty Games',
	'Educational Games',
	'Scary Games',
	'Intelligence Games',
	'Food Games',
	'Horse Racing Games',
	'Monster Games',
	'Quiz Games',
	'Ghost Games',
	'Football Games',
	'Papa\'s Games',
	'Parkour Games',
	'Multiplication Games',
	'Driving Games',
	'Hockey Games',
	'Running Games',
	'Anime Games',
	'Matching Games',
	'Surgeon Games',
	'Stickman Games',
	'Superhero Games',
	'Number Games',
	'Hangman Games',
	'Magic Games',
	'Decorating Games',
	'Physics Games',
	'Idle Games',
	'Wolf Games',
	'Survival Games',
	'Candy Crush Games',
	'Match 3 Games',
	'Army Games',
	'Backgammon',
	'Scrabble Games',
	'Thanksgiving Games',
	'.io Games',
	'Crafting Games',
	'Olympics Games',
	'Fidget Spinner Games',
	'Clicker Games',
	'Slime Games',
	'Battle Royale Games',
	'New Games',
	'Online Games',
	'Video Games',
	'Trivia Games',
	'Flash Games',
	'Merge Games',
	'Henry Stickmin Games',
	'Horror Games',
	'Keyboard Games',
	'First Person Shooter Games',
	'Color Games',
	'Story Games',
	'Horse Games',
	'Co-op Games',
	'Space Games',
	'Retro Games',
	'Pop It Games',
	'Winter Games',
	'Drifting Games',
	'Runner Games',
	'Nitrome Games',
	'Obby Games',
];

export default categories;
