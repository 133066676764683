import React, { useEffect, useRef, useState } from 'react';
import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { fromEvent, map, tap } from 'rxjs';
import styled from 'styled-components';
import moment from 'moment';

import { openModal, openToast } from 'app/src/actions/client';
import { downloadZip, gameSetVersionActive, getGameById } from 'app/src/epics/game';
import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { useSelectRevenueSharesByTeamId } from 'app/src/selectors/team';
import { pushEvent, trackInspectorEvent } from 'app/src/utils/tracking';
import { getTeamRevenueShares } from 'app/src/epics/team';
import { gameHasNonZeroShare } from 'app/src/utils/revenueShares';
import { getLatestVersionReview } from 'app/src/utils/versions';
import checkPermissions from 'app/src/utils/checkPermissions';
import isVersionActive from 'app/src/utils/isVersionActive';

import PreviewIcon from 'shared/designTokens/icons/ui/small/PreviewIcon';
import OverflowIcon from 'shared/designTokens/icons/ui/small/OverflowIcon';
import DownloadIcon from 'shared/designTokens/icons/ui/small/DownloadIcon';
import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';

import IconButton from 'app/src/components/ui/IconButton';
import FloatingMenu from 'app/src/components/ui/FloatingMenu';
import Button from 'app/src/components/ui/Button';

import { isMobile } from 'shared/vars';
import _ from 'shared/copy';

const Actions = styled.div`
	display: flex;
	flex-wrap: wrap;

	${!isMobile && `
		flex-wrap: nowrap;
		gap: 12px;
	`}
`;

const ActionsDivider = styled.div`
	width: 100%;
	height: 12px;
`;

const ActionsIcons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 12px;
`;

const MoreActions = styled.div`
	position: relative;
`;

const VersionActions = props => {
	const { game, item, extendedActions = [] } = props;
	const { id, state, created_at, reviews = [] } = item;

	const dispatch = useDispatch();

	const moreActionsRef = useRef();

	const user = useSelectUser();
	const permissions = useSelectPermissions();

	const [moreActionsActive, setMoreActionsActive] = useState(false);

	const getRevenueSharesStatus = useSelectApiStatus(getTeamRevenueShares.id);
	const revenueShares = useSelectRevenueSharesByTeamId(game.team.id);
	const activeRevenueShare = gameHasNonZeroShare(game, revenueShares);
	const status = combinedApiStatus(useSelectApiStatus(gameSetVersionActive.id), useSelectApiStatus(getGameById.id));

	const review = getLatestVersionReview(reviews);
	const isAdmin = user.role === 'admin' || user.role === 'read-only-admin';
	const showSetActive = (
		checkPermissions(permissions, [['can_edit_owned_game_tracks']])
		|| (checkPermissions(permissions, [['can_activate_external_versions']]) && game?.external_prefix?.length > 0)
		|| (checkPermissions(permissions, [['can_activate_approved_versions']]) && review?.status === 'approved')
	) && !isVersionActive(item, game) && game.approved;

	const userRelevantForNoRevShareWarning = checkPermissions(permissions, [['can_edit_owned_game_tracks'], ['can_edit_all_teams']]);
	const showNoRevShareWarning = userRelevantForNoRevShareWarning && getRevenueSharesStatus.done && !activeRevenueShare && !game.tracks.find(({ track }) => track === 'public');

	const createSetPublicVersionHandler = v => () => {
		dispatch(gameSetVersionActive.fetch({ gameId: game.id, versionId: v.id }, ({ success$ }) => (
			success$.pipe(
				map(() => openToast({ body: _`toastNewVersionActive` })),
			)
		)));
	};

	const createConfirmSetPublicVersionNoRevShareHandler = v => () => {
		dispatch(openModal({ key: 'confirm-set-public-version-no-rev-share', data: { onConfirm: createSetPublicVersionHandler(v) } }));
	};

	useEffect(() => {
		if (userRelevantForNoRevShareWarning) {
			dispatch(getTeamRevenueShares.fetch({ teamId: game.team.id }));
		}
	}, [user]);

	useEffect(() => {
		if (!moreActionsActive || !moreActionsRef.current) return;

		const click$ = fromEvent(document.body, 'click').pipe(
			tap(e => {
				if (!moreActionsRef.current.contains(e.target)) {
					setMoreActionsActive(false);
				}
			}),
		);

		const subscription = click$.subscribe();

		return () => {
			if (subscription) subscription.unsubscribe();
		};
	}, [moreActionsActive, moreActionsRef.current]);

	return (
		<Actions>
			{showSetActive && (
				<Button
					secondary
					disabled={game.tracks.length > 1 || status.pending || item.state !== 'done'}
					onClick={showNoRevShareWarning ? createConfirmSetPublicVersionNoRevShareHandler(item) : createSetPublicVersionHandler(item)}
				>
					{_`setActive`}
				</Button>
			)}
			{isMobile && (showSetActive) && <ActionsDivider />}
			<ActionsIcons>
				<IconButton
					to={`https://inspector.poki.dev/?game=poki-${id}`}
					disabled={state !== 'done'}
					title={_`openInInspector`}
					icon={SearchIcon}
					onClick={e => {
						e.preventDefault();
						trackInspectorEvent('p4d', 'open-in-inspector', { versionId: id, isAdmin, role: user.role });
						window.open(`https://inspector.poki.dev/?game=poki-${id}${isAdmin ? '&analytics=false' : ''}`, '_blank');
					}}
				/>
				<IconButton
					to={`https://poki.com/en/preview/${game.id}/${id}`}
					disabled={state !== 'done'}
					openInNewTab
					title={_`preview`}
					icon={PreviewIcon}
					onClick={() => pushEvent('gameVersions', 'previewVersion', { versionId: id, isAdmin, role: user.role })}
				/>
				<MoreActions ref={moreActionsRef}>
					<IconButton
						disabled={state !== 'done'}
						icon={OverflowIcon}
						onClick={() => {
							setMoreActionsActive(curr => !curr);
						}}
					/>
					<FloatingMenu
						active={moreActionsActive}
						xDirection="left"
						buttons={[
							{
								icon: PreviewIcon,
								name: _`previewQrCode`,
								onClick: () => dispatch(openModal({ key: 'game-preview-qr', data: { game, version: item } })),
							},
							{
								icon: DownloadIcon,
								name: _`downloadProcessedFiles`,
								onClick: () => dispatch(downloadZip.fetch({ gameId: game.id, versionId: id, type: 'hosted' })),
							},
							moment(created_at * 1000).isAfter(moment('2021-02-17')) && {
								icon: DownloadIcon,
								name: _`downloadOriginalZip`,
								onClick: () => dispatch(downloadZip.fetch({ gameId: game.id, versionId: id, type: 'source' })),
							},
							...extendedActions,
						]}
					/>
				</MoreActions>
			</ActionsIcons>
		</Actions>
	);
};

export default VersionActions;
