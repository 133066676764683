import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import GridContainer from 'app/src/components/ui/GridContainer';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectUser } from 'app/src/selectors/user';
import { useSelectGamesByTeamId } from 'app/src/selectors/game';
import Container from 'app/src/components/ui/Container';
import GameOverviewChecklistModule from 'app/src/components/modules/GameOverviewChecklistModule';
import MessageBox from 'app/src/components/ui/MessageBox';

import _ from 'shared/copy';

const StyledMessageBox = styled(MessageBox)`
	margin-bottom: 0;
`;

const GameCreatePage = () => {
	const activeTeam = useSelectActiveTeam();
	const user = useSelectUser();
	const { data: games } = useSelectGamesByTeamId(activeTeam?.id);

	return (
		<Container>
			<Helmet key={`GameCreatePage-${activeTeam.code}`}>
				<title>Add your game - Poki for Developers</title>
			</Helmet>

			<GridContainer cols={1}>
				{games?.length === 0 && (
					<StyledMessageBox
						id="welcome-game-create-page"
						persistHide
						title={_`welcomeGameTitle${{ name: user?.name }}`}
						description={(
							<span dangerouslySetInnerHTML={{ __html: _`welcomeGameDescription` }} />
						)}
					/>
				)}
				<GameOverviewChecklistModule />
			</GridContainer>
		</Container>
	);
};

export default GameCreatePage;
