import React from 'react';

const Coin1 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M23.8404 51.0384L44.4404 48.3984L54.8004 54.3184L40.8804 53.3584L23.8404 51.0384L34.1204 49.7184L23.8404 51.0384L40.8804 53.3584L54.8004 54.3184L44.4404 48.3984L34.1204 49.7184L44.4404 48.3984L87.4804 57.6784L110.68 67.4384L119.52 76.8784L113.56 91.3984L88.8404 91.9584L62.0804 87.6384L47.7204 83.7984L23.2004 71.9984L20.4004 67.5584L21.1204 64.1184L23.8404 51.0384Z" fill="#FFD100" />
			<path d="M88.8405 91.9586L62.0805 87.6386L58.5605 69.5586L92.5205 76.0786L88.8405 91.9586Z" fill="#FFC500" />
			<path d="M119.561 76.8781L113.601 91.3981L88.8809 91.9581L92.5609 76.0781L119.561 76.8781Z" fill="#FFDC00" />
			<path d="M62.0787 87.6386L58.5587 69.5586L47.7188 83.7986L62.0787 87.6386Z" fill="#FF9E00" />
			<path d="M23.8404 51.0391L20.4004 67.5591L23.2004 71.9991L28.2004 60.1191L23.8404 51.0391Z" fill="#FF9E00" />
			<path d="M23.8398 51.0384L44.4398 48.3984L87.5198 57.6784L110.72 67.4384L119.56 76.8784L92.5598 76.0784L58.5598 69.5584L28.1598 60.1184L23.8398 51.0384Z" fill="#FFD100" />
			<path d="M74.5201 61.2798L54.0802 56.8798L38.1602 56.8398L51.2802 62.6799L76.7201 68.1999L96.4001 70.1599L74.5201 61.2798Z" fill="#FFDC00" />
			<path d="M119.559 76.8797L101.119 70.5997L95.3594 64.6797L110.719 67.4397L119.559 76.8797Z" fill="#FFED85" />
			<path d="M58.5592 69.5583L51.2792 62.6783L36.8792 56.2383L28.1992 60.1183L58.5592 69.5583Z" fill="#FFC500" />
			<path d="M23.8398 51.0384L28.1998 60.1184L36.8798 56.2384L40.8798 53.3184L54.7998 54.3184L44.4398 48.3984L23.8398 51.0384Z" fill="#FFE200" />
			<path d="M78.6406 59.5606L87.4806 57.6406L110.681 67.4006L95.3206 64.6406L78.6406 59.5606Z" fill="#FFE200" />
			<path d="M96.4395 70.1586L74.5195 61.2786L78.6395 59.5586L95.3195 64.6786L101.08 70.5986L96.4395 70.1586Z" fill="#FF9E00" />
			<path d="M38.2009 56.8394L54.1209 56.8794L74.5609 61.2794L78.6809 59.5594L54.8009 54.3194L40.8809 53.3594L36.8809 56.2794L38.2009 56.8394Z" fill="#FFAF00" />
			<path d="M47.7192 83.8011L58.5592 69.5611L28.1592 60.1211L23.1992 72.0011L47.7192 83.8011Z" fill="#FFAF00" />
		</svg>
	);
};

export default Coin1;
