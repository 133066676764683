import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, tap, ignoreElements } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import { listGamesByTeamId } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import Button from 'app/src/components/ui/Button';
import AutoCompleteInput from 'app/src/components/input/AutoCompleteInput';
import TextInput from 'app/src/components/input/TextInput';
import Modal from 'app/src/components/ui/Modal';
import { validate, isRequired } from 'app/src/utils/validate';
import { registerModal } from 'app/src/modals';
import { useSelectTeamByCode, useSelectTeamCurrency } from 'app/src/selectors/team';
import DateInput from 'app/src/components/input/DateInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import _ from 'shared/copy';
import { useSelectGamesByTeamId } from 'app/src/selectors/game';
import GridContainer from 'app/src/components/ui/GridContainer';
import { createUpfrontPaymentForTeamId } from 'app/src/epics/additionalPayments';
import moment from 'moment';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const InternalNotesTextArea = styled(TextAreaInput)`
	max-width: 100%;
`;

const AddUpfrontPaymentModal = props => {
	const { data: { teamCode } } = props;

	const team = useSelectTeamByCode(teamCode);

	const dispatch = useDispatch();
	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const listGamesByTeamIdStatus = useSelectApiStatus(listGamesByTeamId.id);
	const createUpfrontPaymentForTeamIdStatus = useSelectApiStatus(createUpfrontPaymentForTeamId.id);
	const [exit$] = useState(new Subject());

	const [amount, setAmount] = useState('');
	const [startDate, setStartDate] = useState();
	const [internalNotes, setInternalNotes] = useState();
	const [gameIds, setGameIds] = useState([]);

	const { data: games = [] } = useSelectGamesByTeamId(team?.id);
	const teamCurrency = useSelectTeamCurrency(team);

	const setAndFormatAmount = value => {
		if (!value) {
			setAmount('');
			return;
		}

		const numValue = Number(value);
		if (!Number.isNaN(numValue)) {
			setAmount(numValue);
		}
	};

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: team.id }));
	}, []);

	const handleSubmit = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'upfrontPayment_team',
				value: team,
			},
			{
				field: 'upfrontPayment_amount',
				value: amount,
			},
			{
				field: 'upfrontPayment_startDate',
				value: startDate,
			},
			{
				field: 'upfrontPayment_internalNotes',
				value: internalNotes,
			},
			{
				field: 'upfrontPayment_games',
				value: gameIds,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(createUpfrontPaymentForTeamId.fetch({ teamId: team.id, amount, startDate, internalNotes, gameIds }, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	if (!team) return null;

	return (
		<Modal wide exit$={exit$}>
			<>
				<h2>{_`addUpfrontPayment`}</h2>
				<p>{_`addUpfrontPaymentDescription`}</p>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<GridContainer cols={2}>
						<TextInput
							label={_`team`}
							disabled
							name="upfrontPayment_team"
							value={team.name}
						/>
					</GridContainer>
					<GridContainer cols={2}>
						<div>
							<TextInput
								label={_`amount`}
								autoFocus
								disabled={createUpfrontPaymentForTeamId.pending}
								name="upfrontPayment_amount"
								prefix={teamCurrency === 'usd' ? '$' : '€'}
								placeholder={500}
								description={_`upfrontPaymentAmountDescription`}
								errors={errors.upfrontPayment_amount}
								value={amount}
								valueSetter={setAndFormatAmount}
								required={isRequired('upfrontPayment_amount')}
							/>
							<DateInput
								label={_`startDate`}
								name="upfrontPayment_startDate"
								disabled={createUpfrontPaymentForTeamId.pending}
								description={_`upfrontPaymentStartDateDescription`}
								errors={errors.upfrontPayment_startDate}
								value={startDate}
								valueSetter={setStartDate}
								required={isRequired('upfrontPayment_startDate')}
								minDate={moment().startOf('day')}
							/>
						</div>
						<AutoCompleteInput
							label={_`game(s)`}
							name="upfrontPayment_games"
							disabled={createUpfrontPaymentForTeamId.pending || listGamesByTeamIdStatus.pending}
							values={games.map(game => ({
								value: game.id,
								desc: game.title,
							}))}
							errors={errors.upfrontPayment_games}
							value={gameIds}
							valueSetter={setGameIds}
							required={isRequired('upfrontPayment_games')}
						/>
					</GridContainer>
					<InternalNotesTextArea
						label={_`internalNotes`}
						name="upfrontPayment_internalNotes"
						disabled={createUpfrontPaymentForTeamId.pending}
						description={_`upfrontPaymentNotesDescription`}
						errors={errors.upfrontPayment_internalNotes}
						value={internalNotes}
						valueSetter={setInternalNotes}
						required={isRequired('upfrontPayment_internalNotes')}
					/>
					<Buttons>
						<Button disabled={createUpfrontPaymentForTeamId.pending} secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={createUpfrontPaymentForTeamIdStatus.pending}>{createUpfrontPaymentForTeamIdStatus.pending ? _`creating` : _`create`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('add-upfront-payment', AddUpfrontPaymentModal);
