import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectVideoJS } from 'app/src/selectors/playtests';
import { customScrollbar, isMobile } from 'shared/vars';
import { getTimeHashFromMilliseconds, getTimeHashFromSeconds } from 'app/src/components/playtest/util/getTimeHash';

const ConsoleLogsContainer = styled.div`
	font: 12px/1.5em monospace;
	background: rgba(0, 0, 0, 0.2);
	overflow-x: hidden;
	overflow-y: scroll;
	resize: vertical;

	&:hover {
		background: rgba(0, 0, 0, 0.8);
	}

	${isMobile ? `
		max-height: 200px;
	` : `
		position: absolute;
		height: calc(100% - 30px);
		width: 400px;
		top: 0;
		right: 0;
	`}

	${customScrollbar}
	::-webkit-scrollbar {
		/* width: 12px; */
		scrollbar-width: thin;
	}
`;

const ConsoleLogsItem = styled.details`
	${props => props.logLevel === 'error' && `
		color: #ff0000;
	`}
	${props => props.logLevel === 'warn' && `
		color: #ff9900;
	`}
	${props => props.logLevel !== 'error' && props.logLevel !== 'warn' && `
		color: #ADB5BD;
	`}
	white-space: pre-wrap;
`;

const ConsoleLogsItemTimeLink = styled.span`
	color: #ffffff;

	${props => props.eventStatus === 'future' && `
		color: #999999;
	`}

	${props => props.eventStatus === 'current' && `
		color: #7fbbff;
	`}

	&:hover{
		cursor: pointer;
		text-decoration: underline;
		color: #0077ff;
	}
`;

export const ConsoleLogs = props => {
	const { data } = props;

	const [consoleItems, setConsoleItems] = useState([]);

	const videoJS = useSelector(selectVideoJS);
	const [currentTimeHash, setCurrentTimeHash] = useState(null);
	const processVideoInterval = useRef(null);
	const dataTimeHash = useRef([]);

	useEffect(() => {
		// Hash data by time
		if (!data) {
			return;
		}

		const relevantItems = data.filter(item => item.type === 'console');
		relevantItems.forEach((item, i) => {
			item.uniqueIndex = i;
		});

		setConsoleItems(relevantItems);

		dataTimeHash.current.length = 0;
		relevantItems.forEach(item => {
			const timeHash = getTimeHashFromMilliseconds(item.offset);
			if (!dataTimeHash.current[timeHash]) {
				dataTimeHash.current[timeHash] = [];
			}
			dataTimeHash.current[timeHash].push(item);
		});
	}, [data]);

	useEffect(() => {
		if (videoJS) {
			processVideoInterval.current = setInterval(() => {
				const timeHash = getTimeHashFromSeconds(videoJS.currentTime());
				if (dataTimeHash.current[timeHash]) {
					// only re-render if there is a new state to render
					setCurrentTimeHash(timeHash);
				}
			}, 50);
		}

		return () => {
			clearInterval(processVideoInterval.current);
		};
	}, [videoJS]);

	const onConsoleLogsItemClick = e => {
		const { target } = e;

		if (target.dataset.time) {
			const { currentTarget } = e;
			const { offset } = currentTarget.dataset;

			const targetOffset = offset - 500; // 100ms before the event

			videoJS.currentTime(targetOffset / 1000);
			setCurrentTimeHash(getTimeHashFromMilliseconds(targetOffset));

			e.preventDefault();
		}
	};

	const renderConsoleLogsItemSummary = ({ level, message, offset }) => {
		const timeHash = getTimeHashFromMilliseconds(offset);
		let eventStatus = 'past';
		if (currentTimeHash < timeHash) {
			eventStatus = 'future';
		} else if (currentTimeHash === timeHash) {
			eventStatus = 'current';
		}

		const offsetMinus = offset < 0 ? '-' : '';
		const offsetAsTime = new Date(Math.abs(offset)).toISOString().substr(14, 9);
		const timeLog = `[${offsetMinus}${offsetAsTime}]`;
		const consoleLog = `console.${level}`;

		const summaryLength = 35 - consoleLog.length - offsetMinus.length;
		const summaryTitle = message.length > summaryLength ? `${message.slice(0, summaryLength)}...` : message;
		return (
			<summary>
				<ConsoleLogsItemTimeLink eventStatus={eventStatus} data-time>{timeLog}</ConsoleLogsItemTimeLink>{' '}<b>{consoleLog}</b>{' - '}{summaryTitle}
			</summary>
		);
	};

	return (
		<ConsoleLogsContainer>
			{consoleItems.map(({ level, message, offset, uniqueIndex }) => (
				<ConsoleLogsItem key={`${uniqueIndex}`} logLevel={level} onClick={onConsoleLogsItemClick} data-offset={offset}>
					{renderConsoleLogsItemSummary({ level, message, offset, uniqueIndex })}
					<span>{message}</span>
				</ConsoleLogsItem>
			))}
		</ConsoleLogsContainer>
	);
};
