import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fromEvent, EMPTY, of, switchMap } from 'rxjs';

import EnterIcon from 'shared/designTokens/icons/ui/tiny/EnterIcon';
import VerifiedIcon from 'shared/designTokens/icons/ui/small/VerifiedIcon';

import history from 'app/history';
import Link from 'app/src/components/Link';
import useEpic from 'app/src/hooks/useEpic';
import { isMobile, motionSpeed01, motionSpeed02, motionOut } from 'shared/vars';
import { closeModal } from 'app/src/actions/client';
import { useDispatch } from 'react-redux';
import Tooltip from 'shared/components/Tooltip';
import _ from 'shared/copy';

const Container = styled.div`
	transition: opacity ${motionSpeed02} ${motionOut};

	${props => props.apiStatus.pending && `
	opacity: 0.5;
	`}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey1};
`;

const StyledVerifiedIcon = styled(VerifiedIcon)`
	width: 18px;
	height: 18px;
	color: ${props => (props.active ? props.theme.pureWhite : props.theme.pokiBlue)};
	vertical-align: middle;
	padding-bottom: 2px;
`;

const Subtitle = styled.div`
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};
`;

const StyledEnterIcon = styled(EnterIcon)`
	display: none;

	&& { ${/* force significance */''}
		margin-left: auto;
		margin-top: 10px;
		margin-right: 24px;
	}
`;

const Item = styled(Link)`
	background: ${props => props.theme.pureWhite};
	display: flex;
	flex-direction: row;
	border-bottom: ${props => (!props.last ? `1px solid ${props.theme.grey7}` : 'none')};
	font-size: 14px;
	transition: ${motionSpeed01} ${motionOut};

	${!isMobile ? `
	padding: 12px 0 12px 24px;
	height: 60px;
	` : `
	padding: 8px 0 8px 24px;
	height: 52px;
	`}

	&:last-of-type {
		border: none;
	}

	&:hover {
		background: ${props => props.theme.grey7}
	}

	> svg {
		margin-top: 5px;
		margin-right: 15px;
		margin-left: 1px;

		[fill] {
			fill: ${props => props.theme.pokiBlue};
		}
	}


	${props => props.active && `
	background: ${props.theme.static.pokiBlue};

	> svg [fill] {
		fill: ${props.theme.static.pureWhite};
	}

	&:hover {
		background: ${props.theme.static.pokiBlue};
	}

	${Title} {
		color: ${props.theme.static.pureWhite};
	}

	${Subtitle} {
		color: ${props.theme.static.grey5};
	}

	${StyledEnterIcon} {
		display: block;
	}
	`}
`;

const Image = styled.div`
	position: relative;
	border-radius: 8px;
	background: ${props => (props.url ? `url("${props.url}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 26px;
	width: 26px;
	margin-top: 5px;
	margin-right: 14px;
`;

const KeyboardControlledList = props => {
	const { apiStatus, isActive = true, image, title, isVerified, subtitle, itemLink, icon, items = [] } = props;

	const dispatch = useDispatch();

	const [activeItem, setActiveItem] = useState(0);

	// Set first item as active upon changing
	useEffect(() => {
		setActiveItem(0);
	}, [items]);

	// Open/close search shortcut
	useEpic(() => {
		const updateActiveItem = direction => {
			let newActiveItem = activeItem + direction;

			if (newActiveItem < 0) {
				newActiveItem = items.length - 1;
			} else if (newActiveItem > items.length - 1) {
				newActiveItem = 0;
			}

			setActiveItem(newActiveItem);
		};

		return fromEvent(window, 'keydown').pipe(
			switchMap(e => {
				if (!isActive) return EMPTY;

				const { keyCode } = e;

				if (keyCode === 38) { // ArrowUp
					updateActiveItem(-1);
					e.preventDefault();
				} else if (keyCode === 40) { // ArrowDown
					updateActiveItem(1);
					e.preventDefault();
				} else if (keyCode === 13) { // Enter
					e.preventDefault();
					const link = itemLink(items[activeItem]);

					if (!link) return EMPTY;

					history.push(link);

					return of(closeModal());
				}

				return EMPTY;
			}),
		);
	}, [isActive, activeItem, items]);

	return (
		<Container apiStatus={apiStatus}>
			{items.map((item, idx) => {
				const sub = subtitle(item);
				const img = image(item);
				const Icon = icon(item);
				const active = activeItem === idx ? 1 : 0;

				return (
					<Item
						onClick={() => dispatch(closeModal())}
						key={`${item.type}-${item.id}`}
						to={itemLink && itemLink(item)}
						last={idx === items.length - 1 ? 1 : 0}
						active={active}
					>
						{(img || !Icon) && (
							<Image url={img} />
						)}
						{Icon && <Icon />}
						<Info>
							<Title>
								{title(item)}
								{isVerified(item) && (
									<Tooltip
										placement="top"
										arrow
										content={_`verifiedDeveloper`}
									>
										<span>
											<StyledVerifiedIcon active={active} />
										</span>
									</Tooltip>
								)}
							</Title>
							<Subtitle>{sub}</Subtitle>
						</Info>
						{!isMobile && <StyledEnterIcon />}
					</Item>
				);
			})}
		</Container>
	);
};

export default KeyboardControlledList;
