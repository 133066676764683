import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Subject, merge, of, tap, switchMap, ignoreElements } from 'rxjs';
import styled from 'styled-components';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import TextInput from 'app/src/components/input/TextInput';
import formatSlug from 'app/src/utils/formatSlug';
import MultiRowInput from 'app/src/components/input/MultiRowInput';
import { useSelectDomainCollectionByName } from 'app/src/selectors/domains';
import { validate } from 'app/src/utils/validate';
import { startScreenShake } from 'app/src/actions/effects';
import { createDomainCollection, patchDomainCollection } from 'app/src/epics/domains';
import { uncaughtServerError } from 'app/src/actions/client';
import { useSelectApiStatus } from '@poki/rx-api';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const ManageDomainCollectionModal = props => {
	const { data = {} } = props;

	const isEdit = !!data.name;
	const formRef = useRef();
	const [name, setName] = useState(data.name || '');
	const [errors, setErrors] = useState({});
	const [domains, setDomains] = useState(['']);
	const [exit$] = useState(new Subject());
	const domainCollection = useSelectDomainCollectionByName(data.name);
	const dispatch = useDispatch();
	const createDomainCollectionStatus = useSelectApiStatus(createDomainCollection.id);
	const patchDomainCollectionStatus = useSelectApiStatus(patchDomainCollection.id);

	const inProgress = createDomainCollectionStatus.pending || patchDomainCollectionStatus.pending;

	useEffect(() => {
		if (domainCollection?.domains.length > 0) {
			setDomains(domainCollection.domains);
		}
	}, [domainCollection]);

	const formatDomains = () => {
		setDomains(d => d.map(domain => (
			domain
				.replace(/(https?:\/\/)?(www.)?/ig, '')
				.replace(/[^0-9a-z\-.]+?/ig, '')
		)));
	};

	const setNameAndFormat = n => {
		setName(formatSlug(n, false));
	};

	const setNameAndTrim = () => setName(formatSlug(name, true));

	const handleSubmit = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const domainsWithoutEmpty = domains.filter(d => d !== '');

		const checks = [
			{
				validate: 'domainCollection_name',
				errorKey: 'name',
				value: name,
			},
			{
				validate: 'domainCollection_domains',
				errorKey: 'domains',
				value: domainsWithoutEmpty,
			},
		];

		checks.forEach(c => {
			if (!c) return;

			const check = validate(c.validate, c.value);

			if (!check.valid) {
				err[c.errorKey] = check.messages;
			} else {
				delete err[c.errorKey];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		if (isEdit) {
			dispatch(patchDomainCollection.fetch({ name, domains: domainsWithoutEmpty }, ({ success$ }) => (
				merge(
					success$.pipe(
						tap(() => exit$.next()),
						ignoreElements(),
					),
				)
			)));
		} else {
			dispatch(createDomainCollection.fetch({ name, domains: domainsWithoutEmpty }, ({ success$, error$ }) => (
				merge(
					success$.pipe(
						tap(() => exit$.next()),
						ignoreElements(),
					),
					error$.pipe(
						switchMap(action => {
							const { payload: { result: { response } } } = action;

							if (response?.errors?.find(err => err.code === 'domain-collection-exists')) {
								setErrors({
									name: [_`domainCollectionNameAlreadyExists`],
								});

								return of(startScreenShake());
							}

							return of(uncaughtServerError({ action }));
						}),
					),
				)
			)));
		}
	};

	return (
		<Modal alignment="top" exit$={exit$}>
			<>
				<h2>{isEdit ? _`editDomainCollection` : _`addNewDomainCollection`}</h2>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<TextInput
						label={_`name`}
						autoFocus
						name="domainCollection_name"
						placeholder={_`domainCollectionNamePlaceholder`}
						disabled={isEdit || inProgress}
						value={name}
						valueSetter={setNameAndFormat}
						errors={errors.name}
						onBlur={setNameAndTrim}
					/>
					<MultiRowInput
						label={_`domains`}
						name="domainCollection_domains"
						placeholder={_`domainPlaceholder`}
						value={domains}
						valueSetter={setDomains}
						onBlur={formatDomains}
						errors={errors.domains}
						disabled={inProgress}
					/>
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button disabled={inProgress} submit>{inProgress ? _`saving` : _`save`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('manage-domain-collection', ManageDomainCollectionModal);
