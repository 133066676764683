import React from 'react';
import styled from 'styled-components';

import ChevronDownIcon from 'shared/designTokens/icons/ui/small/ChevronDownIcon';

import TriggerableMultiSelectInput from 'app/src/components/input/TriggerableMultiSelectInput';
import { motionSpeed02, motionOut } from 'shared/vars';
import _ from 'shared/copy';

const FilterSelect = styled.div`
	display: flex;
	align-items: center;
	background: ${props => props.theme.grey7};
	border: 1px solid ${props => props.theme.grey5};
	font-size: 16px;
	color: ${props => props.theme.denimBlue};
	padding: 0 8px 0 16px;
	line-height: 48px;
	height: 48px;
	border-radius: 8px;
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	font-family: 'Proxima Nova';
	cursor: pointer;

	${props => props.small && `
	line-height: 36px;
	height: 36px;
	`}

	${props => props.light && `
	background: ${props.theme.pureWhite};
	`}

	${props => props.focus && `
		outline: none;
		border-color: ${props.theme.pokiBlue};
		box-shadow: inset 0 0 0 2px ${props.theme.pokiBlue};
		background: ${props.theme.grey9};
	`}

	&:hover {
		background-color: ${props => props.theme.grey9};
	}

	svg {
		margin-left: 8px;

		[fill] {
			fill: ${props => props.theme.grey5};
		}
	}
`;

const FilterValues = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: auto;
`;

const Title = styled.span`
	margin-right: 4px;
	font-weight: bold;
	white-space: nowrap;
`;

const FilterMultiSelectInput = props => {
	const { className, name, small, light, title, value, values = [], valueSetter, onChange, cantSelectNone, countLabel } = props;

	return (
		<TriggerableMultiSelectInput
			className={className}
			name={name}
			title={title}
			trigger={(onToggle, active) => (
				<FilterSelect
					onClick={onToggle}
					small={small}
					light={light}
					focus={active}
				>
					<Title>{title}:</Title>
					<FilterValues>
						{
							(value && value.length < values.length) ? (
								value.map(v => values.find(f => f.value === v).desc).join(', ')
							) : _`all`
						}
					</FilterValues>
					<ChevronDownIcon />
				</FilterSelect>
			)}
			values={values}
			value={value}
			valueSetter={valueSetter}
			onChange={onChange}
			cantSelectNone={cantSelectNone}
			countLabel={countLabel}
		/>
	);
};

export default FilterMultiSelectInput;
