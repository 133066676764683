import _ from 'shared/copy';

const getReviewStatusText = status => {
	if (status === 'approved') {
		return _`reviewStatusApproved`;
	} else if (status === 'done') {
		return _`reviewStatusDone`;
	} else if (status === 'uploading') {
		return _`reviewStatusUploading`;
	} else if (status === 'rejected') {
		return _`reviewStatusRejected`;
	} else if (status === 'pending') {
		return _`reviewStatusPending`;
	} else if (status === 'closed') {
		return _`reviewStatusClosed`;
	} else if (status === 'error') {
		return _`somethingWentWrong`;
	}
};

export default getReviewStatusText;
