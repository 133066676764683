import React, { useState } from 'react';
import { Subject } from 'rxjs';
import styled from 'styled-components';

import Modal from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import GameTile from 'app/src/components/ui/GameTile';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';

const StyledModal = styled(Modal)`
	width: min-content;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Sizes = styled.div`
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
`;

const SizeContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 44px;
	font-size: 14px;
	line-height: 20px;

	&:last-child {
		margin-right: 0;
	}
`;

const Title = styled.div`
	font-weight: bold;
`;

const Size = props => {
	const { name, width, height, url } = props;

	const [hasLabel, setHasLabel] = useState(true);

	return (
		<SizeContainer
			key={name}
			onMouseEnter={() => setHasLabel(false)}
			onMouseLeave={() => setHasLabel(true)}
		>
			<GameTile url={getGameThumbnailUrl(url, width)} width={width} height={height} hasLabel={hasLabel} />
			<Title>{name}</Title>
			{width}px x {height}px
		</SizeContainer>
	);
};

const ThumbnailPreviewModal = props => {
	const { data: { url } } = props;

	const [exit$] = useState(new Subject());

	const sizes = [
		{
			name: _`small`,
			width: 94,
			height: 94,
		},
		{
			name: _`medium`,
			width: 204,
			height: 204,
		},
		{
			name: _`large`,
			width: 314,
			height: 314,
		},
	];

	return (
		<StyledModal
			exit$={exit$}
		>
			<h2>{_`thumbnailPreview`}</h2>
			<p>{_`thumbnailPreviewDesc`}</p>
			<Sizes>
				{sizes.map(sizeProps => <Size url={url} {...sizeProps} />)}
			</Sizes>
		</StyledModal>
	);
};

registerModal('thumbnail-preview', ThumbnailPreviewModal);
