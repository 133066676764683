import React, { useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { merge, of, Subject, switchMap, tap } from 'rxjs';
import styled from 'styled-components';

import history from 'app/history';
import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import { openToast, uncaughtServerError } from 'app/src/actions/client';
import { startScreenShake } from 'app/src/actions/effects';
import { deleteGame } from 'app/src/epics/game';
import { registerModal } from 'app/src/modals';
import TextInput from 'app/src/components/input/TextInput';
import Button from 'app/src/components/ui/Button';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const ConfirmRemoveGameModal = ({ data }) => {
	const { gameName, gameId, teamCode } = data;

	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());
	const [confirm, setConfirm] = useState('');

	const deleteGameStatus = useSelectApiStatus(deleteGame.id);

	const handleSubmit = () => {
		dispatch(deleteGame.fetch({ gameId }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					tap(() => {
						exit$.next();
					}),
					switchMap(() => (
						of(
							tap(() => {
								history.push(`/${teamCode}`);
							}),
							openToast({ body: 'Game removed successfuly' }),
						)
					)),
				),
				error$.pipe(
					tap(() => exit$.next()),
					switchMap(action => (
						of(
							startScreenShake(),
							uncaughtServerError({ action }),
						)
					)),
				),
			)
		)));
	};

	return (
		<Modal
			exit$={exit$}
			canExit={source => (source === EXIT_SOURCES.SUBJECT || !deleteGameStatus.pending)}
		>
			<h2>{_`confirmRemoveGame`}</h2>
			<p dangerouslySetInnerHTML={{ __html: _`confirmRemoveGameDesc${{ gameName }}` }} />
			<TextInput
				disabled={deleteGameStatus.pending}
				value={confirm}
				valueSetter={setConfirm}
			/>
			<Buttons>
				<Button disabled={deleteGameStatus.pending} secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
				<Button onClick={handleSubmit} submit negative disabled={confirm !== 'remove' || deleteGameStatus.pending}>{deleteGameStatus.pending ? _`removing` : _`confirmRemoval`}</Button>
			</Buttons>
		</Modal>
	);
};

registerModal('confirm-remove-game', ConfirmRemoveGameModal);
