import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { merge, Subject, EMPTY, of, map, tap, ignoreElements, switchMap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import history from 'app/history';
import { createTeam, getTeamByCode } from 'app/src/epics/team';
import { startScreenShake } from 'app/src/actions/effects';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import Modal from 'app/src/components/ui/Modal';
import { validate } from 'app/src/utils/validate';
import getRandomDeveloperDisplayName from 'app/src/utils/getRandomDeveloperDisplayName';
import { registerModal } from 'app/src/modals';
import formatSlug from 'app/src/utils/formatSlug';
import { uncaughtServerError } from 'app/src/actions/client';
import dataFormatter from 'app/src/utils/dataFormatter';
import SelectInput from 'app/src/components/input/SelectInput';
import { teamCustomerSegments } from 'shared/vars';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import _ from 'shared/copy';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const countdown = 3;

const CreateTeamModal = () => {
	const dispatch = useDispatch();

	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const [name, setName] = useState('');
	const [customerSegment, setCustomerSegment] = useState('');
	const [code, setCode] = useState('');
	const [codeValidated, setCodeValidated] = useState(false);
	const [confirmCountdown, setConfirmCountdown] = useState(-1);
	const [placeholderName] = useState(getRandomDeveloperDisplayName());
	const [placeholderCode] = useState(formatSlug(placeholderName));
	const [adjustedCodeManually, setAdjustedCodeManually] = useState(false);
	const createTeamStatus = useSelectApiStatus(createTeam.id);
	const getTeamByCodeStatus = useSelectApiStatus(getTeamByCode.id);
	const [exit$] = useState(new Subject());
	const permissions = useSelectPermissions();

	const setCodeAndFormat = c => {
		if (c.length > 0) {
			setAdjustedCodeManually(true);
		} else {
			setAdjustedCodeManually(false); // Automatically generate code again if we've cleared the field
		}

		setCode(formatSlug(c, false));
	};

	const setCodeAndTrim = () => setCode(formatSlug(code, true));

	const setNameAndCode = n => {
		setName(n);
		if (!adjustedCodeManually) { // Don't overwrite code if we've changed it manually
			setCode(formatSlug(n, true));
		}
	};

	const handleSubmit = e => {
		e.preventDefault();

		const err = { ...errors };

		let check = validate('team_name', name);

		if (!check.valid) {
			err.name = check.messages;
			dispatch(startScreenShake());
		} else {
			delete err.name;
		}

		check = validate('team_code', code);
		if (!check.valid) {
			err.code = check.messages;
			dispatch(startScreenShake());
		} else {
			delete err.code;
		}

		setErrors(err);

		if (Object.values(err).length === 0) {
			setConfirmCountdown(countdown);
		}
	};

	const handleConfirm = e => {
		e.preventDefault();

		dispatch(createTeam.fetch({ name, code, customerSegment }, ({ success$ }) => (
			success$.pipe(
				map(({ payload: { result: { response } } }) => dataFormatter.deserialize(response)),
				tap(data => {
					exit$.next();

					if (checkPermissions(permissions, [['can_read_all_teams']])) {
						history.push(`/${data.code}`);
					}
				}),
				ignoreElements(),
			)
		)));
	};

	useEffect(() => {
		if (confirmCountdown <= 0) return;

		const timeout = setTimeout(() => {
			setConfirmCountdown(confirmCountdown - 1);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [confirmCountdown]);

	useEffect(() => {
		setCodeValidated(false);

		const debouncedHandler = setTimeout(() => {
			const err = { ...errors };

			if (code === '') {
				delete err.code;
				setErrors(err);
				return;
			}

			const check = validate('team_code', code);
			if (!check.valid) {
				err.code = check.messages;
			} else {
				delete err.code;
			}

			setErrors(err);

			if (!err.code) {
				dispatch(getTeamByCode.fetch(code, ({ success$, error$ }) => (
					merge(
						success$.pipe(
							tap(() => {
								setErrors({ ...errors, code: [_`teamCodeExistsError`] });
							}),
							ignoreElements(),
						),
						error$.pipe(
							switchMap(action => {
								const { payload: { result: { status } } } = action;
								if (status === 404) {
									setCodeValidated(true);
									return EMPTY;
								}

								return of(uncaughtServerError({ action }));
							}),
						),
					)
				)));
			}
		}, 250);

		return () => {
			clearTimeout(debouncedHandler);
		};
	}, [code]);

	const submitDisabled = !name || !code || !customerSegment || !codeValidated || confirmCountdown > 0;

	return (
		<Modal exit$={exit$}>
			<>
				<h2>{_`addNewTeam`}</h2>
				<Form ref={formRef} onSubmit={confirmCountdown === 0 ? handleConfirm : confirmCountdown > 0 ? (e => e.preventDefault()) : handleSubmit}>
					<TextInput
						label={_`teamName`}
						autoFocus
						name="name"
						disabled={confirmCountdown >= 0}
						placeholder={placeholderName}
						value={name}
						valueSetter={setNameAndCode}
						errors={errors.name}
						description={_`teamNameDescription`}
					/>
					<TextInput
						label={_`teamCode`}
						name="code"
						disabled={confirmCountdown >= 0 || getTeamByCodeStatus.pending}
						placeholder={placeholderCode}
						value={code}
						valueSetter={setCodeAndFormat}
						errors={errors.code}
						description={_`teamCodeDescription`}
						descriptionHTML
						valid={codeValidated}
						onBlur={setCodeAndTrim}
						prefix="https://developers.poki.com/"
					/>
					<SelectInput
						label={_`customerSegment`}
						name="customerSegment"
						disabled={confirmCountdown >= 0}
						errors={errors.customer_segment}
						value={customerSegment}
						valueSetter={setCustomerSegment}
						placeholder={_`selectCustomerSegment`}
						values={teamCustomerSegments}
						required
					/>
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={submitDisabled || createTeamStatus.pending}>{createTeamStatus.pending ? _`creating` : confirmCountdown === 0 ? _`confirm` : confirmCountdown > 0 ? _`confirming${{ countdown: confirmCountdown }}` : _`create`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('create-team', CreateTeamModal);
