import React from 'react';

const DashedLine = React.forwardRef(props => {
	const { className } = props;

	return (
		<svg className={className} width="6" height="84" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<pattern id="dashedPattern" patternUnits="userSpaceOnUse" width="6" height="84">
					<path d="M6 0L5.42857 12L0.428571 11.493L-4.76837e-07 0.507042L6 0Z" />
					<path d="M6 17L5.42857 29L0.428571 28.493L-4.76837e-07 17.507L6 17Z" />
					<path d="M6 34L6 46H1L1.39474 34H6Z" />
					<path d="M6 51L6 62H1L1.39474 51H6Z" />
					<path d="M5.42857 67L6 79H-4.76837e-07L0.571428 67H5.42857Z" />
				</pattern>
			</defs>
			<rect width="100%" height="100%" fill="url(#dashedPattern)" />
		</svg>
	);
});

export default DashedLine;
