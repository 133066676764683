import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { merge, ignoreElements, tap } from 'rxjs';

import MailIcon from 'shared/designTokens/icons/ui/small/MailIcon';

import DiscordIcon from 'shared/components/svg/DiscordIcon';
import GoogleIcon from 'shared/components/svg/GoogleIcon';
import GithubIcon from 'shared/components/svg/GithubIcon';
import PokiLogo from 'shared/components/svg/PokiLogo';
import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import FullPageSymbolBackground from 'shared/components/FullPageSymbolBackground';

import { signinUsingEmail } from 'app/src/epics/user';
import { useSelectIsAuthenticated } from 'app/src/selectors/session';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectUser } from 'app/src/selectors/user';
import { validate } from 'app/src/utils/validate';
import getParameterByName from 'app/src/utils/getParameterByName';
import ThemeProvider from 'app/src/utils/ThemeProvider';
import getApiUrl from 'app/src/utils/getApiUrl';

import { domain, isMobile } from 'shared/vars';
import _ from 'shared/copy';

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${props => props.theme.static.pureWhite};
	padding: 50px;

	${!isMobile ? `
	position: absolute;
	left: 50%;
	top: 50%;
	width: 450px;
	transform: translate(-50%, -50%);
	border-radius: 24px;
	box-shadow: ${props => props.theme.static.boxShadowSmall};
	justify-content: space-evenly;
	` : `
	padding-top: none;
	`}
`;

const Form = styled.form`
	width: 100%;
	height: 50%;
`;

const WaitingForEmail = styled.div`
	width: 100%;
	height: 50%;
	font-size: 16px;
	text-align: center;
`;

const Trouble = styled.div`
	position: relative;
	margin-top: 32px;
	padding-top: 32px;
	color: ${props => props.theme.static.grey3};

	&::before {
		content: '';
		height: 2px;
		width: 80%;
		background: ${props => props.theme.static.grey7};
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const StyledPokiLogo = styled(PokiLogo)`
	width: 300px;
	max-width: 100%;

	${!isMobile ? `
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin: 20px;
	transform: translateX(-50%);
	` :	`
	margin-bottom: 20px;
	`}
`;

const Title = styled.h1`
	font-size: 20px;
	font-weight: bold;
	font-family: 'Proxima Nova';
	text-align: center;
	color: ${props => props.theme.static.denimBlue};
`;

const SignInButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const StyledMailIcon = styled(MailIcon)`
	width: 64px;
	height: 64px;
	margin-top: 36px;
`;

const ButtonIcon = styled.div`
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
	margin-left: -12px;
	position: relative;
	top: 1px;
`;

const SSOButton = styled(Button)`
	&:hover ${ButtonIcon} {
		[fill] {
			fill: ${props => props.theme.static.pureWhite};
		}
	}
`;

const SSOButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	> ${SSOButton}:not(:last-of-type) {
		margin-bottom: 12px;
	}

	${isMobile && `
	margin-top: 48px;
	`}
`;

const Divider = styled.div`
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin: 24px;
	margin-top: 48px;
	text-align: center;
	color: ${props => props.theme.static.grey3};
`;

const Error = styled.div`
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin-top: 24px;
	text-align: center;
	margin-bottom: 8px;
	color: ${props => props.theme.static.rose1};
`;

const StyledTitle = styled.h1`
	font: 700 36px/40px Torus;
	color: ${props => props.theme.denimBlue};
	margin: 0 0 8px;
	text-align: center;

	${isMobile && `
	font-size: 34px;
	`}
`;

const StyledSubTitle = styled.h2`
	font: 400 21px/32px Proxima Nova;
	color: ${props => props.theme.denimBlue};
	text-align: center;
`;

const SignInPage = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const user = useSelectUser();
	const isAuthenticated = useSelectIsAuthenticated();

	const [errors, setErrors] = useState();
	const [email, setEmail] = useState((getParameterByName('email', location.search) || '').replace(/[^\x00-\x7F]/g, '') || ''); // eslint-disable-line no-control-regex
	const [waitingForEmail, setWaitingForEmail] = useState(getParameterByName('waitingForEmail', location.search) !== null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const signinError = location?.state?.errorCode;

	const cliReturn = getParameterByName('cli', location.search);
	const getSSOURL = provider => {
		if (cliReturn) {
			return getApiUrl('auth', `signin/${provider}?return=${encodeURIComponent(cliReturn)}&service=pokifordevs`);
		}
		return getApiUrl('auth', `signin/${provider}?return=${encodeURIComponent(`${domain}/auth/${provider}`)}&service=pokifordevs`);
	};

	useEffect(() => {
		if (signinError) {
			dispatch(startScreenShake());
		}
	}, [!!signinError]);

	if (user && isAuthenticated && !cliReturn) {
		return (
			<Navigate to={{ pathname: `/${user.team.code}` }} />
		);
	}

	const handleSubmit = e => {
		e.preventDefault();

		const check = validate('user_email', email);

		if (!check.valid) {
			setErrors(check.messages);
			dispatch(startScreenShake());
		} else {
			setIsSubmitting(true);

			dispatch(
				signinUsingEmail.fetch(
					email,
					({ success$, error$ }) => merge(
						success$.pipe(
							tap(() => {
								setWaitingForEmail(true);
							}),
							ignoreElements(),
						),
						error$.pipe(
							tap(({ payload: { result: { response: { errors: [error] } } } }) => {
								setErrors({ ...errors, api: `${error.title}` });
								setIsSubmitting(false);
							}),
							ignoreElements(),
						),
					),
				),
			);
		}
	};

	const errorMessages = {
		'forbidden-profile': _`unknownEmailError`,
		'discord-email-not-verified': _`discordNotVerified`,
		'github-missing-email': _`githubMissingEmail`,
	};

	return (
		<ThemeProvider forceLightMode>
			{!isMobile && <FullPageSymbolBackground symbol="coins" />}
			<Helmet key="SignInPage">
				<title>Sign In - Poki for Developers</title>
			</Helmet>
			<InnerContainer>
				<StyledPokiLogo dark />

				{waitingForEmail ? (
					<WaitingForEmail>
						<StyledMailIcon />
						<Title>{_`checkYourEmailTitle`}</Title>
						<span dangerouslySetInnerHTML={{ __html: _`checkYourEmailDescription${{ email }}` }} />
						<Trouble dangerouslySetInnerHTML={{ __html: _`checkYourEmailTrouble` }} />
					</WaitingForEmail>
				) : (
					<Form onSubmit={handleSubmit}>
						<StyledTitle>{_`signIn`}</StyledTitle>
						<StyledSubTitle>{_`alreadyHaveAccount`}</StyledSubTitle>
						{signinError && (
							<Error dangerouslySetInnerHTML={{ __html: errorMessages[signinError] || _`unexpectedServerErrorShort` }} />
						)}

						<SSOButtons>
							<SSOButton
								marketingButton
								tertiary
								to={getSSOURL('google')}
							>
								<ButtonIcon>
									<GoogleIcon />
								</ButtonIcon>
								{_`signInWithX${{ provider: 'Google' }}`}
							</SSOButton>
							<SSOButton
								marketingButton
								tertiary
								to={getSSOURL('discord')}
							>
								<ButtonIcon>
									<DiscordIcon />
								</ButtonIcon>
								{_`signInWithX${{ provider: 'Discord' }}`}
							</SSOButton>
							<SSOButton
								marketingButton
								tertiary
								to={getSSOURL('github')}
							>
								<ButtonIcon>
									<GithubIcon />
								</ButtonIcon>
								{_`signInWithX${{ provider: 'Github' }}`}
							</SSOButton>
						</SSOButtons>

						<Divider>{_`signInUsingMagicLink`}</Divider>

						<TextInput
							name="email"
							placeholder={_`exampleEmail`}
							value={email}
							valueSetter={setEmail}
							errors={errors}
							disabled={isSubmitting}
						/>

						<SignInButton>
							<Button marketingButton submit disabled={isSubmitting}>{_`signIn`}</Button>
						</SignInButton>
					</Form>
				)}
			</InnerContainer>
		</ThemeProvider>
	);
};

export default SignInPage;
