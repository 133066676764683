import React from 'react';

const Diamond2 = props => {
	const { className, style } = props;

	return (
		<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140">
			<path d="M17.5996 52.44L42.3196 20.92L102.92 20L102.96 20.12L122.28 88.12L76.9596 120L33.5196 100.52L17.5996 52.44Z" fill="#17F1D8" />
			<path d="M93.5603 25.96L42.3203 20.92L102.92 20L93.5603 25.96Z" fill="#17D7D8" />
			<path d="M75.6003 40.7209L50.2803 39.6809L42.3203 20.9609L93.5603 26.0009L75.6003 40.7209Z" fill="#4DFFE1" />
			<path d="M38.7598 70.7206L50.2798 39.6406L75.5998 40.6806L79.1198 76.6806L58.8798 94.1606L42.4798 87.6806L38.7598 70.7206Z" fill="#3CF7DC" />
			<path d="M107.8 59.7988L110.72 87.9587L79.1196 76.7188L75.5996 40.7188L107.8 59.7988Z" fill="#18E9DA" />
			<path d="M17.5996 52.4409L24.8796 53.3209L50.2796 39.6409L42.3196 20.9609L17.5996 52.4409Z" fill="#A4FFEE" />
			<path d="M76.9595 119.999L58.9195 94.1988L42.5195 87.7188L33.5195 100.559L76.9595 119.999Z" fill="#17D7D8" />
			<path d="M58.9199 94.1988L79.1599 76.7188L110.76 87.9588L76.9599 119.999L58.9199 94.1988Z" fill="#15C8D1" />
			<path d="M50.2809 39.6406L38.7609 70.7206L24.8809 53.3606L50.2809 39.6406Z" fill="#17F1D8" />
			<path d="M75.5996 40.7209L107.8 59.8009L93.5996 25.9609L75.5996 40.7209Z" fill="#3CF7DC" />
			<path d="M38.7596 70.7214L42.4796 87.7214L33.4796 100.561L17.5996 52.4414L24.8796 53.3214L38.7596 70.7214Z" fill="#4DFFE1" />
			<path d="M93.559 25.9591L107.759 59.7991L110.679 87.9591L76.959 119.999L122.279 88.0791L102.919 20.0391L93.559 25.9591Z" fill="#17F1D8" />
		</svg>
	);
};

export default Diamond2;
