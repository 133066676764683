import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';
import styled, { css } from 'styled-components';
import moment from 'moment';

import { getGameEventsForGame, getGameEventCategoriesForGame, getGameEventActionsForGame, getGameEventLabelsForGame, getTotalUsersForGame } from 'app/src/epics/data';
import { useSelectDataByEpic } from 'app/src/selectors/data';
import useURLState from 'app/src/hooks/useURLState';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectTheme } from 'app/src/selectors/session';
import formatNumber from 'app/src/utils/formatNumber';

import FilterMultiSelectInput from 'app/src/components/input/FilterMultiSelectInput';
import DateRangeInput from 'app/src/components/input/DateRangeInput';
import GridContainer from 'app/src/components/ui/GridContainer';
import CodeSnippet from 'app/src/components/ui/CodeSnippet';
import Container from 'app/src/components/ui/Container';
import Chart from 'app/src/components/ui/charts/Chart';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import { dayMonthYearFormat, earliestMomentInP4D } from 'shared/vars';

import _ from 'shared/copy';

const filtersFlex = css`
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (min-width: 550px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const Filters = styled.div`
	${filtersFlex}
`;

const FiltersLeft = styled.div`
	${filtersFlex}
`;

const StyledDateRangeInput = styled(DateRangeInput)`
	@media (max-width: 550px) {
		width: 100%;
	}
`;

const InlineExample = styled.span`
	font-family: Courier, Courier new, mono;
	white-space: nowrap;
`;

const ExamplePokiSDK = styled.span`
	color: ${props => (props.theme.isDarkMode ? '#fff' : '#000')};
`;

const ExampleSnippetFunc = styled.span`
	color: ${props => props.theme.rose3};
`;

const ExampleSnippetString = styled.span`
	color: ${props => props.theme.green3};
`;

const GameEventsSubPage = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();
	const theme = useSelectTheme();

	const exampleArgs = '\'category\', \'action\', \'label\'';
	const exampleSnippet = `PokiSDK.measure(${exampleArgs})`;

	const [category, setCategory] = useURLState('category');
	const [action, setAction] = useURLState('action');
	const [label, setLabel] = useURLState('label');

	const [endDate, setEndDate] = useURLState(
		'endDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(1, 'day');
			date.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
			return date;
		})(),
	);

	const [startDate, setStartDate] = useURLState(
		'startDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(2, 'day');
			date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			return date;
		})(),
	);

	const { rows: events, total: totalEvents } = useSelectDataByEpic(getGameEventsForGame.id);
	const { rows: categories } = useSelectDataByEpic(getGameEventCategoriesForGame.id);
	const { rows: actions } = useSelectDataByEpic(getGameEventActionsForGame.id);
	const { rows: labels } = useSelectDataByEpic(getGameEventLabelsForGame.id);
	const { rows: totalUsers } = useSelectDataByEpic(getTotalUsersForGame.id);
	const getGameEventsForGameStatus = useSelectApiStatus(getGameEventsForGame.id);
	const getGameEventCategoriesForGameStatus = useSelectApiStatus(getGameEventCategoriesForGame.id);
	const getGameEventActionsForGameStatus = useSelectApiStatus(getGameEventActionsForGame.id);
	const getGameEventLabelsForGameStatus = useSelectApiStatus(getGameEventLabelsForGame.id);
	const getTotalUsersForGameStatus = useSelectApiStatus(getTotalUsersForGame.id);
	const apiStatus = combinedApiStatus(getGameEventsForGameStatus, getGameEventCategoriesForGameStatus, getGameEventActionsForGameStatus, getGameEventLabelsForGameStatus, getTotalUsersForGameStatus);

	const [page, setPage] = useURLState('page', 1);
	const [sort, setSort] = useURLState('sort', { field: 'users', direction: -1 });

	useEffect(() => {
		dispatch(getGameEventsForGame.fetch({
			gameId: game.id,
			teamId: activeTeam.id,
			category,
			action,
			label,
			from: startDate,
			to: endDate,
			sortField: sort.field,
			sortDirection: sort.direction,
		}));
	}, [game, activeTeam, startDate, endDate, category, action, label, sort]);

	useEffect(() => {
		dispatch(getGameEventCategoriesForGame.fetch({
			gameId: game.id,
			teamId: activeTeam.id,
			from: startDate,
			to: endDate,
			action,
			label,
		}));
	}, [game, activeTeam, startDate, endDate, action, label]);

	useEffect(() => {
		dispatch(getGameEventActionsForGame.fetch({
			gameId: game.id,
			teamId: activeTeam.id,
			from: startDate,
			to: endDate,
			category,
			label,
		}));
	}, [game, activeTeam, startDate, endDate, category, label]);

	useEffect(() => {
		dispatch(getGameEventLabelsForGame.fetch({
			gameId: game.id,
			teamId: activeTeam.id,
			from: startDate,
			to: endDate,
			category,
			action,
		}));
	}, [game, activeTeam, startDate, endDate, category, action]);

	useEffect(() => {
		dispatch(getTotalUsersForGame.fetch({
			gameId: game.id,
			teamId: activeTeam.id,
			from: startDate,
			to: endDate,
		}));
	}, [game, activeTeam, startDate, endDate]);

	const groupColors = [
		theme.pokiBlue,
		theme.rose1,
		theme.green1,
		theme.yellow5,
		theme.purple1,
	];

	const items = useMemo(() => {
		if (!events || !totalUsers || totalUsers.length === 0) {
			return [];
		}

		const totalCount = totalUsers[0].users;

		// Color the groups based on the category.
		const groups = events.reduce((acc, event) => {
			if (acc.includes(event.category)) {
				return acc;
			}
			return [...acc, event.category];
		}, []);

		return events.map(event => {
			const { users } = event;

			return {
				...event,
				name: [event.category, event.action, event.label].filter(Boolean).join(' - '),
				color: groupColors[groups.indexOf(event.category) % groupColors.length],
				users,
				percentile: users / totalCount,
			};
		});
	}, [events, totalUsers]);

	const colorLookup = useMemo(() => {
		if (!items) {
			return {};
		}

		return items.reduce((acc, item) => {
			acc[item.name] = item.color;
			return acc;
		}, {});
	}, [items]);

	const categoryFilterValues = useMemo(() => Object.values(categories).map(c => ({
		value: c.category,
		desc: c.category,
	})).filter(c => c.value), [categories]);

	const actionFilterValues = useMemo(() => Object.values(actions).map(a => ({
		value: a.action,
		desc: a.action,
	})).filter(a => a.value), [actions]);

	const labelFilterValues = useMemo(() => Object.values(labels).map(a => ({
		value: a.label,
		desc: a.label,
	})).filter(a => a.value), [labels]);

	return (
		<Container>
			<Helmet key="GameEventsSubPage">
				<title>Events - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<Filters>
				<FiltersLeft>
					<FilterMultiSelectInput
						name="filter-category"
						value={category}
						values={categoryFilterValues}
						title={_`category`}
						valueSetter={setCategory}
						light
						cantSelectNone
					/>
					<FilterMultiSelectInput
						name="filter-action"
						value={action}
						values={actionFilterValues}
						title={_`action`}
						valueSetter={setAction}
						light
						cantSelectNone
					/>
					<FilterMultiSelectInput
						name="filter-label"
						value={label}
						values={labelFilterValues}
						title={_`label`}
						valueSetter={setLabel}
						light
						cantSelectNone
					/>
				</FiltersLeft>
				<div>
					<StyledDateRangeInput
						allTimeStartDate={moment.unix(game.created_at)}
						minDate={earliestMomentInP4D}
						maxDate={moment.utc().subtract(1, 'day')}
						value={[startDate.format(dayMonthYearFormat), endDate.format(dayMonthYearFormat)]}
						valueSetter={([_startDate, _endDate]) => {
							setStartDate(_startDate);
							setEndDate(_endDate);
						}}
						prefix={`${_`dateRange`}:`}
						light
					/>
				</div>
			</Filters>
			<GridContainer cols={1}>
				<Card
					title={_`gameEvents`}
					noPadding
				>
					<Table
						title={_`gameEvents`}
						isLoading={!apiStatus.done}
						items={items}
						page={page}
						perPage={16}
						setPagination={setPage}
						autoPaging
						setSort={setSort}
						sortField={sort.field}
						sortDirection={sort.direction}
						totalItems={totalEvents}
						columns={[
							{
								title: 'Category',
								width: 'auto',
								content: ({ item }) => item.category,
								sortField: 'category',
								defaultSortDirection: 1,
							},
							{
								title: 'Action',
								width: 'auto',
								content: ({ item }) => item.action,
								sortField: 'action',
								defaultSortDirection: 1,
							},
							{
								title: 'Label',
								width: 'auto',
								content: ({ item }) => item.label,
								sortField: 'label',
								defaultSortDirection: 1,
							},
							{
								title: 'Users',
								width: 'auto',
								content: ({ item }) => formatNumber(item.users),
								sortField: 'users',
								defaultSortDirection: -1,
							},
							{
								title: 'Percentage',
								longTitle: 'Percentage of total users during this period.',
								width: 'auto',
								content: ({ item }) => `${Math.round(item.percentile * 1000) / 10}%`,
							},
						]}
					/>
				</Card>
			</GridContainer>
			<GridContainer cols={1}>
				<Card
					title={_`gameEventsGraph`}
				>
					<Chart
						type="bar"
						stacked
						apiStatus={apiStatus}
						data={items}
						getGroupedBy={d => d.name}
						getGroupName={d => d.name}
						getGroupColor={v => colorLookup[v]}
						keyOrder={['percentile', 'users', 'name']}
						xAxis={{
							type: 'text',
							displayName: 'Event',
							field: 'name',
						}}
						yAxis={{
							type: 'percentile',
							displayName: 'Players',
							field: 'percentile',
						}}
					/>
				</Card>
			</GridContainer>
			<GridContainer cols={1}>
				<Card
					title={_`gameEventIntegrationGuide`}
				>
					<p>
						You can measure events using the following SDK function.
						Please replace <ExampleSnippetString>category</ExampleSnippetString>, <ExampleSnippetString>action</ExampleSnippetString>, and <ExampleSnippetString>label</ExampleSnippetString> with tactfully chosen values.
						For example the start of a level could be measured using <InlineExample><ExamplePokiSDK>PokiSDK.</ExamplePokiSDK><ExampleSnippetFunc>measure</ExampleSnippetFunc>(<ExampleSnippetString>&apos;level&apos;, &apos;start&apos;, &apos;1&apos;</ExampleSnippetString></InlineExample>)
					</p>

					<CodeSnippet
						hasCopy
						textToCopy={exampleSnippet}
						content={(
							<>
								PokiSDK.<ExampleSnippetFunc>measure</ExampleSnippetFunc>
								(<ExampleSnippetString>{exampleArgs}</ExampleSnippetString>)
							</>
						)}
					/>
				</Card>
			</GridContainer>
		</Container>
	);
};

export default GameEventsSubPage;
