import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { createGoogleDriveFolderForGame, getGameById, listGamesByTeamId, patchGame, syncGame, gameSetVersionActive } from 'app/src/epics/game';
import { useSelectUser } from 'app/src/selectors/user';
import { useSelectGame } from 'app/src/selectors/game';
import { isReleasedForDeveloper } from 'app/src/utils/game';
import { useSelectActiveTeam } from 'app/src/selectors/team';

import GridContainer from 'app/src/components/ui/GridContainer';
import Container from 'app/src/components/ui/Container';
import HeroGame from 'app/src/components/HeroGame';
import GameNotifications from 'app/src/components/GameNotifications';
import useActionCounter from 'app/src/hooks/useActionCounter';
import RealTimeCounter from 'app/src/components/ui/RealTimeCounter';
import Block2 from 'shared/components/svg/symbols/Block2';
import Coin3 from 'shared/components/svg/symbols/Coin3';
import Diamond2 from 'shared/components/svg/symbols/Diamond2';
import Heart3 from 'shared/components/svg/symbols/Heart3';
import Star3 from 'shared/components/svg/symbols/Star3';
import EmptyMessage from 'app/src/components/ui/EmptyMessage';

import { isMobile } from 'shared/vars';
import _ from 'shared/copy';

const GamePage = props => {
	const { subComponent: SubComponent, eventPath } = props;

	const activeTeam = useSelectActiveTeam();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { gameId } = useParams();

	const game = useSelectGame(gameId);
	const user = useSelectUser();

	const isOverviewPage = eventPath === 'game';
	const isDeveloperSupport = user.role === 'developer-support';

	const number = Number(game?.id.replace(/[^0-9]/g, ''));
	const symbols = [Coin3, Diamond2, Star3, Heart3, Block2];
	const Symbol = symbols[number % symbols.length];

	const actionCounter = useActionCounter(
		syncGame.success.type,
		patchGame.success.type,
		createGoogleDriveFolderForGame.success.type,
		gameSetVersionActive.success.type,
	);

	useEffect(() => {
		dispatch(getGameById.fetch(gameId));
	}, [actionCounter, gameId]);

	useEffect(() => {
		if (!game) return;

		if (game.team.id !== activeTeam.id) {
			navigate(`/${game.team.code}/games/${game.id}`);
			return;
		}

		dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
	}, [game?.id]);

	// Don't render the counter if the game is not released.
	const renderCounter = isOverviewPage && isReleasedForDeveloper(game);

	return (
		<Container>
			{isDeveloperSupport && isOverviewPage ? (
				<EmptyMessage
					type="page"
					title={_`welcomeToYourDashboard`}
					desc={_`dashboardEmptyStateDesc`}
				/>
			) : (
				<>
					<GridContainer cols={1}>
						<HeroGame renderCounter={renderCounter} game={game} number={number} symbols={symbols} symbol={Symbol} />
						{(isMobile && renderCounter) && <RealTimeCounter key={game?.id} colorNumber={number % symbols.length} game={game} />}
						<GameNotifications game={game} />
					</GridContainer>
					{game && <SubComponent game={game} />}
				</>
			)}
		</Container>
	);
};

export default GamePage;
