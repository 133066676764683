import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, tap, map } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import { getGameById, createGameVersion } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectGame } from 'app/src/selectors/game';
import Button from 'app/src/components/ui/Button';
import ProgressBar from 'app/src/components/ui/ProgressBar';
import FileInput from 'app/src/components/input/FileInput';
import prettyFileSize from 'app/src/utils/prettyFileSize';
import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import { registerModal } from 'app/src/modals';
import _ from 'shared/copy';
import TextInput from 'app/src/components/input/TextInput';
import { isRequired, validate } from 'app/src/utils/validate';
import CheckBoxInput from 'app/src/components/input/CheckBoxInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import SlideOut from 'app/src/components/ui/SlideOut';
import WarningMessage from 'app/src/components/ui/WarningMessage';

const StyledModal = styled(Modal)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Form = styled.form`
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`;

const UploadText = styled.div`
	font-weight: bold;
	margin-bottom: 10px;
`;

const UploadProgressBar = styled(ProgressBar)`
	width: 100%;
	transition: opacity 0.2s ease-out;

	${props => props.inactive && `
	opacity: 0.1;
	`}
`;

const Buttons = styled.div`
	margin-top: auto;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const StyledFileInput = styled(FileInput)`
	${props => (props.$hidden ? `
	display: none;
	` : '')}
`;

const OptimizeImagesWarning = styled(WarningMessage)`
	margin-top: -10px;
`;

const GameCreateVersionModal = props => {
	const { data: { gameId } } = props;

	const dispatch = useDispatch();
	const formRef = useRef();
	const game = useSelectGame(gameId);
	const [errors, setErrors] = useState({});
	const [optimizeImages, setOptimizeImages] = useState(true);
	const [file, setFile] = useState();
	const [label, setLabel] = useState('');
	const [notes, setNotes] = useState('');
	const [isUploading, setIsUploading] = useState();
	const [exit$] = useState(new Subject());

	const createGameVersionStatus = useSelectApiStatus(createGameVersion.id);

	useEffect(() => {
		dispatch(getGameById.fetch(gameId));
	}, []);

	useEffect(() => {
		const { error } = createGameVersionStatus;

		if (error) {
			setIsUploading(false);

			const err = {
				error: error.response?.error || _`unknown`,
				message: error.response?.message || _`unknown`,
			};

			setErrors({
				...errors,
				version_file: [_`serverError${err}`],
			});

			dispatch(startScreenShake());
		}
	}, [createGameVersionStatus]);

	const handleUpload = () => {
		const err = { ...errors };
		delete err.version_file;

		setErrors(err);
	};

	const handleSubmit = e => {
		e.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'version_file',
				value: file,
			},
			{
				field: 'version_label',
				value: label,
			},
			{
				field: 'version_notes',
				value: notes,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		const formData = new FormData();
		formData.set('file', file); // Ensure file is set even if it was drag-and-dropped
		formData.set('notes', notes);
		formData.set('label', label);

		if (!optimizeImages) {
			formData.set('disable-image-compression', 'true');
		}

		setIsUploading(true);

		dispatch(
			createGameVersion.fetch({ gameId: game.id, formData }, ({ success$: _success$ }) => (
				_success$.pipe(
					tap(() => {
						exit$.next();
					}),
					map(() => getGameById.fetch(gameId)),
				)
			)),
		);
	};

	const { progress, progressEvent } = createGameVersionStatus;

	if (!game) return null;

	const submitDisabled = isUploading || !file;

	return (
		<StyledModal exit$={exit$} canExit={source => !isUploading || source === EXIT_SOURCES.SUBJECT}>
			<h2>{_`createVersion`}</h2>
			<Form ref={formRef} onSubmit={handleSubmit}>
				{isUploading && (
					progressEvent && (
						<>
							<UploadText>
								{_`uploading`} {prettyFileSize(progressEvent.loaded)} / {prettyFileSize(progressEvent.total)}
							</UploadText>
							<UploadProgressBar progress={progress} inactive={progress >= 1} />
						</>
					)
				)}
				<StyledFileInput
					label={_`uploadAZipOrFolder`}
					name="version_file"
					accept="application/zip, application/x-zip-compressed"
					webkitdirectory=""
					directory=""
					multiple
					errors={errors.version_file}
					onUpload={handleUpload}
					value={file}
					valueSetter={setFile}
					required={isRequired('version_file')}
					$hidden={isUploading}
				/>
				{!isUploading && (
					<>
						<TextInput
							label={_`name`}
							name="version_label"
							placeholder={file?.name || _`setACustomLabel`}
							required={isRequired('version_label')}
							value={label}
							errors={errors.version_label}
							valueSetter={setLabel}
						/>
						<TextAreaInput
							label={_`versionNotes`}
							name="version_notes"
							placeholder={_`versionNotesPlaceholder`}
							required={isRequired('version_notes')}
							value={notes}
							errors={errors.version_notes}
							valueSetter={setNotes}
						/>
						<SlideOut
							title={_`advancedOptions`}
						>
							<CheckBoxInput
								text={_`optimizeImages`}
								name="optimize_images"
								value={optimizeImages}
								valueSetter={setOptimizeImages}
								description={_`optimizeImagesDescription`}
								isBox
							/>
							{!optimizeImages && (
								<OptimizeImagesWarning message={_`optimizeImagesWarning`} />
							)}
						</SlideOut>
					</>
				)}
				<Buttons>
					<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
					<Button submit disabled={submitDisabled}>
						{isUploading ? _`uploading` : _`upload`}
					</Button>
				</Buttons>
			</Form>
		</StyledModal>
	);
};

registerModal('create-game-version', GameCreateVersionModal);
