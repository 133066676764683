import 'shared/wdyr';

import 'array-flat-polyfill';
import 'app/src/utils/uncaughtErrorApiEpicPatch';

import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
/*
HistoryRouter is unstable because it uses an internal history package that might differ from the one we use
Should not give any errors, but good to keep an eye on react-router-dom and upgrade when possible.
*/
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import Root from 'app/src/components/Root';
import history from 'app/history';
import { hydrated } from 'app/src/actions/client';
import { initialState as initialStatePersistedSession } from 'app/src/reducers/persistedSession';
import { initializeStore } from 'app/src/store';
import { prep as prepEmojiHover } from 'shared/utils/emojiHover';

// Hot Reloading
if (module.hot) module.hot.accept();

const container = document.getElementById('app-root');
const root = createRoot(container);

// Debug variables
const store = initializeStore({
	persistedStates: [
		{
			key: 'persistedSession',
			initialState: initialStatePersistedSession,
		},
	],
});
window.store = store;

// Render
root.render(
	<Provider store={store}>
		<HistoryRouter history={history}>
			<Root />
		</HistoryRouter>
	</Provider>,
);

store.dispatch(hydrated());

prepEmojiHover();
