import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Tooltip from 'app/src/components/ui/Tooltip';
import formatNumber from 'app/src/utils/formatNumber';
import Table from 'app/src/components/ui/Table';
import _ from 'shared/copy';
import GamesTooltip from 'app/src/components/GamesTooltip';
import { dayMonthYearTimeFormat } from 'shared/vars';
import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import Flag from 'app/src/components/ui/Flag';

const Type = styled.div`
	display: flex;
	flex-direction: column;
`;

const Details = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: ${props => props.theme.grey3};
`;

const itemTypeToName = type => {
	switch (type) {
		case 'refer-a-friend':
			return _`referAFriend`;
		case 'license-fee':
			return _`licenseFee`;
		case 'donate-all-earnings':
			return _`donateAllEarnings`;
		case 'donate-certain-amount':
			return _`donateCertainAmount`;
		case 'revenue-correction':
			return _`revenueCorrection`;
		case 'server-costs':
			return _`serverCosts`;
		case 'manual-adjustment':
			return _`manualAdjustment`;
		case 'poki-community-ambassador':
			return _`pokiCommunityAmbassador`;
		case 'industry-sponsorship':
			return _`industrySponsorship`;
		case 'contractual-milestone':
			return _`contractualMilestone`;
		default:
			return _`productionCosts`;
	}
};

const OneOffPaymentsTable = props => {
	const { oneOffPayments, isLoading, adminView, sortField, sortDirection, setSort, setPagination, page, perPage, totalItems } = props;

	return (
		<Table
			isLoading={isLoading}
			items={oneOffPayments}
			setSort={setSort}
			sortField={sortField}
			sortDirection={sortDirection}
			setPagination={setPagination}
			page={page}
			perPage={perPage}
			totalItems={totalItems}
			columns={[
				{
					title: _`dateSent`,
					content: ({ item }) => moment(item.send_date * 1000).format(dayMonthYearTimeFormat),
					sortField: 'send_date',
				},
				adminView ? {
					title: _`team`,
					content: ({ item }) => (
						adminView ? (
							<TeamNameWithFlag team={item.team} overrideLink={`/${item.team.code}/settings/additional-payments`} />
						) : (
							item.team.name
						)
					),
				} : undefined,
				{
					title: _`amount`,
					content: ({ item }) => {
						const amountString = item.type === 'donate-all-earnings'
							? _`allEarnings`
							: `${item.currency === 'usd' ? '$' : '€'}${formatNumber(Number(item.amount / 100), { allowDecimals: true })}`;

						return (
							<>
								{adminView ? <Flag countryCode={item.team.billing?.country} /> : null}
								{item.internal_notes ? (
									<Tooltip
										text={amountString}
										title={_`internalNotes`}
										body={item.internal_notes}
									/>
								) : amountString}
							</>
						);
					},
					sortField: 'amount',
				},
				{
					title: _`type`,
					content: ({ item }) => {
						if (item.type === 'refer-a-friend') {
							return (
								<Type>
									{itemTypeToName(item.type)}
									<Details>{item.target_team.name}</Details>
								</Type>
							);
						} else if (item.type === 'license-fee' || item.type === 'production-costs' || item.type === 'revenue-correction' || item.type === 'server-costs' || item.type === 'manual-adjustment') {
							return (
								<Type>
									{itemTypeToName(item.type)}
									<Details><GamesTooltip games={item.games} /></Details>
								</Type>
							);
						} else if (item.type === 'donate-all-earnings' || item.type === 'donate-certain-amount' || item.type === 'contractual-milestone' || item.type === 'poki-community-ambassador' || item.type === 'industry-sponsorship') {
							return (
								<Type>
									{itemTypeToName(item.type)}
								</Type>
							);
						}

						return _`unknown`;
					},
					sortField: 'type',
				},
			]}
		/>
	);
};

export default OneOffPaymentsTable;
