import React from 'react';

const SpinnerIcon = props => {
	const { className } = props;

	return (
		<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.8952 5.74052C11.1293 4.52399 12.8706 4.52399 13.1047 5.74052L14.0048 10.4175C14.0525 10.6654 14.1822 10.89 14.373 11.0553L17.9733 14.1733C18.9098 14.9843 18.0392 16.4923 16.8686 16.0867L12.3682 14.5278C12.1296 14.4451 11.8702 14.4451 11.6317 14.5278L7.13128 16.0867C5.96068 16.4923 5.09007 14.9843 6.02655 14.1733L9.62689 11.0553C9.81773 10.89 9.94743 10.6654 9.99513 10.4175L10.8952 5.74052Z" fill="white" />
		</svg>
	);
};

export default SpinnerIcon;
