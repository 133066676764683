import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import styled, { keyframes, css } from 'styled-components';
import moment from 'moment';

import Button from 'app/src/components/ui/Button';
import _ from 'shared/copy';
import ChevronDownIcon from 'shared/designTokens/icons/ui/small/ChevronDownIcon';
import ChevronRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';
import UploadIcon from 'shared/designTokens/icons/ui/tiny/UploadIcon';
import CheckIcon from 'shared/designTokens/icons/ui/tiny/CheckIcon';
import ChecklistIcon from 'shared/designTokens/icons/ui/tiny/ChecklistIcon';
import ClipboardIcon from 'shared/designTokens/icons/ui/tiny/ClipboardIcon';
import WrenchIcon from 'shared/designTokens/icons/ui/tiny/WrenchIcon';
import { createGameVersion, gameSetVersionActive, listAllVersionsByGameId, patchGame } from 'app/src/epics/game';
import { cancelReviewRequest, createReviewRequest, getReviewsForGame } from 'app/src/epics/review';
import useActionCounter from 'app/src/hooks/useActionCounter';
import { useSelectAllVersionListByGameId } from 'app/src/selectors/game';
import { useSelectReviewsByGameId } from 'app/src/selectors/review';
import { useSelectTheme } from 'app/src/selectors/session';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import getTimeSince from 'app/src/utils/getTimeSince';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import { dayMonthYearTimeFormat } from 'shared/vars';

const notifications = [
	// Upload your first version
	{
		id: 'upload-first-version',
		activeCheck: ({ game, versions }) => (
			(game && game.approved)
			// No versions yet
			&& versions.length === 0
		),
		icon: UploadIcon,
		title: 'Upload your first version',
		content: ({ game }) => <><strong>{game.title}</strong> needs a version, what are you waiting for?</>,
		actions: ({ game, location }) => {
			const to = `/${game.team.code}/games/${game.id}/versions`;
			if (location.pathname === to) return null;
			return <Button to={to} primary>{_`goToVersions`}</Button>;
		},
		bgColor: theme => theme.green8,
		color: theme => theme.pokiBlue,
		alwaysOpen: true,
	},
	// Changes requested
	{
		id: 'changes-requested',
		activeCheck: ({ permissions, lastReview }) => (
			checkPermissions(permissions, [['can_create_owned_reviews', 'can_read_owned_reviews']])
			// Last review has changes requested and isn't seen yet
			&& (lastReview && (lastReview.status === 'rejected' && !lastReview.seen_by_developer))
		),
		icon: WrenchIcon,
		title: 'Changes requested on version',
		content: ({ lastReview }) => (
			<>Your version <strong>{getVersionLabel(lastReview.version)}</strong> still needs some work, please check out the report for details and submit an updated version for review.</>
		),
		actions: ({ game, location }) => {
			const to = `/${game.team.code}/games/${game.id}/versions`;
			if (location.pathname === to) return null;
			return <Button to={to} primary>{_`goToVersions`}</Button>;
		},
		bgColor: theme => theme.rose3,
		color: theme => theme.static.pureWhite,
	},
	// Approved
	{
		id: 'approved',
		activeCheck: ({ permissions, lastReview }) => (
			checkPermissions(permissions, [['can_create_owned_reviews', 'can_read_owned_reviews']])
			// The last review on this game is an approved review that wasn't seen yet
			&& (lastReview && (lastReview.status === 'approved' && !lastReview.seen_by_developer))
		),
		icon: CheckIcon,
		title: 'Version approved!',
		content: ({ lastReview }) => (
			<>We&apos;ve approved <strong>{getVersionLabel(lastReview.version)}</strong>. Nice work!</>
		),
		actions: ({ game, location }) => {
			const to = `/${game.team.code}/games/${game.id}/versions`;
			if (location.pathname === to) return null;
			return <Button to={to} primary>{_`goToVersions`}</Button>;
		},
		bgColor: theme => theme.green2,
		color: theme => theme.static.pureWhite,
	},
	// Submit version for review
	{
		id: 'submit-version-for-review',
		activeCheck: ({ permissions, versions, reviewsForGame, game }) => (
			checkPermissions(permissions, [['can_create_owned_reviews', 'can_read_owned_reviews']])
			// Has versions
			&& versions.length > 0
			// Has been approved
			&& (game && game.approved)
			// Has no non-closed reviews
			&& !reviewsForGame.some(review => review.status !== 'closed')
		),
		icon: ChecklistIcon,
		title: 'Send us your version to be reviewed',
		content: ({ game }) => <>We can&apos;t wait to test <strong>{game.title}</strong>. Request a review on one of your versions!</>,
		actions: ({ game, location }) => {
			const to = `/${game.team.code}/games/${game.id}/versions`;
			if (location.pathname === to) return null;
			return <Button to={to} primary>{_`goToVersions`}</Button>;
		},
		bgColor: theme => theme.green8,
		color: theme => theme.pokiBlue,
	},
	// Version pending review
	{
		id: 'version-pending-review',
		activeCheck: ({ permissions, lastReview }) => (
			checkPermissions(permissions, [['can_create_owned_reviews', 'can_read_owned_reviews']])
			// Last review is pending
			&& (lastReview && (lastReview.status === 'pending'))
		),
		icon: ClipboardIcon,
		title: 'Version pending review',
		content: ({ lastReview }) => (
			<>You requested a review on <strong>{getVersionLabel(lastReview.version)}</strong> about <strong title={moment(lastReview.created_at * 1000).format(dayMonthYearTimeFormat)}>{_`timeAgo${{ timeSince: getTimeSince(lastReview.created_at * 1000) }}`}</strong>. We&apos;ll get back to you ASAP.</>
		),
		actions: ({ game, location }) => {
			const to = `/${game.team.code}/games/${game.id}/versions`;
			if (location.pathname === to) return null;
			return <Button to={to} primary>{_`goToVersions`}</Button>;
		},
		bgColor: theme => theme.pokiBlue,
		color: theme => theme.static.pureWhite,
		animatePending: true,
	},
];

const Container = styled.div`
	border-radius: 8px;
	display: flex;
	width: 100%;
	overflow: hidden;
	box-shadow: ${props => props.theme.boxShadowSmall};
	flex-direction: column;
	margin-top: -12px;
`;

const backgroundAnimation = keyframes`
	0% {
		background-position-x: 0;
	}

	100% {
		background-position-x: -28px;
	}
`;

const Title = styled.div`
	color: ${props => props.$color};
	font-weight: bold;
	display: flex;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;
	user-select: none;
	${props => (!props.$alwaysOpen ? 'cursor: pointer;' : '')}

	svg [fill] {
		fill: ${props => props.$color};
	}

	svg:last-child {
		margin-left: auto;
	}

	${props => (props.$animatePending ? css`
	background: repeating-linear-gradient(
		-45deg,
		#ffffff11,
		#ffffff11 10px,
		#ffffff00 10px,
		#ffffff00 20px
	);

	background-size: 200%;
	animation: ${backgroundAnimation} 1.5s linear infinite;
	` : '')}

	background-color: ${props => props.$bgColor};
`;

const ContentContainer = styled.div`
	display: ${props => (props.$active ? 'flex' : 'none')};
	padding: 16px 18px;
	align-items: center;
	background: ${props => props.theme.pureWhite};
	min-height: 68px;
`;

const Content = styled.div`
`;

const Actions = styled.div`
	margin-left: auto;
`;

const Notification = props => {
	const { game, notification, reviewsForGame, lastReview } = props;

	const theme = useSelectTheme();
	const location = useLocation();

	const [active, setActive] = useState(notification.alwaysOpen);

	const bgColor = notification.bgColor(theme);
	const color = notification.color(theme);

	return (
		<Container
			key={notification.id}
		>
			<Title
				$bgColor={bgColor}
				$color={color}
				$alwaysOpen={notification.alwaysOpen}
				$animatePending={notification.animatePending}
				onClick={!notification.alwaysOpen ? () => setActive(!active) : null}
			>
				{notification.icon && <notification.icon />}
				<span>{notification.title}</span>
				{!notification.alwaysOpen && (active ? <ChevronDownIcon /> : <ChevronRightIcon />)}
			</Title>
			<ContentContainer $active={active}>
				<Content>
					<notification.content game={game} reviewsForGame={reviewsForGame} lastReview={lastReview} />
				</Content>
				{notification.actions && (
					<Actions>
						<notification.actions game={game} location={location} lastReview={lastReview} />
					</Actions>
				)}
			</ContentContainer>
		</Container>
	);
};

const GameNotifications = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const { data: versions = [] } = useSelectAllVersionListByGameId(game?.id);
	const { data: reviewsForGame = [] } = useSelectReviewsByGameId(game?.id);
	const permissions = useSelectPermissions();

	const listAllVersionsByGameIdStatus = useSelectApiStatus(listAllVersionsByGameId.id);
	const getReviewsForGameStatus = useSelectApiStatus(getReviewsForGame.id);

	const apiStatus = combinedApiStatus(listAllVersionsByGameIdStatus, getReviewsForGameStatus);

	// Some actions need to refresh our notifications
	const actionCounter = useActionCounter(
		createGameVersion.success.type,
		createReviewRequest.success.type,
		cancelReviewRequest.success.type,
		patchGame.success.type,
		gameSetVersionActive.success.type,
	);

	useEffect(() => {
		if (!game) return;

		dispatch(listAllVersionsByGameId.fetch({ gameId: game.id }));

		if (checkPermissions(permissions, [['can_create_owned_reviews', 'can_read_owned_reviews']])) {
			dispatch(getReviewsForGame.fetch({ gameId: game.id }));
		}
	}, [game?.id, actionCounter, permissions]);

	const lastReview = useMemo(() => {
		if (!reviewsForGame.length) return null;
		return reviewsForGame.filter(review => review.status !== 'closed').sort((a, b) => b.createdAt - a.createdAt)[0];
	}, [reviewsForGame]);

	const activeNotifications = useMemo(() => (
		notifications.filter(notification => notification.activeCheck({ permissions, game, versions, reviewsForGame, lastReview }))
	), [permissions, game, reviewsForGame, versions, lastReview]);

	if (!game || apiStatus.pending) return null;

	return activeNotifications.map(notification => (
		<Notification
			game={game}
			notification={notification}
			reviewsForGame={reviewsForGame}
			lastReview={lastReview}
		/>
	));
};

export default GameNotifications;
