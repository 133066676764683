import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSelectApiStatus } from '@poki/rx-api';
import { fromEvent, EMPTY, switchMap } from 'rxjs';

import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';
import UserIcon from 'shared/designTokens/icons/ui/small/UserIcon';
import UsersIcon from 'shared/designTokens/icons/ui/small/UsersIcon';

import { useSelectSearchResults } from 'app/src/selectors/search';
import { clearSearchResults } from 'app/src/actions/search';
import { useSelectUser } from 'app/src/selectors/user';
import { registerModal } from 'app/src/modals';
import { search } from 'app/src/epics/search';
import { isMobile } from 'shared/vars';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import useEpic from 'app/src/hooks/useEpic';

import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import KeyboardControlledList from 'app/src/components/ui/KeyboardControlledList';
import TextInput from 'app/src/components/input/TextInput';

import _ from 'shared/copy';

const StyledModal = styled(Modal)`
	padding: 0;
`;

const InputContainer = styled.div`
	padding: 24px 24px 0;
`;

const Search = () => {
	const dispatch = useDispatch();

	const [query, setQuery] = useState('');
	const user = useSelectUser();

	const searchStatus = useSelectApiStatus(search.id);
	const searchResults = useSelectSearchResults();

	const canExit = useCallback(
		source => (source === EXIT_SOURCES.CLICK_OUTSIDE || source === EXIT_SOURCES.EXIT_BUTTON || query.length === 0),
		[query],
	);

	useEffect(() => () => {
		dispatch(clearSearchResults());
	}, []);

	// Actually search, debounced
	useEffect(() => {
		if (query.trim().length < 3) return;

		const debouncedHandler = setTimeout(() => {
			dispatch(search.fetch(query));
		}, 200);

		return () => {
			clearTimeout(debouncedHandler);
		};
	}, [query]);

	// ESC to clear
	useEpic(() => (
		fromEvent(window, 'keydown').pipe(
			switchMap(e => {
				const { keyCode } = e;
				if (keyCode === 27) { // ESC
					setQuery('');
					e.preventDefault();
					return EMPTY;
				}

				return EMPTY;
			}),
		)
	), [query]);

	return (
		<StyledModal alignment="top" canExit={canExit} hideExitButton ignoreFocusCheck>
			<InputContainer>
				<TextInput
					icon={SearchIcon}
					valueSetter={setQuery}
					value={query}
					placeholder={_`productSearchPlaceholder`}
					autoFocus
					description={!isMobile ? (query.length > 0 ? _`pressEscToClear` : _`pressEscToQuit`) : null}
					shortDesc
				/>
			</InputContainer>
			{searchResults?.length > 0 && (
				<KeyboardControlledList
					apiStatus={searchStatus}
					items={searchResults}
					itemLink={item => {
						switch (item.type) {
							case 'teams':
								return `/${item.code}`;

							case 'games':
								if (!item.team) return null;
								return `/${item.team.code}/games/${item.id}`;

							case 'users':
								if (!item.team) return null;
								return `/${item.team.code}/settings/users`;
						}
					}}
					image={item => {
						switch (item.type) {
							case 'games':
								return getGameThumbnailUrl(item.thumbnail_url, 26);

							case 'users':
								return null;

							case 'teams':
								return null;
						}
					}}
					icon={item => {
						switch (item.type) {
							case 'games':
								return null;

							case 'users':
								return UserIcon;

							case 'teams':
								return UsersIcon;
						}
					}}
					title={item => {
						switch (item.type) {
							case 'teams':
								return item.name;

							case 'users':
								return item.name || item.email;

							case 'games':
								return item.title;
						}
					}}
					isVerified={item => {
						if (user.role !== 'admin') {
							return false;
						}

						switch (item.type) {
							case 'teams':
								return item.verified;

							case 'users':
								return item.team.verified;

							case 'games':
								return item.team.verified;
						}
					}}
					subtitle={item => {
						switch (item.type) {
							case 'games':
							case 'users':
								return item.team?.name;

							case 'teams':
								return null;
						}
					}}
				/>
			)}
		</StyledModal>
	);
};

registerModal('search', Search);
